import React, { useState, useEffect } from 'react';
import MainButton from "../../../components/MainButton";
import styled from "styled-components";
import colors from "../../../styles/colors";
import {connect} from "react-redux";
import {getAlerts, updateAlert} from "../../../actions/alerts";
import Notification from "../../../components/Notification";

const PageWrapper = styled.div`
  padding-top: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const StyledTextArea = styled.textarea`
  width: 600px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid ${colors.darkGray};
  display: block;
  margin-bottom: 15px;
`;

const NotificationSuccess = styled.div`
  padding: 20px 0;
`;

const AspenAlert = (props) => {
    const [formValue, setFormValue] = useState('');

    useEffect(() => {
        getAlerts();
    }, []);

    useEffect(() => {
        // getAlerts();
        if (props.alerts && props.alerts.length > 0) {
            setFormValue(props.alerts[0].body);
        }
    }, [props.alerts]);

    const onChange = (e) => {
        setFormValue(e.target.value);
    }

    const saveForm = () => {
        let alert = {
            body: formValue
        }
        props.updateAlert(alert, 1);
    }

    const getAlerts = () => {
        props.getAlerts()
    }

    return (
        <PageWrapper>
            <HeadingContainer>
                <StyledHeading>Aspen Alert</StyledHeading>
            </HeadingContainer>
            <StyledTextArea type="text" rows="41" onChange={(e) => onChange(e)} value={formValue || ''} />
            <MainButton title="Publish" onClick={saveForm} />
            <NotificationSuccess>
                {
                    props.success &&
                    <Notification className="success" message='Alert Updated' />
                }
            </NotificationSuccess>
            <a href="https://aspenalert.com" target="_blank">Visit Alert</a>
        </PageWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        alerts: state.alerts.data,
        success: state.alerts.success
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAlerts: () => dispatch(getAlerts()),
    updateAlert: (alertDetails, alertId) => dispatch(updateAlert(alertDetails, alertId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AspenAlert);