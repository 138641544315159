const jobsDefaultState = {
  data: [],
  singleJob: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const jobsReducer = (state = jobsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_JOB':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_JOB_SUCCESS':
      return {
        ...state,
        singleJob: action.singleJob,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_GET_JOBS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_JOBS_SUCCESS':
      return {
        ...state,
        data: action.jobs,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_JOB_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_JOB_SUCCESS':
      return {
        ...state,
        data: state.data.map((job) => {
          // console.log('job: ', job);
          // console.log('action.job: ', action.jobDetails);
          if (job.id === action.jobDetails.id) {
            console.log('got match');
            return {
              ...job,
              ...action.jobDetails /// add object to array
            }
          } else {
            return job;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Microsite has been updated',
      };
    case 'START_REMOVE_JOBS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_JOBS_SUCCESS':
      return {
        ...state,
        data: state.data.filter(job => { // check original posts and only include posts that don't have that to delete ID
          return !action.jobIds.includes(job.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your jobs have been removed!',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default jobsReducer;