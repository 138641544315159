import React, {Component} from 'react';
import colors from '../styles/colors';
import styled from "styled-components";
import Dropzone from 'react-dropzone';
import Banner from '../images/content-submit-banner.png';
import Heading from '../images/content-submission-heading.png';
import Heading2x from '../images/content-submission-heading@2x.png';
import ABButton from "../components/ABButton";
import Spinner from "../components/Spinner";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {uploadFiles, saveMultiplePhotos} from "../actions/upload";
import {sendMicrositeEmail} from "../actions/mail";

const SubmitContentWrapper = styled.div`
  
`;

const PageHeading = styled.div`
  text-align: center;
  padding: 10px 0;
  img {
    width: 100%;
    height: auto;
    max-width: 1018px;
  }
`;

const BigHeader = styled.div`
 width: 100vw;
   position: relative;
   left: 50%;  
   margin-left: -50vw;
   img {
    width: 100%;
   }
`;

const TextHeading = styled.h1`
  font-size: 30px;
  text-transform: uppercase;
  @media (min-width: 576px) { font-size: 35px; }
  @media (min-width: 768px) { font-size: 40px; }
  @media (min-width: 992px) { font-size: 49px; }
`;

const FormSection = styled.div`
  padding: 6px 0;
  h3 {
    color: ${colors.black};
    width: 100%;
    font-size: 18px;
    margin: 7px 0;
  }
  input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    border-radius: none;
    box-shadow: none;
  }
`;

const SelectFilesButton = styled.button`
  font-size: 15px;
  padding: 6px 10px;
  background: none;
  drop-shadow: none;
  cursor: pointer;
  outline: none;
  margin: 0;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
`;

const SubmitButton = styled.div`
  padding: 30px 0;
  border: none;
`;

const FileRow = styled.div`
  padding: 5px 10px;
  button {
    margin-left: 10px;
  }
`;

const FileName = styled.div`
  margin: 10px 0;
  font-size: 14px;
`;

const UploadSpinner = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 10px;
  div {
    padding-left: 5px;
  }
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class MicrositeSubmission extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sponsorName: '',
      sponsorConvertedName: '',
      pdfFile: null,
      pdfFileName: '',
      multipleFiles: [],
      multipleFilesNames: []
    }
  }

  componentDidUpdate(prevProps) {
    console.log('prevProps: ', prevProps);
    // compare props and only set state after loading is false/complete
    if (this.props.uploadSuccess !== prevProps.uploadSuccess) {
      if (this.props.uploadSuccess) {
        toast.success('Files Uploaded Successfully');
        this.clearForm();
      }
    }

    if (this.props.uploadError !== prevProps.uploadError) {
      if (this.props.uploadError) {
        toast.error('Error Uploading Files');
      }
    }

  }

  clearForm() {
    this.setState((state) => {
      return {
        sponsorName: '',
        sponsorConvertedName: '',
        pdfFile: null,
        pdfFileName: '',
        multipleFiles: [],
        multipleFilesNames: []
      };
    });
  }

  handleSponsorNameChange = (value) => {
    this.setState(() => {
      return {
        sponsorName: value,
        sponsorConvertedName: value.split(' ').join('-').toLowerCase()
      }
    });
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    this.setState(() => {
      return {
        pdfFile: files[0],
        pdfFileName: files[0].name,
      }
    });

    console.log(files);



    // if (files && files.length > 0) {
    //   const isVerified = this.verifyFile(files);
    //   if (isVerified) {
    //     this.props.getS3Url();
    //     // imageBase64Data
    //     const currentFile = files[0];
    //     const myFileItemReader = new FileReader();
    //     myFileItemReader.addEventListener("load", () => {
    //       // console.log(myFileItemReader.result)
    //       const myResult = myFileItemReader.result;
    //       this.setState({
    //         imgSrc: myResult, //this is the base64 code for preview
    //         imgSrcExt: extractImageFileExtensionFromBase64(myResult),
    //         uploadedFile: currentFile
    //       })
    //     }, false);
    //
    //     myFileItemReader.readAsDataURL(currentFile)
    //
    //   }
    // }

  }

  handleMultipleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    files.map((item) => {
      this.setState((state) => {
        return {
          multipleFiles: [...state.multipleFiles, item],
          multipleFilesNames: [...state.multipleFilesNames, item.name]
        };
      });
    });

    console.log(files);


    // if (files && files.length > 0) {
    //   const isVerified = this.verifyFile(files);
    //   if (isVerified) {
    //     this.props.getS3Url();
    //     // imageBase64Data
    //     const currentFile = files[0];
    //     const myFileItemReader = new FileReader();
    //     myFileItemReader.addEventListener("load", () => {
    //       // console.log(myFileItemReader.result)
    //       const myResult = myFileItemReader.result;
    //       this.setState({
    //         imgSrc: myResult, //this is the base64 code for preview
    //         imgSrcExt: extractImageFileExtensionFromBase64(myResult),
    //         uploadedFile: currentFile
    //       })
    //     }, false);
    //
    //     myFileItemReader.readAsDataURL(currentFile)
    //
    //   }
    // }

  }

  removeSingleFile = () => {
    this.setState((prevState) => {
      return {
        pdfFile: null,
        pdfFileName: ''
      };
    });
  };

  removeFileFromMultiple = (index) => {
    this.setState((prevState) => {
      return {
        multipleFiles: prevState.multipleFiles.filter((_, i) => i !== index),
        multipleFilesNames: prevState.multipleFilesNames.filter((_, i) => i !== index)
      };
    });
  };

  submitContent = () => {
    console.log('submitting content');
    let finalImages = this.state.multipleFiles;
    finalImages.push(this.state.pdfFile);
    // this.props.uploadFiles(finalImages, this.state.sponsorConvertedName);
    this.props.sendMicrositeEmail(this.state.sponsorConvertedName);
    this.props.saveMultiplePhotos(finalImages, this.state.sponsorConvertedName);
  };

  render() {
    return (
      <SubmitContentWrapper>
        <BigHeader>
          <img src={Banner} alt="" />
        </BigHeader>
        <PageHeading>
          <TextHeading>Microsite Content Submission</TextHeading>
        </PageHeading>
        <FormSection>
          <h3>Sponsor Organization Name</h3>
          <input onChange={(e) => this.handleSponsorNameChange(e.target.value)} value={this.state.sponsorName} />
        </FormSection>
        <FormSection>
          <h3>Microsite Content Completed PDF</h3>
          <Dropzone
            onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
            multiple={false}
            accept='application/pdf'
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <SelectFilesButton type="button">Select PDF File</SelectFilesButton>
                </div>
                <FileName>
                  {
                    this.state.pdfFileName &&
                    <FileRow>
                      {this.state.pdfFileName}
                      <button type="button" onClick={() => this.removeSingleFile()}>remove</button>
                    </FileRow>
                  }
                </FileName>
              </section>
            )}
          </Dropzone>
        </FormSection>
        <FormSection>
          <h3>Accompanying Media</h3>
          <Dropzone
            onDrop={(acceptedFiles) => this.handleMultipleOnDrop(acceptedFiles)}
            multiple={true}
            accept={acceptedFileTypes}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <SelectFilesButton type="button">Select File(s)</SelectFilesButton>
                </div>
                <FileName>
                  {
                    this.state.multipleFilesNames.map((item, index) => {
                      return (
                        <FileRow key={index}>
                          {item}
                          <button type="button" onClick={() => this.removeFileFromMultiple(index)}>remove</button>
                        </FileRow>
                      )
                    })
                  }
                </FileName>
              </section>
            )}
          </Dropzone>
        </FormSection>
        <SubmitButtonWrapper>
          <SubmitButton>
            <ABButton
              onClick={() => this.submitContent()}
              title='Submit Content'
              submitting={this.props.uploadLoading}
              disabled={!this.state.sponsorName || !this.state.pdfFile} />
          </SubmitButton>
          {
            this.props.uploadLoading &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }
        </SubmitButtonWrapper>
        <ToastContainer />
      </SubmitContentWrapper>
    )};
}

const mapStateToProps = (state) => {
  return {
    uploadLoading: state.upload.uploadLoading,
    uploadSuccess: state.upload.uploadSuccess,
    uploadError: state.upload.uploadError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadFiles: (files, folderName) => dispatch(uploadFiles(files, folderName)),
  saveMultiplePhotos: (files, folderName) => dispatch(saveMultiplePhotos(files, folderName)),
  sendMicrositeEmail: (company) => dispatch(sendMicrositeEmail(company))
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrositeSubmission);