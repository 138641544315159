import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetEvent = () => ({
  type: 'START_GET_EVENT',
});

export const getEventSuccess = (singleEvent) => ({
  type: 'GET_EVENT_SUCCESS',
  singleEvent
});

export const getEvent = (id) => {
  console.log('getting event with id: ', id);
  return (dispatch) => {
    dispatch(startGetEvent());
    axiosInstance.get(`event/${id}`)
      .then((response) => {
        if (response.data.event === false) { // no post yet
        } else { // has post
          console.log('event data: ', response.data);
          dispatch(getEventSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startGetEvents = () => ({
  type: 'START_GET_EVENTS',
});

export const getEventsSuccess = (events) => ({
  type: 'GET_EVENTS_SUCCESS',
  events
});

export const getEvents = (queryParams) => {
  return (dispatch) => {
    let url = 'events';
    if (queryParams) {
      url = `events/${queryParams}`;
    }
    console.log('get events url: ', url);
    dispatch(startGetEvents());
    axiosInstance.get(url)
      .then((response) => {
        console.log('events: ', response);
        if (response.data.events === false) { // no post yet
        } else { // has posts
          dispatch(getEventsSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreateEvent = () => ({
  type: 'START_CREATE_EVENT'
});

export const createEventSuccess = (eventDetails) => ({
  type: 'CREATE_EVENT_SUCCESS',
  eventDetails
});

export const createEvent = (eventDetails) => {
  console.log('creating event', eventDetails);
  return (dispatch) => {
    dispatch(startCreateEvent());
    axiosInstance.post('event', eventDetails)
      .then((response) => {
        dispatch(createEventSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startEventUpdate = () => ({
  type: 'START_EVENT_UPDATE',
});

export const updateEventSuccess = (eventDetails) => ({
  type: 'UPDATE_EVENT_SUCCESS',
  eventDetails
});

export const updateEvent = (eventDetails, eventId) => {
  return (dispatch) => {
    dispatch(startEventUpdate());
    axiosInstance.put(`event/${eventId}`, eventDetails)
      .then((response) => {
        dispatch(updateEventSuccess(eventDetails));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startRemoveEvents = () => ({
  type: 'START_REMOVE_EVENTS'
});

export const removeEventsSuccess = (eventIds) => ({
  type: 'REMOVE_EVENTS_SUCCESS',
  eventIds
});

export const removeEvents = (eventIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: eventIds
    };
    dispatch(startRemoveEvents());
    axiosInstance.delete('events', { data: items })
      .then((response) => {
        dispatch(removeEventsSuccess(eventIds));
        console.log('events removed!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

