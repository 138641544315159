import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrositeCategories = () => ({
  type: 'START_GET_MICROSITE_CATEGORIES',
});

export const getMicrositeCategoriesSuccess = (micrositeCategories) => ({
  type: 'GET_MICROSITE_CATEGORIES_SUCCESS',
  micrositeCategories
});

export const getMicrositeCategories = () => {
  return (dispatch) => {
    dispatch(startGetMicrositeCategories());
    axiosInstance.get('microsite-categories')
      .then((response) => {
        if (response.data.categories === false) { // no categories yet
        } else { // has posts
          dispatch(getMicrositeCategoriesSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};
