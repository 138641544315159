import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetContactForms = () => ({
  type: 'START_GET_CONTACT_FORMS',
});

export const getContactFormsSuccess = (contactForms) => ({
  type: 'GET_CONTACT_FORMS_SUCCESS',
  contactForms
});

export const getContactForms = (id) => {
  return (dispatch) => {
    dispatch(startGetContactForms());
    axiosInstance.get(`contact-forms`)
      .then((response) => {
        console.log('getting contactForms: ', response);
        dispatch(getContactFormsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};