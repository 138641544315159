import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {slugify} from '../../../utility/string-utils';
import {
  FormContainer,
  FormFieldRow,
  FormFieldColumn,
  FormSection,
  FormField,
  FormInputFull,
  FormInput,
  FormTextArea,
  FormLabel,
  FormSubHeading,
  FormItalicHeading,
  Col,
  Required,
  FormSectionPadding
} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import {history} from '../../../routers/AppRouter';
import {createMicrosite, getMicrosite, updateMicrosite} from '../../../actions/microsite';
import {createMicrositeSubmission, updateMicrositeSubmission} from '../../../actions/microsite-submission';
import Spinner from "../../../components/Spinner";
import {setLocation} from '../../../actions/location';
import {getMicrositeCategories} from '../../../actions/microsite-categories';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const UploadSpinner = styled.div`
  font-size: 14px;
  padding: 20px 0;
  max-width: 1365px;
  text-align: center;
`;

const RadioSection = styled.div`
  max-width: 150px;
  font-size: 15px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

class OrganizationDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      email: '',
      firstNameError: false,
      lastNameError: false,
      companyError: false,
      emailError: false,
      recName1Error: false,
      recEmail1Error: false,
      applicationType: '',
      primaryColor: '',
      secondaryColor: '',
    };

  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    // this.props.getMicrositeCategories(); // for the multi select in contact info
    if (this.props.microsite !== prevProps.microsite) {
      this.loadFields();
    }
  }

  loadFields = () => {

    this.setState({
      firstName: this.props.microsite.firstName || '',
      lastName: this.props.microsite.lastName || '',
      jobTitle: this.props.microsite.jobTitle || '',
      company: this.props.microsite.company || '',
      email: this.props.microsite.email || '',
      applicationType: this.props.microsite.applicationType,
      primaryColor: this.props.microsite.primaryColor || '',
      secondaryColor: this.props.microsite.secondaryColor || '',
    });
  };

  onNextClick = (close) => {

    if (this.state.firstName && this.state.lastName && this.state.company && this.state.email) {
      this.props.setLocation('contact-info', 'true');
      let microsite = {
        pageTitle: slugify(this.state.company),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        jobTitle: this.state.jobTitle,
        company: this.state.company,
        email: this.state.email,
        applicationType: this.state.applicationType,
        primaryColor: this.state.primaryColor.replace('#', ''),
        secondaryColor: this.state.secondaryColor.replace('#', ''),
      };

      if (this.props.microsite) {
        microsite.userId = this.props.microsite.userId;
        this.props.updateMicrosite(microsite, this.props.microsite.id);
      } else {
        this.props.createMicrosite(microsite);
      }

      let micrositeSubmission = {
        organizationDetails: true
      };
      this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);

      if (close) {
        // this.props.router.push({
        //   pathname: `/microsite-submission/review-form`,
        //   query: { id: this.props.microsite.id },
        // });
        history.push(`../${this.props.microsite.id}`);
        return;
      }
    } else {
      this.showValidationErrors();
    }
  };

  showValidationErrors = () => {
    if (!this.state.firstName) {
      this.setState(() => ({firstNameError: true}));
    }
    if (!this.state.lastName) {
      this.setState(() => ({lastNameError: true}));
    }
    if (!this.state.company) {
      this.setState(() => ({companyError: true}));
    }
    if (!this.state.email) {
      this.setState(() => ({emailError: true}));
    }
  };

  onFirstNameChange = (e) => {
    const firstName = e.target.value;
    if (firstName) {
      this.setState(() => ({firstNameError: false}));
    }
    this.setState(() => ({firstName}));
  };

  onLastNameChange = (e) => {
    const lastName = e.target.value;
    if (lastName) {
      this.setState(() => ({lastNameError: false}));
    }
    this.setState(() => ({lastName}));
  };

  onJobTitleChange = (e) => {
    const jobTitle = e.target.value;
    this.setState(() => ({jobTitle}));
  };

  onCompanyChange = (e) => {
    const company = e.target.value;
    if (company) {
      this.setState(() => ({companyError: false}));
    }
    this.setState(() => ({company}));
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    if (email) {
      this.setState(() => ({emailError: false}));
    }
    this.setState(() => ({email}));
  };

  handleCategoryChange = (applicationType) => {
    this.setState({ applicationType: applicationType});
  };

  onPrimaryColorChange = (e) => {
    const primaryColor = e.target.value;
    this.setState(() => ({primaryColor}));
  };

  onSecondaryColorChange = (e) => {
    const secondaryColor = e.target.value;
    this.setState(() => ({secondaryColor}));
  };

  render() {
    return (
      <>
        <PageWrapper>
          {/*<ProgressBar />*/}
          <PageHeading>Organization Details</PageHeading>
          <FormContainer>
            <FormSection>
              <div>
                <FormSubHeading>Microsite Content Contact:</FormSubHeading>
                <FormFieldRow>
                  <FormFieldColumn>
                    <FormLabel>First Name*</FormLabel>
                    <FormInput
                      value={this.state.firstName}
                      onChange={this.onFirstNameChange}
                      type="text"
                      required={this.state.firstNameError}
                    />
                  </FormFieldColumn>
                  <FormFieldColumn>
                    <FormLabel>Last Name*</FormLabel>
                    <FormInput
                      value={this.state.lastName}
                      onChange={this.onLastNameChange}
                      type="text"
                      required={this.state.lastNameError}
                    />
                  </FormFieldColumn>
                </FormFieldRow>
              </div>
              <div>
                <FormField>
                  <FormLabel>Job Title</FormLabel>
                  <FormInputFull
                    value={this.state.jobTitle}
                    onChange={this.onJobTitleChange}
                    type="text"
                  />
                </FormField>
                <FormField>
                  <FormLabel>Company/Organization*</FormLabel>
                  <FormInputFull
                    value={this.state.company}
                    onChange={this.onCompanyChange}
                    type="text"
                    required={this.state.companyError}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Email Address*</FormLabel>
                  <FormInputFull
                    value={this.state.email}
                    onChange={this.onEmailChange}
                    type="text"
                    required={this.state.emailError}
                  />
                </FormField>
              </div>

            </FormSection>

            <FormSection>
              <FormSubHeading>Organization Brand Color:</FormSubHeading>
              <FormItalicHeading>Please identify the organization’s brand colors using RGB Hex Codes.</FormItalicHeading>
              <FormFieldRow>
                <FormFieldColumn>
                  <FormLabel>Primary</FormLabel>
                  <FormInput
                    value={this.state.primaryColor}
                    onChange={this.onPrimaryColorChange}
                    type="text"
                  />
                </FormFieldColumn>
                <FormFieldColumn>
                  <FormLabel>Secondary</FormLabel>
                  <FormInput
                    value={this.state.secondaryColor}
                    onChange={this.onSecondaryColorChange}
                    type="text"
                  />
                </FormFieldColumn>
              </FormFieldRow>

              <FormSectionPadding>
                <FormSubHeading>Category:</FormSubHeading>
                <FormItalicHeading>Please select your Microsite category.</FormItalicHeading>

                <RadioSection>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="all"
                      checked={this.state.applicationType === 'all'}
                      onChange={() => this.handleCategoryChange('all')}
                    />
                    All
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="upstream"
                      checked={this.state.applicationType === 'upstream'}
                      onChange={() => this.handleCategoryChange('upstream')}
                    />
                    Upstream
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="downstream"
                      checked={this.state.applicationType === 'downstream'}
                      onChange={() => this.handleCategoryChange('downstream')}
                    />
                    Downstream
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="fillfinish"
                      checked={this.state.applicationType === 'fillfinish'}
                      onChange={() => this.handleCategoryChange('fillfinish')}
                    />
                    Fill Finish
                  </label>
                </RadioSection>
              </FormSectionPadding>

            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>
          {
            this.props.loading &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.microsite.loading,
    error: state.microsite.error,
    errorMessage: state.microsite.errorMessage,
    microsite: state.microsite.data,
    location: state.location.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeCategories: () => dispatch(getMicrositeCategories()),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  createMicrosite: (microsite) => dispatch(createMicrosite(microsite)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  setLocation: (page) => dispatch(setLocation(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);
