import axiosInstance from '../utility/axios';
import {signOut} from './sign-in';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMediaTags = () => ({
  type: 'START_GET_MEDIA_TAGS',
});

export const getMediaTagsSuccess = (mediaTags) => ({
  type: 'GET_MEDIA_TAGS_SUCCESS',
  mediaTags
});

export const getMediaTags = (mediaImageId) => {
  return (dispatch) => {
    dispatch(startGetMediaTags());
    axiosInstance.get(`media-tags/${mediaImageId}`)
      .then((response) => {
        console.log('media tags: ', response.data);
        dispatch(getMediaTagsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startGetMediaTag = () => ({
  type: 'START_GET_MEDIA_TAG',
});

export const getMediaTagSuccess = (mediaTags) => ({
  type: 'GET_MEDIA_TAG_SUCCESS',
  mediaTags
});

export const getMediaTag = (mediaImageId) => {
  return (dispatch) => {
    dispatch(startGetMediaTag());
    axiosInstance.get(`media-tag/${mediaImageId}`)
      .then((response) => {
        dispatch(getMediaTagSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreateMediaTags = () => ({
  type: 'START_CREATE_MEDIA_TAGS'
});

export const createMediaTagsSuccess = (mediaTags) => ({
  type: 'CREATE_MEDIA_TAGS_SUCCESS',
  mediaTags
});

export const createMediaTags = (mediaTags, mediaImageId) => {
  console.log('creating media tag', mediaTags);
  return (dispatch) => {
    dispatch(startCreateMediaTags());
    axiosInstance.post(`media-tags/${mediaImageId}`, mediaTags)
      .then((response) => {
        dispatch(createMediaTagsSuccess(response.data));
        console.log('media tag created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startUpdateMediaTags = () => ({
  type: 'START_UPDATE_MEDIA_TAGS'
});

export const updateMediaTagsSuccess = (mediaTags) => ({
  type: 'UPDATE_MEDIA_TAGS_SUCCESS',
  mediaTags
});

export const updateMediaTags = (mediaTags, mediaImageId) => {
  console.log('creating media tag', mediaTags);
  return (dispatch) => {
    dispatch(startUpdateMediaTags());
    axiosInstance.put(`media-tags/${mediaImageId}`, mediaTags)
      .then((response) => {
        dispatch(updateMediaTagsSuccess(response.data));
        console.log('media tag created: ', response.data);
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};
