import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AlertRegulatory } from "../alert-types";
import Modal from 'react-modal';
import { createAlertRegulatory, updateAlertRegulatory } from "../../../../actions/alert/alert-regulatory";
import { createAlertDiscovery, updateAlertDiscovery } from "../../../../actions/alert/alert-discovery";
import { createAlertProject, updateAlertProject } from "../../../../actions/alert/alert-project";
import AlertRegulatoryForm from "../AlertRegulatoryForm";
import colors from "../../../../styles/colors";

interface ImageStyleProps {
  width?: number;
}

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditRightContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertRegulatory: AlertRegulatory;
  contentType: string;
  buttonTitle: string;
  createAlertRegulatory: (alertRegulatory: AlertRegulatory) => void;
  updateAlertRegulatory: (alertRegulatory: AlertRegulatory) => void;
  createAlertDiscovery: (alertRegulatory: AlertRegulatory) => void;
  updateAlertDiscovery: (alertRegulatory: AlertRegulatory) => void;
  createAlertProject: (alertRegulatory: AlertRegulatory) => void;
  updateAlertProject: (alertRegulatory: AlertRegulatory) => void;
}

const AlertComponentE: React.FC<Props> = ({ alertItemId, alertRegulatory, contentType, buttonTitle, createAlertRegulatory, updateAlertRegulatory, createAlertDiscovery, updateAlertDiscovery, createAlertProject, updateAlertProject }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertRegulatory, setNewAlertRegulatory] = useState<AlertRegulatory>({ id: 0, title: '', body: '', image: '', link: '', alertItemId: 0, attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertRegulatory: AlertRegulatory) => {
    setNewAlertRegulatory(alertRegulatory);
    setModalIsOpen(true);
  }

  const handleSave = (alertRegulatory: AlertRegulatory) => {
    if (contentType === 'regulatory') {
      createAlertRegulatory(alertRegulatory);
    }
    if (contentType === 'discovery') {
      createAlertDiscovery(alertRegulatory);
    }
    if (contentType === 'project') {
      createAlertProject(alertRegulatory);
    }
    closeModal();
  }

  const handleUpdate = (alertRegulatory: AlertRegulatory) => {
    if (contentType === 'regulatory') {
      updateAlertRegulatory(alertRegulatory);
    }
    if (contentType === 'discovery') {
      updateAlertDiscovery(alertRegulatory);
    }
    if (contentType === 'project') {
      updateAlertProject(alertRegulatory);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertRegulatory && Object.keys(alertRegulatory).length > 0) ?
          <>
            <TitleStyle>{alertRegulatory.title}</TitleStyle>
            {
              alertRegulatory.imageWidth &&
              <ImageStyle width={alertRegulatory.imageWidth}> <a href={alertRegulatory.link}><img src={`https://${alertRegulatory.image}`} alt="" width={alertRegulatory.imageWidth} /></a></ImageStyle>
            }
            {
              !alertRegulatory.imageWidth &&
              <ImageStyle> <a href={alertRegulatory.link}><img src={`https://${alertRegulatory.image}`} alt="" /></a></ImageStyle>
            }
            <CitationStyle>{alertRegulatory.citation}</CitationStyle>
            <BodyStyle><div dangerouslySetInnerHTML={{ __html: alertRegulatory.body }} /></BodyStyle>
            <ButtonContainer>
              <LearnMoreLink><a href={alertRegulatory.link}>Learn More</a></LearnMoreLink>
            </ButtonContainer>
            <EditRightContainer>
              <EditButton onClick={() => handleEdit(alertRegulatory)}>Edit</EditButton>
            </EditRightContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertRegulatoryForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertRegulatory={newAlertRegulatory}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertRegulatory: state.alertRegulatory.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertRegulatory: (alertRegulatory: AlertRegulatory) => dispatch(createAlertRegulatory(alertRegulatory)),
  updateAlertRegulatory: (alertRegulatory: AlertRegulatory) => dispatch(updateAlertRegulatory(alertRegulatory)),
  createAlertDiscovery: (alertRegulatory: AlertRegulatory) => dispatch(createAlertDiscovery(alertRegulatory)),
  updateAlertDiscovery: (alertRegulatory: AlertRegulatory) => dispatch(updateAlertDiscovery(alertRegulatory)),
  createAlertProject: (alertRegulatory: AlertRegulatory) => dispatch(createAlertProject(alertRegulatory)),
  updateAlertProject: (alertRegulatory: AlertRegulatory) => dispatch(updateAlertProject(alertRegulatory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentE);