import React, {Component} from 'react';
import styled from "styled-components";
import colors from './styles/colors';
import { NavLink } from 'react-router-dom';

const StyledUL = styled.ul`
  list-style: none;
  margin: 0; 
    
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-flex-flow: row wrap;
  justify-content: center;
  
  a {
    text-decoration: none;
    display: block;
    padding: 7px 0;
    margin: 0 20px;
    color: black;
    border-bottom: 2px solid white;
    :hover {
      color: ${colors.blue};
      border-bottom: 2px solid ${colors.blue};
    }
  }
  
  @media all and (max-width: 800px) {
    justify-content: space-around;
  }

  @media all and (max-width: 600px) {
    -webkit-flex-flow: column wrap;
      flex-flow: column wrap;
      padding: 0;
    
    a {
      margin: 0; 
      text-align: center; 
      padding: 10px;
      border-top: 2px solid rgba(255,255,255,0.3); 
      border-bottom: 2px solid rgba(0,0,0,0.1); 
    }
  
    .li:last-of-type a {
      border-bottom: none;
    }
  }
  
`;

class Navigation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <StyledUL>
          <li>
            <NavLink to="/aspen-alert">Alert</NavLink>
          </li>
          <li>
            <NavLink to="/content-submit">Xchange</NavLink>
          </li>
          <li>
            <NavLink to="/surveys">Surveys</NavLink>
          </li>
          <li>
            <NavLink to="/events-calendar">Events Calendar</NavLink>
          </li>
          <li>
            <NavLink to="/career-center">Career Center</NavLink>
          </li>
        </StyledUL>
      </div>
    )};
}

export { Navigation as default};