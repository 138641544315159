const documentsDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const documentsReducer = (state = documentsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_DOCUMENTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_DOCUMENTS_SUCCESS':
      return {
        ...state,
        data: action.documents,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_CREATE_DOCUMENTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'CREATE_DOCUMENTS_SUCCESS':
      return {
        ...state,
        data: action.documents,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your account has been created!',
      };
    case 'START_DOCUMENTS_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'DOCUMENTS_UPDATE_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.documents
        },
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default documentsReducer;