import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertQuote } from './alert-types.js';
import ABDropzone from '../../../components/ABDropzone';
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

interface AlertQuoteProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertQuote: AlertQuote) => void;
    handleUpdate: (alertQuote: AlertQuote) => void;
    alertQuote: AlertQuote
}

const toolbarConfig = {
    options: [
        'inline'
    ],
    inline: {
        options: ['bold', 'italic', 'underline'],
    }
};

const AlertQuoteForm: React.FC<AlertQuoteProps> = ({ alertQuote, alertItemId, pageTitle, handleSave, handleUpdate }) => {

    const [id, setId] = useState(0);
    const [image, setImage] = useState('');
    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [citation, setCitation] = useState('');
    const [link, setLink] = useState('');
    const [attribution, setAttribution] = useState('');
    const [editing, setEditing] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        // check if editing
        if (alertQuote.id !== 0) {
            setId(alertQuote.id);
            setEditing(true);
            setImage(alertQuote.image);
            setImageHeight(alertQuote.imageHeight || 0);
            setImageWidth(alertQuote.imageWidth || 0);
            setLink(alertQuote.link);
            setAttribution(alertQuote.attribution);
            setCitation(alertQuote.citation || '');
            // wyswig
            const contentBlock = htmlToDraft(alertQuote.citation || '');
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [])

    const onSave = () => {

        if (imageUploaded && !image) {
            toast.error('Error Uploading Image');
            return;
        }

        const editorBody = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const alertQuote: AlertQuote = {
            id,
            image,
            link,
            alertItemId: alertItemId,
            attribution,
            citation: editorBody
        }

        if (imageHeight !== null && imageHeight !== 0) {
            alertQuote.imageHeight = imageHeight
        }
        if (imageWidth !== null && imageWidth !== 0) {
            alertQuote.imageWidth = imageWidth
        }

        if (editing) {
            handleUpdate(alertQuote);
        } else {
            handleSave(alertQuote);
        }
    };

    const handleImageHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageHeight = e.currentTarget.value;
        setImageHeight(parseInt(imageHeight, 10));
    };

    const handleImageWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageWidth = e.currentTarget.value;
        setImageWidth(parseInt(imageWidth, 10));
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        setLink(link);
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const handleCitationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const citation = e.currentTarget.value;
        setCitation(citation);
    };

    const handleFilesDrop = (cloudfrontUrl: string) => {
        setImage(cloudfrontUrl);
        setImageUploaded(true);
    };

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormSubHeading>Image:</FormSubHeading>
                <ABDropzone onFilesDrop={handleFilesDrop} maxWidth={600} />

                {
                    (image && !imageUploaded) &&
                    <DefaultImagePreviewStyle>
                        <FormSubHeading>Image Preview:</FormSubHeading>
                        <img src={`https://${image}`} />
                    </DefaultImagePreviewStyle>
                }

                <FormSubHeading>Image Height:</FormSubHeading>
                <ABInputField
                    value={imageHeight || ''}
                    onChange={handleImageHeightChange}
                    placeholder="height"
                />

                <FormSubHeading>Image Width:</FormSubHeading>
                <ABInputField
                    value={imageWidth || ''}
                    onChange={handleImageWidthChange}
                    placeholder="width"
                />

                <FormSubHeading>Image Citation:</FormSubHeading>
                {/* <ABInputField
                    value={citation}
                    onChange={handleCitationChange}
                    placeholder="Citation"
                /> */}
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="post-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={toolbarConfig}
                    editorStyle={{ columnCount: 1 }}
                    stripPastedStyles={true}
                />

                <FormSubHeading>Link:</FormSubHeading>
                <ABInputField
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="Link"
                />

                <FormSubHeading>Attribution Tracking:</FormSubHeading>
                <ABInputField
                    value={attribution}
                    onChange={handleAttributionChange}
                    placeholder="Attribution"
                />

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertQuoteForm;
