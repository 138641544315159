import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import ABInputField from "../../../components/ABInputField";
import { BASE_URL } from "../../../utility/constants";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import { connect } from 'react-redux';
import { getAlertNews } from '../../../actions/alert/alert-news';
import { getAlertQuote } from '../../../actions/alert/alert-quote';
import { getAlertFact } from '../../../actions/alert/alert-fact';
import AlertComponentA from "./components/AlertComponentA";
import AlertComponentB from "./components/AlertComponentB";
import AlertComponentC from "./components/AlertComponentC";
import { getAlertOtd } from "../../../actions/alert/alert-otd";
import { getAlertPatent } from "../../../actions/alert/alert-patent";
import AlertComponentD from "./components/AlertComponentD";
import { getAlertTed } from "../../../actions/alert/alert-ted";
import { getAlertHumor } from "../../../actions/alert/alert-humor";
import { getAlertRegulatory } from "../../../actions/alert/alert-regulatory";
import { getAlertDiscovery } from "../../../actions/alert/alert-discovery";
import { getAlertProject } from "../../../actions/alert/alert-project";
import AlertComponentE from "./components/AlertComponentE";
import { getAlertUpstream } from "../../../actions/alert/alert-upstream";
import { getAlertDownstream } from "../../../actions/alert/alert-downstream";
import { getAlertFill } from "../../../actions/alert/alert-fill";
import { getAlertSpotlight } from "../../../actions/alert/alert-spotlight";
import { getAlertSolution } from "../../../actions/alert/alert-solution";
import { getAlertBioprocess } from "../../../actions/alert/alert-bioprocess";
import { getAlertPoll } from "../../../actions/alert/alert-poll";
import { getAlertSurvey } from "../../../actions/alert/alert-survey";
import AlertComponentF from "./components/AlertComponentF";
import { getAlertJobs } from "../../../actions/alert/alert-job";
import AlertJobComponent from "./components/AlertJobComponent";
import { getAlertEvent } from "../../../actions/alert/alert-event";
import AlertEventComponent from "./components/AlertEventComponent";
import Modal from 'react-modal';
import AlertEmailPreview from "./alert-email-preview/AlertEmailPreview";
import ABSmallButton from "../../../components/ABSmallButton";
import { getAlertPreviousPoll } from "../../../actions/alert/alert-previous-poll";
import { getAlertPreviousSurvey } from "../../../actions/alert/alert-previous-survey";
import AlertPreviousPollComponent from "./components/AlertPreviousPollComponent";
import { getAlertAds } from "../../../actions/alert/alert-ads";
import AlertAdComponent from "./components/AlertAdComponent";
import { AlertAds, AlertNews } from "./alert-types";
import { history } from "../../../routers/AppRouter";
import AlertItemComponent from "./components/AlertItemComponent";
import { getAlertItem } from "../../../actions/alert/alert-item";

const PageContainer = styled.div`
  padding: 20px 40px;
  font-family: 'open sans', 'helvetica neue', helvetica, arial, sans-serif;
  max-width: 700px;
`;

const BlueTopBar = styled.div`
    background-color: rgb(71, 113, 167);
    padding: 10px;
`;

const BlueBarContainer = styled.div`
    /* width: 600px; */
    text-align: right;
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    padding: 0 15px 0 0;
    margin: 0;
`;

const PreviewLink = styled.div`
  padding: 10px;
  text-align: center;
`;

const NewsAlertHeading = styled.div`
  padding: 10px;
  background-color: rgb(255, 183, 27);
  font-weight: bold;
  line-height: 33px;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.03em;
`;

const AlertHeading = styled.div`
  padding: 10px;
  background-color: rgb(21, 79, 145);
  font-weight: bold;
  line-height: 33px;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.03em;
`;

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const AlertSection = styled.div`
  max-width: 660px;
  margin: 0 auto;
  padding: 15px 0;
  border-bottom: 1px solid lightgray;
`;

const AlertSubSection = styled.div`
  max-width: 660px;
   margin: 0 auto;
  padding: 25px 0;
`;

const EditButton = styled.div`
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const Alert = (props: any) => {

  // const { data, refetch } = useFetch(`${BASE_URL}alert-item/${props.match.params.id}`);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    props.getAlertItem(props.match.params.id);
    props.getAlertNews(props.match.params.id);
    props.getAlertRegulatory(props.match.params.id);
    props.getAlertDiscovery(props.match.params.id);
    props.getAlertProject(props.match.params.id);
    props.getAlertQuote(props.match.params.id);
    props.getAlertHumor(props.match.params.id);
    props.getAlertFact(props.match.params.id);
    props.getAlertTed(props.match.params.id);
    props.getAlertPatent(props.match.params.id);
    props.getAlertBioprocess(props.match.params.id);
    props.getAlertUpstream(props.match.params.id);
    props.getAlertDownstream(props.match.params.id);
    props.getAlertOtd(props.match.params.id);
    props.getAlertFill(props.match.params.id);
    props.getAlertSpotlight(props.match.params.id);
    props.getAlertSolution(props.match.params.id);
    props.getAlertPoll(props.match.params.id);
    props.getAlertSurvey(props.match.params.id);
    props.getAlertJobs(props.match.params.id);
    props.getAlertEvent(props.match.params.id);
    props.getAlertPreviousPoll(props.match.params.id);
    props.getAlertPreviousSurvey(props.match.params.id);
    props.getAlertAds(props.match.params.id);
  }, []);

  // useEffect(() => {
  //   // console.log('data: ', data);

  // }, [data]);

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const findAlertNewsByPosition = (position: number) => {
    return props.alertNews.find((alertNews: AlertNews) => alertNews.position === position);
  }

  const findAlertAdByPosition = (position: number) => {
    return props.alertAds.find((alertAd: AlertAds) => alertAd.position === position);
  }

  const checkIfAdAvailable = (position: number) => {
    if (props.alertAds.find((alertAd: AlertAds) => alertAd.position === position)) {
      return true;
    } else {
      return false;
    }
  }

  const openEmailPreviewPage = () => {
    history.push({
      pathname: `../alert-email-preview/${props.match.params.id}`,
    });
  }

  const openHolidayEmailPreviewPage = () => {
    history.push({
      pathname: `../alert-holiday-email-preview/${props.match.params.id}`,
    });
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '700px'
    },
  };

  return (
    <>
      <PageContainer>
        <PreviewLink>
          <ABSmallButton title="Email Preview/Code" onClick={openEmailPreviewPage} />
        </PreviewLink>
        <PreviewLink>
          <ABSmallButton title="Email Holiday Preview/Code" onClick={openHolidayEmailPreviewPage} />
        </PreviewLink>
        {/* <BlueTopBar>
          <BlueBarContainer>
            Issue# {data.issue} | {moment(data.date).format('MMMM Do, YYYY')}
            &nbsp;&nbsp;
            <button>Edit</button>
          </BlueBarContainer>
        </BlueTopBar> */}

        {
          props.alertItem &&
          <AlertItemComponent
            alertItemId={props.match.params.id}
            alertItem={props.alertItem}
            buttonTitle="Add Alert News"
          />
        }

        <div>
          <NewsAlertHeading>News Alert</NewsAlertHeading>

          {
            props.alertNews &&
            props.alertNews.map((item: AlertNews) => {
              return (
                <AlertSection>
                  <AlertComponentA
                    key={item.id}
                    alertItemId={props.match.params.id}
                    alertNews={item}
                    buttonTitle="Add Alert News"
                  />
                </AlertSection>
              )
            })
          }

          {/* This is just a temporary hack to for the add alert button */}
          <AlertSection>
            <AlertComponentA
              alertItemId={props.match.params.id}
              alertNews={findAlertNewsByPosition(99)}
              buttonTitle="Add Alert News"
            />
          </AlertSection>

          {/* <AlertSection>
            <AlertComponentA
              alertItemId={props.match.params.id}
              alertNews={findAlertNewsByPosition(1)}
              buttonTitle="Add Alert News"
            />
          </AlertSection>

          <AlertSection>
            <AlertComponentA
              alertItemId={props.match.params.id}
              alertNews={findAlertNewsByPosition(2)}
              buttonTitle="Add Alert News"
            />
          </AlertSection>

          <AlertSection>
            <AlertComponentA
              alertItemId={props.match.params.id}
              alertNews={findAlertNewsByPosition(3)}
              buttonTitle="Add Alert News"
            />
          </AlertSection> */}

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(1)}
              buttonTitle="Add Alert Ad 1"
              position={1}
            />
          </AlertSection>

          <AlertHeading>Quote Alert</AlertHeading>
          <AlertSection>
            <AlertComponentB
              alertItemId={props.match.params.id}
              alertQuote={props.alertQuote}
              contentType={'quote'}
              buttonTitle="Add Quote Alert"
            />
          </AlertSection>

          <AlertHeading>On This Day Alert</AlertHeading>
          <AlertSection>
            <AlertComponentC
              alertItemId={props.match.params.id}
              alertFact={props.alertOtd}
              contentType={'otd'}
              buttonTitle="Add On This Day Alert"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(2)}
              buttonTitle="Add Alert Ad 2"
              position={2}
            />
          </AlertSection>

          <AlertHeading>Ted Alert</AlertHeading>
          <AlertSection>
            <AlertComponentC
              alertItemId={props.match.params.id}
              alertFact={props.alertTed}
              contentType={'ted'}
              buttonTitle="Add Ted Alert"
            />
          </AlertSection>

          <AlertHeading>Poll Alert</AlertHeading>
          <AlertSection>
            <AlertComponentF
              alertItemId={props.match.params.id}
              alertPoll={props.alertPoll}
              contentType={'poll'}
              buttonTitle="Add Poll Alert"
            />
            <AlertSubSection>
              <TitleStyle>Previous Poll Alert</TitleStyle>
              <AlertPreviousPollComponent
                alertItemId={props.match.params.id}
                alertPreviousPoll={props.alertPreviousPoll}
                contentType={'poll'}
                buttonTitle="Add Previous Poll"
              />
            </AlertSubSection>
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(3)}
              buttonTitle="Add Alert Ad 3"
              position={3}
            />
          </AlertSection>

          <AlertHeading>Bioprocess Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertBioprocess}
              contentType={'bioprocess'}
              buttonTitle="Add Bioprocess Alert"
            />
          </AlertSection>

          <AlertHeading>Survey Alert</AlertHeading>
          <AlertSection>
            <AlertComponentF
              alertItemId={props.match.params.id}
              alertPoll={props.alertSurvey}
              contentType={'survey'}
              buttonTitle="Add Survey Alert"
            />
            <AlertSubSection>
              <TitleStyle>Previous Survey Alert</TitleStyle>
              <AlertPreviousPollComponent
                alertItemId={props.match.params.id}
                alertPreviousPoll={props.alertPreviousSurvey}
                contentType={'survey'}
                buttonTitle="Add Previous Survey"
              />
            </AlertSubSection>
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(4)}
              buttonTitle="Add Alert Ad 4"
              position={4}
            />
          </AlertSection>

          <AlertHeading>Upstream Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertUpstream}
              contentType={'upstream'}
              buttonTitle="Add Upstream Alert"
            />
          </AlertSection>

          <AlertHeading>Event Alert</AlertHeading>
          <AlertSection>
            <AlertEventComponent
              alertItemId={props.match.params.id}
              alertEvent={props.alertEvent}
              contentType={'alert'}
              buttonTitle="Add Event Alert"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(5)}
              buttonTitle="Add Alert Ad 5"
              position={5}
            />
          </AlertSection>

          <AlertHeading>Downstream Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertDownstream}
              contentType={'downstream'}
              buttonTitle="Add Downstream Alert"
            />
          </AlertSection>

          <AlertHeading>Spotlight Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertSpotlight}
              contentType={'spotlight'}
              buttonTitle="Add Spotlight Alert"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(6)}
              buttonTitle="Add Alert Ad 6"
              position={6}
            />
          </AlertSection>

          <AlertHeading>Fill / Finish Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertFill}
              contentType={'fill'}
              buttonTitle="Add Fill/Finish Alert"
            />
          </AlertSection>

          <AlertHeading>Regulatory Alert</AlertHeading>
          <AlertSection>
            <AlertComponentE
              alertItemId={props.match.params.id}
              alertRegulatory={props.alertRegulatory}
              contentType={'regulatory'}
              buttonTitle="Add Alert Regulatory"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(7)}
              buttonTitle="Add Alert Ad 7"
              position={7}
            />
          </AlertSection>

          <AlertHeading>Discovery Alert</AlertHeading>
          <AlertSection>
            <AlertComponentE
              alertItemId={props.match.params.id}
              alertRegulatory={props.alertDiscovery}
              contentType={'discovery'}
              buttonTitle="Add Alert Discovery"
            />
          </AlertSection>

          <AlertHeading>Project Alert</AlertHeading>
          <AlertSection>
            <AlertComponentE
              alertItemId={props.match.params.id}
              alertRegulatory={props.alertProject}
              contentType={'project'}
              buttonTitle="Add Alert Project"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(8)}
              buttonTitle="Add Alert Ad 8"
              position={8}
            />
          </AlertSection>

          <AlertHeading>Patent Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertPatent}
              contentType={'patent'}
              buttonTitle="Add Patent Alert"
            />
          </AlertSection>

          <AlertHeading>Solution Alert</AlertHeading>
          <AlertSection>
            <AlertComponentD
              alertItemId={props.match.params.id}
              alertPatent={props.alertSolution}
              contentType={'solution'}
              buttonTitle="Add Solution Alert"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(9)}
              buttonTitle="Add Alert Ad 9"
              position={9}
            />
          </AlertSection>

          <AlertHeading>Humor Alert</AlertHeading>
          <AlertSection>
            <AlertComponentB
              alertItemId={props.match.params.id}
              alertQuote={props.alertHumor}
              contentType={'humor'}
              buttonTitle="Add Humor Alert"
            />
          </AlertSection>

          <AlertHeading>Fact Alert</AlertHeading>
          <AlertSection>
            <AlertComponentC
              alertItemId={props.match.params.id}
              alertFact={props.alertFact}
              contentType={'fact'}
              buttonTitle="Add Fact Alert"
            />
          </AlertSection>

          <AlertSection>
            <AlertAdComponent
              alertItemId={props.match.params.id}
              alertAds={findAlertAdByPosition(10)}
              buttonTitle="Add Alert Ad 10"
              position={10}
            />
          </AlertSection>

          <AlertHeading>Career Alert</AlertHeading>

          <AlertSection>
            <AlertJobComponent
              alertItemId={props.match.params.id}
              alertJob={props.alertJob[0]}
              buttonTitle="Add Alert Job"
              position={1}
            />
          </AlertSection>

          <AlertSection>
            <AlertJobComponent
              alertItemId={props.match.params.id}
              alertJob={props.alertJob[1]}
              buttonTitle="Add Alert Job"
              position={2}
            />
          </AlertSection>

          <AlertSection>
            <AlertJobComponent
              alertItemId={props.match.params.id}
              alertJob={props.alertJob[2]}
              buttonTitle="Add Alert Job"
              position={3}
            />
          </AlertSection>

          <AlertSection>
            <AlertJobComponent
              alertItemId={props.match.params.id}
              alertJob={props.alertJob[3]}
              buttonTitle="Add Alert Job"
              position={4}
            />
          </AlertSection>

          {/* <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <AlertEmailPreview
              alertNews={props.alertNews}
            />
          </Modal> */}

        </div>
      </PageContainer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    alertItem: state.alertItem.data,
    alertNews: state.alertNews.data,
    alertRegulatory: state.alertRegulatory.data,
    alertDiscovery: state.alertDiscovery.data,
    alertProject: state.alertProject.data,
    alertQuote: state.alertQuote.data,
    alertHumor: state.alertHumor.data,
    alertFact: state.alertFact.data,
    alertTed: state.alertTed.data,
    alertOtd: state.alertOtd.data,
    alertPatent: state.alertPatent.data,
    alertBioprocess: state.alertBioprocess.data,
    alertUpstream: state.alertUpstream.data,
    alertDownstream: state.alertDownstream.data,
    alertFill: state.alertFill.data,
    alertSpotlight: state.alertSpotlight.data,
    alertSolution: state.alertSolution.data,
    alertPoll: state.alertPoll.data,
    alertSurvey: state.alertSurvey.data,
    alertJob: state.alertJob.data,
    alertEvent: state.alertEvent.data,
    alertPreviousPoll: state.alertPreviousPoll.data,
    alertPreviousSurvey: state.alertPreviousSurvey.data,
    alertAds: state.alertAds.data,
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getAlertItem: (id: number) => dispatch(getAlertItem(id)),
  getAlertNews: (alertItemId: number) => dispatch(getAlertNews(alertItemId)),
  getAlertRegulatory: (alertItemId: number) => dispatch(getAlertRegulatory(alertItemId)),
  getAlertDiscovery: (alertItemId: number) => dispatch(getAlertDiscovery(alertItemId)),
  getAlertProject: (alertItemId: number) => dispatch(getAlertProject(alertItemId)),
  getAlertQuote: (alertItemId: number) => dispatch(getAlertQuote(alertItemId)),
  getAlertHumor: (alertItemId: number) => dispatch(getAlertHumor(alertItemId)),
  getAlertFact: (alertItemId: number) => dispatch(getAlertFact(alertItemId)),
  getAlertTed: (alertItemId: number) => dispatch(getAlertTed(alertItemId)),
  getAlertOtd: (alertItemId: number) => dispatch(getAlertOtd(alertItemId)),
  getAlertPatent: (alertItemId: number) => dispatch(getAlertPatent(alertItemId)),
  getAlertBioprocess: (alertItemId: number) => dispatch(getAlertBioprocess(alertItemId)),
  getAlertUpstream: (alertItemId: number) => dispatch(getAlertUpstream(alertItemId)),
  getAlertDownstream: (alertItemId: number) => dispatch(getAlertDownstream(alertItemId)),
  getAlertFill: (alertItemId: number) => dispatch(getAlertFill(alertItemId)),
  getAlertSpotlight: (alertItemId: number) => dispatch(getAlertSpotlight(alertItemId)),
  getAlertSolution: (alertItemId: number) => dispatch(getAlertSolution(alertItemId)),
  getAlertPoll: (alertItemId: number) => dispatch(getAlertPoll(alertItemId)),
  getAlertSurvey: (alertItemId: number) => dispatch(getAlertSurvey(alertItemId)),
  getAlertJobs: (alertItemId: number) => dispatch(getAlertJobs(alertItemId)),
  getAlertEvent: (alertItemId: number) => dispatch(getAlertEvent(alertItemId)),
  getAlertPreviousPoll: (alertItemId: number) => dispatch(getAlertPreviousPoll(alertItemId)),
  getAlertPreviousSurvey: (alertItemId: number) => dispatch(getAlertPreviousSurvey(alertItemId)),
  getAlertAds: (alertItemId: number) => dispatch(getAlertAds(alertItemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);