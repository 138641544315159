import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, CheckboxStyling } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertItem } from './alert-types.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ABDropzone from '../../../components/ABDropzone';
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const FormFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 750px;
`;

const AlertFormLeft = styled.div`
    /* width: 360px; */
`;

const AlertFormRight = styled.div`
    /* width: 360px; */
    padding-left: 40px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
  justify-content: flex-end;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

const DatePickerStyle = styled.div`
  input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

interface AlertItemProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertItem: AlertItem) => void;
    handleUpdate: (alertItem: AlertItem) => void;
    alertItem: AlertItem
}

const AlertItemForm: React.FC<AlertItemProps> = ({ alertItem, alertItemId, pageTitle, handleSave, handleUpdate }) => {

    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [issue, setIssue] = useState(0);
    const [date, setDate] = useState<Date | null>(null);
    const [editing, setEditing] = useState(false);
    const [status, setStatus] = useState('draft');
    const [holidayAlert, setHolidayAlert] = useState(false);

    useEffect(() => {
        // check if editing
        if (alertItem.id !== 0) {
            setId(alertItem.id!);
            setEditing(true);
            setTitle(alertItem.title);
            setIssue(alertItem.issue);
            const parsedDate = alertItem.date ? new Date(alertItem.date) : null;
            setDate(parsedDate);
            setStatus(alertItem.status);
            console.log('holidayAlert: ', holidayAlert);
            if (alertItem.holidayAlert) {
                console.log('holidayAlert: ', holidayAlert);

                setHolidayAlert(alertItem.holidayAlert!);
            }
        }
    }, [])

    const onSave = () => {

        const alertItem: AlertItem = {
            id,
            issue,
            title,
            date,
            status,
            holidayAlert
        }

        if (editing) {
            handleUpdate(alertItem);
        } else {
            handleSave(alertItem);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const title = e.currentTarget.value;
        setTitle(title);
    };

    const handleIssueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const issue = parseInt(e.currentTarget.value, 10);
        setIssue(issue);
    };

    const handleDateChange = (date: Date | null) => {
        date!.setHours(1);
        date!.setMinutes(0);
        date!.setSeconds(0);
        setDate(date);
    };

    const handleStatusChange = (status: string) => {
        setStatus(status);
    };

    const handleHolidayToggle = () => {
        setHolidayAlert((prevIsOn) => !prevIsOn);
    };

    const toolbarConfig = {
        options: [
            'inline',
            'textAlign',
            'link'
        ],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        textAlign: {
            options: ['left', 'center', 'right', 'justify'],
        },
        link: {
            options: ['link', 'unlink'],
        }
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormFlex>
                    <AlertFormLeft>

                        <FormSubHeading>Issue:</FormSubHeading>
                        <ABInputField
                            value={issue}
                            onChange={handleIssueChange}
                            placeholder="Issue"
                        />

                        <FormSubHeading>Title:</FormSubHeading>
                        <ABInputField
                            value={title}
                            onChange={handleTitleChange}
                            placeholder="Title"
                        />

                        <CheckboxStyling>
                            <FormSubHeading>Holiday Alert:</FormSubHeading>
                            <input
                                type="checkbox"
                                checked={holidayAlert}
                                onChange={handleHolidayToggle}
                            />
                        </CheckboxStyling>

                    </AlertFormLeft>

                    <AlertFormRight>

                        <FormSubHeading>Date:</FormSubHeading>
                        <DatePickerStyle>
                            <DatePicker
                                selected={date}
                                onChange={handleDateChange}
                                dateFormat="MMMM d, yyyy"
                            />
                        </DatePickerStyle>

                        {/* <FormSubHeading>View More Link:</FormSubHeading>
                        <ABInputField
                            value={viewMoreLink}
                            onChange={handleViewMoreLinkChange}
                            placeholder="View More Link"
                        /> */}

                        <FormSubHeading>Status:</FormSubHeading>
                        <RadioSection>
                            <label>
                                <input
                                    key="above"
                                    type="radio"
                                    value="above"
                                    checked={status === 'draft'}
                                    onChange={() => handleStatusChange('draft')}
                                />
                                Draft
                            </label>
                            <label>
                                <input
                                    key="below"
                                    type="radio"
                                    value="below"
                                    checked={status === 'published'}
                                    onChange={() => handleStatusChange('published')}
                                />
                                Published
                            </label>
                        </RadioSection>


                    </AlertFormRight>
                </FormFlex>

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertItemForm;
