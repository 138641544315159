import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from  'react-router-dom';

export const PrivateRoute = ({ isAuthenticated, component: Component, ...rest}) => ( // rest grabs the rest of the props, we are destructuring others and renaming Component
  <Route {...rest} component={(props) => (
    isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    )
  )}/>
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.userInfo.token
});

export default connect(mapStateToProps)(PrivateRoute);