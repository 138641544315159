import axios from 'axios'; // regular axios
import axiosInstance from '../utility/axios'; // my custom axios
import {showError} from "./profile";
import {BASE_URL} from "../utility/constants";

export const startGetS3Url = () => ({
  type: 'START_GET_S3URL',
});

export const getS3UrlSuccess = (s3Url) => ({
  type: 'GET_S3URL_SUCCESS',
  s3Url
});

export const getS3Url = (photoInfo) => {
  return (dispatch) => {
    dispatch(startGetS3Url());
    axiosInstance.get('upload')
      .then((response) => {
        dispatch(getS3UrlSuccess(response.data));
        dispatch(savePhoto(photoInfo, response.data));
      })
      .catch((error) => {
        console.log('error: ', error);
        if (error.response && error.response.status === 401) {
          setTimeout(function() {
            dispatch(showUploadError('Unable to upload image'));
          }, 3000);
        } else {
          dispatch(showUploadError('Error uploading image'));
          console.log(error);
        }
      });
  }
};

export const startSavePhoto = () => ({
  type: 'START_SAVE_PHOTO'
});

export const savePhotoSuccess = (uploadInfo) => ({
  type: 'SAVE_PHOTO_SUCCESS',
  uploadInfo
});

export const savePhoto = (photoInfo, s3Info) => {
  return (dispatch) => {
    dispatch(startSavePhoto());
    axiosInstance.put(s3Info.url, photoInfo.file, {
      headers: {
        'Content-Type': 'image/jpg'
      }
    })
      .then((response) => {
        dispatch(savePhotoSuccess(response.data));
        //update profilePhoto url in profile with cloudfront url
        let cloudFrontImageLink = `ddi5p6cgm1jjn.cloudfront.net/${s3Info.key}`;
        console.log('cloudFrontImageLink: ', cloudFrontImageLink);
        let profile = {
          profileImage: cloudFrontImageLink
        };
        // dispatch(updateProfileImage(profile));
      })
      .catch((error) => {
        dispatch(showUploadError('Unable to upload image.'));
        console.log(error);
      });
  }
};

export const startGetFolders = () => ({
  type: 'START_GET_FOLDERS'
});

export const getFoldersSuccess = (folders) => ({
  type: 'GET_FOLDERS_SUCCESS',
  folders
});

export const getFolders = () => {
  return (dispatch) => {
    dispatch(startGetFolders());
    axiosInstance.get('xchange-folders')
      .then((response) => {
        let folderNames = [];

        response.data.map((filename) => {
          const folderName = filename.substring(
            filename.lastIndexOf('uploads/') + 8,
            filename.lastIndexOf('/')
          );
          folderNames.push(folderName);
        });
        const uniqueFolderNames = [...new Set(folderNames)]; //return name only once
        console.log('folderNames: ', uniqueFolderNames);

        dispatch(getFoldersSuccess(uniqueFolderNames));
      })
      .catch((error) => {
        console.log('error getting folders');
        console.log(error);
      });
  }
};

export const startGetFiles = () => ({
  type: 'START_GET_FILES'
});

export const getFilesSuccess = (files) => ({
  type: 'GET_FILES_SUCCESS',
  files
});

export const getFiles = (folderName) => {
  return (dispatch) => {
    dispatch(startGetFiles());
    axiosInstance.get(`xchange-files/${folderName}`)
      .then((response) => {
        console.log('response in files action: ', response);
        dispatch(getFilesSuccess(response.data));
      })
      .catch((error) => {
        console.log('error getting files');
        console.log(error);
      });
  }
};

export const startUploadFiles = () => ({
  type: 'START_UPLOAD_FILES'
});

export const uploadFilesSuccess = (files) => ({
  type: 'UPLOAD_FILES_SUCCESS',
  files
});

export const uploadFiles = (files, folderName) => {
  console.log('saving files', files);
  let formData = new FormData();
  files.map((file) => {
    formData.append('file', file);
  });
  return (dispatch) => {
    dispatch(startUploadFiles());

    const customAxiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {'content-type': 'multipart/form-data'}
    });

    customAxiosInstance.post(`upload/${folderName}`, formData)
      .then((response) => {
        dispatch(uploadFilesSuccess(response.data));
        console.log('files saved!');
      })
      .catch((error) => {
        dispatch(showUploadError());
        console.log(error);
      });
  }
};

export const showUploadError = (errorMessage) => ({
  type: 'SHOW_UPLOAD_ERROR',
  errorMessage
});

export const startMultipleUploadFiles = () => ({
  type: 'START_MULTIPLE_UPLOAD_FILES'
});

export const uploadMultipleFilesSuccess = (completed) => ({
  type: 'UPLOAD_MULTIPLE_FILES_SUCCESS',
  completed
});

export const saveMultiplePhotos = (files, subfolder, mainFolder) => async dispatch => {
  dispatch(startMultipleUploadFiles());
  let fileKeys = [];
  let fileNames = [];

  for (let file of files) {
    let body = {
      type: file.type,
      mainFolder: mainFolder,
      subFolder: subfolder,
      name: file.name.split('.').slice(0, -1).join('.'),
      extension: file.name.substr(file.name.lastIndexOf('.') + 1)
    };
    const uploadConfig = await axiosInstance.post('upload/?bucket=my-aspen-brook', body);

    console.log('file upload success');

    try {
      await axios.put(uploadConfig.data.url, file, {
        headers: {
          'Content-Type': file.type
        }
      });
    } catch (error) {
      dispatch(showUploadError());
      return;
    }

    fileKeys.push(uploadConfig.data.key);
    let cloudFrontImageLink = `dir80cd32z1m2.cloudfront.net/${uploadConfig.data.key}`;
    fileNames.push(cloudFrontImageLink);
  }

  dispatch(uploadMultipleFilesSuccess(true));

  return fileNames;

};

export const startSingleFileUpload = () => ({
  type: 'START_SINGLE_FILE_UPLOAD'
});

export const singleFileUploadSuccess = (completed) => ({
  type: 'SINGLE_FILE_UPLOAD_SUCCESS',
  completed
});

export const saveSingleFile = (file, subFolder, mainFolder) => async dispatch => {
  dispatch(startSingleFileUpload());

  //   let body = {
  //     type: file.type,
  //     folder: folderName,
  //     name: file.name.split('.').slice(0, -1).join('.'),
  //     extension: file.name.substr(file.name.lastIndexOf('.') + 1)
  //   };
  //
  //   // gets the url to upload from s3 first
  //   const uploadConfig = await axiosInstance.post('upload/?bucket=my-aspen-brook', body);
  //
  //   // once we have the pre-signed url we can put an object to amazon s3
  //   try {
  //     let res = await axios.put(uploadConfig.data.url, file, {
  //       headers: {
  //         'Content-Type': file.type
  //       }
  //     });
  //     console.log('response: ', res);
  //   } catch (error) {
  //     dispatch(showUploadError());
  //     return;
  //   }
  //
  // let cloudFrontImageLink = `dir80cd32z1m2.cloudfront.net/${uploadConfig.data.key}`;
  // dispatch(singleFileUploadSuccess(true));
  // return cloudFrontImageLink;

  console.log('subFolder: ', subFolder);

  let body = {
    type: file.type,
    name: file.name.split('.').slice(0, -1).join('.'),
    extension: file.name.substr(file.name.lastIndexOf('.') + 1),
    mainFolder: mainFolder,
    subFolder: subFolder
  };

  // gets the url to upload from s3 first
  const uploadConfig = await axiosInstance.post('upload/?bucket=my-aspen-brook', body);

  console.log('uploadConfig: ', uploadConfig);

  // once we have the pre-signed url we can put an object to amazon s3
  try {
    let res = await axios.put(uploadConfig.data.url, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    console.log('response: ', res);
  } catch (error) {
    dispatch(showUploadError());
    return;
  }

  let cloudFrontImageLink = `dir80cd32z1m2.cloudfront.net/${uploadConfig.data.key}`;
  let profile = {
    profileImage: cloudFrontImageLink
  };
  dispatch(singleFileUploadSuccess(true));
  return cloudFrontImageLink;

};