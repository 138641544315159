// Get Page
import axiosInstance from '../utility/axios';
import {startUserLogin} from "./sign-in";
import {startProfileUpdate} from "./profile";

export const startLoading = () => ({
  type: 'START_SIGNUP_LOADING',
});

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const accountCreated = (userInfo) => ({
  type: 'ACCOUNT_CREATED',
  userInfo
});

export const startCreateAccount = (userInfo) => {
  return (dispatch) => {
    dispatch(startLoading());
    axiosInstance.post('user', userInfo)
      .then((response) => {
        dispatch(accountCreated(userInfo));
        dispatch(startUserLogin(userInfo, true));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};