import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertAds } from './alert-types.js';
import ABDropzone from '../../../components/ABDropzone';
import { ToastContainer, toast } from 'react-toastify';
import { Microsite } from '../../../types/types.js';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const FormFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 750px;
`;

const AlertAdFormLeft = styled.div`
    /* width: 360px; */
`;

const AlertAdFormRight = styled.div`
    /* width: 360px; */
    padding-left: 40px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 450px;
  border: 1px solid ${colors.lightGray};
`;

interface AlertAdFormProps {
    alertItemId: number;
    pageTitle: string;
    microsites: Microsite[]
    handleSave: (alertAds: AlertAds) => void;
    handleUpdate: (alertAds: AlertAds) => void;
    alertAds: AlertAds;
    handleDelete: (id: number) => void;
}

const AlertAdForm: React.FC<AlertAdFormProps> = ({ alertAds, alertItemId, pageTitle, microsites, handleSave, handleUpdate, handleDelete }) => {

    const [id, setId] = useState(0);
    const [image, setImage] = useState('');
    const [imageUploaded, setImageUploaded] = useState(false);
    const [link, setLink] = useState('');
    const [position, setPosition] = useState(0);
    const [attribution, setAttribution] = useState('');
    const [editing, setEditing] = useState(false);
    const [micrositeLink, setMicrositeLink] = useState('');
    const [micrositeId, setMicrositeId] = useState(0);

    useEffect(() => {
        // check if editing
        if (alertAds.id !== 0) {
            setId(alertAds.id);
            setEditing(true);
            setImage(alertAds.image);
            setLink(alertAds.link);
            setPosition(alertAds.position);
            setAttribution(alertAds.attribution);
            setMicrositeLink(alertAds.micrositeLink);
            setMicrositeId(getIdFromURL(alertAds.micrositeLink));
        }
    }, [])

    const getIdFromURL = (urlString: string): number => {
        if (!urlString) {
            return 0;
        }
        let url = new URL(urlString);
        let id = url.searchParams.get("id");
        if (id) {
            return parseInt(id, 10);
        } else {
            return 0;
        }
    }

    const onSave = () => {

        if (imageUploaded && !image) {
            toast.error('Error Uploading Image');
            return;
        }

        const alertAds: AlertAds = {
            id,
            image,
            link,
            micrositeLink,
            alertItemId: alertItemId,
            position,
            attribution
        }

        if (editing) {
            handleUpdate(alertAds);
        } else {
            handleSave(alertAds);
        }
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        setLink(link);
    };

    const handleMicrositeLinkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value) {
            const micrositeId = parseInt(e.currentTarget.value, 10);
            const microsite = microsites.find(obj => obj.id === micrositeId);
            setMicrositeId(micrositeId);
            setMicrositeLink(`https://aspenalert.com/marketplace/microsite/${microsite?.pageTitle}?id=${micrositeId}`);
            console.log('microsite link: ', `https://aspenalert.com/marketplace/microsite/${microsite?.pageTitle}?id=${micrositeId}`);
        } else {
            setMicrositeId(0);
            setMicrositeLink('');
        }
    };

    const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const position = parseInt(e.currentTarget.value, 10);
        if (isNaN(position)) {
            setPosition(0);
        } else {
            setPosition(position);
        }
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const handleFilesDrop = (cloudfrontUrl: string) => {
        setImage(cloudfrontUrl);
        setImageUploaded(true);
    };

    const onDelete = () => {
        handleDelete(id);
    }

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormSubHeading>Image:</FormSubHeading>
                <ABDropzone onFilesDrop={handleFilesDrop} maxWidth={600} />

                {
                    (image && !imageUploaded) &&
                    <DefaultImagePreviewStyle>
                        <FormSubHeading>Image Preview:</FormSubHeading>
                        <img src={`https://${image}`} />
                    </DefaultImagePreviewStyle>
                }

                <FormSubHeading>Image Link:</FormSubHeading>
                <ABInputField
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="Link"
                />

                <FormSubHeading>View Microsite Link:</FormSubHeading>
                {
                    microsites &&
                    <StyledSelect
                        name="microsites"
                        value={micrositeId}
                        onChange={handleMicrositeLinkChange}
                    >
                        <option value=''>Select</option>
                        {
                            microsites?.length > 0 &&
                            microsites.map((item => {
                                return <option value={item.id}>{item.pageTitle} - {item.id}</option>
                            }))
                        }
                    </StyledSelect>
                }

                {/* <FormSubHeading>Position:</FormSubHeading>
                <ABInputField
                    value={position}
                    onChange={handlePositionChange}
                    placeholder="Position"
                /> */}

                <FormSubHeading>Attribution Tracking:</FormSubHeading>
                <ABInputField
                    value={attribution}
                    onChange={handleAttributionChange}
                    placeholder="Attribution"
                />

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                        <button title="Delete" onClick={() => onDelete()}>Delete</button>
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertAdForm;
