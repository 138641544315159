const mediaImagesDefaultState = {
  data: [],
  singleMediaImage: {},
  loading: false,
  error: false,
  deleteError: false,
  success: false,
  deleteSuccess: false,
  successMessage: '',
  errorMessage: null
};

const mediaImagesReducer = (state = mediaImagesDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_MEDIA_IMAGE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_MEDIA_IMAGE_SUCCESS':
      return {
        ...state,
        singleMediaImage: action.singleMediaImage,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_GET_MEDIA_IMAGES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_MEDIA_IMAGES_SUCCESS':
      return {
        ...state,
        data: action.mediaImages,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_MEDIA_IMAGE_UPLOAD':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'MEDIA_IMAGE_UPLOAD_SUCCESS':
      return {
        ...state,
        data: [...state.data, action.mediaImageDetails],
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Media image uploaded',
      };
    case 'START_MEDIA_IMAGE_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_MEDIA_IMAGE_SUCCESS':
      return {
        ...state,
        data: state.data.map((mediaImage) => {
          // console.log('mediaImage: ', mediaImage);
          // console.log('action.mediaImage: ', action.mediaImageDetails);
          if (mediaImage.id === action.mediaImageDetails.id) {
            console.log('got match');
            console.log('action.mediaImageDetails: ', action.mediaImageDetails);
            return {
              ...mediaImage,
              ...action.mediaImageDetails /// add object to array
            }
          } else {
            return mediaImage;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Microsite has been updated',
      };
    case 'START_REMOVE_MEDIA_IMAGES':
      return {
        ...state,
        loading: true,
        deleteError: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_MEDIA_IMAGES_SUCCESS':
      return {
        ...state,
        data: state.data.filter(mediaImage => { // check original posts and only include posts that don't have that to delete ID
          return !action.mediaImageIds.includes(mediaImage.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        deleteSuccess: true,
        deleteError: false,
        errorMessage: null,
        successMessage: 'Your ads have been removed!',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    case 'SHOW_DELETE_ERROR':
      return {
        ...state,
        loading: false,
        deleteError: true
      };
    default:
      return state;
  }
};

export default mediaImagesReducer;
