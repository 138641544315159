import axiosInstance from '../utility/axios';
import {startLoading} from "./sign-up";
import {history} from "../routers/AppRouter";
import {signOut} from "./sign-in";

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetCategories = () => ({
  type: 'START_GET_CATEGORIES',
});

export const getCategoriesSuccess = (categories) => ({
  type: 'GET_CATEGORIES_SUCCESS',
  categories
});

export const getCategories = () => {
  return (dispatch) => {
    dispatch(startGetCategories());
    axiosInstance.get(`categories`)
      .then((response) => {
        console.log('categories: ', response.data);
        dispatch(getCategoriesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startGetCategoriesByPostIds = () => ({
  type: 'START_GET_CATEGORIES_BY_POST_IDS',
});

export const getCategoriesByPostIdsSuccess = (categories) => ({
  type: 'GET_CATEGORIES__BY_POST_IDS_SUCCESS',
  categories
});

export const getCategoriesByPostIds = (postTypeIds) => {
  return (dispatch) => {
    dispatch(startGetCategoriesByPostIds(postTypeIds));
    console.log('postTypeIds!!!: ', postTypeIds);
    axiosInstance.post(`categories-multiple-post-types`, postTypeIds)
      .then((response) => {
        dispatch(getCategoriesByPostIdsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreateCategory = () => ({
  type: 'START_CREATE_CATEGORY'
});

export const createCategorySuccess = (category) => ({
  type: 'CREATE_CATEGORY_SUCCESS',
  category
});

export const createCategory = (category) => {
  console.log('creating category', category);
  return (dispatch) => {
    dispatch(startCreateCategory());
    axiosInstance.post('category', category)
      .then((response) => {
        dispatch(createCategorySuccess(response.data));
        console.log('category created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startCategoriesUpdate = () => ({
  type: 'START_CATEGORY_UPDATE',
});

export const updateCategoriesSuccess = (categories) => ({
  type: 'CATEGORY_UPDATE_SUCCESS',
  categories
});

export const updateCategories = (categories) => {
  return (dispatch) => {
    dispatch(startCategoriesUpdate());
    axiosInstance.put('post', categories)
      .then((response) => {
        dispatch(updateCategoriesSuccess(categories));
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};

