import axiosInstance from "../../utility/axios";

export const startGetAlertSurvey = () => ({
    type: 'START_GET_ALERT_SURVEY',
  });
  
  export const getAlertSurveySuccess = (alertSurvey) => ({
    type: 'GET_ALERT_SURVEY_SUCCESS',
    alertSurvey
  });
  
  export const getAlertSurvey = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-survey/${alertItemId}`;
      console.log('get alertSurvey url: ', url);
      dispatch(startGetAlertSurvey());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertSurvey: ', response.data);
          dispatch(getAlertSurveySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertSurveyCreate = () => ({
    type: 'START_ALERT_SURVEY_CREATE',
});

export const createAlertSurveySuccess = (alertSurvey) => ({
    type: 'CREATE_ALERT_SURVEY_SUCCESS',
    alertSurvey
});

export const createAlertSurvey = (alertSurvey) => {
    console.log('do alert survey: ', alertSurvey);
    return (dispatch) => {
        dispatch(startAlertSurveyCreate());
        axiosInstance.post(`alert-survey`, alertSurvey)
            .then((response) => {
                console.log('AlerttSurvey Create: ', response.data);
                dispatch(createAlertSurveySuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertSurveyUpdate = () => ({
    type: 'START_ALERT_SURVEY_UPDATE',
  });
  
  export const updateAlertSurveySuccess = (alertSurvey) => ({
    type: 'UPDATE_ALERT_SURVEY_SUCCESS',
    alertSurvey
  });
  
  export const updateAlertSurvey = (alertSurvey) => {
    console.log('alertSurvey: ', alertSurvey);
    return (dispatch) => {
      dispatch(startAlertSurveyUpdate());
      axiosInstance.put(`alert-survey/${alertSurvey.id}`, alertSurvey)
        .then((response) => {
          dispatch(updateAlertSurveySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});