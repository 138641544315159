import React, { Component } from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {startUserLogin} from "../actions/sign-in";
import Notification from "../components/Notification";
import colors from '../styles/colors';

import Logo from '../images/aspen-media-logo.jpg';
import Logo2x from '../images/aspen-media-logo@2x.jpg';
import ABButton from "../components/ABButton";

const PageWrapper = styled.div`
  max-width: 600px;
  margin: 75px  auto;
`;

const StyledLogo = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const ButtonPadding = styled.div`
  text-align: center;
  padding-top: 20px;
`;

const FormGroup = styled.div`
  margin: 20px 0;
`;

const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  font-size: 16px;
`;

class SignIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: ''
    };

  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => {
      return {
        email: email
      }
    });
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => {
      return {
        password: password
      }
    });
  };

  onSubmit = () => {
    if (this.state.email === '' || this.state.password === '') {
      this.setState(() => {
        console.log('validation error');
        return {
          error: 'Please fill out all fields'
        }
      });
    } else {
      let userInfo = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.startUserLogin(userInfo);
      this.setState(() => {
        return {
          email: '',
          password: '',
          error: ''
        }
      });
    }
  };

  render() {
    return (
      <PageWrapper>
        {
          this.state.error &&
          <Notification className="error" message={this.state.error} />
        }
        {
          this.props.error &&
          <Notification className="error" message={this.props.errorMessage} />
        }
        <div>
          <StyledLogo>
            <img src={Logo} alt="" srcSet={`${Logo} 1x, ${Logo2x} 2x`} />
          </StyledLogo>
            <FormGroup>
              <label>Email </label>
              <StyledInput
                autoFocus
                id="email"
                value={this.state.email}
                onChange={this.onEmailChange}
                required
                style={{marginTop: 8, marginBottom: 8}}
              />
            </FormGroup>
            <FormGroup>
              <label>Password </label>
              <StyledInput
                type="password"
                id="password"
                value={this.state.password}
                onChange={this.onPasswordChange}
                required
                style={{marginTop: 8, marginBottom: 8}}
              />
            </FormGroup>
            {
              !this.props.loading &&
              <ButtonPadding>
                <ABButton title="Log In" onClick={() => this.onSubmit()}/>
                {/*<div className="form-text">*/}
                  {/*<div className="signup-link">*/}
                    {/*<NavLink to="/sign-up" activeClassName="is-active">Create Account</NavLink>*/}
                  {/*</div>*/}
                  {/*<div className="password-trouble">*/}
                    {/*<a href="mailto:support@redfoxreviews.com">Forgot Password?</a>*/}
                  {/*</div>*/}
                {/*</div>*/}
              </ButtonPadding>
            }
            {
              this.props.loading &&
              <div className="loading-text">Loading...</div>
            }
        </div>
      </PageWrapper>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => ({
  startUserLogin: (userInfo) => dispatch(startUserLogin(userInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);