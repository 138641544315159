import axiosInstance from '../utility/axios';
import {startLoading} from "./sign-up";
import {history} from "../routers/AppRouter";
import {signOut} from "./sign-in";

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetPostTypes = () => ({
  type: 'START_GET_POST_TYPES',
});

export const getPostTypesSuccess = (postTypes) => ({
  type: 'GET_POST_TYPES_SUCCESS',
  postTypes
});

export const getPostTypes = () => {
  return (dispatch) => {
    dispatch(startGetPostTypes());
    axiosInstance.get('post-types')
      .then((response) => {
        console.log('postTypes: ', response);
        if (response.data.postTypes === false) { // no post yet
        } else { // has postTypes
          dispatch(getPostTypesSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreatePostType = () => ({
  type: 'START_CREATE_POST_TYPE'
});

export const createPostTypeSuccess = (postTypes) => ({
  type: 'CREATE_POST_TYPE_SUCCESS',
  postTypes
});

export const createPostType = (postTypes) => {
  console.log('creating post type', postTypes);
  return (dispatch) => {
    dispatch(startCreatePostType());
    axiosInstance.post('post types', postTypes)
      .then((response) => {
        dispatch(createPostTypeSuccess(response.data));
        console.log('post types created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startPostTypeUpdate = () => ({
  type: 'START_POST_TYPE_UPDATE',
});

export const updatePostTypeSuccess = (postTypes) => ({
  type: 'POST_TYPE_UPDATE_SUCCESS',
  postTypes
});

export const updatePostType = (postTypes) => {
  return (dispatch) => {
    dispatch(startPostTypeUpdate());
    axiosInstance.put('post', postTypes)
      .then((response) => {
        dispatch(updatePostTypeSuccess(postTypes));
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};

