import axiosInstance from '../utility/axios';

export const showBulkUpdatePostMicrositeError = (errorMessage) => ({
    type: 'SHOW_BULK_UPDATE_POST_MICROSITE_ERROR',
    errorMessage
  });

export const resetBulkUpdatePostMicrositeNotifications = () => ({
    type: 'RESET_BULK_UPDATE_POST_MICROSITE_NOTIFICATIONS'
});

export const startBulkUpdatePostMicrosite = () => ({
    type: 'START_BULK_UPDATE_POST_MICROSITE',
});

export const bulkUpdatePostMicrositeSuccess = (postIds) => ({
    type: 'BULK_UPDATE_POST_MICROSITE_SUCCESS',
    postIds
});

export const bulkUpdatePostMicrosite = (bulkDetails) => {
    return (dispatch) => {
        dispatch(startBulkUpdatePostMicrosite());
        axiosInstance.put(`bulk-update-post-microsite`, bulkDetails)
        .then((response) => {
            dispatch(bulkUpdatePostMicrositeSuccess(bulkDetails));
            setTimeout(() => {
                dispatch(resetBulkUpdatePostMicrositeNotifications());
            }, 3000)
        })
        .catch((error) => {
            dispatch(showBulkUpdatePostMicrositeError(error));
            console.log(error);
            setTimeout(() => {
                dispatch(resetBulkUpdatePostMicrositeNotifications());
            }, 3000)
        });
    }
};