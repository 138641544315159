const messagingReducerDefaultState = {
  data: {},
  loading: false,
  success: false,
  error: false,
  errorMessage: null
};

const messagingReducer = (state = messagingReducerDefaultState, action) => {
  switch(action.type) {
    case 'START_CREATE_MESSAGE':
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorMessage: null,
      };
    case 'CREATE_MESSAGE_SUCCESS':
      console.log('action: ', action);
      console.log('state: ', state);

      console.log(...state.data);
      console.log('...action.message ', ...action.message);
      return {
        ...state,
        data: action.message,
        loading: false,
        error: false,
        success: true,
        errorMessage: null
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default messagingReducer;