import axiosInstance from "../../utility/axios";

export const startGetAlertOtd = () => ({
    type: 'START_GET_ALERT_OTD',
  });
  
  export const getAlertOtdSuccess = (alertOtd) => ({
    type: 'GET_ALERT_OTD_SUCCESS',
    alertOtd
  });
  
  export const getAlertOtd = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-otd/${alertItemId}`;
      console.log('get alertOtd url: ', url);
      dispatch(startGetAlertOtd());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertOtd: ', response.data);
          dispatch(getAlertOtdSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertOtdCreate = () => ({
    type: 'START_ALERT_OTD_CREATE',
});

export const createAlertOtdSuccess = (alertOtd) => ({
    type: 'CREATE_ALERT_OTD_SUCCESS',
    alertOtd
});

export const createAlertOtd = (alertOtd) => {
    console.log('do alert otd: ', alertOtd);
    return (dispatch) => {
        dispatch(startAlertOtdCreate());
        axiosInstance.post(`alert-otd`, alertOtd)
            .then((response) => {
                console.log('Alert Otd Create: ', response.data);
                dispatch(createAlertOtdSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertOtdUpdate = () => ({
    type: 'START_ALERT_OTD_UPDATE',
  });
  
  export const updateAlertOtdSuccess = (alertOtd) => ({
    type: 'UPDATE_ALERT_OTD_SUCCESS',
    alertOtd
  });
  
  export const updateAlertOtd = (alertOtd) => {
    console.log('alertOtd: ', alertOtd);
    return (dispatch) => {
      dispatch(startAlertOtdUpdate());
      axiosInstance.put(`alert-otd/${alertOtd.id}`, alertOtd)
        .then((response) => {
          dispatch(updateAlertOtdSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});