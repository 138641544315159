import axiosInstance from '../utility/axios';
import {signOut} from "./sign-in";

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrositeGallery = () => ({
  type: 'START_GET_MICROSITE_GALLERY',
});

export const getMicrositeGallerySuccess = (micrositeGallery) => ({
  type: 'GET_MICROSITE_GALLERY_SUCCESS',
  micrositeGallery
});

export const getMicrositeGallery = (micrositeId) => {
  return (dispatch) => {
    dispatch(startGetMicrositeGallery());
    axiosInstance.get(`microsite-gallery/${micrositeId}`)
      .then((response) => {
        dispatch(getMicrositeGallerySuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function() {
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreateMicrositeGallery = () => ({
  type: 'START_CREATE_MICROSITE_GALLERY'
});

export const createMicrositeGallerySuccess = (micrositeGallery) => ({
  type: 'CREATE_MICROSITE_GALLERY_SUCCESS',
  micrositeGallery
});

export const createMicrositeGallery = (micrositeGallery, micrositeId) => {
  console.log('creating microsite gallery', micrositeGallery);
  return (dispatch) => {
    dispatch(startCreateMicrositeGallery());
    axiosInstance.post(`microsite-gallery/${micrositeId}`, micrositeGallery)
      .then((response) => {
        dispatch(createMicrositeGallerySuccess(response.data));
        console.log('microsite gallery created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startMicrositeGalleryUpdate = () => ({
  type: 'START_MICROSITE_GALLERY_UPDATE',
});

export const updateMicrositeGallerydSuccess = (micrositeGallery) => ({
  type: 'MICROSITE_GALLERY_UPDATE_SUCCESS',
  micrositeGallery
});

export const updateMicrositeGallery = (micrositeGallery, micrositeId) => {
  return (dispatch) => {
    dispatch(startMicrositeGalleryUpdate());
    console.log('micrositeGallery: ', micrositeGallery);
    console.log('micrositeId: ', micrositeId);
    axiosInstance.put(`microsite-gallery/${micrositeId}`, micrositeGallery)
      .then((response) => {
        dispatch(updateMicrositeGallerydSuccess(micrositeGallery));
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};
