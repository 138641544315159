import axiosInstance from '../utility/axios';
import {updatePostKeyWords} from './post-keywords';
import {postDocuments} from './post';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

// export const startGetPost = () => ({
//   type: 'START_GET_POST',
// });
//
// export const getPostSuccess = (post) => ({
//   type: 'GET_POST_SUCCESS',
//   post
// });
//
// export const getPost = (id) => {
//   return (dispatch) => {
//     dispatch(startGetPost());
//     axiosInstance.get(`post/${id}`)
//       .then((response) => {
//         if (response.data.post === false) { // no post yet
//         } else { // has post
//           dispatch(getPostSuccess(response.data));
//         }
//       })
//       .catch((error) => {
//         dispatch(showError(error));
//         console.log(error);
//       });
//   }
// };

export const startGetPosts = () => ({
  type: 'START_GET_POSTS',
});

export const getPostsSuccess = (posts) => ({
  type: 'GET_POSTS_SUCCESS',
  posts
});

export const getPosts = (keyword, limit, skip, selectedCategory) => {
  // console.log('selectedCategory: ', selectedCategory);
  let formattedCategoryIds = {};
  return (dispatch) => {
    let url = 'posts';
    if (limit && skip && keyword) {
      url = `posts?q=${keyword}&limit=${limit}&skip=${skip}`;
    } else if (limit && skip) {
      url = `posts?limit=${limit}&skip=${skip}`;
    } else if (keyword) {
      url = `posts?q=${keyword}`;
    }
    if (selectedCategory) {
      formattedCategoryIds.categoryIds = [selectedCategory];
    }

    dispatch(startGetPosts());
    axiosInstance.post(url, formattedCategoryIds)
      .then((response) => {
        console.log('posts: ', response);
        if (response.data.posts === false) { // no post yet
        } else { // has posts
          dispatch(getPostsSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

// export const startCreatePost = () => ({
//   type: 'START_CREATE_POST'
// });
//
// export const createPostSuccess = (postDetails) => ({
//   type: 'CREATE_POST_SUCCESS',
//   postDetails
// });
//
// export const createPost = (postDetails, multipleCloudFrontUrls, keywords) => {
//   console.log('creating post', postDetails);
//   return (dispatch) => {
//     dispatch(startCreatePost());
//     axiosInstance.post('post', postDetails)
//       .then((response) => {
//         console.log('post created for check');
//         console.log('Post created response: ', response.data);
//         if (keywords.length > 0) {
//           dispatch(updatePostKeyWords(keywords, response.data.id))
//         }
//         if (multipleCloudFrontUrls.length > 0) {
//           dispatch(postDocuments(response.data.id, multipleCloudFrontUrls));
//         }
//         dispatch(createPostSuccess(response.data));
//         console.log('post created!');
//       })
//       .catch((error) => {
//         dispatch(showError());
//         console.log(error);
//       });
//   }
// };
//
// export const postDocuments = (postId, multipleCloudFrontUrls) => {
//   return (dispatch) => {
//     console.log('postDocuments postId', postId);
//     console.log('postDocuments multipleCloudFrontUrls', multipleCloudFrontUrls);
//
//     let formattedDocumentURLs = {
//       documents: []
//     };
//
//     multipleCloudFrontUrls.map((item) => {
//       let imageItem = {
//         imageUrl: item
//       };
//       formattedDocumentURLs.documents.push(imageItem);
//     });
//
//     dispatch(startCreatePost());
//     axiosInstance.post(`documents/${postId}`, formattedDocumentURLs)
//       .then((response) => {
//         // added documents
//       })
//       .catch((error) => {
//         dispatch(showError());
//         console.log(error);
//       });
//   }
// };
//
// export const startPostUpdate = () => ({
//   type: 'START_POST_UPDATE',
// });
//
// export const updatePostSuccess = (postDetails) => ({
//   type: 'UPDATE_POST_SUCCESS',
//   postDetails
// });
//
// export const updatePost = (postDetails, postId, multipleCloudFrontUrls, keywords) => {
//   return (dispatch) => {
//     console.log('keywords.length in action: ', keywords.length);
//     dispatch(startPostUpdate());
//     axiosInstance.put(`post/${postId}`, postDetails)
//       .then((response) => {
//         if (keywords.length > 0) {
//           dispatch(updatePostKeyWords(keywords, response.data.id))
//         }
//         if (multipleCloudFrontUrls.length > 0) {
//           dispatch(postDocuments(response.data.id, multipleCloudFrontUrls));
//         }
//         dispatch(updatePostSuccess(postDetails));
//       })
//       .catch((error) => {
//         dispatch(showError(error));
//         console.log(error);
//       });
//   }
// };

// update a single post title and status in the array of posts
// helps show a change on the main posts page
export const updatePostInPosts = (postDetails) => ({
  type: 'UPDATE_POST_IN_POSTS_SUCCESS',
  postDetails
});

export const startRemovePosts = () => ({
  type: 'START_REMOVE_POSTS'
});

export const removePostsSuccess = (postIds) => ({
  type: 'REMOVE_POSTS_SUCCESS',
  postIds
});

export const removePosts = (postIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: postIds
    };
    dispatch(startRemovePosts());
    axiosInstance.delete('posts', { data: items })
      .then((response) => {
        dispatch(removePostsSuccess(postIds));
        console.log('posts removed!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};
