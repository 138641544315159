import axiosInstance from '../utility/axios';

export const startLoading = () => ({
  type: 'START_SUPPORT_LOADING',
});

export const showError = () => ({
  type: 'SHOW_ERROR'
});

export const supportRequestSubmitted = () => ({
  type: 'SUPPORT_REQUEST_SENT'
});

export const startSupportRequest = ({name, email, message}) => {
  let url = `support/?name=${name}&email=${email}&body=${message}&type=support`;
  return (dispatch) => {
    dispatch(startLoading());
    axiosInstance.get(url)
      .then((response) => {
        console.log('support request submitted!');
        dispatch(supportRequestSubmitted());
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};