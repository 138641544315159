const micrositesDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const micrositesReducer = (state = micrositesDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_MICROSITES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_MICROSITES_SUCCESS':
      return {
        ...state,
        data: action.microsites,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_CREATE_MICROSITE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'START_REMOVE_MICROSITES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_MICROSITES_SUCCESS':
      return {
        ...state,
        data: state.data.filter(microsite => { // check original microsites and only include microsites that don't have that to delete ID
          return !action.micrositeIds.includes(microsite.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Microsite has been removed!',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default micrositesReducer;
