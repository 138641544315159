import React, {Component} from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
import Logo from './images/logo.png';
import Logo2x from './images/logo@2x.png';

const LogoWrapper = styled.div`
  text-align: center;
  display: block;
  @media all and (min-width: 1281px) {
    text-align: left;
  }
`;

const LogoImage = styled.img`
  margin: 40px 0;
  width: 100%;
  max-width: 425px;
  height: auto;
`;

class HeaderLogo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <LogoWrapper>
          {/*<NavLink to="/home">*/}
            {/*<LogoImage src={Logo} alt="" srcSet={`${Logo} 1x, ${Logo2x} 2x`} />*/}
          {/*</NavLink>*/}
          <div>
            <LogoImage src={Logo} alt="" srcSet={`${Logo} 1x, ${Logo2x} 2x`} />
          </div>
        </LogoWrapper>
      </div>
    )};
}

export { HeaderLogo as default};