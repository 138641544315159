import { history } from "../../routers/AppRouter";
import axiosInstance from "../../utility/axios";
import { showError } from "../ads";
import { getAlertItemsSuccess } from "./alert-items";

export const startGetAlertItem = () => ({
    type: 'START_GET_ALERT_ITEM',
});

export const getAlertItemSuccess = (alertDetails) => ({
    type: 'GET_ALERT_ITEM_SUCCESS',
    alertDetails
});

export const getAlertItem = (id) => {
    return (dispatch) => {
        let url = `alert-item/${id}`;
        dispatch(startGetAlertItem());
        axiosInstance.get(url)
            .then((response) => {
                // console.log('getAlertItem data response: ', response.data);
                dispatch(getAlertItemSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertItemCreate = () => ({
    type: 'START_ALERT_CREATE',
});

export const createAlertItemSuccess = (alertDetails) => ({
    type: 'CREATE_ALERT_SUCCESS',
    alertDetails
});

export const createAlertItem = (alertDetails) => {
    return (dispatch) => {
        dispatch(startAlertItemCreate());
        axiosInstance.post(`alert-item`, alertDetails)
            .then((response) => {
                dispatch(createAlertItemSuccess(response.data));
                history.push(`../alerts`);
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertItemUpdate = () => ({
    type: 'START_ALERT_ITEM_UPDATE',
});

export const updateAlertItemSuccess = (alertDetails) => ({
    type: 'UPDATE_ALERT_ITEM_SUCCESS',
    alertDetails
});

export const updateAlertItem = (alertDetails) => {
    return (dispatch) => {
        dispatch(startAlertItemUpdate());
        axiosInstance.put(`alert-item/${alertDetails.id}`, alertDetails)
            .then((response) => {
                dispatch(updateAlertItemSuccess(alertDetails));
                setTimeout(() => {
                    dispatch(clearAlertItemSuccess());
                }, 3000)
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};


export const clearAlertItemSuccess = () => ({
    type: 'ALERT_CLEAR_SUCCESS',
});