import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import {history} from '../../../routers/AppRouter';
import {getMicrosite, updateMicrosite} from '../../../actions/microsite';
import {updateMicrositeSetting} from '../../../actions/microsite-settings';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

function ClientPortalAccess(props) {

const [clientPortalId, setClientPortalId] = useState(null);

useEffect(() => {
    if (props.match.params.id) {
        props.getMicrosite(props.match.params.id);
    }
}, []);

useEffect(() => {
    if (Object.keys(props.microsite).length !== 0) { // load ui fields once object comes in
        loadFields();
    }
}, [props.microsite]); // call function when this dependency value changes

  const loadFields = () => {
    setClientPortalId(props.microsite.clientPortalId);
  };

  const handleClientPortalIdChange = (e) => {
    const clientPortalId = e.target.value;
    setClientPortalId(clientPortalId);
  };

  const onNextClick = (close) => {
    let microsite = {
      clientPortalId: clientPortalId
    };
    props.updateMicrosite(microsite, props.microsite.id);

    if (close) {
      history.push(`../${props.microsite.id}`);
      return;
    }
  };
    
  return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Client Portal Access</PageTitle>
          </PageHeading>
          <FormContainer>
            <FormSection>
              <FormSubHeading>Client Portal Id:</FormSubHeading>
              <FormField>
                  <FormInputFull
                    value={clientPortalId}
                    onChange={handleClientPortalIdChange}
                    type="text"
                    rows= "2"
                    cols="10"
                  />
                </FormField>
              <ABButton title="Save & Close" onClick={() => onNextClick(true)} />
            </FormSection>
          </FormContainer>

        </PageWrapper>
      </>
    );
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientPortalAccess);
