import {CATEGORY_NAME, POST_TYPE_NAME} from '../utility/constants';

const postDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const postReducer = (state = postDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_POST':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_POST_SUCCESS':
      return {
        ...state,
        data: action.post,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'CREATE_POST_SUCCESS':
      return {
        ...state,
        data: [
          action.postDetails,
          ...state.data,
        ],
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Post has been added!',
      };
    case 'UPDATE_POST_SUCCESS': // updating single post
      return {
        ...state,
        data: state.data.map((post) => {
          // console.log('post: ', post);
          // console.log('action.post: ', action.postDetails);
          if (post.id === action.postDetails.id) {
            console.log('got match');
            return {
              ...post,
              ...action.postDetails /// add object to array
            }
          } else {
            return post;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_POST_SUCCESS':
      return {
        ...state,
        data: state.data.filter(post => action.postId !== post.postId), // filter original state and return any that don't have the remove postId
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your post has been removed',
      };
    case 'SHOW_POSTS_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default postReducer;
