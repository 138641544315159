import axiosInstance from "../utility/axios";
import {showError} from "./ads";

export const startGetAlerts = () => ({
    type: 'START_GET_ALERTS',
});

export const getAlertsSuccess = (alerts) => ({
    type: 'GET_ALERTS_SUCCESS',
    alerts
});

export const getAlerts = (queryParams) => {
    return (dispatch) => {
        let url = 'alerts';
        if (queryParams) {
            url = `alerts`;
        }
        console.log('get alerts url: ', url);
        dispatch(startGetAlerts());
        axiosInstance.get(url)
            .then((response) => {
                dispatch(getAlertsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertUpdate = () => ({
    type: 'START_ALERT_UPDATE',
});

export const updateAlertSuccess = (alertDetails) => ({
    type: 'UPDATE_ALERT_SUCCESS',
    alertDetails
});

export const updateAlert = (alertDetails, alertId) => {
    return (dispatch) => {
        dispatch(startAlertUpdate());
        axiosInstance.put(`alert/${alertId}`, alertDetails)
            .then((response) => {
                dispatch(updateAlertSuccess(alertDetails));
                setTimeout(() => {
                    dispatch(clearAlertSuccess());
                }, 3000)
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};


export const clearAlertSuccess = () => ({
    type: 'ALERT_CLEAR_SUCCESS',
});