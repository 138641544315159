import React, { Component } from 'react';
import styled from 'styled-components';

class Admin extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div>
        <div>Admin Page</div>
      </div>
    )
  }
}

export default Admin;