import axiosInstance from "../../utility/axios";

export const startGetAlertRegulatory = () => ({
    type: 'START_GET_ALERT_REGULATORY',
  });
  
  export const getAlertRegulatorySuccess = (alertRegulatory) => ({
    type: 'GET_ALERT_REGULATORY_SUCCESS',
    alertRegulatory
  });
  
  export const getAlertRegulatory = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-regulatory/${alertItemId}`;
      console.log('get alertRegulatory url: ', url);
      dispatch(startGetAlertRegulatory());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertRegulatory: ', response.data);
          dispatch(getAlertRegulatorySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertRegulatoryCreate = () => ({
    type: 'START_ALERT_REGULATORY_CREATE',
});

export const createAlertRegulatorySuccess = (alertRegulatory) => ({
    type: 'CREATE_ALERT_REGULATORY_SUCCESS',
    alertRegulatory
});

export const createAlertRegulatory = (alertRegulatory) => {
    console.log('do alert regulatory: ', alertRegulatory);
    return (dispatch) => {
        dispatch(startAlertRegulatoryCreate());
        axiosInstance.post(`alert-regulatory`, alertRegulatory)
            .then((response) => {
                console.log('Alert Regulatory Create: ', response.data);
                dispatch(createAlertRegulatorySuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertRegulatoryUpdate = () => ({
    type: 'START_ALERT_REGULATORY_UPDATE',
  });
  
  export const updateAlertRegulatorySuccess = (alertRegulatory) => ({
    type: 'UPDATE_ALERT_REGULATORY_SUCCESS',
    alertRegulatory
  });
  
  export const updateAlertRegulatory = (alertRegulatory) => {
    console.log('alertRegulatory: ', alertRegulatory);
    return (dispatch) => {
      dispatch(startAlertRegulatoryUpdate());
      axiosInstance.put(`alert-regulatory/${alertRegulatory.id}`, alertRegulatory)
        .then((response) => {
          dispatch(updateAlertRegulatorySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});