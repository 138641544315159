import React, { Component } from 'react';
import ABButton from "../components/ABButton";
import {connect} from "react-redux";
import './Support.css';
import {startSupportRequest} from "../actions/support";
import Notification from "../components/Notification";

class Support extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      error: ''
    };
  }

  componentDidUpdate(prevProps) {
    // compare props, make sure it's different or they will loop
    // make sure success is true as well
    if (this.props.success !== prevProps.success && this.props.success) {
      this.clearFields();
    }
  }

  onNameChange = (e) => {
    const name = e.target.value;
    this.setState(() => {
      return {
        name: name
      }
    });
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => {
      return {
        email: email
      }
    });
  };

  onMessageChange = (e) => {
    const message = e.target.value;
    this.setState(() => {
      return {
        message: message
      }
    });
  };

  clearFields() {
    this.setState(() => {
      return {
        name: '',
        email: '',
        message: '',
        error: ''
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.name === '' || this.state.email === '' || this.state.message === '') {
      this.setState(() => {
        console.log('validation error');
        return {
          error: 'Please fill out all fields'
        }
      });
    } else {
      let formFields = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      };
      this.props.startSupportRequest(formFields);
    }
  };

  render() {
    return (
      <div className="container">
        {
          this.props.success &&
          <Notification className="error" message="Support request sent" />
        }
        {
          this.state.error && !this.props.success &&
          <Notification className="error" message={this.state.error} />
        }
        {
          this.props.error && !this.props.success &&
          <Notification className="error" message="Sorry an error occurred, please email support@boostworkapp.com" />
        }
        <div className="page-content">
          <div className="page-heading">Contact Support</div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="John Doe"
                className="form-control"
                autoFocus
                value={this.state.name}
                onChange={this.onNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                placeholder="johndoe@gmail.com"
                className="form-control"
                value={this.state.email}
                onChange={this.onEmailChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="6"
                placeholder="Describe your problem or leave us a suggestion."
                value={this.state.message}
                onChange={this.onMessageChange}
              />
            </div>
            {
              !this.props.loading &&
              <ABButton title="Submit" shadow={true} />
            }
            {
              this.props.loading &&
              <div className="loading-text">Loading...</div>
            }
          </form>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.support.loading,
    error: state.support.error,
    success: state.support.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  startSupportRequest: (formFields) => dispatch(startSupportRequest(formFields)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Support);