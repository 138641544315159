const supportReducerDefaultState = {
  data: [],
  loading: false,
  error: false,
  success: false
};

const supportReducer = (state = supportReducerDefaultState, action) => {
  switch(action.type) {
    case 'START_SUPPORT_LOADING':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'SUPPORT_REQUEST_SENT':
      return {
        ...state,
        loading: false,
        error: false,
        success: true
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};

export default supportReducer;