const adsDefaultState = {
  data: [],
  singleAd: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const adsReducer = (state = adsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_AD':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_AD_SUCCESS':
      return {
        ...state,
        singleAd: action.singleAd,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_GET_ADS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_ADS_SUCCESS':
      return {
        ...state,
        data: action.ads,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_AD_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_AD_SUCCESS':
      return {
        ...state,
        data: state.data.map((ad) => {
          // console.log('ad: ', ad);
          // console.log('action.ad: ', action.adDetails);
          if (ad.id === action.adDetails.id) {
            console.log('got match');
            return {
              ...ad,
              ...action.adDetails /// add object to array
            }
          } else {
            return ad;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Ad has been updated',
      };
    case 'START_REMOVE_ADS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_ADS_SUCCESS':
      return {
        ...state,
        data: state.data.filter(ad => { // check original ads and only include ads that don't have that to delete ID
          return !action.adIds.includes(ad.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your ads have been removed!',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default adsReducer;