const profileDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const profileReducer = (state = profileDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_PROFILE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_PROFILE_SUCCESS':
      return {
        ...state,
        data: action.profile,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_CREATE_PROFILE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'CREATE_PROFILE_SUCCESS':
      return {
        ...state,
        data: action.profileInfo,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your account has been created!',
      };
    case 'START_PROFILE_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'PROFILE_UPDATE_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.profileInfo
        },
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default profileReducer;