import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { saveSingleFile, uploadFilesSuccess } from '../actions/upload';

const LoadingStyle = styled.div`
  font-weight: bold;
  color: red;
`

interface FileDropzoneProps {
  onFilesDrop: (cloudfrontUrl: string) => void;
  saveSingleFile: (file: File, subFolder: string, mainFolder: string) => Promise<any>;
  maxWidth?: number;
}

const ABDropzone: React.FC<FileDropzoneProps & { uploadSuccess: boolean, uploadLoading: boolean }> = ({ onFilesDrop, saveSingleFile, uploadSuccess, uploadLoading, maxWidth }) => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    if (maxWidth) {
      const file = acceptedFiles[0];
      if (file && file.type.startsWith('image/')) {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          if (image.width > maxWidth) {
            alert(`File width exceeds the maximum allowed width of ${maxWidth}px`);
          } else {
            setFiles(acceptedFiles);
            saveImageToS3(acceptedFiles[0]);
          }
        };
      }
    } else {
      setFiles(acceptedFiles);
      saveImageToS3(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const saveImageToS3 = async (file: File) => {
    if (file) {
      try {
        const cloudfrontUrl = await saveSingleFile(file, 'images', 'alerts');
        if (cloudfrontUrl) {
          onFilesDrop(cloudfrontUrl);
        }
      } catch (error) {
        console.error('Error saving file to S3:', error);
      }
    }
  };

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag and drop files here or click to select files</p>
      </div>
      <div>
        {files.map((file) => (
          <div key={file.name} style={filePreviewStyles}>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={imageStyles}
            />
            {
              (uploadLoading && !uploadSuccess) &&
              <LoadingStyle>loading...</LoadingStyle>
            }
            <p>{file.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const dropzoneStyles: React.CSSProperties = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
};

const filePreviewStyles: React.CSSProperties = {
  marginTop: '10px',
  display: 'inline-block',
};

const imageStyles: React.CSSProperties = {
  width: '100px',
  height: '100px',
  objectFit: 'cover',
};

const mapStateToProps = (state: any) => {
  return {
    uploadLoading: state.upload.uploadLoading,
    uploadSuccess: state.upload.uploadSuccess
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  saveSingleFile: (file: File, subFolder: string, mainFolder: string) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ABDropzone);