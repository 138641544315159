import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertNews } from './alert-types.js';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ABDropzone from '../../../components/ABDropzone';
import ABSelect from '../../../components/ABSelect';
import { ToastContainer, toast } from 'react-toastify';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const FormFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 750px;
`;

const AlertFormLeft = styled.div`
    /* width: 360px; */
`;

const AlertFormRight = styled.div`
    /* width: 360px; */
    padding-left: 40px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

interface AlertFormProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertNews: AlertNews) => void;
    handleUpdate: (alertNews: AlertNews) => void;
    handleDelete: (id: number) => void;
    alertNews: AlertNews
}

const toolbarConfig = {
    options: [
        'inline',
        'textAlign',
        'link'
    ],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify'],
    },
    link: {
        options: ['link', 'unlink'],
    }
};

const AlertForm: React.FC<AlertFormProps> = ({ alertNews, alertItemId, pageTitle, handleSave, handleUpdate, handleDelete }) => {

    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [link, setLink] = useState('');
    const [position, setPosition] = useState(1);
    const [citation, setCitation] = useState('');
    const [attribution, setAttribution] = useState('');
    const [editing, setEditing] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        // check if editing
        if (alertNews.id !== 0) {
            setId(alertNews.id);
            setEditing(true);
            setTitle(alertNews.title);
            setImage(alertNews.image);
            setImageHeight(alertNews.imageHeight || 0);
            setImageWidth(alertNews.imageWidth || 0);
            setLink(alertNews.link);
            setPosition(alertNews.position);
            setAttribution(alertNews.attribution);
            setCitation(alertNews.citation || '');

            const contentBlock = htmlToDraft(alertNews.body);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [alertNews])

    const onSave = () => {

        if (imageUploaded && !image) {
            toast.error('Error Uploading Image');
            return;
        }

        const editorBody = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const alertNews: AlertNews = {
            id,
            title,
            body: editorBody,
            image,
            link,
            alertItemId: alertItemId,
            position,
            citation,
            attribution
        }

        if (imageHeight !== null && imageHeight !== 0) {
            alertNews.imageHeight = imageHeight
        }
        if (imageWidth !== null && imageWidth !== 0) {
            alertNews.imageWidth = imageWidth
        }

        if (editing) {
            handleUpdate(alertNews);
        } else {
            handleSave(alertNews);
        }
    };

    const onDelete = () => {
        handleDelete(id);
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const title = e.currentTarget.value;
        setTitle(title);
    };

    const handleImageHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageHeight = e.currentTarget.value;
        setImageHeight(parseInt(imageHeight, 10));
    };

    const handleImageWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageWidth = e.currentTarget.value;
        setImageWidth(parseInt(imageWidth, 10));
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        setLink(link);
    };

    const handlePositionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const position = e.currentTarget.value;
        setPosition(parseInt(position, 10));
    };

    const handleCitationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const citation = e.currentTarget.value;
        setCitation(citation);
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    const handleFilesDrop = (cloudfrontUrl: string) => {
        setImage(cloudfrontUrl);
        setImageUploaded(true);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormFlex>
                    <AlertFormLeft>
                        <FormSubHeading>Title:</FormSubHeading>
                        <ABInputField
                            value={title}
                            onChange={handleTitleChange}
                            placeholder="Title"
                        />

                        <FormSubHeading>Body:</FormSubHeading>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="post-editor"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={toolbarConfig}
                            stripPastedStyles={true}
                        />
                        {/* <StyledTextArea
                            name="Body"
                            cols={30}
                            rows={5}
                            value={body}
                            onChange={handleBodyChange}
                        ></StyledTextArea> */}
                    </AlertFormLeft>

                    <AlertFormRight>
                        <FormSubHeading>Image:</FormSubHeading>
                        <ABDropzone onFilesDrop={handleFilesDrop} maxWidth={600} />

                        {
                            (image && !imageUploaded) &&
                            <DefaultImagePreviewStyle>
                                <FormSubHeading>Image Preview:</FormSubHeading>
                                <img src={`https://${image}`} />
                            </DefaultImagePreviewStyle>
                        }

                        <FormSubHeading>Image Height:</FormSubHeading>
                        <ABInputField
                            value={imageHeight || ''}
                            onChange={handleImageHeightChange}
                            placeholder="height"
                        />

                        <FormSubHeading>Image Width:</FormSubHeading>
                        <ABInputField
                            value={imageWidth || ''}
                            onChange={handleImageWidthChange}
                            placeholder="width"
                        />

                        <FormSubHeading>Image Credit/Citation:</FormSubHeading>
                        <ABInputField
                            value={citation}
                            onChange={handleCitationChange}
                            placeholder="Citation"
                        />

                        <FormSubHeading>Link for image/learn more:</FormSubHeading>
                        <ABInputField
                            value={link}
                            onChange={handleLinkChange}
                            placeholder="Link"
                        />

                        <FormSubHeading>Position:</FormSubHeading>
                        <ABSelect
                            value={position}
                            onChange={handlePositionChange}
                            placeholder="Position"
                        />

                        <FormSubHeading>Attribution for Site Search:</FormSubHeading>
                        <ABInputField
                            value={attribution}
                            onChange={handleAttributionChange}
                            placeholder="Attribution"
                        />
                    </AlertFormRight>
                </FormFlex>

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                        <button title="Delete" onClick={() => onDelete()}>Delete</button>
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertForm;
