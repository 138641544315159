import { useEffect, useState } from "react";
import axios from "axios";
import { AlertData, AlertPatent } from "../pages/admin/alerts/alert-types";

function useAlert(url: string) {
    const [data, setData] = useState<AlertData | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('data in hook: ', url);
        setLoading(true);
        axios.get(url)
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [url]);

    const refetch = () => {
        setLoading(true);
        axios.get(url)
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return { data, loading, error, refetch };
}

export default useAlert;