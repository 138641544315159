import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertNews, deleteAlertNews, updateAlertNews } from '../../../../actions/alert/alert-news';
import styled from 'styled-components';
import { AlertNews } from "../alert-types";
import Modal from 'react-modal';
import AlertNewsForm from "../AlertNewsForm";
import colors from '../../../../styles/colors';

interface ImageStyleProps {
  width?: number;
}

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertNews: AlertNews;
  buttonTitle: string;
  createAlertNews: (alertNews: AlertNews) => void;
  updateAlertNews: (alertNews: AlertNews) => void;
  deleteAlertNews: (id: number) => void;
}

const AlertComponentA: React.FC<Props> = ({ alertItemId, alertNews, buttonTitle, createAlertNews, updateAlertNews, deleteAlertNews }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertNews, setNewAlertNews] = useState<AlertNews>({ id: 0, title: '', body: '', image: '', link: '', alertItemId: 0, position: 0, attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertNews: AlertNews) => {
    setNewAlertNews(alertNews);
    setModalIsOpen(true);
  }

  const handleSave = (alertNews: AlertNews) => {
    createAlertNews(alertNews);
    closeModal();
  }

  const handleUpdate = (alertNews: AlertNews) => {
    updateAlertNews(alertNews);
    closeModal();
  }

  const handleDelete = (id: number) => {
    deleteAlertNews(id);
    closeModal();
  }

  return (
    <>
      {
        alertNews ?
          <>
            <TitleStyle>{alertNews.title}</TitleStyle>
            {
              alertNews.imageWidth &&
              <ImageStyle width={alertNews.imageWidth}> <a href={alertNews.link}><img src={`https://${alertNews.image}`} alt="" width={alertNews.imageWidth} /></a></ImageStyle>
            }
            {
              !alertNews.imageWidth &&
              <ImageStyle> <a href={alertNews.link}><img src={`https://${alertNews.image}`} alt="" /></a></ImageStyle>
            }
            <CitationStyle>{alertNews.citation}</CitationStyle>
            <BodyStyle><div dangerouslySetInnerHTML={{ __html: alertNews.body }} /></BodyStyle>
            <ButtonContainer>
              <LearnMoreLink><a href={alertNews.link}>Learn More</a></LearnMoreLink>
              <EditButton onClick={() => handleEdit(alertNews)}>Edit</EditButton>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertNewsForm
          handleSave={handleSave}
          handleDelete={handleDelete}
          alertItemId={alertItemId}
          alertNews={newAlertNews}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertNews: state.alertNews.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertNews: (alertNews: AlertNews) => dispatch(createAlertNews(alertNews)),
  updateAlertNews: (alertNews: AlertNews) => dispatch(updateAlertNews(alertNews)),
  deleteAlertNews: (id: number) => dispatch(deleteAlertNews(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentA);