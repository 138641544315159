import axiosInstance from "../../utility/axios";

export const startGetAlertPreviousPoll = () => ({
    type: 'START_GET_ALERT_PREVIOUS_POLL',
  });
  
  export const getAlertPreviousPollSuccess = (alertPreviousPoll) => ({
    type: 'GET_ALERT_PREVIOUS_POLL_SUCCESS',
    alertPreviousPoll
  });
  
  export const getAlertPreviousPoll = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-previous-poll/${alertItemId}`;
      console.log('get alertPreviousPoll url: ', url);
      dispatch(startGetAlertPreviousPoll());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertPreviousPoll: ', response.data);
          dispatch(getAlertPreviousPollSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertPreviousPollCreate = () => ({
    type: 'START_ALERT_PREVIOUS_POLL_CREATE',
});

export const createAlertPreviousPollSuccess = (alertPreviousPoll) => ({
    type: 'CREATE_ALERT_PREVIOUS_POLL_SUCCESS',
    alertPreviousPoll
});

export const createAlertPreviousPoll = (alertPreviousPoll) => {
    console.log('do alert previous-polls: ', alertPreviousPoll);
    return (dispatch) => {
        dispatch(startAlertPreviousPollCreate());
        axiosInstance.post(`alert-previous-poll`, alertPreviousPoll)
            .then((response) => {
                dispatch(createAlertPreviousPollSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertPreviousPollUpdate = () => ({
    type: 'START_ALERT_PREVIOUS_POLL_UPDATE',
  });
  
  export const updateAlertPreviousPollSuccess = (alertPreviousPoll) => ({
    type: 'UPDATE_ALERT_PREVIOUS_POLL_SUCCESS',
    alertPreviousPoll
  });
  
  export const updateAlertPreviousPoll = (alertPreviousPoll) => {
    console.log('alertPreviousPoll: ', alertPreviousPoll);
    return (dispatch) => {
      dispatch(startAlertPreviousPollUpdate());
      axiosInstance.put(`alert-previous-poll/${alertPreviousPoll.id}`, alertPreviousPoll)
        .then((response) => {
          dispatch(updateAlertPreviousPollSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});