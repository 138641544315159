import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrosites = () => ({
  type: 'START_GET_MICROSITES',
});

export const getMicrositesSuccess = (microsites) => ({
  type: 'GET_MICROSITES_SUCCESS',
  microsites
});

export const getMicrosites = () => {
  return (dispatch) => {
    dispatch(startGetMicrosites());
    axiosInstance.get('microsites')
      .then((response) => {
        console.log('microsites: ', response);
        if (response.data.microsites === false) { // no post yet
        } else { // has microsites
          dispatch(getMicrositesSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startRemoveMicrosites = () => ({
  type: 'START_REMOVE_MICROSITES'
});

export const removeMicrositesSuccess = (micrositeIds) => ({
  type: 'REMOVE_MICROSITES_SUCCESS',
  micrositeIds
});

export const removeMicrosites = (micrositeIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: micrositeIds
    };
    dispatch(startRemoveMicrosites());
    axiosInstance.delete('microsites', { data: items })
      .then((response) => {
        dispatch(removeMicrositesSuccess(micrositeIds));
        console.log('microsites removed!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

