const locationReducerDefaultState = [];

const locationReducer = (state = locationReducerDefaultState, action) => {
  switch(action.type) {
    case 'SET_LOCATION':
      return [
        ...state, //take current state and add the new object to the array, but return a new array completely
        action.location.page
      ]; //return new state with this added on
    case 'REMOVE_EXPENSE':
      return state.filter((expense) => { //filter through only return an object matching the id
        return expense.id !== action.id;
      });
    case 'EDIT_EXPENSE':
      return state.map((expense) => {
        if(expense.id === action.id) {
          return {
            ...expense,
            ...action.updates /// add object to array
          }
        } else {
          return expense;
        }
      });
    default:
      return state;
  }
};

export default locationReducer;