import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import axios from 'axios'; // regular axios
import colors from "../../../styles/colors";
import { NavLink } from 'react-router-dom';
import ABSmallButton from "../../../components/ABSmallButton";
import ABFormButton from "../../../components/ABFormButton";
import { CSVLink } from "react-csv";
import {BASE_URL} from "../../../utility/constants";
import moment from 'moment';

const PageWrapper = styled.div`
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const LinkTitle = styled.div`
  padding: 10px 0;
  font-size: 20px;
  display: flex;
  align-items: center;

  span {
    width: 150px;
  }
`;

const StatsExport = (props) =>  {

    const [viewData, setViewData] = useState([]);
    const [clickData, setClickData] = useState([]);

    const viewHeaders = [
        { label: 'Id', key: 'id' },
        { label: 'Page Link', key: 'pageLink' },
        { label: 'Page Title', key: 'pageTitle' },
        { label: 'Page Type', key: 'pageType' },
        { label: 'Page Id', key: 'pageId' },
        { label: 'Attribution', key: 'attribution' },
        { label: 'Attribution2', key: 'attribution2' },
        { label: 'First Name', key: `firstName` },
        { label: 'Last Name', key: `lastName` },
        { label: 'Email', key: `email` },
        { label: 'City', key: `city` },
        { label: 'State', key: `state` },
        { label: 'Country', key: `country` },
        { label: 'Date', key: `createdAt` },
    ];

    const clickHeaders = [
        { label: 'Id', key: 'id' },
        { label: 'External Link', key: 'clickLink' },
        { label: 'Page Link', key: 'pageLink' },
        { label: 'Page Title', key: 'pageTitle' },
        { label: 'Page Type', key: 'pageType' },
        { label: 'Page Id', key: 'pageId' },
        { label: 'Attribution', key: 'attribution' },
        { label: 'Attribution2', key: 'attribution2' },
        { label: 'First Name', key: `firstName` },
        { label: 'Last Name', key: `lastName` },
        { label: 'Email', key: `email` },
        { label: 'City', key: `city` },
        { label: 'State', key: `state` },
        { label: 'Country', key: `country` },
        { label: 'Date', key: 'createdAt' },
    ];

    useEffect(() => {
        getStatView();
        getClickView();
    }, []);

    const getStatView = () => {
        const token = localStorage.getItem("token");
        const customAxiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Auth': token
            }
        });
        customAxiosInstance.get('stat-views')
            .then((response) => {
                const dataWithFormat = response.data.map((item) => {
                    item.createdAt = moment(item.createdAt).format('MMM. Do, YYYY')
                    return item;
                })
                setViewData(dataWithFormat);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getClickView = () => {
        const token = localStorage.getItem("token");
        const customAxiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Auth': token
            }
        });
        customAxiosInstance.get('stat-clicks')
            .then((response) => {
                const dataWithFormat = response.data.map((item) => {
                    item.createdAt = moment(item.createdAt).format('MMM. Do, YYYY')
                    return item;
                })
                setClickData(dataWithFormat);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <PageWrapper>
            <HeadingContainer>
                <StyledHeading>Stats Export</StyledHeading>
            </HeadingContainer>
            <div>
                <LinkTitle>
                    <span>Views/Clicks</span>
                    <CSVLink
                        data={viewData}
                        headers={viewHeaders}
                        filename={"aspen-alert-views-clicks.csv"}
                    >
                        <ABFormButton title="Export" />
                    </CSVLink>
                </LinkTitle>
                <LinkTitle>
                    <span>External Clicks</span>
                    <CSVLink
                        data={clickData}
                        headers={clickHeaders}
                        filename={"aspen-alert-external-clicks.csv"}
                    >
                        <ABFormButton title="Export" />
                    </CSVLink>
                </LinkTitle>
            </div>
        </PageWrapper>
    );
};

export default StatsExport;