// export const BASE_URL = 'http://127.0.0.1:3000/';
export const BASE_URL = 'https://api.myaspenbrook.com/';

// export const DEV_ENVIRONEMENT = true;
export const DEV_ENVIRONEMENT = false;

// TODO these should be pulled in on a static object to reference
export const POST_TYPE_NAME = {
  1: 'Alerts',
  2: 'Atheneum Content',
  5: 'Xchange Content',
};

// TODO these should be pulled in on a static object to reference
export const CATEGORY_NAME = [
  { id: 1, name: 'Books' },
  { id: 3, name: 'Events' },
  { id: 4, name: 'Guides' },
  { id: 7, name: 'White Papers' },
  { id: 8, name: 'Application Notes' },
  { id: 9, name: 'Articles' },
  { id: 10, name: 'Solutions' },
  { id: 12, name: 'Case Studies' },
  { id: 13, name: 'Posters' },
  { id: 19, name: 'Spotlight' },
  { id: 20, name: 'Patents' },
  { id: 22, name: 'Presentations' },
  // {id: 25, name: 'Solutions'},
  { id: 29, name: 'Research Papers' },
  { id: 30, name: 'Surveys' }
];

export const XCHANGE_CATEGORY_NAME = [
  { id: 11, name: 'Data Sheets' },
  { id: 15, name: 'Press Releases' },
  { id: 27, name: 'Videos' },
  { id: 31, name: 'Manuals' },
];

export const CAREER_CATEGORY_NAME = [
  { id: 1, name: 'All' },
  { id: 2, name: 'Science' },
  { id: 3, name: 'Business' }
];

export const ALERT_CATEGORY_NAME = {

};

// TODO these should be pulled in on a static object to reference
export const POST_CATEGORY_NAME = {
  1: 'Upstream',
  2: 'Downstream',
};

