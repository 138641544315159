import axiosInstance from "../../utility/axios";

export const startGetAlertFact = () => ({
    type: 'START_GET_ALERT_FACT',
  });
  
  export const getAlertFactSuccess = (alertFact) => ({
    type: 'GET_ALERT_FACT_SUCCESS',
    alertFact
  });
  
  export const getAlertFact = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-fact/${alertItemId}`;
      console.log('get alertFact url: ', url);
      dispatch(startGetAlertFact());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertFact: ', response.data);
          dispatch(getAlertFactSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertFactCreate = () => ({
    type: 'START_ALERT_FACT_CREATE',
});

export const createAlertFactSuccess = (alertFact) => ({
    type: 'CREATE_ALERT_FACT_SUCCESS',
    alertFact
});

export const createAlertFact = (alertFact) => {
    console.log('do alert fact: ', alertFact);
    return (dispatch) => {
        dispatch(startAlertFactCreate());
        axiosInstance.post(`alert-fact`, alertFact)
            .then((response) => {
                console.log('Alert Fact Create: ', response.data);
                dispatch(createAlertFactSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertFactUpdate = () => ({
    type: 'START_ALERT_FACT_UPDATE',
  });
  
  export const updateAlertFactSuccess = (alertFact) => ({
    type: 'UPDATE_ALERT_FACT_SUCCESS',
    alertFact
  });
  
  export const updateAlertFact = (alertFact) => {
    console.log('alertFact: ', alertFact);
    return (dispatch) => {
      dispatch(startAlertFactUpdate());
      axiosInstance.put(`alert-fact/${alertFact.id}`, alertFact)
        .then((response) => {
          dispatch(updateAlertFactSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});