const signUpReducerDefaultState = {
  data: [],
  loading: false,
  error: false,
  errorMessage: null,
  reviewSuccess: false
};

const signUpReducer = (state = signUpReducerDefaultState, action) => {
  switch(action.type) {
    case 'ACCOUNT_CREATED':
      return {
        ...state,
        loading: false, //after finishing add data and loading false
        error: false,
        errorMessage: null,
        data: action.userInfo,
      };
    case 'STRIPE_ACCOUNT_CREATED':
      return {
        ...state,
        loading: false, //after finishing add data and loading false
        error: false,
        errorMessage: null,
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default signUpReducer;