import React, { Component } from 'react';
import { connect } from "react-redux";
import { history } from "../../../routers/AppRouter";
import { CATEGORY_NAME, POST_TYPE_NAME, POST_CATEGORY_NAME, XCHANGE_CATEGORY_NAME } from "../../../utility/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import TimeZoneSelect from '../../../components/TimeZoneSelect';
import Dropzone from 'react-dropzone';
import { extractImageFileExtensionFromBase64 } from '../../../helpers/ReusableUtils';
import { ToastContainer, toast } from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MainButton from '../../../components/MainButton';

// redux
import { saveSingleFile } from '../../../actions/upload';
import { slugify } from '../../../utility/string-utils';
import { createEvent, getEvent, updateEvent } from '../../../actions/events';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`

`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  padding: 15px;
  margin: 0 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 15px 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  font-size: 16px;
  padding: 10px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue}
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 100px;
  max-width: 150px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue};
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const ModalPreviewTitle = styled.div`
  position: absolute;
  background: ${colors.darkGray};
  color: ${colors.white};
  left: 0;
  text-align: center;
  top: -56px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  z-index: 100;

  @media (min-width: 720px) {
    width: ${props => props.modalWidth ? props.modalWidth : '442px'};
    min-height: 495px;
  }
`;

const ModalHeading = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${colors.lightGray}
  color: ${colors.white};
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

const ModalFeaturedImage = styled.div`
  padding: 20px 0;
  text-align: center;
  img {
    max-width: 300px;
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  color: ${colors.black};
  font-weight: bold;
  padding-top: 15px;
`;

const ModalDetails = styled.div`
  padding-top: 10px;
  padding: 0;
  opacity: 0.8;
  font-size: 15px;
  color: ${colors.black};
  // p {
  //   padding: 0;
  //   line-height: 15px;
  // }
`;

const ModalBody = styled.div`
  padding: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

const ModalDocuments = styled.div`
  display: flex;
  padding: 15px 0;
  h2 {
    font-weight: bold;
    font-size: 15px;
    color: ${colors.darkGray};
    padding: 0 15px 0 0;
    margin: 0;
  }
  h3 {
    font-size: 13px;
    color: ${colors.darkGray};
    padding: 5px 0;
    margin: 0;
  }
  img {
    margin: 0 5px;
    border: 1px solid ${colors.lightGray};
    max-height: 100px;
    max-width: 150px;
    object-fit: cover;
  }
`;

const ModalArrowRight = styled.button`
  position: absolute;
  background: none;
  border: none;
  right: -50px;
  top: 42%;
  cursor: pointer;
  outline: none;
`;

const ModalArrowLeft = styled.button`
  position: absolute;
  background: none;
  border: none;
  left: -50px;
  top: 42%;
  cursor: pointer;
  transform: rotate(180deg);
  outline: none;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

class EventListing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalPosition: 0,
      modalWidth: '442px',
      title: '',
      // boldTitle: false,
      // italicTitle: false,
      // displayTitle: true,
      editorState: EditorState.createEmpty(),
      date: new Date(),
      showCalendar: false,
      displayImageAbove: true,
      eventType: '',
      imageLink: '',
      link: '',
      editingEventId: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      company: '',
      location: '',
      startDateTime: new Date(),
      endDateTime: new Date(),
      timeZone: '',
      notes: '',
      attribution: '',
      attribution2: '',
      clientPortalId: null
    }
  }

  componentDidMount() {
    const eventId = this.props.match.params.id;
    console.log('eventId: ', eventId);
    if (eventId) {
      this.props.getEvent(eventId);
      this.setState(() => ({
        editingEventId: parseInt(eventId),
      }));
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.event !== prevProps.event) {
      // needed to pull in wysyig content
      const contentBlock = htmlToDraft(this.props.event.description);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      this.setState(() => ({
        title: this.props.event.title,
        // boldTitle: this.props.post.boldTitle || false,
        // italicTitle: this.props.post.italicTitle || false,
        // displayTitle: this.props.post.displayTitle,
        editorState: editorState,
        date: new Date(this.props.event.date),
        // displayImageAbove: this.props.event.displayImageAbove,
        imageLink: this.props.event.imageLink,
        link: this.props.event.link,
        eventType: this.props.event.eventType,
        company: this.props.event.company,
        location: this.props.event.location,
        startDateTime: new Date(this.props.event.startDateTime),
        endDateTime: new Date(this.props.event.endDateTime),
        timeZone: this.props.event.timeZone,
        notes: this.props.event.notes,
        attribution: this.props.event.attribution,
        attribution2: this.props.event.attribution2,
        clientPortalId: this.props.event.clientPortalId,
      }));
    }

  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  setStartDate = (date) => {
    this.setState(() => ({ date: date }));
  };

  openCalendar = (open) => {
    this.setState(() => ({
      showCalendar: open
    }));
  };

  clearState = () => {
    this.setState(() => ({
      title: '',
      boldTitle: '',
      italicTitle: '',
      displayTitle: true,
      editorState: EditorState.createEmpty(),
      date: new Date(),
      showCalendar: false,
      displayImageAbove: true,
      link: null,
      imageLink: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      company: '',
      location: '',
      startDateTime: new Date(),
      endDateTime: new Date(),
      timeZone: '',
      notes: '',
      attribution: '',
      attribution2: '',
      clientPortalId: null,
    }), history.push('/events'));
  };

  cancelCalendar = () => {
    console.log(this.state.date);
    this.setState(() => ({
      showCalendar: false,
      date: null,
    }));
  };

  handleDisplayTitleChange = () => {
    this.setState(() => ({ displayTitle: !this.state.displayTitle }));
  };

  handleTitleChange = (title) => {
    this.setState(() => ({ title }));
  };

  handleCompanyChange = (company) => {
    this.setState(() => ({ company }));
  };

  handleLinkChange = (link) => {
    this.setState(() => ({ link }));
  };

  handleLocationChange = (location) => {
    this.setState(() => ({ location }));
  };

  handleEventTypeChange = (eventType) => {
    this.setState(() => ({
      eventType: eventType
    }));
  };

  handleDisplayImageChange = (displayAbove) => {
    this.setState(() => ({
      displayImageAbove: displayAbove
    }));
  };

  handleFeatureImageLink = (link) => {
    this.setState(() => ({
      link: link
    }));
  };

  handleBoldSelect = () => {
    this.setState(() => ({
      boldTitle: !this.state.boldTitle
    }));
  };

  handleItalicSelect = () => {
    this.setState(() => ({
      italicTitle: !this.state.italicTitle
    }));
  };

  handleDateChange = (date) => {
    this.setState(() => ({
      date
    }));
  };

  handleStartDateChange = (date) => {
    this.setState(() => ({
      startDateTime: date
    }));
  };

  handleEndDateChange = (date) => {
    this.setState(() => ({
      endDateTime: date
    }));
  };

  handleTimeZoneSelect = (e) => {
    let timeZomeName = e.target.value;
    this.setState(() => ({
      timeZone: timeZomeName
    }));
  };

  handleNotesChange = (value) => {
    this.setState(() => ({
      notes: value
    }));
  };

  handleAttributionChange = (value) => {
    this.setState(() => ({
      attribution: value
    }));
  };

  handleAttribution2Change = (value) => {
    this.setState(() => ({
      attribution2: value
    }));
  };

  handleClientPortalIdChange = (value) => {
    this.setState(() => ({
      clientPortalId: value
    }));
  };

  // Modal

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleModelChange = (position) => {
    const initialModalPosition = this.state.modalPosition;
    if (position > 0 && initialModalPosition !== 3) {
      this.setModalSize(initialModalPosition + 1);
    } else if (position < 0 && initialModalPosition !== 0) {
      this.setModalSize(initialModalPosition - 1);
    }
  };

  setModalSize = (modalPosition) => {
    if (modalPosition === 0) {
      this.setState(() => ({
        modalWidth: '442px',
        modalPosition: modalPosition
      }));
    } else {
      this.setState(() => ({
        modalWidth: '1200px',
        modalPosition: modalPosition
      }));
    }
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  imageCheck() {
    if (this.state.imgSrc !== null) {
      return <ImagePreview src={this.state.imgSrc} />
    } else if (this.state.imageLink) {
      return <ImagePreview src={`https://${this.props.event.imageLink}`} />
    } else {
      return (
        <ImagePlaceholderStyled>No Image</ImagePlaceholderStyled>
      )
    }
  }

  finalizePost = (type, cloudfrontUrl) => {
    // console.log('multipleCloudFrontUrls: ', multipleCloudFrontUrls);
    // console.log('existingMultipleFiles: ', this.state.existingMultipleFiles);

    const formattedStartTime = moment(this.state.startDateTime).format("YYYY-MM-DD HH:mm:ss");
    const formattedEndTime = moment(this.state.endDateTime).format("YYYY-MM-DD HH:mm:ss");

    // console.log('this.state.startDateTime: ', this.state.startDateTime);
    // console.log('this.state.timeZone: ', this.state.timeZone);
    //
    // console.log('formattedStartTime: ', moment(formattedStartTime).format("YYYY-MM-DD HH:mm:ss"));
    // console.log('formattedEndTime: ', moment(formattedEndTime).format("YYYY-MM-DD HH:mm:ss"));
    //
    // console.log('start without utc and has tz: ', moment(this.state.startDateTime).tz(this.state.timeZone).format("YYYY-MM-DD HH:mm:ss"));
    //
    // let startDateTimeWithOffset = moment(this.state.startDateTime).tz(this.state.timeZone).format("YYYY-MM-DD HH:mm:ss");
    // let endDateTimeWithOffset = moment(this.state.endDateTime).tz(this.state.timeZone).format("YYYY-MM-DD HH:mm:ss");
    //
    // console.log('startDateTimeWithOffset: ', startDateTimeWithOffset);
    // console.log('endDateTimeWithOffset: ', endDateTimeWithOffset);

    let eventDetails = {
      title: this.state.title,
      boldTitle: this.state.boldTitle,
      italicTitle: this.state.italicTitle,
      description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      status: type,
      date: this.state.date ? this.state.date : new Date(),
      // displayImageAbove: this.state.displayImageAbove,
      // displayTitle: this.state.displayTitle,
      imageLink: cloudfrontUrl || this.state.imageLink || null,
      link: this.state.link || null,
      permalink: slugify(this.state.title) || null,
      eventType: this.state.eventType,
      company: this.state.company,
      location: this.state.location,
      startDateTime: formattedStartTime,
      endDateTime: formattedEndTime,
      timeZone: this.state.timeZone,
      notes: this.state.notes,
      attribution: this.state.attribution,
      attribution2: this.state.attribution2,
      clientPortalId: this.state.clientPortalId,
    };

    const eventId = this.state.editingEventId;
    if (eventId) {
      eventDetails.id = eventId;
      this.props.updateEvent(eventDetails, eventId);
    } else {
      this.props.createEvent(eventDetails);
    }

    // console.log("event details: ", eventDetails);

    this.clearState();

  };

  publishPost = (type) => {

    if (this.state.title && this.state.editorState && this.state.eventType && this.state.startDateTime && (this.state.imageLink || this.state.uploadedFile)) {

      if (type !== 'preview') {

        // Just a feature image was uploaded
        if (this.state.uploadedFile) {
          this.props.saveSingleFile(this.state.uploadedFile, 'logos', 'events')
            .then((cloudfrontUrl) => {
              // add the cloudfront url to the post
              this.finalizePost(type, cloudfrontUrl);
            });
        }

        // no images or additional documents uploaded
        if (!this.state.uploadedFile) {
          this.finalizePost(type);
        }

      } else {
        // show post preview
      }

    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Event</StyledHeading>
          <RightSideHeading>
            <ButtonMargin><MainButton title="Preview Event" onClick={() => this.openModal()} /></ButtonMargin>
            <ButtonMargin><MainButton title="Save as Draft" onClick={() => this.publishPost('draft')} /></ButtonMargin>
            <div>
              <ButtonMargin><MainButton title="Schedule an Event" onClick={() => this.openCalendar(!this.state.showCalendar)} /></ButtonMargin>
              <SchedulePopup showCalendar={this.state.showCalendar}>
                <DatePicker
                  inline
                  selected={this.state.date}
                  onChange={date => this.handleDateChange(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <MainButton title="Schedule Now" onClick={() => this.publishPost('scheduled')} />
                <MainButton title="Cancel" onClick={() => this.cancelCalendar()} />
              </SchedulePopup>
            </div>
            <MainButton title="Publish Now" dark={true} onClick={() => this.publishPost('published')} />
          </RightSideHeading>
        </HeadingContainer>
        <MainContentContainer>
          <MainContent>
            {/*<TitleFeaturesSelect>*/}
            {/*<StyledIconButtons onClick={() => this.handleBoldSelect()}>*/}
            {/*<img src={BoldIcon} alt="" srcSet={`${BoldIcon} 1x, ${BoldIcon2x} 2x`} />*/}
            {/*</StyledIconButtons>*/}
            {/*<StyledIconButtons onClick={() => this.handleItalicSelect()}>*/}
            {/*<img src={ItalicIcon} alt="" srcSet={`${ItalicIcon} 1x, ${ItalicIcon2x} 2x`} />*/}
            {/*</StyledIconButtons>*/}
            {/*<DisplayTitleCheck>*/}
            {/*<label>*/}
            {/*<input*/}
            {/*key="displayTitle"*/}
            {/*type="checkbox"*/}
            {/*value="displayTitle"*/}
            {/*checked={this.state.displayTitle}*/}
            {/*onChange={() => this.handleDisplayTitleChange()}*/}
            {/*/>*/}
            {/*Display Title With Post*/}
            {/*</label>*/}
            {/*</DisplayTitleCheck>*/}
            {/*</TitleFeaturesSelect>*/}
            <StyledInput
              type="text"
              value={this.state.title}
              onChange={(e) => this.handleTitleChange(e.target.value)}
              placeholder="Title"
            />
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="post-editor"
              onEditorStateChange={this.onEditorStateChange}
              stripPastedStyles
              toolbar={{
                image: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: false,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: true, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
                colorPicker: {
                  colors: ['rgb(255, 0, 0)', 'rgb(26,188,156)']
                },
              }}
            />
            {/*<textarea*/}
            {/*disabled*/}
            {/*value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}*/}
            {/*/>*/}
            <FeaturedImageSection>
              <PreviewSection>
                <div>
                  <StyledTitle>Featured Image</StyledTitle>
                  <StyledImageSection>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MainButton title='Select' />
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </StyledImageSection>
                </div>
                {this.imageCheck()}
              </PreviewSection>
              {/*<DisplaySection>*/}
              {/*<StyledTitle>Display</StyledTitle>*/}
              {/*<RadioSection>*/}
              {/*<label>*/}
              {/*<input*/}
              {/*key="above"*/}
              {/*type="radio"*/}
              {/*value="above"*/}
              {/*checked={this.state.displayImageAbove}*/}
              {/*onChange={() => this.handleDisplayImageChange(true)}*/}
              {/*/>*/}
              {/*Above Post Title*/}
              {/*</label>*/}
              {/*<label>*/}
              {/*<input*/}
              {/*key="below"*/}
              {/*type="radio"*/}
              {/*value="below"*/}
              {/*checked={!this.state.displayImageAbove}*/}
              {/*onChange={() => this.handleDisplayImageChange(false)}*/}
              {/*/>*/}
              {/*Below Post Title*/}
              {/*</label>*/}
              {/*</RadioSection>*/}
              {/*</DisplaySection>*/}

              {/*<FeaturedImageLinkSection>*/}
              {/*<StyledTitle>Link for featured image</StyledTitle>*/}
              {/*<LinkInput*/}
              {/*type="text"*/}
              {/*value={this.state.link || ''}*/}
              {/*onChange={(e) => this.handleFeatureImageLink(e.target.value)}*/}
              {/*/>*/}
              {/*</FeaturedImageLinkSection>*/}
            </FeaturedImageSection>

            {/*<StyledTitle>Organization Name</StyledTitle>*/}
            {/*<StyledInput*/}
            {/*type="text"*/}
            {/*value={this.state.company}*/}
            {/*onChange={(e) => this.handleCompanyChange(e.target.value)}*/}
            {/*/>*/}

            <StyledTitle>Event Location</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.location}
              onChange={(e) => this.handleLocationChange(e.target.value)}
            />

            <StyledTitle>Event Source</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.link}
              onChange={(e) => this.handleLinkChange(e.target.value)}
            />

            <StyledTitle>From</StyledTitle>
            <StyledDatePicker
              selected={this.state.startDateTime}
              onChange={date => this.handleStartDateChange(date)}
              showTimeSelect
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />

            <StyledTitle>To</StyledTitle>
            <StyledDatePicker
              selected={this.state.endDateTime}
              onChange={date => this.handleEndDateChange(date)}
              showTimeSelect
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />

            <StyledTitle>Timezone</StyledTitle>
            <TimeZoneSelect value={this.state.timeZone} onChange={(e => this.handleTimeZoneSelect(e))} />

            <StyledTitle>Notes</StyledTitle>
            <StyledTextArea
              name="notes"
              id="notes"
              cols="30"
              rows="5"
              value={this.state.notes || ''}
              onChange={(e) => this.handleNotesChange(e.target.value)}>
            </StyledTextArea>
            <StyledTitle>Attribution</StyledTitle>
            <StyledInput
              name="attribution"
              id="attribution"
              cols="30"
              rows="5"
              value={this.state.attribution || ''}
              onChange={(e) => this.handleAttributionChange(e.target.value)}>
            </StyledInput>
            <StyledTitle>Attribution 2</StyledTitle>
            <StyledInput
              name="attribution2"
              id="attribution2"
              cols="30"
              rows="5"
              value={this.state.attribution2 || ''}
              onChange={(e) => this.handleAttribution2Change(e.target.value)}>
            </StyledInput>
            <StyledTitle>Client Portal ID</StyledTitle>
            <StyledInput
              name="clientPortalId"
              id="clientPortalId"
              cols="30"
              rows="5"
              value={this.state.clientPortalId || ''}
              onChange={(e) => this.handleClientPortalIdChange(e.target.value)}>
            </StyledInput>

          </MainContent>
          <StyledSidebar>
            <SidebarSection>
              <StyledTitle>Event Type*</StyledTitle>
              <SidebarSectionBackground>
                <RadioSection>
                  <label>
                    <input
                      name="eventType"
                      type="radio"
                      value="conference"
                      checked={this.state.eventType === 'conference'}
                      onChange={() => this.handleEventTypeChange('conference')}
                    />
                    Conference
                  </label>
                  <label>
                    <input
                      name="eventType"
                      type="radio"
                      value="seminar"
                      checked={this.state.eventType === 'seminar'}
                      onChange={() => this.handleEventTypeChange('seminar')}
                    />
                    Seminar
                  </label>
                  <label>
                    <input
                      name="eventType"
                      type="radio"
                      value="tradeshow"
                      checked={this.state.eventType === 'tradeshow'}
                      onChange={() => this.handleEventTypeChange('tradeshow')}
                    />
                    Tradeshow
                  </label>
                  <label>
                    <input
                      name="eventType"
                      type="radio"
                      value="training"
                      checked={this.state.eventType === 'training'}
                      onChange={() => this.handleEventTypeChange('training')}
                    />
                    Training
                  </label>
                  <label>
                    <input
                      name="eventType"
                      type="radio"
                      value="webinar"
                      checked={this.state.eventType === 'webinar'}
                      onChange={() => this.handleEventTypeChange('webinar')}
                    />
                    Webinar
                  </label>
                </RadioSection>
              </SidebarSectionBackground>
            </SidebarSection>
          </StyledSidebar>
        </MainContentContainer>
        <ModalStyled
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.closeModal()}
          contentLabel="Sort"
          overlayClassName="PreviewOverlay"
          modalWidth={this.state.modalWidth}
        >
          <ModalBody>
            <ModalPreviewTitle>Event Preview</ModalPreviewTitle>
            <ModalHeading>Listing</ModalHeading>
            {
              this.state.imageLink &&
              <ModalFeaturedImage>
                <a href={this.state.link}>
                  <img src={`https://${this.state.imageLink}`} alt="" />
                </a>
              </ModalFeaturedImage>
            }
            <ModalTitle>{this.state.title}</ModalTitle>
            <ModalDetails dangerouslySetInnerHTML={{
              __html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
            }} />
          </ModalBody>
          {/*<ModalArrowRight onClick={() => this.handleModelChange(1)}><ReactSVG src={ArrowIcon} /></ModalArrowRight>*/}
          {/*<ModalArrowLeft onClick={() => this.handleModelChange(-1)}><ReactSVG src={ArrowIcon} /></ModalArrowLeft>*/}
        </ModalStyled>
        <ToastContainer />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.singleEvent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEvent: (id) => dispatch(getEvent(id)),
  createEvent: (eventDetails) => dispatch(createEvent(eventDetails)),
  updateEvent: (eventDetails, eventId) => dispatch(updateEvent(eventDetails, eventId)),
  // getCategoriesByPostIds: (postTypeIds) => dispatch(getCategoriesByPostIds(postTypeIds)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventListing);
