import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import {history} from "../../../routers/AppRouter";
import moment from 'moment';
import styled from 'styled-components'
import { useTable, useRowSelect } from 'react-table';
import {connect} from "react-redux";
import {FormInput} from '../../../styles/form-styles';

import {getJobs, removeJobs} from '../../../actions/jobs';
import MainButton from '../../../components/MainButton';
import { ToastContainer, toast } from 'react-toastify';
import { bulkUpdate } from '../../../actions/bulk-update';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BulkContainer = styled.div`
  padding: 20px 0 40px 0;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const StyledSmallHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 20px;
  font-weight: bold;
`;

const TitleInput = styled.div`
  margin-right: 25px;
  width: 300px;
`;

const BulkInputContainer = styled.div`
  padding: 10px 0;
`;

const ButtonPadding = styled.div`
  padding: 0 5px;
`;

const Title = styled.div`
  cursor: pointer;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const handleEditRow = (id) => {
  history.push(`career-listing/${id}`);
};

const createPost = () => {
  history.push('/career-listing')
};

function Table({ columns, data, deleteSelectedJobs, onBulkChange, bulkUpdate }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  // Render the UI for your table
  return (
    <>
      <HeadingContainer>
        <StyledHeading>Career Center</StyledHeading>
        <ButtonPadding><MainButton title="Post a Listing" onClick={() => createPost()} /></ButtonPadding>
        <ButtonPadding><MainButton title="Delete Listings" onClick={() => deleteSelectedJobs(selectedFlatRows)} /></ButtonPadding>
      </HeadingContainer>

      <BulkContainer>
        <StyledSmallHeading>Bulk Update</StyledSmallHeading>

        <BulkInputContainer>
          <HeadingContainer>
            <TitleInput>
                <FormInput
                  type="number" 
                  placeholder="Client Portal Id" 
                  onChange={(e) => onBulkChange(e)} 
                />
            </TitleInput>
            <ButtonPadding><MainButton title="Update Client Id's" onClick={() => bulkUpdate(selectedFlatRows, 'clientPortalId')} /></ButtonPadding>
          </HeadingContainer>
        </BulkInputContainer>

        <BulkInputContainer>
          <HeadingContainer>
            <TitleInput>
              <FormInput placeholder="Attribution" onChange={(e) => onBulkChange(e)} />
            </TitleInput>
            <ButtonPadding><MainButton title="Update Attribution" onClick={() => bulkUpdate(selectedFlatRows, 'attribution')} /></ButtonPadding>
          </HeadingContainer>
        </BulkInputContainer>
        
      </BulkContainer>

      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, 1000).map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {/* Debug code for row selection */}
      {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      {
        Object.keys(selectedRowIds).map((item) => {
          return <div>{item}</div>
        })
      }
      <pre>
        <code>
        {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                d => d.original
              )
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <ToastContainer />
    </>
  )
}

function Jobs(props) {

  const [bulkValue, setBulkValue] = useState('');

  // had to be in here to get be able to fire props dispatch
  const deleteSelectedJobs = (selectedRows) => {
    let jobIdsToDelete = [];
    selectedRows.map((item) => {
      jobIdsToDelete.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    props.removeJobs(jobIdsToDelete);
  };

  useEffect(() => {
    props.getJobs();
  }, []);

  useEffect(() => {
    if (props.bulkSuccessMessage) {
      toast.success(props.bulkSuccessMessage);
    }
    
  }, [props.bulkSuccessMessage]);

  useEffect(() => {
    if (props.bulkError) {
      toast.error('Error Updating');
    }
    
  }, [props.bulkError]);

  const getSelectedPosts = (selectedRows) => {
    let selectedPostIds = [];
    selectedRows.map((item) => {
      selectedPostIds.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    selectedPostIds.map((itemId) => {
      console.log(itemId);
    })
  };

  const onBulkChange = (e) => {
    const bulkValue = e.target.value;
    console.log(bulkValue);
    setBulkValue(bulkValue);
  };

  // update selected all selected rows in database with table name, field to update and what new value
  // fieldName is the actual fieldName in the databae row
  const bulkUpdate = (selectedRows, fieldName) => {
    // console.log('bulkValue: ', bulkValue);
    let selectedPostIds = [];
      selectedRows.map((item) => {
        selectedPostIds.push(parseInt(item.original.id, 10)); // original is the actual object info
      });
      selectedPostIds.map((itemId) => {
        console.log(itemId);
      })

      let bulkDetails = {
        postIds: {
          selectedPostIds: [selectedPostIds]
        },
        newValue: bulkValue,
        tableName: 'jobs',
        fieldName: fieldName,
      };

      props.bulkUpdate(bulkDetails);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Listing Info',
        columns: [
          {
            Header: 'Title',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.jobTitle}</Title>;
            },
          },
          {
            Header: 'Client Portal ID',
            accessor: d => {
              return <Title>{d.clientPortalId}</Title>
            },
          },
          {
            Header: 'Date',
            accessor: d => {
              return moment(d.date).format('MMM. Do, YYYY');
            }
          },
          {
            Header: 'Category',
            accessor: 'category',
          },
          {
            Header: 'Attribution',
            accessor: d => {
              return d.attribution;
            },
          },
          {
            Header: 'Attribution 2',
            accessor: d => {
              return d.attribution2;
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
        ],
      },
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} 
        data={props.jobs} 
        deleteSelectedJobs={deleteSelectedJobs}
        onBulkChange={onBulkChange}
        bulkUpdate={bulkUpdate} 
      />
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    jobs: state.jobs.data,
    bulkSuccessMessage: state.bulkUpdate.bulkSuccessMessage,
    bulkError: state.bulkUpdate.bulkError
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJobs: () => dispatch(getJobs()),
  removeJobs: (idsToDelete) => dispatch(removeJobs(idsToDelete)),
  bulkUpdate: (bulkDetails) => dispatch(bulkUpdate(bulkDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
