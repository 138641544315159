import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startResetPassword = () => ({
  type: 'START_RESET_PASSWORD',
});

export const resetPasswordSuccess = () => ({
  type: 'RESET_PASSWORD_SUCCESS',
});

export const resetPassword = (userInfo) => {
  return (dispatch) => {
    dispatch(startResetPassword());
    axiosInstance.put('pwreset', userInfo)
      .then((response) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((error) => {
        dispatch(showError('Error resetting password'));
        console.log(error);
      });
  }
};

