import axiosInstance from "../../utility/axios";

export const startGetAlertSpotlight = () => ({
    type: 'START_GET_ALERT_SPOTLIGHT',
  });
  
  export const getAlertSpotlightSuccess = (alertSpotlight) => ({
    type: 'GET_ALERT_SPOTLIGHT_SUCCESS',
    alertSpotlight
  });
  
  export const getAlertSpotlight = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-spotlight/${alertItemId}`;
      console.log('get alertSpotlight url: ', url);
      dispatch(startGetAlertSpotlight());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertSpotlight: ', response.data);
          dispatch(getAlertSpotlightSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertSpotlightCreate = () => ({
    type: 'START_ALERT_SPOTLIGHT_CREATE',
});

export const createAlertSpotlightSuccess = (alertSpotlight) => ({
    type: 'CREATE_ALERT_SPOTLIGHT_SUCCESS',
    alertSpotlight
});

export const createAlertSpotlight = (alertSpotlight) => {
    console.log('do alert spotlight: ', alertSpotlight);
    return (dispatch) => {
        dispatch(startAlertSpotlightCreate());
        axiosInstance.post(`alert-spotlight`, alertSpotlight)
            .then((response) => {
                console.log('AlerttSpotlight Create: ', response.data);
                dispatch(createAlertSpotlightSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertSpotlightUpdate = () => ({
    type: 'START_ALERT_SPOTLIGHT_UPDATE',
  });
  
  export const updateAlertSpotlightSuccess = (alertSpotlight) => ({
    type: 'UPDATE_ALERT_SPOTLIGHT_SUCCESS',
    alertSpotlight
  });
  
  export const updateAlertSpotlight = (alertSpotlight) => {
    console.log('alertSpotlight: ', alertSpotlight);
    return (dispatch) => {
      dispatch(startAlertSpotlightUpdate());
      axiosInstance.put(`alert-spotlight/${alertSpotlight.id}`, alertSpotlight)
        .then((response) => {
          dispatch(updateAlertSpotlightSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});