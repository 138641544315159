import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormFileName} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import {history} from '../../../routers/AppRouter';
import {shortenFileName} from '../../../utility/string-utils';
import {createMicrosite, getMicrosite, updateMicrosite} from '../../../actions/microsite';
import Spinner from "../../../components/Spinner";

import Dropzone from 'react-dropzone';
import {base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef} from '../../../utility/reusable-utils';

import ABButton from '../../../components/ABButton';
import ABSmallButton from '../../../components/ABSmallButton';
import {saveMultiplePhotos, saveSingleFile} from '../../../actions/upload';
import {updateMicrositeSubmission} from '../../../actions/microsite-submission';
import {setLocation} from '../../../actions/location';
import {getMicrositeSetting, updateMicrositeSetting} from '../../../actions/microsite-settings';
import {createMicrositeGallery, getMicrositeGallery, updateMicrositeGallery} from '../../../actions/microsite-gallery';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ImagePreview = styled.img`
  max-width: 300px;
  max-height: 150px;
  object-fit: cover;
  margin-left: 20px;
`;

const CenterImage = styled.div`
  padding: 0 0 15px 0;
`;

const ImageSelection = styled.div`
  display: flex;
  // max-width: 300px;
  max-height: 150px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
  outline: none;
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const UploadSpinner = styled.div`
  font-size: 14px;
  padding: 20px 0;
  max-width: 1365px;
  text-align: center;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

const acceptedVideoTypes = 'video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedVideoTypesArray = acceptedVideoTypes.split(",").map((item) => {return item.trim()});

class MediaGallery extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      buttonText: '',
      buttonLink: '',
      imgSrc: null,
      imgSrc2: null,
      imgSrc3: null,
      imgSrc4: null,
      imgSrc5: null,
      imgSrc6: null,
      imgSrc7: null,
      imgSrc8: null,
      imgSrc9: null,
      imgSrc10: null,
      imgSrcExt: null,
      imgSrcExt2: null,
      imgSrcExt3: null,
      imgSrcExt4: null,
      imgSrcExt5: null,
      imgSrcExt6: null,
      imgSrcExt7: null,
      imgSrcExt8: null,
      imgSrcExt9: null,
      imgSrcExt10: null,
      uploadedFile: null,
      uploadedFile2: null,
      uploadedFile3: null,
      uploadedFile4: null,
      uploadedFile5: null,
      uploadedFile6: null,
      uploadedFile7: null,
      uploadedFile8: null,
      uploadedFile9: null,
      uploadedFile10: null,
      uploadedFileName: '',
      uploadedFileName2: '',
      uploadedFileName3: '',
      uploadedFileName4: '',
      uploadedFileName5: '',
      uploadedFileName6: '',
      uploadedFileName7: '',
      uploadedFileName8: '',
      uploadedFileName9: '',
      uploadedFileName10: '',
      imageUploading: false,
      showSection: false,
      imageSizeError1: false,
      imageSizeError2: false,
      imageSizeError3: false,
      imageSizeError4: false,
      imageSizeError5: false,
      imageSizeError6: false,
      imageSizeError7: false,
      imageSizeError8: false,
      imageSizeError9: false,
      imageSizeError10: false,
    };

  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeGallery(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.micrositeGallery !== prevProps.micrositeGallery) {
      this.loadFields();
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.setState({
        showSection: this.props.micrositeSetting.showGallery || false
      });
    }
  }

  loadFields = () => {

    this.setState({
      uploadedFileName: this.props.micrositeGallery.imageUrl1 || '',
      uploadedFileName2: this.props.micrositeGallery.imageUrl2 || '',
      uploadedFileName3: this.props.micrositeGallery.imageUrl3 || '',
      uploadedFileName4: this.props.micrositeGallery.imageUrl4 || '',
      uploadedFileName5: this.props.micrositeGallery.imageUrl5 || '',
      uploadedFileName6: this.props.micrositeGallery.imageUrl6 || '',
      uploadedFileName7: this.props.micrositeGallery.imageUrl7 || '',
      uploadedFileName8: this.props.micrositeGallery.imageUrl8 || '',
      uploadedFileName9: this.props.micrositeGallery.imageUrl9 || '',
      uploadedFileName10: this.props.micrositeGallery.imageUrl10 || ''
    });
  };

  onNextClick = (close) => {

    if (!this.state.imageSizeError1 && !this.state.imageSizeError2 && !this.state.imageSizeError3 && !this.state.imageSizeError4 && !this.state.imageSizeError5
      && !this.state.imageSizeError6 && !this.state.imageSizeError7 && !this.state.imageSizeError8 && !this.state.imageSizeError9 && !this.state.imageSizeError10) {
      this.props.setLocation('review-form');

      if (this.state.uploadedFile || this.state.uploadedFile2 || this.state.uploadedFile3 || this.state.uploadedFile4 || this.state.uploadedFile5 || this.state.uploadedFile6
        || this.state.uploadedFile7 || this.state.uploadedFile8 || this.state.uploadedFile9 || this.state.uploadedFile10) {
        this.setState({imageUploading: true});

        let formattedCompanyName = this.props.microsite.company.split(' ').join('-').toLowerCase();

        let allCloudFrontUrls = [];
        let allUploadedFiles = [];

        if (this.state.uploadedFile) {
          allUploadedFiles.push({fileNumber: 1, file: this.state.uploadedFile});
        }

        if (this.state.uploadedFile2) {
          allUploadedFiles.push({fileNumber: 2, file: this.state.uploadedFile2});
        }

        if (this.state.uploadedFile3) {
          allUploadedFiles.push({fileNumber: 3, file: this.state.uploadedFile3});
        }

        if (this.state.uploadedFile4) {
          allUploadedFiles.push({fileNumber: 4, file: this.state.uploadedFile4});
        }

        if (this.state.uploadedFile5) {
          allUploadedFiles.push({fileNumber: 5, file: this.state.uploadedFile5});
        }

        if (this.state.uploadedFile6) {
          allUploadedFiles.push({fileNumber: 6, file: this.state.uploadedFile6});
        }

        if (this.state.uploadedFile7) {
          allUploadedFiles.push({fileNumber: 7, file: this.state.uploadedFile7});
        }

        if (this.state.uploadedFile8) {
          allUploadedFiles.push({fileNumber: 8, file: this.state.uploadedFile8});
        }

        if (this.state.uploadedFile9) {
          allUploadedFiles.push({fileNumber: 9, file: this.state.uploadedFile9});
        }

        if (this.state.uploadedFile10) {
          allUploadedFiles.push({fileNumber: 10, file: this.state.uploadedFile10});
        }

        let count = 0;
        allUploadedFiles.map((item, index) => {
          this.props.saveSingleFile(item.file, formattedCompanyName, 'microsites')
            .then((cloudFrontUrl) => {
              console.log('cloudFrontUrl: ', cloudFrontUrl);
              allCloudFrontUrls.push({fileNumber: item.fileNumber, cloudFrontUrl: cloudFrontUrl});
              console.log('cloudfront url returned: ', cloudFrontUrl);
              count++;
              if (count === allUploadedFiles.length) {
                this.setState({imageUploading: false});
                this.saveForm(allCloudFrontUrls, close);
              }
            });
        });
      } else {
        this.saveForm(null, close);
      }
    }
  };

  saveForm = (allCloudFrontUrls, close) => {
    let micrositeSetting = {
      showGallery: this.state.showSection
    };

    let micrositeGallery = {
      imageUrl1: this.state.uploadedFileName,
      imageUrl2: this.state.uploadedFileName2,
      imageUrl3: this.state.uploadedFileName3,
      imageUrl4: this.state.uploadedFileName4,
      imageUrl5: this.state.uploadedFileName5,
      imageUrl6: this.state.uploadedFileName6,
      imageUrl7: this.state.uploadedFileName7,
      imageUrl8: this.state.uploadedFileName8,
      imageUrl9: this.state.uploadedFileName9,
      imageUrl10: this.state.uploadedFileName10,
    };

    if (allCloudFrontUrls) {
      allCloudFrontUrls.map((item, index) => {
        switch(item.fileNumber) {
          case 1:
            console.log('image 1');
            micrositeGallery.imageUrl1 = item.cloudFrontUrl;
            break;
          case 2:
            console.log('image 2');
            micrositeGallery.imageUrl2 = item.cloudFrontUrl;
            break;
          case 3:
            console.log('image 3');
            micrositeGallery.imageUrl3 = item.cloudFrontUrl;
            break;
          case 4:
            console.log('image 4');
            micrositeGallery.imageUrl4 = item.cloudFrontUrl;
            break;
          case 5:
            console.log('image 5');
            micrositeGallery.imageUrl5 = item.cloudFrontUrl;
            break;
          case 6:
            console.log('image 6');
            micrositeGallery.imageUrl6 = item.cloudFrontUrl;
            break;
          case 7:
            console.log('image 7');
            micrositeGallery.imageUrl7 = item.cloudFrontUrl;
            break;
          case 8:
            console.log('image 8');
            micrositeGallery.imageUrl8 = item.cloudFrontUrl;
            break;
          case 9:
            console.log('image 9');
            micrositeGallery.imageUrl9 = item.cloudFrontUrl;
            break;
          case 10:
            console.log('image 10');
            micrositeGallery.imageUrl10 = item.cloudFrontUrl;
            break;
          default:
          // should only be 3 max
        }
      });
    }

    if (Object.keys(this.props.micrositeGallery).length === 0) {
      this.props.createMicrositeGallery(micrositeGallery, this.props.microsite.id);
    } else {
      micrositeGallery = this.props.microsite.userId;
      this.props.updateMicrositeGallery(micrositeGallery, this.props.microsite.id);
    }

    let micrositeSubmission = {
      gallery: true
    };

    this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);

    this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

    if (close) {
      history.push(`../${this.props.microsite.id}`);
      return;
    }
  };

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({showSection: !showSection}));
  };

  // File upload

  verifyFile = (files, isVideo) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!isVideo) {
        if (!acceptedFileTypesArray.includes(currentFileType)) {
          console.log('file type not allowed!');
          alert('This file is not allowed. Only images are allowed.');
          return false
        }
      } else if (isVideo) {
        if (!acceptedVideoTypesArray.includes(currentFileType)) {
          console.log('file type not allowed!');
          alert('This file is not allowed. Only videos are allowed.');
          return false
        }
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles, fileNumber, isVideo) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files, isVideo);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.isValidImageSize(myResult, 919, 517, fileNumber, isVideo);
          this.fileStateToUpdate(myResult, currentFile, files[0].name, fileNumber);
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  fileStateToUpdate = (myResult, currentFile, fileName, fileNumber) => {
    if (fileNumber === 1) {
      this.setState({
        imgSrc: myResult, //this is the base64 code for preview
        imgSrcExt: extractImageFileExtensionFromBase64(myResult),
        uploadedFile: currentFile,
        uploadedFileName: fileName
      })
    } else if (fileNumber === 2) {
      this.setState({
        imgSrc2: myResult, //this is the base64 code for preview
        imgSrcExt2: extractImageFileExtensionFromBase64(myResult),
        uploadedFile2: currentFile,
        uploadedFileName2: fileName
      })
    } else if (fileNumber === 3) {
      this.setState({
        imgSrc3: myResult, //this is the base64 code for preview
        imgSrcExt3: extractImageFileExtensionFromBase64(myResult),
        uploadedFile3: currentFile,
        uploadedFileName3: fileName
      })
    } else if (fileNumber === 4) {
      this.setState({
        imgSrc4: myResult, //this is the base64 code for preview
        imgSrcExt4: extractImageFileExtensionFromBase64(myResult),
        uploadedFile4: currentFile,
        uploadedFileName4: fileName
      })
    } else if (fileNumber === 5) {
      this.setState({
        imgSrc5: myResult, //this is the base65 code for preview
        imgSrcExt5: extractImageFileExtensionFromBase64(myResult),
        uploadedFile5: currentFile,
        uploadedFileName5: fileName
      })
    } else if (fileNumber === 6) {
      this.setState({
        imgSrc6: myResult, //this is the base66 code for preview
        imgSrcExt6: extractImageFileExtensionFromBase64(myResult),
        uploadedFile6: currentFile,
        uploadedFileName6: fileName
      })
    } else if (fileNumber === 7) {
      this.setState({
        imgSrc7: myResult, //this is the base67 code for preview
        imgSrcExt7: extractImageFileExtensionFromBase64(myResult),
        uploadedFile7: currentFile,
        uploadedFileName7: fileName
      })
    } else if (fileNumber === 8) {
      this.setState({
        imgSrc8: myResult, //this is the base68 code for preview
        imgSrcExt8: extractImageFileExtensionFromBase64(myResult),
        uploadedFile8: currentFile,
        uploadedFileName8: fileName
      })
    } else if (fileNumber === 9) {
      this.setState({
        imgSrc9: myResult, //this is the base69 code for preview
        imgSrcExt9: extractImageFileExtensionFromBase64(myResult),
        uploadedFile9: currentFile,
        uploadedFileName9: fileName
      })
    } else if (fileNumber === 10) {
      this.setState({
        imgSrc10: myResult, //this is the base610 code for preview
        imgSrcExt10: extractImageFileExtensionFromBase64(myResult),
        uploadedFile10: currentFile,
        uploadedFileName10: fileName
      })
    }
  };

  imageCheck() {
    if (this.state.imgSrc !== null) {
      return <FormFileName>{this.state.uploadedFileName}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck2() {
    if (this.state.imgSrc2 !== null) {
      return <FormFileName>{this.state.uploadedFileName2}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName2 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName2)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck3() {
    if (this.state.imgSrc3 !== null) {
      return <FormFileName>{this.state.uploadedFileName3}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName3 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName3)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck4() {
    if (this.state.imgSrc4 !== null) {
      return <FormFileName>{this.state.uploadedFileName4}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName4 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName4)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck5() {
    if (this.state.imgSrc5 !== null) {
      return <FormFileName>{this.state.uploadedFileName5}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName5 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName5)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck6() {
    if (this.state.imgSrc6 !== null) {
      return <FormFileName>{this.state.uploadedFileName6}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName6 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName6)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck7() {
    if (this.state.imgSrc7 !== null) {
      return <FormFileName>{this.state.uploadedFileName7}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName7 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName7)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck8() {
    if (this.state.imgSrc8 !== null) {
      return <FormFileName>{this.state.uploadedFileName8}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName8 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName8)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck9() {
    if (this.state.imgSrc9 !== null) {
      return <FormFileName>{this.state.uploadedFileName9}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName9 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName9)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck10() {
    if (this.state.imgSrc10 !== null) {
      return <FormFileName>{this.state.uploadedFileName10}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName10 !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName10)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  isValidImageSize = (fileDetails, width, height, fileNumber, isVideo) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function() {
      if (!isVideo && this.width === width && this.height === height) {
        globalThis.setSingleImageError(false, fileNumber);
      } else if (isVideo) {
        // no error
      } else {
        globalThis.setSingleImageError(true, fileNumber);
      }
    };
  };

  setSingleImageError = (showError, fileNumber) => {
    if (fileNumber === 1) {
      this.setState({
        imageSizeError1: showError
      });
    } else if (fileNumber === 2) {
      this.setState({
        imageSizeError2: showError
      });
    } else if (fileNumber === 3) {
      this.setState({
        imageSizeError3: showError
      });
    } else if (fileNumber === 4) {
      this.setState({
        imageSizeError4: showError
      });
    } else if (fileNumber === 5) {
      this.setState({
        imageSizeError5: showError
      });
    } else if (fileNumber === 6) {
      this.setState({
        imageSizeError6: showError
      });
    } else if (fileNumber === 7) {
      this.setState({
        imageSizeError7: showError
      });
    } else if (fileNumber === 8) {
      this.setState({
        imageSizeError8: showError
      });
    } else if (fileNumber === 9) {
      this.setState({
        imageSizeError9: showError
      });
    } else if (fileNumber === 10) {
      this.setState({
        imageSizeError10: showError
      });
    }
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Media Gallery</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>

            <FormSection>

              <FormSubHeading>Please submit up to 7 Images to be featured within the Microsite gallery.</FormSubHeading>
              <FormItalicHeading>All submitted images should be exactly 919 pixels wide and 517 pixels tall and must not exceed 75MB in size. Accepted file formats include: PDF, JPG, PNG, GIF.</FormItalicHeading>

              <div>
                <div>Image 1</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 1, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck()}
                              {
                                this.state.imageSizeError1 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 2</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 2, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck2()}
                              {
                                this.state.imageSizeError2 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 3</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 3, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck3()}
                              {
                                this.state.imageSizeError3 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 4</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 4, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck4()}
                              {
                                this.state.imageSizeError4 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 5</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 5, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck5()}
                              {
                                this.state.imageSizeError5 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 6</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 6, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck6()}
                              {
                                this.state.imageSizeError6 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Image 7</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 7, false)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck7()}
                              {
                                this.state.imageSizeError7 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

            </FormSection>

            <FormSection>
              <FormSubHeading>Please submit up to 3 Videos to be featured within the Microsite gallery.</FormSubHeading>
              <FormItalicHeading>All submitted videos must not exceed 75MB in size. Accepted file formats include: MP4, WMV, MOV, MPG, MPEG4.</FormItalicHeading>

              <div>
                <div>Video 1</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 8, true)}
                      multiple={false}
                      accept={acceptedVideoTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck8()}
                              {
                                this.state.imageSizeError8 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Video 2</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 9, true)}
                      multiple={false}
                      accept={acceptedVideoTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck9()}
                              {
                                this.state.imageSizeError9 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

              <div>
                <div>Video 3</div>
                <FormField>
                  <CenterImage>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 10, true)}
                      multiple={false}
                      accept={acceptedVideoTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ABSmallButton title="File Upload" />
                            <UploadButtonContainer>
                              {this.imageCheck10()}
                              {
                                this.state.imageSizeError10 &&
                                <ValidationError>Invalid Image Size</ValidationError>
                              }
                            </UploadButtonContainer>
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </CenterImage>
                </FormField>
              </div>

            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>
          {
            (this.state.imageUploading) &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeGallery: state.micrositeGallery.data,
    micrositeSetting: state.micrositeSetting.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  getMicrositeGallery: (id) => dispatch(getMicrositeGallery(id)),
  updateMicrosite: (microsite, micrositeId, multipleCloudFrontUrls, featureProducts, gallery) => dispatch(updateMicrosite(microsite, micrositeId, multipleCloudFrontUrls, featureProducts, gallery)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  setLocation: (page) => dispatch(setLocation(page)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
  createMicrositeGallery: (micrositeGallery, micrositeId) => dispatch(createMicrositeGallery(micrositeGallery, micrositeId)),
  updateMicrositeGallery: (micrositeGallery, micrositeId) => dispatch(updateMicrositeGallery(micrositeGallery, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaGallery);
