import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'

import makeData from './makeData'
import {getMediaImages, removeMediaImages} from '../../../actions/media-images';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import {history} from '../../../routers/AppRouter';
import colors from '../../../styles/colors';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const Title = styled.div`
  cursor: pointer;
  color: ${colors.blue}
  text-decoration: underline;
`;


const ImageContainer = styled.div`
  text-align: center;
  cursor: pointer;
  img {
    max-width: 140px;
    max-height: 140px;
  }
  :hover {
    opacity: .7;
  }
`;

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
                 columns,
                 data,
                 fetchData,
                 loading,
                 pageCount: controlledPageCount,
               }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize })
  }, [fetchData, pageIndex, pageSize])

  // Render the UI for your table
  return (
    <>
      <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        <tr>
          {loading ? (
            // Use our custom loading state to show a loading indicator
            <td colSpan="10000">Loading...</td>
          ) : (
            <td colSpan="10000">
              Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
              results
            </td>
          )}
        </tr>
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

const handleEditRow = (id) => {
  history.push(`media-image/${id}`);
};

// Let's simulate a large dataset on the server (outside of our component)
const serverData = makeData(10000)

function MediaLibraryMultiple(props) {

  const getTags = (mediaTags) => {
    let tags = [];
    if (mediaTags.length > 0) {
      mediaTags.map((item) => {
        tags.push(item.name);
      })
    }
    let tagsWithSpace = tags.join(', ');
    return tagsWithSpace.toString();
  };

  useEffect(() => {
    props.getMediaImages();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Media Library',
        columns: [
          {
            Header: 'Image',
            accessor: data => {
              return <ImageContainer onClick={() => handleEditRow(data.id)}><img
                src={`https://${data.url}`}
              /></ImageContainer>;
            },
          },
          {
            Header: 'Title',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.title}</Title>;
            },
          },
          {
            Header: 'URL',
            accessor: d => {
              return `https://${d.url}`;
            }
          },
          {
            Header: 'Date',
            accessor: d => {
              return moment(d.date).format('MMM. Do, YYYY');
            }
          },
          {
            Header: 'Folder',
            accessor: 'folder',
          },
          {
            Header: 'Tags',
            accessor: data => {
              return <div>{getTags(data.mediaTags)}</div>;
            },
          },
          {
            Header: 'Alt Text',
            accessor: 'altText',
          },
          {
            Header: 'User ID',
            accessor: 'userId',
          }
        ],
      },
    ],
    []
  )

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setData(serverData.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(serverData.length / pageSize));

        setLoading(false)
      }
    }, 1000)
  }, []);

  return (
    <Styles>
      {
        props.mediaImages.length > 0 &&
        <Table
          columns={columns}
          data={props.mediaImages}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
      }
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    mediaImages: state.mediaImages.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaImages: (title, tag) => dispatch(getMediaImages(title, tag)),
  removeMediaImages: (idsToDelete) => dispatch(removeMediaImages(idsToDelete)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibraryMultiple);

// export default MediaLibraryMultiple
