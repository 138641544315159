import axiosInstance from "../../utility/axios";

export const startGetAlertUpstream = () => ({
    type: 'START_GET_ALERT_UPSTREAM',
  });
  
  export const getAlertUpstreamSuccess = (alertUpstream) => ({
    type: 'GET_ALERT_UPSTREAM_SUCCESS',
    alertUpstream
  });
  
  export const getAlertUpstream = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-upstream/${alertItemId}`;
      console.log('get alertUpstream url: ', url);
      dispatch(startGetAlertUpstream());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertUpstream: ', response.data);
          dispatch(getAlertUpstreamSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertUpstreamCreate = () => ({
    type: 'START_ALERT_UPSTREAM_CREATE',
});

export const createAlertUpstreamSuccess = (alertUpstream) => ({
    type: 'CREATE_ALERT_UPSTREAM_SUCCESS',
    alertUpstream
});

export const createAlertUpstream = (alertUpstream) => {
    console.log('do alert upstream: ', alertUpstream);
    return (dispatch) => {
        dispatch(startAlertUpstreamCreate());
        axiosInstance.post(`alert-upstream`, alertUpstream)
            .then((response) => {
                console.log('AlerttUpstream Create: ', response.data);
                dispatch(createAlertUpstreamSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertUpstreamUpdate = () => ({
    type: 'START_ALERT_UPSTREAM_UPDATE',
  });
  
  export const updateAlertUpstreamSuccess = (alertUpstream) => ({
    type: 'UPDATE_ALERT_UPSTREAM_SUCCESS',
    alertUpstream
  });
  
  export const updateAlertUpstream = (alertUpstream) => {
    console.log('alertUpstream: ', alertUpstream);
    return (dispatch) => {
      dispatch(startAlertUpstreamUpdate());
      axiosInstance.put(`alert-upstream/${alertUpstream.id}`, alertUpstream)
        .then((response) => {
          dispatch(updateAlertUpstreamSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});