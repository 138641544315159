import React, { Component } from 'react';
import {connect} from "react-redux";
import { NavLink } from 'react-router-dom';
import {getFolders} from "../actions/upload";
import colors from '../styles/colors';
import Spinner from "../components/Spinner";
import Folder from '../images/folder-icon.png';
import Folder2x from '../images/folder-icon@2x.png';
import styled from "styled-components";

const FolderItems = styled.div`
  padding: 20px 0;
`;

const FolderRow = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 10px 15px;
  color: ${colors.black};
  div {
    padding-left: 10px;
  }
  :link {
    text-decoration: none;
  }
  :hover {
    background: ${colors.lightestGray};
  }
`;

const StyledSpinner = styled.div`
  text-align: center;
  margin-top: 20px;
`;

class MicrositeUploads extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      error: ''
    };
  }

  componentDidMount() {
    this.props.getFolders();
  }

  componentDidUpdate(prevProps) {
    // compare props, make sure it's different or they will loop
    // make sure success is true as well
    if (this.props.success !== prevProps.success && this.props.success) {
      //
    }
  }

  render() {
    return (
      <div>
        {
          !this.props.getFoldersSuccess &&
          <StyledSpinner>
            <Spinner />
          </StyledSpinner>
        }
        <FolderItems>
          {
            this.props.folders &&
            this.props.folders.map((folder) => {
              if (folder !== '/') { // exclude files on the root folder
                return (
                  <FolderRow to={`/microsite-uploads/${folder}`} key={folder}>
                    <img src={Folder} alt="" srcSet={`${Folder} 1x, ${Folder2x} 2x`} />
                    <div>{folder}</div>
                  </FolderRow>
                )
              }
            })
          }
        </FolderItems>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.upload.loading,
    error: state.upload.error,
    getFoldersSuccess: state.upload.getFoldersSuccess,
    folders: state.upload.folders
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFolders: () => dispatch(getFolders()),
});


export default connect(mapStateToProps, mapDispatchToProps)(MicrositeUploads);