import axiosInstance from '../utility/axios';
import {updatePostKeyWords} from './post-keywords';
import {updatePostInPosts} from './posts';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetPost = () => ({
  type: 'START_GET_POST',
});

export const getPostSuccess = (post) => ({
  type: 'GET_POST_SUCCESS',
  post
});

export const getPost = (id) => {
  return (dispatch) => {
    dispatch(startGetPost());
    axiosInstance.get(`post/${id}`)
      .then((response) => {
        if (response.data.post === false) { // no post yet
        } else { // has post
          dispatch(getPostSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreatePost = () => ({
  type: 'START_CREATE_POST'
});

export const createPostSuccess = (postDetails) => ({
  type: 'CREATE_POST_SUCCESS',
  postDetails
});

export const createPost = (postDetails, multipleCloudFrontUrls, keywords) => {
  console.log('creating post', postDetails);
  return (dispatch) => {
    dispatch(startCreatePost());
    axiosInstance.post('post', postDetails)
      .then((response) => {
        console.log('post created for check');
        console.log('Post created response: ', response.data);
        if (keywords.length > 0) {
          dispatch(updatePostKeyWords(keywords, response.data.id))
        }
        if (multipleCloudFrontUrls.length > 0) {
          dispatch(postDocuments(response.data.id, multipleCloudFrontUrls));
        }
        dispatch(createPostSuccess(response.data));
        console.log('post created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const postDocuments = (postId, multipleCloudFrontUrls) => {
  return (dispatch) => {
    console.log('postDocuments postId', postId);
    console.log('postDocuments multipleCloudFrontUrls', multipleCloudFrontUrls);

    let formattedDocumentURLs = {
      documents: []
    };

    multipleCloudFrontUrls.map((item) => {
      let imageItem = {
        imageUrl: item
      };
      formattedDocumentURLs.documents.push(imageItem);
    });

    dispatch(startCreatePost());
    axiosInstance.post(`documents/${postId}`, formattedDocumentURLs)
      .then((response) => {
        // added documents
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startPostUpdate = () => ({
  type: 'START_POST_UPDATE',
});

export const updatePostSuccess = (postDetails) => ({
  type: 'UPDATE_POST_SUCCESS',
  postDetails
});

export const updatePost = (postDetails, postId, multipleCloudFrontUrls, keywords) => {
  return (dispatch) => {
    dispatch(startPostUpdate());
    axiosInstance.put(`post/${postId}`, postDetails)
      .then((response) => {
        if (keywords.length > 0) {
          dispatch(updatePostKeyWords(keywords, response.data.id))
        }
        if (multipleCloudFrontUrls && multipleCloudFrontUrls.length > 0) {
          dispatch(postDocuments(response.data.id, multipleCloudFrontUrls));
        }
        // not working right, doesn't reload the
        // dispatch(updatePostSuccess(postDetails));

        // updated this to just change the title and status in the main posts array
        dispatch(updatePostInPosts(postDetails));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

