import axiosInstance from "../../utility/axios";

export const startGetAlertNewss = () => ({
  type: 'START_GET_ALERT_NEWS',
});

export const getAlertNewsSuccess = (alertNews) => ({
  type: 'GET_ALERT_NEWS_SUCCESS',
  alertNews
});

export const getAlertNews = (alertItemId) => {
  return (dispatch) => {
    let url = `alert-news/${alertItemId}`;
    console.log('get alertNews url: ', url);
    dispatch(startGetAlertNewss());
    axiosInstance.get(url)
      .then((response) => {
        console.log('getAlertNews: ', response.data);
        dispatch(getAlertNewsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertNewsCreate = () => ({
  type: 'START_ALERT_NEWS_CREATE',
});

export const createAlertNewsSuccess = (alertNews) => ({
  type: 'CREATE_ALERT_NEWS_SUCCESS',
  alertNews
});

export const createAlertNews = (alertNews) => {
  console.log('do alert news: ', alertNews);
  return (dispatch) => {
    dispatch(startAlertNewsCreate());
    axiosInstance.post(`alert-news`, alertNews)
      .then((response) => {
        dispatch(createAlertNewsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertNewsUpdate = () => ({
  type: 'START_ALERT_NEWS_UPDATE',
});

export const updateAlertNewsSuccess = (alertNews) => ({
  type: 'UPDATE_ALERT_NEWS_SUCCESS',
  alertNews
});

export const updateAlertNews = (alertNews) => {
  console.log('alertNews: ', alertNews);
  return (dispatch) => {
    dispatch(startAlertNewsUpdate());
    axiosInstance.put(`alert-news/${alertNews.id}`, alertNews)
      .then((response) => {
        dispatch(updateAlertNewsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertNewsRemove = () => ({
  type: 'START_REMOVE_ALERT_NEWS',
});

export const deleteAlertNewsSuccess = (id) => ({
  type: 'REMOVE_ALERT_NEWS_SUCCESS',
  id
});

export const deleteAlertNews = (id) => {
  return (dispatch) => {

    dispatch(startAlertNewsRemove());

    axiosInstance.delete(`alert-news/${id}`)
      .then((response) => {
        dispatch(deleteAlertNewsSuccess(id));
      })
      .catch((error) => {
        console.error('Error deleting alert news:', error);
        dispatch(showError(error));
      });
  }
};


export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});