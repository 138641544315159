const postTypesDefaultState = {
  data: [],
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const postTypesReducer = (state = postTypesDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_POST_TYPES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_POST_TYPES_SUCCESS':
      return {
        ...state,
        data: action.postTypes,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'CREATE_POST_TYPES_SUCCESS':
      return {
        ...state,
        data: [
          action.post,
          ...state.data,
        ],
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Post Type has been added!',
      };
    case 'UPDATE_POST_TYPES_SUCCESS': // updating single post
      return {
        ...state,
        data: state.data.map((post) => {
          if (post.postId === action.post.postId) {
            console.log('got match');
            return {
              ...post,
              ...action.post /// add object to array
            }
          } else {
            return post;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_POST_TYPE_SUCCESS':
      return {
        ...state,
        data: state.data.filter(post => action.postId !== post.postId), // filter original state and return any that don't have the remove postId
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your post has been removed',
      };
    case 'START_SAVE_POST_TYPES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SAVE_POST_TYPES_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your postTypes has been saved!',
      };
    case 'START_REMOVE_POST_TYPES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_POST_TYPES_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your postTypes have been removed!',
      };
    case 'SHOW_POST_TYPES_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default postTypesReducer;