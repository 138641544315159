import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertPreviousPoll } from './alert-types.js';
import ABDropzone from '../../../components/ABDropzone';
import { ToastContainer, toast } from 'react-toastify';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const DefaultImagePreviewStyle = styled.div`
  img {
    width: 100px;
    height: 100px;
  }
`;

interface AlertPreviousPollProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertPreviousPoll: AlertPreviousPoll) => void;
    handleUpdate: (alertPreviousPoll: AlertPreviousPoll) => void;
    alertPreviousPoll: AlertPreviousPoll
}

const AlertPreviousPollForm: React.FC<AlertPreviousPollProps> = ({ alertPreviousPoll, alertItemId, pageTitle, handleSave, handleUpdate }) => {

    const [id, setId] = useState(0);
    const [image, setImage] = useState('');
    const [imageUploaded, setImageUploaded] = useState(false);
    // const [link, setLink] = useState('');
    const [attribution, setAttribution] = useState('');
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        // check if editing
        if (alertPreviousPoll.id !== 0) {
            setId(alertPreviousPoll.id);
            setEditing(true);
            setImage(alertPreviousPoll.image);
            // setLink(alertPreviousPoll.link);
            setAttribution(alertPreviousPoll.attribution);
        }
    }, [])

    const onSave = () => {

        if (imageUploaded && !image) {
            toast.error('Error Uploading Image');
            return;
        }

        const alertPreviousPoll: AlertPreviousPoll = {
            id,
            image,
            alertItemId: alertItemId,
            attribution,
        }

        if (editing) {
            handleUpdate(alertPreviousPoll);
        } else {
            handleSave(alertPreviousPoll);
        }
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        // setLink(link);
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const handleFilesDrop = (cloudfrontUrl: string) => {
        setImage(cloudfrontUrl);
        setImageUploaded(true);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormSubHeading>Image:</FormSubHeading>
                <ABDropzone onFilesDrop={handleFilesDrop} maxWidth={600} />

                {
                    (image && !imageUploaded) &&
                    <DefaultImagePreviewStyle>
                        <FormSubHeading>Image Preview:</FormSubHeading>
                        <img src={`https://${image}`} />
                    </DefaultImagePreviewStyle>
                }

                {/* <FormSubHeading>Link:</FormSubHeading>
                <ABInputField
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="Link"
                /> */}

                <FormSubHeading>Attribution Tracking:</FormSubHeading>
                <ABInputField
                    value={attribution}
                    onChange={handleAttributionChange}
                    placeholder="Attribution"
                />

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
                <ToastContainer />
            </PageWrapper>
        </>
    );
}

export default AlertPreviousPollForm;
