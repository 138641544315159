import axiosInstance from "../../utility/axios";

export const startGetAlertJobs = () => ({
    type: 'START_GET_ALERT_JOBS',
  });
  
  export const getAlertJobsSuccess = (alertJobs) => ({
    type: 'GET_ALERT_JOBS_SUCCESS',
    alertJobs
  });
  
  export const getAlertJobs = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-jobs/${alertItemId}`;
      console.log('get alertJob url: ', url);
      dispatch(startGetAlertJobs());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertJobs: ', response.data);
          dispatch(getAlertJobsSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertJobCreate = () => ({
    type: 'START_ALERT_JOB_CREATE',
});

export const createAlertJobSuccess = (alertJob) => ({
    type: 'CREATE_ALERT_JOB_SUCCESS',
    alertJob
});

export const createAlertJob = (alertJob) => {
    console.log('do alert job: ', alertJob);
    return (dispatch) => {
        dispatch(startAlertJobCreate());
        axiosInstance.post(`alert-job`, alertJob)
            .then((response) => {
                console.log('AlerttJob Create: ', response.data);
                dispatch(createAlertJobSuccess(response.data));
                dispatch(getAlertJobs(response.data.alertItemId));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertJobUpdate = () => ({
    type: 'START_ALERT_JOB_UPDATE',
  });
  
  export const updateAlertJobSuccess = (alertJob) => ({
    type: 'UPDATE_ALERT_JOB_SUCCESS',
    alertJob
  });
  
  export const updateAlertJob = (alertJob) => {
    console.log('alertJob: ', alertJob);
    return (dispatch) => {
      dispatch(startAlertJobUpdate());
      axiosInstance.put(`alert-job/${alertJob.id}`, alertJob)
        .then((response) => {
          dispatch(updateAlertJobSuccess(response.data));
          dispatch(getAlertJobs(response.data.alertItemId));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});