import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertJob, Job } from './alert-types.js';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

const SettingItem = styled.div`
  padding-bottom: 30px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 450px;
  border: 1px solid ${colors.lightGray};
`;

interface AlertFormProps {
    alertItemId: number;
    pageTitle: string;
    position: number;
    handleSave: (alertJob: AlertJob) => void;
    handleUpdate: (alertJob: AlertJob) => void;
    alertJob: AlertJob
    jobs: Job[]
}

const AlertJobForm: React.FC<AlertFormProps> = ({alertJob, alertItemId, pageTitle, position, handleSave, handleUpdate, jobs}) => {

    const [id, setId] = useState(0);
    const [attribution, setAttribution] = useState('');
    const [selectedJobId, setSelectedJobId] = useState(0);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        // check if editing
        if (alertJob.id !== 0) {
            setId(alertJob.id);
            setEditing(true);
            setAttribution(alertJob.attribution);
        }
    }, [])

    const onSave = () => {

        const alertJob: AlertJob = {
            id,
            alertItemId: alertItemId,
            position,
            jobId: selectedJobId,
            attribution
        }

        if (editing) {
            handleUpdate(alertJob);
        } else {
            handleSave(alertJob);
        }
    };

    const handleSelectedJobChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedJobId = e.currentTarget.value;
        setSelectedJobId(parseInt(selectedJobId, 10));
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <SettingItem>
                    <FormSubHeading>Select Job:</FormSubHeading>
                    <StyledSelect
                        name="category"
                        value={selectedJobId}
                        onChange={handleSelectedJobChange}
                    >
                        <option value=''>Select</option>
                        {
                            jobs?.length > 0 &&
                            jobs.map((item => {
                                return <option value={item.id}>{item.jobTitle} - {item.company}</option>
                            }))
                        }
                    </StyledSelect>
                </SettingItem>

                {/* <FormSubHeading>Attribution:</FormSubHeading>
                <ABInputField
                    value={attribution}
                    onChange={handleAttributionChange}
                    placeholder="Attribution"
                /> */}

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
            </PageWrapper>
        </>
    );
}

export default AlertJobForm;
