import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import {history} from "../../../routers/AppRouter";
import moment from 'moment';
import styled from 'styled-components'
import { useTable, useRowSelect } from 'react-table';
import {connect} from "react-redux";
import {FormInput, FormLabel} from '../../../styles/form-styles';

import {getPosts, removePosts} from '../../../actions/posts';
import MainButton from '../../../components/MainButton';
import ABSmallButton from '../../../components/ABSmallButton';
import colors from '../../../styles/colors';
import Modal from 'react-modal';
// import '../../styles/form-modal.css';
import '../../../styles/post-edit-modal.css';
import Post from './Post';
import { ToastContainer, toast } from 'react-toastify';
import { bulkUpdate } from '../../../actions/bulk-update';
import SearchBar from '../../../components/SearchBar';
import { getMicrosites } from '../../../actions/microsites';
import { bulkUpdatePostMicrosite } from '../../../actions/bulk-update-post-microsite';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBarStyle = styled.div`
  input {
    max-width: 300px;
  }
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const StyledSmallHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 20px;
  font-weight: bold;
`;

const Title = styled.div`
  cursor: pointer;
  color: ${colors.blue};
  :hover {
   color: ${colors.black}; 
  }
`;

const SearchBarPadding = styled.div`
  padding: 10px 0 0 0;
`;

const SearchContainer = styled.div`
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
`;

const BulkInputContainer = styled.div`
  padding: 10px 0;
`;

const ButtonPadding = styled.div`
  padding: 0 5px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 320px;
  border: 1px solid ${colors.black};
`;

const CategorySelect = styled.div`
  padding-left: 20px;
`;

const TitleInput = styled.div`
  margin-right: 25px;
  width: 300px;
`;

const TagInput = styled.div`
  margin-left: 25px;
  width: 300px;
`;

const SearchButton = styled.div`
  margin-left: 20px;
`;

const Pagination = styled.div`
  padding: 20px 0;
  text-align: right;
`;

const SmallTitle = styled.div`
  font-size: 18px;
  padding: 10px 0;
`;

const FilterContent = styled.div`
  display: ${props => props.hide === true ? 'none' : 'block'};
  padding: 30px 0 50px 0;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const createPost = () => {
  history.push('/post')
};

// called from SearchFilter component
// not put in yet
// const applyFilters = (categoryIds, year) => {
//   console.log('applyFilters.year: ', year);
//   this.props.getSearchItems(this.state.keyword, this.state.applicationType, this.state.skip, this.state.limit, categoryIds, year);
// }

function Table({ columns, data, deleteSelectedPosts, onKeywordSearchChange, onBulkChange, bulkUpdate, bulkUpdatePostMicrsoite, onSearchFilter, nextPage, previousPage, pageCount, pageLimit, searching, modalOpen, closeModal, postId, selectedCategory, handleSelectedCategory, getSelectedPosts, onSearchChange, onSearchClick, filteredMicrosites, micrositeTitle, selectedMicrosite }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  // Render the UI for your table
  return (
    <>
      <HeadingContainer>
        <StyledHeading>All Posts</StyledHeading>
        <ButtonPadding><MainButton title="Create Post" onClick={() => createPost()} /></ButtonPadding>
        <ButtonPadding><MainButton title="Delete Posts" onClick={() => deleteSelectedPosts(selectedFlatRows)} /></ButtonPadding>
        {/* <ButtonPadding><MainButton title="Get Selected Rows" onClick={() => getSelectedPosts(selectedFlatRows)} /></ButtonPadding> */}
      </HeadingContainer>

      <StyledSmallHeading>Bulk Update</StyledSmallHeading>

      <BulkInputContainer>
        <HeadingContainer>
          <TitleInput>
            <FormInput
              type="number" 
              placeholder="Client Portal Id" 
              onChange={(e) => onBulkChange(e)} 
            />
          </TitleInput>
          <ButtonPadding><MainButton title="Update Client Id's" onClick={() => bulkUpdate(selectedFlatRows, 'clientPortalId')} /></ButtonPadding>
        </HeadingContainer>
      </BulkInputContainer>

      <BulkInputContainer>
        <HeadingContainer>
          <TitleInput>
            <FormInput placeholder="Attribution" onChange={(e) => onBulkChange(e)} />
          </TitleInput>
          <ButtonPadding><MainButton title="Update Attribution" onClick={() => bulkUpdate(selectedFlatRows, 'attribution')} /></ButtonPadding>
        </HeadingContainer>
      </BulkInputContainer>

      <BulkInputContainer>
        <HeadingContainer>
          <StyledSelect
                onChange={(e) => onBulkChange(e)}
              >
                <option value=''>Commercial Content</option>
                <option value="0">False</option>
                <option value="1">True</option>
            </StyledSelect>
          <ButtonPadding><MainButton title="Update Commercial" onClick={() => bulkUpdate(selectedFlatRows, 'commercialContent')} /></ButtonPadding>
        </HeadingContainer>
      </BulkInputContainer>

      <BulkInputContainer>
        <HeadingContainer>
          <SearchBarStyle>
            {
              filteredMicrosites &&
              <SearchBar 
                onChange={(e) => onSearchChange(e)} 
                onSearchClick={(microsite) => onSearchClick(microsite)} 
                micrositeTitle={micrositeTitle}
                hide={false} 
                showSearchResults={searching}
                postsFiltered={filteredMicrosites}
              />
            }
          </SearchBarStyle>
          <ButtonPadding><MainButton title="Update Microsite Links" onClick={() => bulkUpdatePostMicrsoite(selectedFlatRows)} /></ButtonPadding>
        </HeadingContainer>
      </BulkInputContainer>

      <SearchBarPadding>

        <StyledSmallHeading>Search</StyledSmallHeading>
        <SearchContainer>
          <TitleInput>
            <FormInput placeholder="Title/Name/Attribution" onChange={(e) => onKeywordSearchChange(e)} />
          </TitleInput>

          <CategorySelect>
            <StyledSelect
              name="category"
              value={selectedCategory || ''}
              onChange={(e) => handleSelectedCategory(e.target.value)}
            >
              <option value=''>Category</option>
              <option value="8">Application Notes</option>
              <option value="9">Articles</option>
              <option value="1">Books</option>
              <option value="10">Solutions</option>
              <option value="12">Case Studies</option>
              <option value="4">Guides</option>
              <option value="20">Patents</option>
              <option value="19">Spotlight</option>
              <option value="13">Posters</option>
              <option value="22">Presentations</option>
              <option value="29">Research Papers</option>
              <option value="30">Surveys</option>
              <option value="7">White Papers</option>
            </StyledSelect>
          </CategorySelect>

          <FilterContent hide={!searching}>
            {/*<SearchFilter searchItems={this.props.searchItems} microsites={this.props.microsites} applyFilters={(categoryIds, year) => applyFilters(categoryIds, year)} />*/}
          </FilterContent>

          <SearchButton>
            <ABSmallButton title="Filter" onClick={() => onSearchFilter()} />
          </SearchButton>
        </SearchContainer>

      </SearchBarPadding>

      <Pagination>
        {
          pageCount !== 0 &&
          <MainButton onClick={() => previousPage()} title="Previous Page" />
        }
        {
          data.length > pageLimit - 1 &&
          <MainButton onClick={() => nextPage()} title="Next Page" />
        }
        <SmallTitle>Page: {pageCount + 1}</SmallTitle>
      </Pagination>
      
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, 50).map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {/* Debug code for row selection */}
      {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      {
        Object.keys(selectedRowIds).map((item) => {
          return <div>{item}</div>
        })
      }
      <pre>
        <code>
        {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                d => d.original
              )
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <ToastContainer />
      <Modal
        isOpen={modalOpen || false}
        onRequestClose={() => closeModal()}
        contentLabel="Sort"
        className="PostEditModal"
        overlayClassName="PostEditOverlay"
        ariaHideApp={false}
      >
        <Post postId={postId} closeModal={closeModal} />
      </Modal>
    </>
  )
}

function Posts(props) {

  const [keyword, setKeyword] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const pageLimit = 50;
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [postId, setPostId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [bulkValue, setBulkValue] = useState('');
  const [filteredMicrosites, setFilteredMicrosites] = useState([]);
  const [micrositeTitle, setMicrositeTitle] = useState('');
  const [selectedMicrosite, setSelectedMicrosite] = useState({});

  const onKeywordSearchChange = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const onSearchFilter = () => {
    if (keyword.length > 1 && selectedCategory) {
      props.getPosts(keyword, null, null, selectedCategory);
    } else if (keyword.length > 1) {
      props.getPosts(keyword);
    } else if (selectedCategory) {
      console.log('should just be category: ', selectedCategory);
      props.getPosts(null, null, null, selectedCategory);
    } else {
      props.getPosts();
    }
  };

  const nextPage = () => {
    if (props.posts.length > pageLimit - 1) {
      setPageCount(pageCount + pageLimit);
      props.getPosts(keyword, pageLimit, pageCount + pageLimit, selectedCategory);
    }
  };

  const previousPage = () => {
    if (pageCount !== 0) {
      setPageCount(pageCount - pageLimit);
      props.getPosts(keyword, pageLimit, pageCount - pageLimit, selectedCategory);
    }
  };

  // had to be in here to get be able to fire props dispatch
  const deleteSelectedPosts = (selectedRows) => {
    let postIdsToDelete = [];
    selectedRows.map((item) => {
      postIdsToDelete.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    props.removePosts(postIdsToDelete);
  };

  const handleEditRow = (id) => {
    setModalOpen(true);
    setPostId(id);
    // history.push(`post/${id}`);
  };

  const getSelectedPosts = (selectedRows) => {
    let selectedPostIds = [];
    selectedRows.map((item) => {
      selectedPostIds.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    selectedPostIds.map((itemId) => {
      console.log(itemId);
    })
  };

  const onBulkChange = (e) => {
    const bulkValue = e.target.value;
    console.log(bulkValue);
    setBulkValue(bulkValue);
  };

  // update selected all selected rows in database with table name, field to update and what new value
  // fieldName is the actual fieldName in the databae row
  const bulkUpdate = (selectedRows, fieldName) => {
    // console.log('bulkValue: ', bulkValue);
    let selectedPostIds = [];
      selectedRows.map((item) => {
        selectedPostIds.push(parseInt(item.original.id, 10)); // original is the actual object info
      });
      selectedPostIds.map((itemId) => {
        console.log(itemId);
      })

      let bulkDetails = {
        postIds: {
          selectedPostIds: [selectedPostIds]
        },
        newValue: bulkValue,
        tableName: 'posts',
        fieldName: fieldName,
      };

      props.bulkUpdate(bulkDetails);
  }

  // update selected all selected rows in database under posts and microsite field
  const bulkUpdatePostMicrsoite = (selectedRows) => {
    let selectedPostIds = [];
      selectedRows.map((item) => {
        selectedPostIds.push(parseInt(item.original.id, 10)); // original is the actual object info
      });
      selectedPostIds.map((itemId) => {
        console.log(itemId);
      })

      let bulkPostMicrositeDetails = {
        postIds: {
          selectedPostIds: [selectedPostIds]
        },
        newMicrositeId: selectedMicrosite.id,
        newMicrositeLink: micrositeTitle
      };

      props.bulkPostMicrositeUpdate(bulkPostMicrositeDetails);
  }

  const onSearchChange = (e) => {
    const keyword = e.target.value.toLowerCase();

    if (keyword.length >= 1) {
      setSearching(true);
    } else {
      setSearching(false);
    }

    const filteredMicrosites = props.microsites;

    const searchResults = filteredMicrosites.filter(item => item.pageTitle.toLowerCase().includes(keyword));

    setFilteredMicrosites(searchResults);
    setMicrositeTitle(keyword);
  };

  const onSearchClick = (microsite) => {
    console.log('search click: ', microsite);

    setSelectedMicrosite(microsite);
    setMicrositeTitle(microsite.pageTitle);
    setSearching(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelectedCategory = (value) => {
    setSelectedCategory(parseInt(value, 10));
  };

  useEffect(() => {
    props.getPosts();
    props.getMicrosites();
  }, []);

  useEffect(() => {
    setFilteredMicrosites(props.microsites);
  }, [props.microsites]);

  useEffect(() => {
    if (props.bulkSuccessMessage) {
      toast.success(props.bulkSuccessMessage);
    }
    
  }, [props.bulkSuccessMessage]);

  useEffect(() => {
    if (props.bulkSuccessMessagePostMicrosite) {
      toast.success(props.bulkSuccessMessagePostMicrosite);
    }
    
  }, [props.bulkSuccessMessagePostMicrosite]);

  useEffect(() => {
    if (props.bulkError) {
      toast.error('Error Updating');
    }
    
  }, [props.bulkError]);

  useEffect(() => {
    if (props.bulkErrorPostMicrosite) {
      toast.error('Error Updating');
    }
    
  }, [props.bulkErrorPostMicrosite]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Info',
        columns: [
          {
            Header: 'ID',
            accessor: d => {
              return d.id;
            }
          },
          {
            Header: 'Title',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.title}</Title>;
            },
          },
          {
            Header: 'Client Portal ID',
            accessor: d => {
              return <Title>{d.clientPortalId}</Title>
            },
          },
          {
            Header: 'Date',
            accessor: d => {
              return moment(d.date).format('MMM. Do, YYYY');
            }
          },
          {
            Header: 'Date Created',
            accessor: d => {
              return moment(d.createdAt).format('MMM. Do, YYYY');
            }
          },
          {
            Header: 'Commercial',
            accessor: d => {
              if (d.commercialContent === false) {
                return 'false';
              } else if (d.commercialContent === true) {
                return 'true';
              } else {
                return 'not set'
              }
            },
          },
          // {
          //   Header: 'Post Types',
          //   accessor: data => {
          //     let output = [];
          //     data.selectedPostTypes.map((item) => {
          //       output.push(item.name);
          //     });
          //     return output.join(', ');
          //   },
          // },
          {
            Header: 'Categories',
            accessor: data => {
              let output = [];
              data.selectedCategories.map((item) => {
                output.push(item.name);
              });
              return output.join(', ');
            },
          },
          {
            Header: 'Microside Id',
            accessor: d => {
              return d.micrositeId;
            },
          },
          {
            Header: 'Microsite Link',
            accessor: d => {
              return d.micrositeLink;
            },
          },
          {
            Header: 'Application Type',
            accessor: 'applicationType',
          },
          {
            Header: 'Attribution',
            accessor: d => {
              return d.attribution;
            },
          },
          {
            Header: 'Attribution 2',
            accessor: d => {
              return d.attribution2;
            },
          },
          {
            Header: 'Status',
            accessor: 'status',
          },
        ],
      },
    ],
    []
  );

  return (
    <Styles>
      <Table
        columns={columns}
        data={props.posts}
        deleteSelectedPosts={deleteSelectedPosts}
        getSelectedPosts={getSelectedPosts}
        onKeywordSearchChange={onKeywordSearchChange}
        onBulkChange={onBulkChange}
        bulkUpdate={bulkUpdate}
        bulkUpdatePostMicrsoite={bulkUpdatePostMicrsoite}
        onSearchFilter={onSearchFilter}
        pageLimit={pageLimit}
        nextPage={nextPage}
        previousPage={previousPage}
        pageCount={pageCount / pageLimit}
        searching={searching}
        modalOpen={modalOpen}
        closeModal={closeModal}
        postId={postId}
        selectedCategory={selectedCategory}
        handleSelectedCategory={handleSelectedCategory}
        onSearchChange={onSearchChange}
        onSearchClick={onSearchClick}
        filteredMicrosites={filteredMicrosites}
        micrositeTitle={micrositeTitle}
        selectedMicrosite={selectedMicrosite}
      />
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.posts.data,
    bulkSuccessMessage: state.bulkUpdate.bulkSuccessMessage,
    bulkError: state.bulkUpdate.bulkError,
    microsites: state.microsites.data,
    bulkErrorPostMicrosite: state.bulkUpdatePostMicrosite.bulkError,
    bulkSuccessMessagePostMicrosite: state.bulkUpdatePostMicrosite.bulkSuccessMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPosts: (keyword, limit, skip, selectedCategory) => dispatch(getPosts(keyword, limit, skip, selectedCategory)),
  removePosts: (idsToDelete) => dispatch(removePosts(idsToDelete)),
  bulkUpdate: (bulkDetails) => dispatch(bulkUpdate(bulkDetails)),
  bulkPostMicrositeUpdate: (bulkPostMicrositeDetails) => dispatch(bulkUpdatePostMicrosite(bulkPostMicrositeDetails)),
  getMicrosites: () => dispatch(getMicrosites()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
