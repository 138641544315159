import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetUser = () => ({
  type: 'START_GET_USER',
});

export const getUserSuccess = (user) => ({
  type: 'GET_USER_SUCCESS',
  user
});

export const getUser = () => {
  return (dispatch) => {
    dispatch(startGetUser());
    axiosInstance.get('user')
      .then((response) => {
        console.log('response: ', response);
        dispatch(getUserSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};

export const startAccountUpdate = () => ({
  type: 'START_ACCOUNT_UPDATE',
});

export const accountUpdatedSuccess = (accountInfo) => ({
  type: 'ACCOUNT_UPDATE_SUCCESS',
  accountInfo
});

export const accountUpdate = (accountInfo) => {
  return (dispatch) => {
    dispatch(startAccountUpdate());
    axiosInstance.put('user', accountInfo)
      .then((response) => {
        dispatch(accountUpdatedSuccess(accountInfo));
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};

export const startPasswordUpdate = () => ({
  type: 'START_PASSWORD_UPDATE',
});

export const passwordUpdateSuccess = () => ({
  type: 'PASSWORD_UPDATE_SUCCESS',
});

export const passwordUpdate = (passwordInfo) => {
  return (dispatch) => {
    dispatch(startPasswordUpdate());
    axiosInstance.put('users/passcheck', passwordInfo)
      .then((response) => {
        dispatch(passwordUpdateSuccess());
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};

