import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertPoll, updateAlertPoll } from '../../../../actions/alert/alert-poll';
import styled from 'styled-components';
import { AlertPoll } from "../alert-types";
import Modal from 'react-modal';
import AlertPollForm from "../AlertPollForm";
import { createAlertSurvey, updateAlertSurvey } from "../../../../actions/alert/alert-survey";
import ABSmallButton from "../../../../components/ABSmallButton";

interface ImageStyleProps {
  width?: number;
}

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px 0;
`;

const QuestionStyle = styled.div`
  font-size: 18px;
  padding-left: 10px;
`;

interface Props {
  alertItemId: number;
  alertPoll: AlertPoll;
  contentType: string;
  buttonTitle: string;
  createAlertPoll: (alertPoll: AlertPoll) => void;
  updateAlertPoll: (alertPoll: AlertPoll) => void;
  createAlertSurvey: (alertPoll: AlertPoll) => void;
  updateAlertSurvey: (alertPoll: AlertPoll) => void;
}

const AlertComponentB: React.FC<Props> = ({ alertItemId, alertPoll, contentType, buttonTitle, createAlertPoll, updateAlertPoll, createAlertSurvey, updateAlertSurvey }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertPoll, setNewAlertPoll] = useState<AlertPoll>({ id: 0, title: '', link: '', alertItemId: 0, answer1: '', answer2: '', answer3: '', answer4: '', answer5: '', answer6: '', attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertPoll: AlertPoll) => {
    setNewAlertPoll(alertPoll);
    setModalIsOpen(true);
  }

  const handleSave = (alertPoll: AlertPoll) => {
    if (contentType === 'poll') {
      createAlertPoll(alertPoll);
    }
    if (contentType === 'survey') {
      createAlertSurvey(alertPoll);
    }
    closeModal();
  }

  const handleUpdate = (alertPoll: AlertPoll) => {
    if (contentType === 'poll') {
      updateAlertPoll(alertPoll);
    }
    if (contentType === 'survey') {
      updateAlertSurvey(alertPoll);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertPoll && Object.keys(alertPoll).length > 0) ?
          <>
            <TitleStyle>{alertPoll.title}</TitleStyle>
            <QuestionContainer>
              <div>►</div>
              <QuestionStyle>{alertPoll.answer1}</QuestionStyle>
            </QuestionContainer>
            <QuestionContainer>
              <div>►</div>
              <QuestionStyle>{alertPoll.answer2}</QuestionStyle>
            </QuestionContainer>
            {
              alertPoll.answer3 &&
              <QuestionContainer>
                <div>►</div>
                <QuestionStyle>{alertPoll.answer3}</QuestionStyle>
              </QuestionContainer>
            }
            {
              alertPoll.answer4 &&
              <QuestionContainer>
                <div>►</div>
                <QuestionStyle>{alertPoll.answer4}</QuestionStyle>
              </QuestionContainer>
            }
            {
              alertPoll.answer5 &&
              <QuestionContainer>
                <div>►</div>
                <QuestionStyle>{alertPoll.answer5}</QuestionStyle>
              </QuestionContainer>
            }
            {
              alertPoll.answer6 &&
              <QuestionContainer>
                <div>►</div>
                <QuestionStyle>{alertPoll.answer6}</QuestionStyle>
              </QuestionContainer>
            }
            <ButtonContainer>
              {
                contentType === 'poll'
                  ? <a href="https://us8.list-manage.com/survey?u=7796449412bb01b7d9f26f16a&id=a95c0c21f3&e=*|UNIQID|*" target="_blank">
                    <ABSmallButton title="Click Here to Participate" rounded />
                  </a>
                  : <a href="https://us8.list-manage.com/survey?u=7796449412bb01b7d9f26f16a&id=2cb760d7c5&e=*|UNIQID|*" target="_blank">
                    <ABSmallButton title="Click Here to Participate" rounded />
                  </a>
              }
              <ButtonContainer>
                <EditButton onClick={() => handleEdit(alertPoll)}>Edit</EditButton>
              </ButtonContainer>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Alert Edit"
      >
        <AlertPollForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertPoll={newAlertPoll}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertPoll: state.alertPoll.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertPoll: (alertPoll: AlertPoll) => dispatch(createAlertPoll(alertPoll)),
  updateAlertPoll: (alertPoll: AlertPoll) => dispatch(updateAlertPoll(alertPoll)),
  createAlertSurvey: (alertPoll: AlertPoll) => dispatch(createAlertSurvey(alertPoll)),
  updateAlertSurvey: (alertPoll: AlertPoll) => dispatch(updateAlertSurvey(alertPoll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentB);