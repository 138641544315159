import React, { useEffect, HtmlHTMLAttributes, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../../routers/AppRouter";
import { getAlertItems } from "../../../actions/alert/alert-items";
import styled from 'styled-components';
import MainButton from "../../../components/MainButton";
import moment from "moment";
import { AlertItem } from "./alert-types";
import colors from '../../../styles/colors';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TableStyle = styled.div`
  padding: 40px 0;
`;

const TableHeading = styled.div`
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: .6;
  }
`;

const TableCellHeading = styled.div`
  padding: 10px;
  width: 200px;
  font-weight: bold;
`;

const TableCell = styled.div`
  padding: 10px;
  width: 200px;
`;

const FilterContainer = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

interface FilterButtonProps {
  activeFilterTitle?: boolean;
}

const FilterButton = styled.button <FilterButtonProps>`
  background: ${props => props.activeFilterTitle ? colors.blue : colors.white};
  color: ${props => props.activeFilterTitle ? colors.white : colors.black};
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-right: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  border-bottom: 1px solid ${colors.lightGray};
  border-left: 1px solid ${colors.lightGray};

  &:nth-child(2), &:last-child {
    border-left: none;
  }
  :hover {
      background-color: ${props => props.activeFilterTitle ? '' : colors.gray100};
  }
`;

const Alerts = (props: any) => {

  const [activeFilter, setActiveFilter] = useState('draft');

  useEffect(() => {
    props.getAlerts({ status: 'draft' });
  }, []);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, title: string) => {
    setActiveFilter(title);
    if (title === 'all') {
      props.getAlerts();
    } else {
      props.getAlerts({ status: title });
    }
  }

  const handleCreate = () => {
    history.push('/create-alert');
  }

  const handeViewAlert = (id: number) => {
    history.push(`/alert/${id}`);
  }

  return (
    <>
      <PageWrapper>
        <HeadingContainer>
          <MainButton onClick={handleCreate} title="Create Alert" />
        </HeadingContainer>
        <FilterContainer>
          <FilterButton activeFilterTitle={activeFilter === 'draft'} onClick={(e) => handleButtonClick(e, 'draft')}>Draft</FilterButton>
          <FilterButton activeFilterTitle={activeFilter === 'published'} onClick={(e) => handleButtonClick(e, 'published')}>Published</FilterButton>
          <FilterButton activeFilterTitle={activeFilter === 'all'} onClick={(e) => handleButtonClick(e, 'all')}>All</FilterButton>
        </FilterContainer>
        <TableStyle>
          <TableHeading>
            <TableCellHeading>Id</TableCellHeading>
            <TableCellHeading>Issue #</TableCellHeading>
            <TableCellHeading>Title</TableCellHeading>
            <TableCellHeading>Date</TableCellHeading>
            <TableCellHeading>Status</TableCellHeading>
          </TableHeading>
          {
            props.alerts.map((item: AlertItem) => {
              return (
                <TableRow onClick={() => handeViewAlert(item.id!)}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.issue}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{moment(item.date).format('MMM. Do, YYYY')}</TableCell>
                  <TableCell>{item.status}</TableCell>
                </TableRow>
              );
            })
          }
        </TableStyle>
      </PageWrapper>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    alerts: state.alertItems.data
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getAlerts: (queryParams?: object) => dispatch(getAlertItems(queryParams))
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);