const resetPasswordDefaultState = {
  loading: false,
  error: false,
  success: false,
  errorMessage: null,
};

const resetPasswordReducer = (state = resetPasswordDefaultState, action) => {
  switch(action.type) {
    case 'START_RESET_PASSWORD':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;