import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertPreviousPoll, updateAlertPreviousPoll } from '../../../../actions/alert/alert-previous-poll';
import styled from 'styled-components';
import { AlertPreviousPoll } from "../alert-types";
import Modal from 'react-modal';
import { createAlertHumor, updateAlertHumor } from "../../../../actions/alert/alert-humor";
import colors from '../../../../styles/colors';
import AlertPreviousPollForm from "../AlertPreviousPollForm";
import { createAlertPreviousSurvey, updateAlertPreviousSurvey } from "../../../../actions/alert/alert-previous-survey";

const ImageStyle = styled.div`
  img {
    width: 100%;
  }
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertPreviousPoll: AlertPreviousPoll;
  contentType: string;
  buttonTitle: string;
  createAlertPreviousPoll: (alertPreviousPoll: AlertPreviousPoll) => void;
  updateAlertPreviousPoll: (alertPreviousPoll: AlertPreviousPoll) => void;
  createAlertPreviousSurvey: (alertPreviousPoll: AlertPreviousPoll) => void;
  updateAlertPreviousSurvey: (alertPreviousPoll: AlertPreviousPoll) => void;
}

const AlertPreviousPollComponent: React.FC<Props> = ({ alertItemId, alertPreviousPoll, contentType, buttonTitle, createAlertPreviousPoll, updateAlertPreviousPoll, createAlertPreviousSurvey, updateAlertPreviousSurvey }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertPreviousPoll, setNewAlertPreviousPoll] = useState<AlertPreviousPoll>({ id: 0, image: '', link: '', alertItemId: 0, attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertPreviousPoll: AlertPreviousPoll) => {
    setNewAlertPreviousPoll(alertPreviousPoll);
    setModalIsOpen(true);
  }

  const handleSave = (alertPreviousPoll: AlertPreviousPoll) => {
    if (contentType === 'poll') {
      createAlertPreviousPoll(alertPreviousPoll);
    }
    if (contentType === 'survey') {
      createAlertPreviousSurvey(alertPreviousPoll);
    }
    closeModal();
  }

  const handleUpdate = (alertPreviousPoll: AlertPreviousPoll) => {
    if (contentType === 'poll') {
      updateAlertPreviousPoll(alertPreviousPoll);
    }
    if (contentType === 'survey') {
      updateAlertPreviousSurvey(alertPreviousPoll);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertPreviousPoll && Object.keys(alertPreviousPoll).length > 0) ?
          <>
            {
              contentType === 'poll' &&
              <ImageStyle>
                <a href="https://today.yougov.com/about"><img src={`https://${alertPreviousPoll.image}`} alt="" /></a>
              </ImageStyle>
            }
            {
              contentType === 'survey' &&
              <ImageStyle>
                <img src={`https://${alertPreviousPoll.image}`} alt="" />
              </ImageStyle>
            }
            <ButtonContainer>
              <EditButton onClick={() => handleEdit(alertPreviousPoll)}>Edit</EditButton>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertPreviousPollForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertPreviousPoll={newAlertPreviousPoll}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertPreviousPoll: state.alertPreviousPoll.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertPreviousPoll: (alertPreviousPoll: AlertPreviousPoll) => dispatch(createAlertPreviousPoll(alertPreviousPoll)),
  updateAlertPreviousPoll: (alertPreviousPoll: AlertPreviousPoll) => dispatch(updateAlertPreviousPoll(alertPreviousPoll)),
  createAlertPreviousSurvey: (alertPreviousPoll: AlertPreviousPoll) => dispatch(createAlertPreviousSurvey(alertPreviousPoll)),
  updateAlertPreviousSurvey: (alertPreviousPoll: AlertPreviousPoll) => dispatch(updateAlertPreviousSurvey(alertPreviousPoll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertPreviousPollComponent);