import axiosInstance from "../../utility/axios";

export const startGetAlertSolution = () => ({
    type: 'START_GET_ALERT_SOLUTION',
  });
  
  export const getAlertSolutionSuccess = (alertSolution) => ({
    type: 'GET_ALERT_SOLUTION_SUCCESS',
    alertSolution
  });
  
  export const getAlertSolution = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-solution/${alertItemId}`;
      console.log('get alertSolution url: ', url);
      dispatch(startGetAlertSolution());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertSolution: ', response.data);
          dispatch(getAlertSolutionSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertSolutionCreate = () => ({
    type: 'START_ALERT_SOLUTION_CREATE',
});

export const createAlertSolutionSuccess = (alertSolution) => ({
    type: 'CREATE_ALERT_SOLUTION_SUCCESS',
    alertSolution
});

export const createAlertSolution = (alertSolution) => {
    console.log('do alert solution: ', alertSolution);
    return (dispatch) => {
        dispatch(startAlertSolutionCreate());
        axiosInstance.post(`alert-solution`, alertSolution)
            .then((response) => {
                console.log('AlerttSolution Create: ', response.data);
                dispatch(createAlertSolutionSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertSolutionUpdate = () => ({
    type: 'START_ALERT_SOLUTION_UPDATE',
  });
  
  export const updateAlertSolutionSuccess = (alertSolution) => ({
    type: 'UPDATE_ALERT_SOLUTION_SUCCESS',
    alertSolution
  });
  
  export const updateAlertSolution = (alertSolution) => {
    console.log('alertSolution: ', alertSolution);
    return (dispatch) => {
      dispatch(startAlertSolutionUpdate());
      axiosInstance.put(`alert-solution/${alertSolution.id}`, alertSolution)
        .then((response) => {
          dispatch(updateAlertSolutionSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});