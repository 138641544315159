import axiosInstance from "../../utility/axios";

export const startGetAlertPoll = () => ({
    type: 'START_GET_ALERT_POLL',
  });
  
  export const getAlertPollSuccess = (alertPoll) => ({
    type: 'GET_ALERT_POLL_SUCCESS',
    alertPoll
  });
  
  export const getAlertPoll = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-poll/${alertItemId}`;
      console.log('get alertPoll url: ', url);
      dispatch(startGetAlertPoll());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertPoll: ', response.data);
          dispatch(getAlertPollSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertPollCreate = () => ({
    type: 'START_ALERT_POLL_CREATE',
});

export const createAlertPollSuccess = (alertPoll) => ({
    type: 'CREATE_ALERT_POLL_SUCCESS',
    alertPoll
});

export const createAlertPoll = (alertPoll) => {
    console.log('do alert poll: ', alertPoll);
    return (dispatch) => {
        dispatch(startAlertPollCreate());
        axiosInstance.post(`alert-poll`, alertPoll)
            .then((response) => {
                console.log('AlerttPoll Create: ', response.data);
                dispatch(createAlertPollSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertPollUpdate = () => ({
    type: 'START_ALERT_POLL_UPDATE',
  });
  
  export const updateAlertPollSuccess = (alertPoll) => ({
    type: 'UPDATE_ALERT_POLL_SUCCESS',
    alertPoll
  });
  
  export const updateAlertPoll = (alertPoll) => {
    console.log('alertPoll: ', alertPoll);
    return (dispatch) => {
      dispatch(startAlertPollUpdate());
      axiosInstance.put(`alert-poll/${alertPoll.id}`, alertPoll)
        .then((response) => {
          dispatch(updateAlertPollSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});