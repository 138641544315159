import React, { Component } from 'react';
import {connect} from "react-redux";
import {history} from "../../../routers/AppRouter";
import {CATEGORY_NAME, POST_TYPE_NAME, POST_CATEGORY_NAME, XCHANGE_CATEGORY_NAME} from "../../../utility/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeZoneSelect from '../../../components/TimeZoneSelect';
import Dropzone from 'react-dropzone';
import {extractImageFileExtensionFromBase64} from '../../../helpers/ReusableUtils';
import {ToastContainer, toast} from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MainButton from '../../../components/MainButton';

import BoldIcon from '../../../images/icon-bold.png';
import BoldIcon2x from '../../../images/icon-bold@2x.png';
import ItalicIcon from '../../../images/icon-italic.png';
import ItalicIcon2x from '../../../images/icon-italic@2x.png';

import ArrowIcon from '../../../images/icon-arrow.svg';

// redux
import {saveSingleFile} from '../../../actions/upload';
import {slugify} from '../../../utility/string-utils';
import {createAd, getAd, updateAd} from '../../../actions/ads';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContent = styled.div`
  // min-width: 600px;
  width: 100%;
`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  padding: 0 15px;
  margin: 40px 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 0 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const StyledCheckbox = styled.input`
  margin: 20px 0;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  font-size: 16px;
  padding: 10px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue}
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 100px;
  max-width: 150px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue}
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 290px;
  border: 1px solid ${colors.grayBorder};
`;

const ModalPreviewTitle = styled.div`
  position: absolute;
  background: ${colors.darkGray};
  color: ${colors.white};
  left: 0;
  text-align: center;
  top: -56px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  z-index: 100;

  @media (min-width: 720px) {
    width: ${props => props.modalWidth ? props.modalWidth : '442px'};
    min-height: 495px;
  }
`;

const ModalHeading = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${colors.lightGray}
  color: ${colors.white};
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

const ModalFeaturedImage = styled.div`
  padding: 20px 0;
  text-align: center;
  img {
    max-width: 300px;
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  color: ${colors.black};
  font-weight: bold;
  padding-top: 15px;
`;

const ModalDetails = styled.div`
  padding-top: 10px;
  padding: 0;
  opacity: 0.8;
  font-size: 15px;
  color: ${colors.black};
  // p {
  //   padding: 0;
  //   line-height: 15px;
  // }
`;

const ModalBody = styled.div`
  padding: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

const ModalDocuments = styled.div`
  display: flex;
  padding: 15px 0;
  h2 {
    font-weight: bold;
    font-size: 15px;
    color: ${colors.darkGray};
    padding: 0 15px 0 0;
    margin: 0;
  }
  h3 {
    font-size: 13px;
    color: ${colors.darkGray};
    padding: 5px 0;
    margin: 0;
  }
  img {
    margin: 0 5px;
    border: 1px solid ${colors.lightGray};
    max-height: 100px;
    max-width: 150px;
    object-fit: cover;
  }
`;

const ModalArrowRight = styled.button`
  position: absolute;
  background: none;
  border: none;
  right: -50px;
  top: 42%;
  cursor: pointer;
  outline: none;
`;

const ModalArrowLeft = styled.button`
  position: absolute;
  background: none;
  border: none;
  left: -50px;
  top: 42%;
  cursor: pointer;
  transform: rotate(180deg);
  outline: none;
`;
const ValidationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class Ad extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalPosition: 0,
      modalWidth: '442px',
      name: '',
      sponsor: '',
      displayTitle: true,
      date: new Date(),
      showCalendar: false,
      applicationType: '',
      imageLink: '',
      companyLogoLink: '',
      editingAdId: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      placement: '',
      link: '',
      trackingCode: '',
      imageSizeError: false,
      default: false,
    }
  }

  componentDidMount() {
    const adId = this.props.match.params.id;
    if (adId) {
      this.props.getAd(adId);
      this.setState(() => ({
        editingAdId: parseInt(adId),
      }));
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.ad !== prevProps.ad) {

      this.setState(() => ({
        date: new Date(this.props.ad.date),
        name: this.props.ad.name,
        sponsor: this.props.ad.sponsor,
        imageLink: this.props.ad.imageLink,
        companyLogoLink: this.props.ad.companyLogoLink,
        applicationType: this.props.ad.applicationType,
        placement: String(this.props.ad.placement),
        link: this.props.ad.link,
        trackingCode: this.props.ad.trackingCode,
        default: this.props.ad.default
      }));
    }

  }

  setStartDate = (date) => {
    this.setState(() => ({date}));
  };

  openCalendar = (open) => {
    this.setState(() => ({
      showCalendar: open
    }));
  };

  clearState = () => {
    this.setState(() => ({
      name: '',
      sponsor: '',
      date: new Date(),
      showCalendar: false,
      displayImageAbove: true,
      companyLogoLink: null,
      imageLink: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      placement: '',
      link: '',
      trackingCode: '',
      default: false
    }), history.push('/ad-manager'));
  };

  cancelCalendar = () => {
    console.log(this.state.date);
    this.setState(() => ({
      showCalendar: false,
      date: null,
    }));
  };

  handleNameChange = (name) => {
    this.setState(() => ({name}));
  };

  handleSponsorNameChange = (sponsor) => {
    this.setState(() => ({sponsor}));
  };

  handleLinkChange = (link) => {
    this.setState(() => ({link}));
  };

  handleTrackingCodeChange = (trackingCode) => {
    this.setState(() => ({trackingCode}));
  };

  handlePlacementChange = (placement) => {
    this.setState(() => ({placement}));
  };

  handleDisplayImageChange = (displayAbove) => {
    this.setState(() => ({
      displayImageAbove: displayAbove
    }));
  };

  handleFeatureImageLink = (link) => {
    this.setState(() => ({
      companyLogoLink: link
    }));
  };

  handleApplicationTypeChange = (applicationType) => {
    this.setState(() => ({
      applicationType: applicationType
    }));
  };

  handleDefaultChange = () => {
    this.setState(() => ({default : !this.state.default}));
  };

  // Modal

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleModelChange = (position) => {
    const initialModalPosition = this.state.modalPosition;
    if (position > 0 && initialModalPosition !== 3) {
      this.setModalSize(initialModalPosition + 1);
    } else if (position < 0 && initialModalPosition !== 0) {
      this.setModalSize(initialModalPosition - 1);
    }
  };

  setModalSize = (modalPosition) => {
    if (modalPosition === 0) {
      this.setState(() => ({
        modalWidth: '442px',
        modalPosition: modalPosition
      }));
    } else {
      this.setState(() => ({
        modalWidth: '1200px',
        modalPosition: modalPosition
      }));
    }
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.isValidImageSize(myResult, 480, 395);
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  isValidImageSize = (fileDetails, width, height) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function() {
      if (this.width >= width && this.height >= height && this.width <= 490 && this.height <= 405) {
        globalThis.setSingleImageError(false);
      } else {
        globalThis.setSingleImageError(true);
      }
    };
  };

  setSingleImageError = (showError) => {
    this.setState({
      imageSizeError: showError
    });
  };

  imageCheck() {
    if (this.state.imgSrc !== null) {
      return <ImagePreview src={this.state.imgSrc} />
    } else if (this.state.imageLink) {
      return <ImagePreview src={`https://${this.props.ad.imageLink}`} />
    } else {
      return (
        <ImagePlaceholderStyled>No Image</ImagePlaceholderStyled>
      )
    }
  }

  finalizePost = (type, cloudfrontUrl) => {

    let adDetails = {
      name: this.state.name,
      sponsor: this.state.sponsor,
      status: type,
      date: this.state.date ? this.state.date : new Date(),
      displayImageAbove: this.state.displayImageAbove,
      displayTitle: this.state.displayTitle,
      imageLink: cloudfrontUrl || this.state.imageLink || null,
      companyLogoLink: this.state.companyLogoLink || null,
      permalink: slugify(this.state.name) || null,
      applicationType: this.state.applicationType,
      placement: this.state.placement,
      link: this.state.link,
      trackingCode: this.state.trackingCode,
      default: this.state.default
    };

    const adId = this.state.editingAdId;
    if (adId) {
      adDetails.id = adId;
      this.props.updateAd(adDetails, adId);
    } else {
      this.props.createAd(adDetails);
    }

    this.clearState();

  };

  publishPost = (type) => {

    if (this.state.name && this.state.sponsor && !this.state.imageSizeError && this.state.applicationType && this.state.placement && this.state.link && (this.state.imageLink || this.state.uploadedFile)) {

      if (type !== 'preview') {

        // Just a feature image was uploaded
        if (this.state.uploadedFile) {
          this.props.saveSingleFile(this.state.uploadedFile, null, 'ad-manager')
            .then((cloudfrontUrl) => {
              // add the cloudfront url to the post
              this.finalizePost(type, cloudfrontUrl);
            });
        }

        // no images or additional documents uploaded
        if (!this.state.uploadedFile) {
          this.finalizePost(type);
        }

      } else {
        // show post preview
      }

    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Ad Settings</StyledHeading>
          <RightSideHeading>
            <ButtonMargin><MainButton title="Preview Ad" onClick={() => this.openModal()}/></ButtonMargin>
            <ButtonMargin><MainButton title="Save as Draft" onClick={() => this.publishPost('draft')}/></ButtonMargin>
            <div>
              <ButtonMargin><MainButton title="Schedule an Ad" onClick={() => this.openCalendar(!this.state.showCalendar)} /></ButtonMargin>
              <SchedulePopup showCalendar={this.state.showCalendar}>
                <DatePicker
                  inline
                  selected={this.state.date}
                  onChange={date => this.setStartDate(date)}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <MainButton title="Schedule Now" onClick={() => this.publishPost('scheduled')}/>
                <MainButton title="Cancel" onClick={() => this.cancelCalendar()}/>
              </SchedulePopup>
            </div>
            <MainButton title="Publish" dark={true} onClick={() => this.publishPost('published')}/>
          </RightSideHeading>
        </HeadingContainer>
        <MainContentContainer>
          <MainContent>
            <StyledTitle>Ad Name*</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.name}
              onChange={(e) => this.handleNameChange(e.target.value)}
              placeholder="Name"
            />
            <StyledTitle>Sponsor*</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.sponsor}
              onChange={(e) => this.handleSponsorNameChange(e.target.value)}
              placeholder="Sponsor Name"
            />
            <FeaturedImageSection>
              <PreviewSection>
                <div>
                  <StyledTitle>Ad Image*</StyledTitle>
                  <StyledImageSection>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MainButton title='Select' />
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </StyledImageSection>
                </div>
                <ValidationContainer>
                  {this.imageCheck()}
                  {
                    this.state.imageSizeError &&
                    <ValidationError>Invalid Image Size</ValidationError>
                  }
                </ValidationContainer>
              </PreviewSection>
            </FeaturedImageSection>

            <StyledTitle>Placement*</StyledTitle>
            <StyledSelect
              onChange={(e) => this.handlePlacementChange(e.target.value)}
              value={this.state.placement}
            >
              <option value=''>Select</option>
              <option value="1">Atheneum Spot 1</option>
              <option value="2">Atheneum Spot 2</option>
              {/* <option value="3">Marketplace Spot 1</option>
              <option value="4">Marketplace Spot 2</option> */}
              <option value="0">Default Ad Spots</option>
            </StyledSelect>

            <StyledTitle>Link/URL*</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.link}
              onChange={(e) => this.handleLinkChange(e.target.value)}
            />

            <StyledTitle>Tracking Code</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.trackingCode}
              onChange={(e) => this.handleTrackingCodeChange(e.target.value)}
            />

            {/*<label>*/}
              {/*<StyledCheckbox*/}
                {/*key="default"*/}
                {/*type="checkbox"*/}
                {/*value={this.state.default}*/}
                {/*checked={this.state.default}*/}
                {/*onChange={() => this.handleDefaultChange()}*/}
              {/*/>*/}
              {/*&nbsp; Display as default Ad*/}
            {/*</label>*/}

          </MainContent>
          <StyledSidebar>
            <SidebarSection>
              <StyledTitle>Application Type*</StyledTitle>
              <SidebarSectionBackground>
                <RadioSection>
                  {/* <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="upstream"
                      checked={this.state.applicationType === 'upstream'}
                      onChange={() => this.handleApplicationTypeChange('upstream')}
                    />
                    Upstream
                  </label> */}
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="sponsored"
                      checked={this.state.applicationType === 'sponsored'}
                      onChange={() => this.handleApplicationTypeChange('sponsored')}
                    />
                    Sponsored
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="default"
                      checked={this.state.applicationType === 'default'}
                      onChange={() => this.handleApplicationTypeChange('default')}
                    />
                    Default
                  </label>
                </RadioSection>
              </SidebarSectionBackground>
            </SidebarSection>
          </StyledSidebar>
        </MainContentContainer>
        <ModalStyled
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.closeModal()}
          contentLabel="Sort"
          overlayClassName="PreviewOverlay"
          modalWidth={this.state.modalWidth}
        >
          <ModalBody>
            <ModalPreviewTitle>Ad Link</ModalPreviewTitle>
            <ModalHeading>Listing</ModalHeading>
            {
              this.state.imageLink &&
              <ModalFeaturedImage>
                <a href={this.state.companyLogoLink}>
                  <img src={`https://${this.state.imageLink}`} alt="" />
                </a>
              </ModalFeaturedImage>
            }
            <ModalTitle>{this.state.name}</ModalTitle>
          </ModalBody>
        </ModalStyled>
        <ToastContainer />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ad: state.ads.singleAd,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAd: (id) => dispatch(getAd(id)),
  createAd: (adDetails) => dispatch(createAd(adDetails)),
  updateAd: (adDetails, adId) => dispatch(updateAd(adDetails, adId)),
  // getCategoriesByPostIds: (postTypeIds) => dispatch(getCategoriesByPostIds(postTypeIds)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
