import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SignUp.css';
import { startCreateAccount } from "../actions/sign-up";
import Notification from "../components/Notification";

import ABButton from "../components/ABButton";

class SignUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => {
      return {
        email: email
      }
    });
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => {
      return {
        password: password
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.email === '' || this.state.password === '') {
      this.setState(() => {
        console.log('validation error');
        return {
          error: 'Please fill out all fields'
        }
      });
    } else {
      let userInfo = {
        email: this.state.email,
        password: this.state.password
      };
      this.createAccount(userInfo);
    }
  };

  createAccount = (userInfo) => {
    this.props.startCreateAccount(userInfo);
    this.setState(() => {
      return {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      }
    });
  };

  render() {
    return (
      <div className="page-wrapper">
        <div className="container page-padding">
          <div className="row">
            <div className="col-md-12">
              {
                this.props.errorMessage && this.props.errorMessage === 'Network Error' ? (
                  <Notification className="error" message={this.props.errorMessage } />
                ) : this.props.errorMessage && (
                  <Notification className="error" message="User already exists" />
                )
              }
              {
                this.state.error &&
                <Notification className="error" message={this.state.error} />
              }
              <div className="sign-up">
                <form onSubmit={this.onSubmit} className="round-form">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="johndoe@gmail.com"
                      className="form-control"
                      value={this.state.email}
                      onChange={this.onEmailChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Create A Password</label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Password"
                      className="form-control"
                      value={this.state.password}
                      onChange={this.onPasswordChange}
                    />
                  </div>
                  {
                    !this.props.loading &&
                    <div>
                      <div className="center-align round-form-button">
                        <ABButton title="Create Your Account" />
                      </div>
                    </div>
                  }
                  {
                    this.props.loading &&
                    <div className="loading-text">Loading...</div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    step: state.signUp.step,
    loading: state.signUp.loading,
    error: state.signUp.error,
    errorMessage: state.signUp.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => ({
  startCreateAccount: (userInfo) => dispatch(startCreateAccount(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);