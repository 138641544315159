import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import { history } from '../../../routers/AppRouter';
import { createAlertItem, updateAlertItem } from '../../../actions/alert/alert-item.js';
import ABInputField from '../../../components/ABInputField';
import DatePicker from 'react-datepicker';
import { AlertItem } from './alert-types.js';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DatePickerStyle = styled.div`
  input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
  }
`;

interface AlertItemProps {
    createAlertItem: (alertItem: AlertItem) => any;
}

const CreateAlert: React.FC<AlertItemProps> = (props) => {

    const [issue, setIssue] = useState(0);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState<Date | null>(null);
    const [link, setLink] = useState('');

    const onCreate = () => {
        if (issue && title) {
            const alertItem: AlertItem = {
                issue,
                title,
                date,
                status: 'draft'
            }

            props.createAlertItem(alertItem);
        }
    };

    const handleIssueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const issue = parseInt(e.currentTarget.value, 10);
        if (!issue) {
            setIssue(0);
        } else {
            setIssue(issue);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const title = e.currentTarget.value;
        setTitle(title);
    };

    const handleDateChange = (date: Date | null) => {
        date!.setHours(1);
        date!.setMinutes(0);
        date!.setSeconds(0);
        setDate(date);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>Create Alert</PageTitle>
                </PageHeading>
                <FormSubHeading>Issues ID:</FormSubHeading>
                <ABInputField
                    value={issue || ''}
                    onChange={handleIssueChange}
                    placeholder="Issue ID"
                />
                <FormSubHeading>Title:</FormSubHeading>
                <ABInputField
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Title"
                />
                <FormSubHeading>Date:</FormSubHeading>
                <DatePickerStyle>
                    <DatePicker
                        selected={date}
                        onChange={handleDateChange}
                        dateFormat="MMMM d, yyyy"
                    />
                </DatePickerStyle>
                <ActionButtons>
                    <RightButton>
                        <ABButton title="Create Alert" onClick={() => onCreate()} />
                    </RightButton>
                </ActionButtons>
            </PageWrapper>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.accountInfo.loading,
        error: state.accountInfo.error,
        errorMessage: state.accountInfo.errorMessage,
        microsite: state.microsite.data,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    createAlertItem: (alertItem: AlertItem) => dispatch(createAlertItem(alertItem)),
    updateAlertItem: (alertItem: AlertItem) => dispatch(updateAlertItem(alertItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAlert);
