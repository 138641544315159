const uploadDefaultState = {
  data: [],
  folders: [],
  loading: false,
  uploadLoading: false,
  error: false,
  uploadError: false,
  getFilesSuccess: false,
  getFoldersSuccess: false,
  uploadSuccess: false,
  multipleFilesSuccess: false,
  successMessage: '',
  errorMessage: null,
};

const uploadMediaLibraryReducer = (state = uploadDefaultState, action) => {
  switch(action.type) {
    case 'START_MULTIPLE_MEDIA_IMAGE_UPLOAD':
      return {
        ...state,
        uploadLoading: true,
        uploadError: false,
        errorMessage: null,
        multipleFilesSuccess: false,
      };
    case 'UPLOAD_MULTIPLE_MEDIA_IMAGE_SUCCESS':
      return {
        ...state,
        multipleFilesSuccess: true,
        uploadLoading: false,
        uploadError: false,
        errorMessage: null,
        uploadSuccess: true,
      };
    case 'SHOW_UPLOAD_ERROR':
      return {
        ...state,
        uploadLoading: false,
        uploadError: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default uploadMediaLibraryReducer;