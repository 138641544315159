import React, { Component } from 'react';
import Header  from './Header';
import Navigation from "./Navigation";
import HeaderLogo from "./HeaderLogo";
import Footer from './Footer';
import {withRouter} from 'react-router-dom';
import styled from "styled-components";
import Sidebar from './pages/admin/SideBar';

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const ContentWrap = styled.div`
  max-width: 1285px;
  margin: 0 auto;
  padding: 0 20px;
  @media (min-width: 1360px) {
    padding: 0;
  }
  scroll-x: auto;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
`;

class MainLayout extends Component {

  constructor(props) {
    super(props);

    console.log('props in main layout: ', props);

    this.state = {
      showHeader: true,
      showLogoHeader: true,
      showNavigation: true,
      showFooter: true,
      showSidebar: false
    };

  }

  componentDidMount() {
    this.headerCheck();
    this.navigationCheck();
    this.headerLogoCheck();
    this.cmsCheck();
    this.footerCheck();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.headerCheck();
      this.navigationCheck();
      this.headerLogoCheck();
    }
  }

  headerLogoCheck() {
    if (this.props.location.pathname === '/' || this.props.location.pathname === '/sign-in' || this.props.location.pathname === '/home' || this.props.location.pathname === '/admin') {
      this.setState(() => ({showLogoHeader: false}));
    } else {
      this.setState(() => ({showLogoHeader: true}));
    }
  }

  headerCheck() {
    if (this.props.location.pathname === '/' || this.props.location.pathname === '/sign-in' || this.props.location.pathname === '/homes') {
      this.setState(() => ({showHeader: false}));
    } else {
      this.setState(() => ({showHeader: true}));
    }
  }

  navigationCheck() {
    if (this.props.location.pathname === '/home' || this.props.location.pathname === '/microsite-submission' || this.props.location.pathname === '/sign-in' || this.props.location.pathname === '/admin' || this.props.location.pathname === '/posts') {
      this.setState(() => ({showNavigation: false}));
    } else {
      this.setState(() => ({showNavigation: true}));
    }
  }

  cmsCheck() {
    if (this.props.location.pathname === '/admin') {
      this.setState(() => ({showSidebar: true}));
    } else {
      this.setState(() => ({showSidebar: false}));
    }
  }

  footerCheck() {
    if (this.props.location.pathname === '/home') {
      this.setState(() => ({showFooter: true}));
    } else {
      this.setState(() => ({showFooter: false}));
    }
  }

  render() {
    return(
      <PageContainer>
        <Container>
          {
            this.props.isAuthed &&
            <Sidebar />
          }
          <ContentWrap>
            <div>
              { this.props.children }
            </div>
          </ContentWrap>
        </Container>
        {
          this.state.showFooter &&
          <Footer pageName={this.props.location.pathname} />
        }
      </PageContainer>
    );
  }
}

export default withRouter(MainLayout);