import React from 'react';
import styled from "styled-components";
import colors from "../styles/colors";

const StyledButton = styled.button`
  background: ${colors.gray200};
  color: ${colors.white};
  padding: 12px 15px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  :hover {
    background: ${colors.black};
  }
  :disabled {
    background: ${colors.gray200};
    :hover {
      background: ${colors.gray200};
    }
  }
`;

const buttonState = (props) => {
  if (props.submitting) {
    return (
      <StyledButton onClick={props.onClick} disabled={true}>
        Submitting...
      </StyledButton>
    )
  } else {
    return (
      <StyledButton onClick={props.onClick} disabled={props.disabled}>
        {props.title}
      </StyledButton>
    )
  }
};

const ABFormButton = (props) =>  {
  return (
    <div>
      {buttonState(props)}
    </div>
  );
};

export default ABFormButton;
