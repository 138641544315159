import React, { Component } from 'react';
import styled from "styled-components";
import colors from "../styles/colors";
import {connect} from 'react-redux';
import {getPosts} from '../actions/posts';
import Modal from 'react-modal';

import IconSearch from '../images/icon-search.png';
import IconSearch2x from '../images/icon-search@2x.png';

const SearchContainer = styled.div`
  // text-align: center;
`;

const StyledInput = styled.input`
  width: 600px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid ${colors.grayBorder};
  background: no-repeat url(${IconSearch}) 98% 50%;
`;

const SearchContent = styled.div`
  display: ${props => props.showSearchResults === true ? 'block' : 'none'};
  padding: 20px 0;
`;

const Container = styled.div`
  display: flex;
  padding: 40px 0 0 0;
  text-align: left;
  justify-content: space-between;
`;

const ImageSection = styled.div`
  height: 100px;
  width: 140px;
  padding-right: 20px;
`;

const PreviewImage = styled.div`
  height: 100px;
  width: 140px;
  text-align: center;
  img {
    max-height: 100px;
    max-width: 140px;
  } 
`;

const Title = styled.div`
  font-size: 20px;
  color: ${colors.black};
  font-weight: bold;
  cursor: pointer;
  line-height: 26px;
  :hover {
    color: ${colors.blue}
  }
`;

const Body = styled.div`
  padding: 10px 0 0 0;
  opacity: 0.8;
  font-size: 16px;
  color: ${colors.black};
  p {
    padding: 0;
    margin: 0;
  }
`;

const NoResults = styled.div`
  color: ${colors.gray400};
  font-size: 17px;
  padding: 20px 0;
`;


const StyledItem = styled.div`
  cursor: pointer;
  padding: 10px 0;
`;

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps);
    // Typical usage (don't forget to compare props):
  }

  render() {
    return (
      <SearchContainer>
        <StyledInput type="text" onChange={this.props.onChange} value={this.props.micrositeTitle || ''} />
        <SearchContent showSearchResults={this.props.showSearchResults}>
          {
            (this.props.postsFiltered && this.props.postsFiltered.length > 0) &&
            this.props.postsFiltered.map((item) => {
              return (
                <StyledItem onClick={() => this.props.onSearchClick(item)} key={item.id}>{item.pageTitle}</StyledItem>
              )
            })
          }
          {
            this.props.postsFiltered.length === 0 &&
            <NoResults>No Results</NoResults>
          }
        </SearchContent>
      </SearchContainer>
    );
  };

}

const mapStateToProps = (state) => {
  return {
    postsFiltered: state.postsFiltered.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getPosts: (keyword) => dispatch(getPosts(keyword))
});

export default SearchBar;
