const contactFormsDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const contactFormsReducer = (state = contactFormsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_CONTACT_FORMS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_CONTACT_FORMS_SUCCESS':
      return {
        ...state,
        data: action.contactForms,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default contactFormsReducer;