import axiosInstance from '../utility/axios';
import {signOut} from "./sign-in";

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrositeSetting = () => ({
  type: 'START_GET_MICROSITE_SETTING',
});

export const getMicrositeSettingSuccess = (micrositeSetting) => ({
  type: 'GET_MICROSITE_SETTING_SUCCESS',
  micrositeSetting
});

export const getMicrositeSetting = (micrositeId) => {
  return (dispatch) => {
    dispatch(startGetMicrositeSetting());
    axiosInstance.get(`microsite-setting/${micrositeId}`)
      .then((response) => {
        dispatch(getMicrositeSettingSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreateMicrositeSetting = () => ({
  type: 'START_CREATE_MICROSITE_SETTING'
});

export const createMicrositeSettingSuccess = (micrositeSetting) => ({
  type: 'CREATE_MICROSITE_SETTING_SUCCESS',
  micrositeSetting
});

export const createMicrositeSetting = (micrositeSetting, micrositeId) => {
  console.log('creating microsite setting', micrositeSetting);
  return (dispatch) => {
    dispatch(startCreateMicrositeSetting());
    axiosInstance.post(`microsite-setting/${micrositeId}`, micrositeSetting)
      .then((response) => {
        dispatch(createMicrositeSettingSuccess(response.data));
        console.log('microsite setting created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startMicrositeSettingUpdate = () => ({
  type: 'START_MICROSITE_SETTING_UPDATE',
});

export const updateMicrositeSettingdSuccess = (micrositeSetting) => ({
  type: 'MICROSITE_SETTING_UPDATE_SUCCESS',
  micrositeSetting
});

export const updateMicrositeSetting = (micrositeSetting, micrositeId) => {
  return (dispatch) => {
    dispatch(startMicrositeSettingUpdate());
    console.log('micrositeSetting: ', micrositeSetting);
    console.log('micrositeId: ', micrositeId);
    axiosInstance.put(`microsite-setting/${micrositeId}`, micrositeSetting)
      .then((response) => {
        dispatch(updateMicrositeSettingdSuccess(micrositeSetting));
      })
      .catch((error) => {
        dispatch(showError('Error Updating Microsite Setting'));
        console.log(error);
      });
  }
};