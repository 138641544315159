import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormInputSmall} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import {history} from '../../../routers/AppRouter';
import {createMicrosite, getMicrosite, updateMicrosite} from '../../../actions/microsite';
import {updateMicrositeSubmission} from '../../../actions/microsite-submission';
import {setLocation} from '../../../actions/location';
import {getMicrositeSetting, updateMicrositeSetting} from '../../../actions/microsite-settings';
import {getMicrositeCategories} from '../../../actions/microsite-categories';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.form`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

class BeConnected extends Component {

  constructor(props) {
    super(props);

    this.state = {
      facebookLink: '',
      twitterLink: '',
      instagramLink: '',
      linkedInLink: '',
      websiteLink: '',
      showSection: false,
      categoryOptions: [], // for the multi select
      selectedOptions: [], // for the multi select
      recName1: '',
      recEmail1: '',
      recName2: '',
      recEmail2: '',
      recName3: '',
      recEmail3: '',
      recName1Error: false,
      recEmail1Error: false,
    };

  }

  componentDidMount = () => {
    this.props.getMicrositeCategories();
    // the categories get loaded on the page before hopefully
    if (this.props.micrositeCategories.length > 0) {
      this.loadCategoryNamesForMultiSelect();
    }
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.micrositeCategories !== prevProps.micrositeCategories) {
      this.loadCategoryNamesForMultiSelect();
    }
    if (this.props.microsite !== prevProps.microsite) {
      this.loadFields();
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.setState({
        showSection: this.props.micrositeSetting.showContactInfoSection || false
      });
    }
  }

  loadFields = () => {
    console.log('loading fields');
    this.setState({
      facebookLink: this.props.microsite.facebookLink || '',
      twitterLink: this.props.microsite.twitterLink || '',
      instagramLink: this.props.microsite.instagramLink || '',
      linkedInLink: this.props.microsite.linkedInLink || '',
      websiteLink: this.props.microsite.websiteLink || '',
      recName1: this.props.microsite.recName1 || '',
      recEmail1: this.props.microsite.recEmail1 || '',
      recName2: this.props.microsite.recName2 || '',
      recEmail2: this.props.microsite.recEmail2 || '',
      recName3: this.props.microsite.recName3 || '',
      recEmail3: this.props.microsite.recEmail3 || '',
    });
    // if (this.props.microsite.micrositeSelectedCategories.length > 0) {
    //   this.loadSelectedCategoriesForMultiSelect(this.props.microsite.micrositeSelectedCategories);
    //   this.setState({
    //     facebookLink: this.props.microsite.facebookLink || '',
    //     twitterLink: this.props.microsite.twitterLink || '',
    //     instagramLink: this.props.microsite.instagramLink || '',
    //     linkedInLink: this.props.microsite.linkedInLink || '',
    //     websiteLink: this.props.microsite.websiteLink || '',
    //     primaryColor: this.props.microsite.primaryColor || '',
    //     secondaryColor: this.props.microsite.secondaryColor || '',
    //     applicationType: this.props.microsite.applicationType
    //   });
    // } else {
    //   this.setState({
    //     facebookLink: this.props.microsite.facebookLink || '',
    //     twitterLink: this.props.microsite.twitterLink || '',
    //     instagramLink: this.props.microsite.instagramLink || '',
    //     linkedInLink: this.props.microsite.linkedInLink || '',
    //     websiteLink: this.props.microsite.websiteLink || '',
    //     primaryColor: this.props.microsite.primaryColor || '',
    //     secondaryColor: this.props.microsite.secondaryColor || ''
    //   });
    // }
  };

  onNextClick = (close) => {
    this.props.setLocation('logo-bio');
    if (this.state.recName1 && this.state.recEmail1) {
      let micrositeSetting = {
        showContactInfoSection: this.state.showSection
      };

      let microsite = {
        facebookLink: this.state.facebookLink,
        twitterLink: this.state.twitterLink,
        instagramLink: this.state.instagramLink,
        linkedInLink: this.state.linkedInLink,
        websiteLink: this.state.websiteLink,
        recName1: this.state.recName1,
        recEmail1: this.state.recEmail1,
        recName2: this.state.recName2,
        recEmail2: this.state.recEmail2,
        recName3: this.state.recName3,
        recEmail3: this.state.recEmail3,
        userId: this.props.microsite.userId
      };

      let selectedCategories = [];
      if (this.state.selectedOptions.length > 0) {
        this.state.selectedOptions.map((item) => {
          selectedCategories.push(item.id);
        });
        microsite.micrositeSelectedCategories = selectedCategories;
      }

      console.log('selectedCategories: ', selectedCategories);
      this.props.updateMicrosite(microsite, this.props.microsite.id);

      let micrositeSubmission = {
        contactInfo: true
      };
      this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);

      this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

      if (close) {
        history.push(`../${this.props.microsite.id}`);
        return;
      }
    } else {
      this.showValidationErrors();
    }
  };

  showValidationErrors = () => {
    if (!this.state.recName1) {
      this.setState(() => ({recName1Error: true}));
    }
    if (!this.state.recEmail1) {
      this.setState(() => ({recEmail1Error: true}));
    }
  };

  onFacebookLinkChange = (e) => {
    const facebookLink = e.target.value;
    this.setState(() => ({facebookLink}));
  };

  onTwitterLinkChange = (e) => {
    const twitterLink = e.target.value;
    this.setState(() => ({twitterLink}));
  };

  onInstagramLinkChange = (e) => {
    const instagramLink = e.target.value;
    this.setState(() => ({instagramLink}));
  };

  onLinkedInLinkChange = (e) => {
    const linkedInLink = e.target.value;
    this.setState(() => ({linkedInLink}));
  };

  onWebsiteLinkChange = (e) => {
    const websiteLink = e.target.value;
    this.setState(() => ({websiteLink}));
  };

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({showSection: !showSection}));
  };

  onPreviousClick = () => {
    if (this.props.microsite.id) {
      history.push(`/microsite-submission/organization-details/${this.props.microsite.id}`);
    } else {
      history.push('organization-details');
    }
  };

  loadCategoryNamesForMultiSelect = () => {
    // format the names to work with the multi-select
    let options = [];
    this.props.micrositeCategories.map((item) => {
      options.push(
        { value: item.name, label: item.name, id: item.id }
      )
    });
    this.setState(() => ({
      categoryOptions: options
    }));
  };

  loadSelectedCategoriesForMultiSelect = (selectedCategories) => {
    // have to loop through props selected categories and add in the names and labels
    let options = [];
    selectedCategories.map((item) => {

      console.log('item: ', item);

      this.state.categoryOptions.map((categoryItem) => {
        console.log('categoryItem: ', categoryItem);
        if (item.categoryId === categoryItem.id) {
          options.push(
            { value: categoryItem.value, label: categoryItem.value, id: item.categoryId}
          )
        }
      });

      console.log('selectedOptions: ', options);

    });

    this.setState(() => ({
      selectedOptions: options
    }));
  };

  onRecName1Change = (e) => {
    const recName1 = e.target.value;
    if (recName1) {
      this.setState(() => ({recName1Error: false}));
    }
    this.setState(() => ({recName1}));
  };

  onRecEmail1Change = (e) => {
    const recEmail1 = e.target.value;
    if (recEmail1) {
      this.setState(() => ({recEmail1Error: false}));
    }
    this.setState(() => ({recEmail1}));
  };

  onRecName2Change = (e) => {
    const recName2 = e.target.value;
    this.setState(() => ({recName2}));
  };

  onRecEmail2Change = (e) => {
    const recEmail2 = e.target.value;
    this.setState(() => ({recEmail2}));
  };

  onRecName3Change = (e) => {
    const recName3 = e.target.value;
    this.setState(() => ({recName3}));
  };

  onRecEmail3Change = (e) => {
    const recEmail3 = e.target.value;
    this.setState(() => ({recEmail3}));
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Be Connected</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>
            <FormSection>
              <div>
                <div>
                  <FormSubHeading>Organization Social Media Channels:</FormSubHeading>
                  <FormItalicHeading>Please identify the organization’s social media channel links/URLs.</FormItalicHeading>
                  <FormFieldRow>
                    <FormFieldColumn>
                      <FormLabel>Facebook</FormLabel>
                      <FormInput
                        value={this.state.facebookLink}
                        onChange={this.onFacebookLinkChange}
                        type="text"
                      />
                    </FormFieldColumn>
                    <FormFieldColumn>
                      <FormLabel>Twitter</FormLabel>
                      <FormInput
                        value={this.state.twitterLink}
                        onChange={this.onTwitterLinkChange}
                        type="text"
                      />
                    </FormFieldColumn>
                  </FormFieldRow>
                </div>
                <div>
                  <FormFieldRow>
                    <FormFieldColumn>
                      <FormLabel>Instagram</FormLabel>
                      <FormInput
                        value={this.state.instagramLink}
                        onChange={this.onInstagramLinkChange}
                        type="text"
                      />
                    </FormFieldColumn>
                    <FormFieldColumn>
                      <FormLabel>LinkedIn</FormLabel>
                      <FormInput
                        value={this.state.linkedInLink}
                        onChange={this.onLinkedInLinkChange}
                        type="text"
                      />
                    </FormFieldColumn>
                  </FormFieldRow>
                </div>
              </div>

              <FormSectionPadding>
                <FormSubHeading>Organization Website URL:</FormSubHeading>
                <FormItalicHeading>Please identify the organizations website URL to be displayed on your Microsite.</FormItalicHeading>
                <FormField>
                  <FormLabel>Website/URL</FormLabel>
                  <FormInputFull
                    value={this.state.websiteLink}
                    onChange={this.onWebsiteLinkChange}
                    type="text"
                  />
                </FormField>
              </FormSectionPadding>

            </FormSection>

            <FormSection>

              <FormSubHeading>Microsite Contact Request Email Addresses:</FormSubHeading>
              <FormItalicHeading>Please identify email addresses to be notified when contact requests are
                submitted.</FormItalicHeading>
              <FormFieldRow>
                <FormFieldColumn>
                  <FormLabel>Recipient Name*</FormLabel>
                  <FormInput
                    value={this.state.recName1}
                    onChange={this.onRecName1Change}
                    type="text"
                    required={this.state.recName1Error}
                  />
                </FormFieldColumn>
                <FormFieldColumn>
                  <FormLabel>Email Address*</FormLabel>
                  <FormInput
                    value={this.state.recEmail1}
                    onChange={this.onRecEmail1Change}
                    type="text"
                    required={this.state.recEmail1Error}
                  />
                </FormFieldColumn>
              </FormFieldRow>
              <FormFieldRow>
                <FormFieldColumn>
                  <FormLabel>Recipient Name</FormLabel>
                  <FormInput
                    value={this.state.recName2}
                    onChange={this.onRecName2Change}
                    type="text"
                  />
                </FormFieldColumn>
                <FormFieldColumn>
                  <FormLabel>Email Address</FormLabel>
                  <FormInput
                    value={this.state.recEmail2}
                    onChange={this.onRecEmail2Change}
                    type="text"
                  />
                </FormFieldColumn>
              </FormFieldRow>
              <FormFieldRow>
                <FormFieldColumn>
                  <FormLabel>Recipient Name</FormLabel>
                  <FormInput
                    value={this.state.recName3}
                    onChange={this.onRecName3Change}
                    type="text"
                  />
                </FormFieldColumn>
                <FormFieldColumn>
                  <FormLabel>Email Address</FormLabel>
                  <FormInput
                    value={this.state.recEmail3}
                    onChange={this.onRecEmail3Change}
                    type="text"
                  />
                </FormFieldColumn>
              </FormFieldRow>

            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
    micrositeCategories: state.micrositeCategories.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrositeCategories: () => dispatch(getMicrositeCategories()),
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  setLocation: (page) => dispatch(setLocation(page)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeConnected);
