import React from 'react';
import {connect} from 'react-redux';
import colors from '../../../styles/colors';
import styled from 'styled-components';
import MainButton from '../../../components/MainButton';
import {EditorState} from 'draft-js';
import {FormInput} from '../../../styles/form-styles';
import {getMediaImages, removeMediaImages} from '../../../actions/media-images';
import ABSmallButton from '../../../components/ABSmallButton';
import {history} from '../../../routers/AppRouter';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const Title = styled.div`
  cursor: pointer;
  color: ${colors.blue}
  text-decoration: underline;
`;

const SearchBarPadding = styled.div`
  padding: 25px 0 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.div`
  margin-right: 25px;
  width: 300px;
`;

const TagInput = styled.div`
  margin-left: 25px;
  width: 300px;
`;

const SearchButton = styled.div`
  margin-left: 50px;
`;

const ImageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
`;

const ImagePadding = styled.div`
  padding: 20px;
  img {
    max-width: 400px;
    max-height: 400px;
    cursor: pointer;
    :hover {
      opacity: .7;
    }
  }
`;

const BlueLink = styled.div`
  cursor: pointer;
  color: ${colors.blue};
  padding-left: 20px;
`;

class MediaLibraryImageView extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      title: '',
      tag: ''
    }

  }

  componentDidMount() {
    this.props.getMediaImages();
  }

  onTitleSearchChange = (e) => {
    const title = e.target.value;
    this.setState(() => ({
      title,
    }));
  };

  onTagSearchChange = (e) => {
    const tag = e.target.value;
    this.setState(() => ({
      tag,
    }));
  };

  onSearchFilter = (e) => {

    if (e) { // if a key was selected
      if (e.key === 'Enter') {
        this.doSearch();
      } else if (e.key !== 'Enter') {
        // do nothing if any other key was selected
      }
    } else { // if the filter button was selected and no a key
      this.doSearch();
    }

  };

  doSearch = () => {
    if (this.state.title.length > 1 && this.state.tag.length > 1) {
      this.props.getMediaImages(this.state.title, this.state.tag);
    } else if (this.state.title.length > 1) {
      this.props.getMediaImages(this.state.title);
    } else if (this.state.tag.length > 1) {
      this.props.getMediaImages(null, this.state.tag);
    } else {
      this.props.getMediaImages();
    }
  }

  handleEditImage = (id) => {
    history.push(`media-image/${id}`);
  };

  showListView = () => {
    history.push('/media-library')
  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Media Library</StyledHeading>
          {/*<MainButton title="Add Image" onClick={() => createPost()} />*/}
          {/*<MainButton title="Delete Images" onClick={() => this.deleteSelectedMediaImages(selectedFlatRows)} />*/}
        </HeadingContainer>
        <SearchBarPadding>
          <TitleInput>
            <FormInput placeholder="Title" onChange={(e) => this.onTitleSearchChange(e)} onKeyDown={(e) => this.onSearchFilter(e)} />
          </TitleInput>
          <TagInput>
            <FormInput placeholder="Tag" onChange={(e) => this.onTagSearchChange(e)} onKeyDown={(e) => this.onSearchFilter(e)}/>
          </TagInput>
          <SearchButton>
            <ABSmallButton title="Filter" onClick={() => this.onSearchFilter()} />
          </SearchButton>
          <BlueLink onClick={() => this.showListView()}>Show List View</BlueLink>
          {/*<button onClick={() => this.showImageView()}>Image View</button>*/}
        </SearchBarPadding>
        <ImageContent>
          {
            this.props.mediaImages &&
            this.props.mediaImages.map((item) => {
              return <ImagePadding onClick={() => this.handleEditImage(item.id)}><img src={'https://' + item.url} alt=""/></ImagePadding>
            })
          }
        </ImageContent>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mediaImages: state.mediaImages.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaImages: (title, tag) => dispatch(getMediaImages(title, tag)),
  removeMediaImages: (idsToDelete) => dispatch(removeMediaImages(idsToDelete)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibraryImageView);
