import React, { Component } from 'react';
import {connect} from "react-redux";
import {history} from "../../../routers/AppRouter";
import {CATEGORY_NAME, POST_TYPE_NAME, POST_CATEGORY_NAME, XCHANGE_CATEGORY_NAME} from "../../../utility/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone';
import {extractImageFileExtensionFromBase64} from '../../../helpers/ReusableUtils';
import {ToastContainer, toast} from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MainButton from '../../../components/MainButton';

import BoldIcon from '../../../images/icon-bold.png';
import BoldIcon2x from '../../../images/icon-bold@2x.png';
import ItalicIcon from '../../../images/icon-italic.png';
import ItalicIcon2x from '../../../images/icon-italic@2x.png';

import ArrowIcon from '../../../images/icon-arrow.svg';

// redux
import {createPost, getPost, updatePost} from '../../../actions/post';
import {getPostTypes} from '../../../actions/post-types';
import Checkbox from '../../../components/Checkbox';
import {getCategories, getCategoriesByPostIds, startGetCategoriesByPostIds} from '../../../actions/categories';
import {getS3Url, saveMultiplePhotos, saveSingleFile} from '../../../actions/upload';
import {slugify} from '../../../utility/string-utils';
import {getMicrosites} from '../../../actions/microsites';
import SearchBar from '../../../components/SearchBar';
import ABFormButton from '../../../components/ABFormButton';
import {FormItalicHeading} from '../../../styles/form-styles';
import {getPostKeywords} from '../../../actions/post-keywords';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`

`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  padding: 15px;
  margin: 0 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 15px 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  font-size: 16px;
  padding: 10px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue};
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 100px;
  max-width: 150px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const OriginalPublishDateSection = styled.div`
  padding: 25px 0 0 0;
  input {
    width: 350px;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue};
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const ModalPreviewTitle = styled.div`
  position: absolute;
  background: ${colors.darkGray};
  color: ${colors.white};
  left: 0;
  text-align: center;
  top: -56px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  z-index: 100;

  @media (min-width: 720px) {
    width: ${props => props.modalWidth ? props.modalWidth : '442px'};
    min-height: 495px;
  }
`;

const ModalHeading = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${colors.lightGray};
  color: ${colors.white};
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

const ModalFeaturedImage = styled.div`
  padding: 20px 0;
  text-align: center;
  img {
    max-width: 300px;
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  color: ${colors.black};
  font-weight: bold;
  padding-top: 15px;
`;

const ModalDetails = styled.div`
  padding-top: 10px;
  padding: 0;
  opacity: 0.8;
  font-size: 15px;
  color: ${colors.black};
  // p {
  //   padding: 0;
  //   line-height: 15px;
  // }
`;

const ModalBody = styled.div`
  padding: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

const ModalDocuments = styled.div`
  display: flex;
  padding: 15px 0;
  h2 {
    font-weight: bold;
    font-size: 15px;
    color: ${colors.darkGray};
    padding: 0 15px 0 0;
    margin: 0;
  }
  h3 {
    font-size: 13px;
    color: ${colors.darkGray};
    padding: 5px 0;
    margin: 0;
  }
  img {
    margin: 0 5px;
    border: 1px solid ${colors.lightGray};
    max-height: 100px;
    max-width: 150px;
    object-fit: cover;
  }
`;

const ModalArrowRight = styled.button`
  position: absolute;
  background: none;
  border: none;
  right: -50px;
  top: 42%;
  cursor: pointer;
  outline: none;
`;

const ModalArrowLeft = styled.button`
  position: absolute;
  background: none;
  border: none;
  left: -50px;
  top: 42%;
  cursor: pointer;
  transform: rotate(180deg);
  outline: none;
`;

const SelectStyled = styled.select`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
  height: 45px;
  background: none;
  border: 1px solid ${colors.lightGray};
`;

const KeywordItems = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`;

const KeywordItem = styled.div`
  background: ${colors.gray100};
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 5px 5px 5px 0;
`;

const KeywordTitle = styled.div`
  font-size: 15px;
`;

const RemoveButton = styled.div`
  background: ${colors.lightestGray};
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${colors.lightGray};
  padding: 0 4px;
  margin-left: 4px;
`;

const KeywordButtonPadding = styled.div`
  padding: 0;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class Post extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalPosition: 0,
      modalWidth: '442px',
      title: '',
      boldTitle: false,
      italicTitle: false,
      displayTitle: true,
      editorState: EditorState.createEmpty(),
      date: new Date(),
      originalPublishDate: new Date(),
      showCalendar: false,
      displayImageAbove: true,
      applicationType: '',
      commercialContent: false,
      featuredImage: '',
      featuredImageLink: '',
      notes: '',
      attribution: '',
      attribution2: '',
      selectedPostTypes: [],
      formattedPostTypes: [],
      editingPostId: null,
      categories: [],
      selectedCategories: [],
      formattedAtheneumCategories: [],
      atheneumExcerpt: '',
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      multipleFiles: [],
      multipleFilesNames: [],
      multipleFilesPreview: [],
      existingMultipleFiles: [],
      removedDocuments: [],
      micrositeId: null,
      filteredMicrosites: [],
      selectedMicrosite: {},
      micrositeTitle: '',
      searching: false,
      selectedKeyword: '',
      keywords: [],
      clientPortalId: null
    }
  }

  componentDidMount() {

    const postId = this.props.postId;
    // old way before modal
    // const postId = this.props.match.params.id;

    if (postId) {
      this.props.getPost(postId);
      this.setState(() => ({
        editingPostId: parseInt(postId),
      }));
      this.props.getPostKeywords(postId);
    }
    // this.props.getPostTypes();
    this.props.getCategories();
    this.props.getMicrosites();

    // hack because some things aren't lining up in request order
    setTimeout(() => {
      console.log('keep timing out');
      this.getSelectedCategories();
    }, 1000)
  }

  componentDidUpdate(prevProps) {

    if (this.props.post !== prevProps.post) {
      // needed to pull in wysyig content
      const contentBlock = htmlToDraft(this.props.post.body);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      // we first need to find out what postTypesId's were selected
      // we can then get what categories where selected
      // if (this.props.post.selectedPostTypes.length > 0) {
      //   const postTypeIds = {
      //     postTypeIds: []
      //   };
      //   this.props.post.selectedPostTypes.map((item) => {
      //     postTypeIds.postTypeIds.push(item.postTypeId);
      //   });
      //   this.props.getCategoriesByPostIds(postTypeIds);
      // }

      // let groupDocuments = [];
      // this.props.post.documents.map((item) => {
      //   console.log('item.imageUrl: ', item.imageUrl);
      //   groupDocuments.push(item.imageUrl);
      // });

      this.setState(() => ({
        title: this.props.post.title,
        boldTitle: this.props.post.boldTitle || false,
        italicTitle: this.props.post.italicTitle || false,
        displayTitle: this.props.post.displayTitle,
        editorState: editorState,
        date: new Date(this.props.post.date),
        originalPublishDate: new Date(this.props.post.originalPublishDate),
        displayImageAbove: this.props.post.displayImageAbove,
        featuredImage: this.props.post.featuredImage,
        featuredImageLink: this.props.post.featuredImageLink,
        notes: this.props.post.notes,
        attribution: this.props.post.attribution,
        attribution2: this.props.post.attribution2,
        clientPortalId: this.props.post.clientPortalId,
        atheneumExcerpt: this.props.post.atheneumExcerpt,
        atheneumDocument: this.props.post.atheneumDocument || null,
        atheneumDocument2: this.props.post.atheneumDocument2 || null,
        atheneumDocument3: this.props.post.atheneumDocument3 || null,
        atheneumDocument4: this.props.post.atheneumDocument4 || null,
        atheneumDocument5: this.props.post.atheneumDocument5 || null,
        existingMultipleFiles: this.props.post.documents || [],
        applicationType: this.props.post.applicationType,
        commercialContent: this.props.post.commercialContent || false,
        micrositeTitle: this.props.post.micrositeLink,
        micrositeId: this.props.post.micrositeId,
      }));

    }

    if (this.props.microsites !== prevProps.microsites) {
      this.setState(() => ({
        filteredMicrosites: this.props.microsites
      }));
    }

    // if (this.props.postTypes !== prevProps.postTypes) {

    //   // we are creating a new array of postTypes adding if they are checked or not

    //   let formattedPostTypes = [];

    //   if (this.props.post.selectedPostTypes && this.state.editingPostId) { // editing a post
    //     this.props.postTypes.map((item, index) => {
    //       item.checked = this.checkPostTypeCheck(item.id);
    //       item.name = POST_TYPE_NAME[item.id];
    //       formattedPostTypes.push(item);
    //     });
    //   } else {
    //     console.log('in second on post');
    //     this.props.postTypes.map((item, index) => { // creating new post
    //       item.checked = false;
    //       item.name = POST_TYPE_NAME[item.id];
    //       formattedPostTypes.push(item);
    //     });
    //   }

    //   this.setState(() => ({
    //     formattedPostTypes: formattedPostTypes
    //   }));

    // }

    if (this.props.postKeywords !== prevProps.postKeywords) {
      if (!this.state.justSaved) {
        this.loadKeywordsFields();
      }
    }

  }

  checkCategoryCheck = (categoryId) => {
    let checked = false;
    this.props.post.selectedCategories.map((item) => {
      if (item.categoryId === categoryId) {
        checked = item.categoryId === categoryId;
      }
    });
    return checked;
  };

  getSelectedCategories = () => {
    // we are creating a new array of categories adding if they are checked or not sorting based on post type

    let formattedAtheneumCategories = [];

    if (this.props.post.selectedCategories && this.state.editingPostId) { // editing a post
      
      console.log('getSelectedCategories');
      console.log('this.props.categories:  ', this.props.categories);

      this.props.categories.map((item, index) => { // editing a post
        item.checked = this.checkCategoryCheck(item.id);
        if (CATEGORY_NAME.find(x => x.id === item.id)) {
          item.name = CATEGORY_NAME.find(x => x.id === item.id).name;
          formattedAtheneumCategories.push(item);
        }
      });

    } else { // creating a new post or selecting a new post type

      this.props.categories.map((item, index) => { // creating new categories
        item.checked = false;
        if (CATEGORY_NAME.find(x => x.id === item.id)) {
          item.name = CATEGORY_NAME.find(x => x.id === item.id).name;
          formattedAtheneumCategories.push(item);
        }
      });
    }

    console.log('formattedAtheneumCategories: ', formattedAtheneumCategories);

    this.setState(() => ({
      formattedAtheneumCategories: formattedAtheneumCategories,
    }));

  }

  loadKeywordsFields = () => {
    this.setState({
      keywords: this.props.postKeywords
    });
  };

  // checkPostTypeCheck = (postTypeId) => {
  //   let checked = false;
  //   this.props.post.selectedPostTypes.map((item) => {
  //     if (item.postTypeId === postTypeId) {
  //       checked = item.postTypeId === postTypeId;
  //     }
  //   });
  //   return checked;
  // };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  setStartDate = (date) => {
    this.setState(() => ({date}));
  };

  openCalendar = (open) => {
    this.setState(() => ({
      showCalendar: open
    }));
  };

  clearState = () => {

    this.setState(() => ({
      title: '',
      boldTitle: '',
      italicTitle: '',
      displayTitle: true,
      editorState: EditorState.createEmpty(),
      date: new Date(),
      originalPublishDate: new Date(),
      showCalendar: false,
      displayImageAbove: true,
      selectedPostTypes: [],
      selectedCategories: [],
      selectedKeyword: '',
      keywords: [],
      clientPortalId: null,
      atheneumExcerpt: '',
      featuredImageLink: null,
      featuredImage: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      multipleFiles: [],
      multipleFilesNames: [],
      existingMultipleFiles: [],
      notes: '',
      attribution: '',
      attribution2: '',
    }));

    if (this.state.editingPostId) {
      this.props.closeModal();
    }

  };

  cancelCalendar = () => {
    console.log(this.state.date);
    this.setState(() => ({
      showCalendar: false,
      date: null,
    }));
  };

  handleDisplayTitleChange = () => {
    this.setState(() => ({displayTitle : !this.state.displayTitle}));
  };

  handleTitleChange = (title) => {
    this.setState(() => ({title}));
  };

  // postTypeSelect = (checked, index) => {
  //   this.state.formattedPostTypes[index].checked = checked;
  //   this.forceUpdate();
  // };

  categorySelect = (checked, index, postType) => {
    let newFormattedAtheneumCategories = this.state.formattedAtheneumCategories;
    newFormattedAtheneumCategories[index].checked = checked;
    // this.state.formattedAtheneumCategories[index].checked = checked;
    this.setState(() => ({
      formattedAtheneumCategories : newFormattedAtheneumCategories
    }));
    // this.forceUpdate();
  };

  handleApplicationTypeChange = (applicationType) => {
    this.setState(() => ({
      applicationType: applicationType
    }));
  };

  handleCommercialContentChange = (isCommercialContent) => {
    this.setState(() => ({
      commercialContent: isCommercialContent
    }));
  };

  handleDisplayImageChange = (displayAbove) => {
    this.setState(() => ({
      displayImageAbove: displayAbove
    }));
  };

  handleFeatureImageLink = (link) => {
    this.setState(() => ({
      featuredImageLink: link
    }));
  };

  handleAtheneumExcerptChange = (value) => {
    this.setState(() => ({
      atheneumExcerpt: value
    }));
  };

  handleNotesChange = (value) => {
    this.setState(() => ({
      notes: value
    }));
  };

  handleAttributionChange = (value) => {
    this.setState(() => ({
      attribution: value
    }));
  };

  handleAttribution2Change = (value) => {
    this.setState(() => ({
      attribution2: value
    }));
  };

  handleClientPortalIdChange = (value) => {
    this.setState(() => ({
      clientPortalId: value
    }));
  };

  handleBoldSelect = () => {
    this.setState(() => ({
      boldTitle: !this.state.boldTitle
    }));
  };

  handleItalicSelect = () => {
    this.setState(() => ({
      italicTitle: !this.state.italicTitle
    }));
  };

  handleOriginalPublishDateChange = (date) => {
    this.setState(() => ({
      originalPublishDate: date
    }));
  };

  // Modal

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleModelChange = (position) => {
    const initialModalPosition = this.state.modalPosition;
    if (position > 0 && initialModalPosition !== 3) {
      this.setModalSize(initialModalPosition + 1);
    } else if (position < 0 && initialModalPosition !== 0) {
      this.setModalSize(initialModalPosition - 1);
    }
  };

  setModalSize = (modalPosition) => {
    if (modalPosition === 0) {
      this.setState(() => ({
        modalWidth: '442px',
        modalPosition: modalPosition
      }));
    } else {
      this.setState(() => ({
        modalWidth: '1200px',
        modalPosition: modalPosition
      }));
    }
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  handleMultipleOnDrop(files, rejectedFiles) {

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {

        files.map((item) => {

          const currentFile = item;
          const myFileItemReader = new FileReader();
          myFileItemReader.addEventListener("load", () => {
            // console.log(myFileItemReader.result)
            const myResult = myFileItemReader.result;
            this.setState((state) => {
              return {
                multipleFilesPreview: [...state.multipleFilesPreview, myResult], //this is the base64 code for preview
                multipleFiles: [...state.multipleFiles, item],
                multipleFilesNames: [...state.multipleFilesNames, item.name]
              }
            })
          }, false);

          myFileItemReader.readAsDataURL(currentFile)

        });

      }
    }

  }

  removeFileFromMultiple = (index) => {
    this.setState((prevState) => {
      return {
        multipleFilesPreview: prevState.multipleFilesPreview.filter((_, i) => i !== index),
        multipleFiles: prevState.multipleFiles.filter((_, i) => i !== index),
        multipleFilesNames: prevState.multipleFilesNames.filter((_, i) => i !== index)
      };
    });
  };

  removeFileFromExistingMultiple = (item, index) => {
    this.setState((prevState) => {
      return {
        removedDocuments: [...this.state.removedDocuments, item],
        existingMultipleFiles: prevState.existingMultipleFiles.filter((_, i) => i !== index),
      };
    });
  };

  imageCheck() {
    if (this.state.imgSrc !== null) {
      return <ImagePreview src={this.state.imgSrc} />
    } else if (this.state.featuredImage) {
      return <ImagePreview src={`https://${this.props.post.featuredImage}`} />
    } else {
      return (
        <ImagePlaceholderStyled>No Image</ImagePlaceholderStyled>
      )
    }
  }

  multipleImageCheck(index) {
    // return (<div>test</div>);
    if (this.state.multipleFilesPreview.length > 0) {
      return <ImagePreview src={this.state.multipleFilesPreview[index]} />
    } else {
      return (
        <div>empty</div>
      )
    }
  }

  existingMultipleImageCheck(index) {
    if (this.state.existingMultipleFiles.length > 0) {
      return <ImagePreview src={`https://${this.state.existingMultipleFiles[index].imageUrl}`} />
    } else {
      return (
        <div>empty</div>
      )
    }
  }

  finalizePost = (type, selectedCategoriesArray, cloudfrontUrl, multipleCloudFrontUrls) => {
    // console.log('multipleCloudFrontUrls: ', multipleCloudFrontUrls);
    // console.log('existingMultipleFiles: ', this.state.existingMultipleFiles);

    let postDetails = {
      title: this.state.title,
      boldTitle: this.state.boldTitle,
      italicTitle: this.state.italicTitle,
      body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      status: type,
      date: this.state.date ? this.state.date : new Date(),
      displayImageAbove: this.state.displayImageAbove,
      postTypes: [2], // hard coding atheneum post type
      categories: selectedCategoriesArray,
      displayTitle: this.state.displayTitle,
      featuredImage: cloudfrontUrl || this.state.featuredImage || null,
      featuredImageLink: this.state.featuredImageLink || null,
      atheneumExcerpt: this.state.atheneumExcerpt || null,
      permalink: slugify(this.state.title) || null,
      applicationType: this.state.applicationType,
      commercialContent: this.state.commercialContent,
      originalPublishDate: this.state.originalPublishDate,
      micrositeLink: this.state.selectedMicrosite.pageTitle || this.state.micrositeTitle,
      micrositeId: this.state.selectedMicrosite.id,
      notes: this.state.notes,
      attribution: this.state.attribution,
      attribution2: this.state.attribution2,
      clientPortalId: this.state.clientPortalId || null
    };

    console.log('postDetails: ', postDetails);

    // console.log('multipleCloudFrontUrls.length: ', multipleCloudFrontUrls);

    // if (multipleCloudFrontUrls && multipleCloudFrontUrls.length === 1) {
    //   postDetails.atheneumDocument = multipleCloudFrontUrls[0];
    // } else if (multipleCloudFrontUrls && multipleCloudFrontUrls.length === 2) {
    //   postDetails.atheneumDocument = multipleCloudFrontUrls[0];
    //   postDetails.atheneumDocument2 = multipleCloudFrontUrls[1];
    // } else if (multipleCloudFrontUrls && multipleCloudFrontUrls.length === 3) {
    //   postDetails.atheneumDocument = multipleCloudFrontUrls[0];
    //   postDetails.atheneumDocument2 = multipleCloudFrontUrls[1];
    //   postDetails.atheneumDocument3 = multipleCloudFrontUrls[2];
    // } else if (multipleCloudFrontUrls && multipleCloudFrontUrls.length === 4) {
    //   postDetails.atheneumDocument = multipleCloudFrontUrls[0];
    //   postDetails.atheneumDocument2 = multipleCloudFrontUrls[1];
    //   postDetails.atheneumDocument3 = multipleCloudFrontUrls[2];
    //   postDetails.atheneumDocument4 = multipleCloudFrontUrls[3];
    // } else if (multipleCloudFrontUrls && multipleCloudFrontUrls.length === 5) {
    //   postDetails.atheneumDocument = multipleCloudFrontUrls[0];
    //   postDetails.atheneumDocument2 = multipleCloudFrontUrls[1];
    //   postDetails.atheneumDocument3 = multipleCloudFrontUrls[2];
    //   postDetails.atheneumDocument4 = multipleCloudFrontUrls[3];
    //   postDetails.atheneumDocument5 = multipleCloudFrontUrls[4];
    // }


    // save post keywords if there are some in the post action

    let noDuplicatesArray = [];
    console.log('submitted post keywords: ', this.state.keywords);
    if (this.state.keywords.length > 0) {

      // filter out duplicates
      noDuplicatesArray = this.state.keywords.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.place === item.place && t.name === item.name
        ))
      )
    }

    const postId = this.state.editingPostId;
    if (postId) {
      postDetails.id = postId;
      this.props.updatePost(postDetails, postId, multipleCloudFrontUrls, noDuplicatesArray);
    } else {
      this.props.createPost(postDetails, multipleCloudFrontUrls, noDuplicatesArray);
    }

    this.clearState();

  };

  publishPost = (type) => {
    // console.log('this.state.title: ', this.state.title);
    // console.log('this.state.editorState: ', this.state.editorState);
    // console.log('this.state.applicationType: ', this.state.applicationType);
    // console.log('this.state.originalPublishDate: ', this.state.originalPublishDate);
    // console.log('this.selectedPostTypesValidation(): ', this.selectedPostTypesValidation());
    // console.log('this.selectedCategoriesValidation(): ', this.selectedCategoriesValidation());

    if (this.state.title && this.state.editorState && this.state.commercialContent !== null && this.state.applicationType && this.state.originalPublishDate && this.selectedCategoriesValidation()) {

      let selectedCategoriesArray  = [];
      if (this.state.formattedAtheneumCategories.length > 0) {
        this.state.formattedAtheneumCategories.map((item) => {
          if (item.checked) {
            selectedCategoriesArray.push(item.id);
          }
        });
      }

      if (type !== 'preview') {

        // Just a feature image was uploaded
        if (this.state.uploadedFile && this.state.multipleFiles.length === 0) {
          console.log('feature only');
          this.props.saveSingleFile(this.state.uploadedFile, 'feature-images', 'posts')
            .then((cloudfrontUrl) => {
              // add the cloudfront url to the post
              this.finalizePost(type, selectedCategoriesArray, cloudfrontUrl);
            });
        }

        // a feature image and additional documents were uploaded
        if (this.state.uploadedFile && this.state.multipleFiles.length > 0) {
          console.log('feature and docs');
          this.props.saveSingleFile(this.state.uploadedFile, 'feature-images', 'posts')
            .then((cloudfrontUrl) => {
              // add the cloudfront url to the post
              this.props.saveMultiplePhotos(this.state.multipleFiles, 'additional-documents', 'posts')
                .then((multipleCloudFrontUrls) => {
                  console.log('multipleCloudFrontUrls: ', multipleCloudFrontUrls);
                  this.finalizePost(type, selectedCategoriesArray, cloudfrontUrl, multipleCloudFrontUrls);
                })
            });
        }

        // only added additional documents
        if (!this.state.uploadedFile && this.state.multipleFiles.length > 0) {
          console.log('just docs');
          this.props.saveMultiplePhotos(this.state.multipleFiles, 'additional-documents', 'posts')
            .then((multipleCloudFrontUrls) => {
              console.log(multipleCloudFrontUrls);
              let newOldFiles = this.state.existingMultipleFiles.concat(multipleCloudFrontUrls);
              this.finalizePost(type, selectedCategoriesArray, null, newOldFiles);
            })
        }

        // no images or additional documents uploaded
        if (!this.state.uploadedFile && this.state.multipleFiles.length === 0) {
          this.finalizePost(type, selectedCategoriesArray);
        }

      } else {
        // show post preview
      }

    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  selectedCategoriesValidation = () => {
    // check and make sure at least 1 category is checked
    let isTrue = false;
    const arrayToCheck = this.state.formattedAtheneumCategories;
    for (let i = 0; i < arrayToCheck.length; i++) {
      if (arrayToCheck[i].checked) {
        isTrue = true;
        break;
      }
    }
    return isTrue;
  };

  onSearchChange = (e) => {
    const keyword = e.target.value.toLowerCase();

    if (keyword.length >= 1) {
      this.setState(() => ({
        searching: true
      }));
    } else {
      this.setState(() => ({
        searching: false
      }));
    }

    const filteredMicrosites = this.props.microsites;

    const searchResults = filteredMicrosites.filter(item => item.pageTitle.toLowerCase().includes(keyword));

    this.setState(() => ({
      filteredMicrosites: searchResults,
      micrositeTitle: keyword
    }));
  };

  onSearchClick = (microsite) => {
    console.log('search click: ', microsite);
    this.setState(() => ({
      selectedMicrosite: microsite,
      micrositeTitle: microsite.pageTitle,
      searching: false
    }));
  };

  onKeywordChange = (e) => {
    const selectedKeyword = e.target.value;
    this.setState(() => (
      {selectedKeyword}));
  };

  onKeywordEnter = (e) => {
    if (this.state.keywords.length < 3) {
      if (e.key === 'Enter') {
        let keywordItem = {
          name: e.target.value.toLowerCase(),
        };
        this.setState(() => (
          {
            selectedKeyword: '',
            keywords: [...this.state.keywords, keywordItem]
          }
        ));
      }
    }
  };

  onAddKeyword = () => {
    let keywordItem = {
      name: this.state.selectedKeyword.toLowerCase(),
    };
    this.setState(() => (
      {
        selectedKeyword: '',
        keywords: [...this.state.keywords, keywordItem]
      }
    ));
  };

  removeItem(item) {
    this.setState({keywords: this.state.keywords.filter(function(keptItem) {
        return keptItem.name !== item.name
      })});
  }

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Create Post</StyledHeading>
          <RightSideHeading>
            <ButtonMargin><MainButton title="Preview Post" onClick={() => this.openModal()}/></ButtonMargin>
            <ButtonMargin><MainButton title="Save as Draft" onClick={() => this.publishPost('draft')}/></ButtonMargin>
            <div>
              <ButtonMargin><MainButton title="Schedule a Post" onClick={() => this.openCalendar(!this.state.showCalendar)} /></ButtonMargin>
              <SchedulePopup showCalendar={this.state.showCalendar}>
                <DatePicker
                  inline
                  selected={this.state.date}
                  onChange={date => this.setStartDate(date)}
                  showTimeSelect
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <MainButton title="Schedule Now" onClick={() => this.publishPost('scheduled')}/>
                <MainButton title="Cancel" onClick={() => this.cancelCalendar()}/>
              </SchedulePopup>
            </div>
            <MainButton title="Publish Now" dark={true} onClick={() => this.publishPost('published')}/>
          </RightSideHeading>
        </HeadingContainer>
        <MainContentContainer>
          <MainContent>
            <TitleFeaturesSelect>
              <StyledIconButtons onClick={() => this.handleBoldSelect()}>
                <img src={BoldIcon} alt="" srcSet={`${BoldIcon} 1x, ${BoldIcon2x} 2x`} />
              </StyledIconButtons>
              <StyledIconButtons onClick={() => this.handleItalicSelect()}>
                <img src={ItalicIcon} alt="" srcSet={`${ItalicIcon} 1x, ${ItalicIcon2x} 2x`} />
              </StyledIconButtons>
              <DisplayTitleCheck>
                <label>
                  <input
                    key="displayTitle"
                    type="checkbox"
                    value="displayTitle"
                    checked={this.state.displayTitle}
                    onChange={() => this.handleDisplayTitleChange()}
                  />
                  Display Title With Post
                </label>
              </DisplayTitleCheck>
            </TitleFeaturesSelect>
            <StyledInput
              type="text"
              value={this.state.title}
              onChange={(e) => this.handleTitleChange(e.target.value)}
              placeholder="Title"
            />
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="post-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                image: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: false,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: true, mandatory: false },
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
                colorPicker: {
                  colors: ['rgb(255, 0, 0)', 'rgb(26,188,156)']
                },
              }}
            />
            {/*<textarea*/}
            {/*disabled*/}
            {/*value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}*/}
            {/*/>*/}
            <FeaturedImageSection>
              <PreviewSection>
                <div>
                  <StyledTitle>Featured Image</StyledTitle>
                  <StyledImageSection>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MainButton title='Select' />
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </StyledImageSection>
                </div>
                {this.imageCheck()}
              </PreviewSection>
              <DisplaySection>
                <StyledTitle>Display</StyledTitle>
                <RadioSection>
                  <label>
                    <input
                      key="above"
                      type="radio"
                      value="above"
                      checked={this.state.displayImageAbove}
                      onChange={() => this.handleDisplayImageChange(true)}
                    />
                    Above Post Title
                  </label>
                  <label>
                    <input
                      key="below"
                      type="radio"
                      value="below"
                      checked={!this.state.displayImageAbove}
                      onChange={() => this.handleDisplayImageChange(false)}
                    />
                    Below Post Title
                  </label>
                </RadioSection>
              </DisplaySection>
              <FeaturedImageLinkSection>
                <StyledTitle>Link for featured image</StyledTitle>
                <LinkInput
                  type="text"
                  value={this.state.featuredImageLink || ''}
                  onChange={(e) => this.handleFeatureImageLink(e.target.value)}
                />
              </FeaturedImageLinkSection>
            </FeaturedImageSection>
            <OriginalPublishDateSection>
              <StyledTitle>Original Publication Date</StyledTitle>
              <StyledDatePicker
                selected={this.state.originalPublishDate}
                onChange={date => this.handleOriginalPublishDateChange(date)}
                showTimeSelect
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              {/*<LinkInput*/}
                {/*type="text"*/}
                {/*value={this.state.featuredImageLink || ''}*/}
                {/*onChange={(e) => this.handleFeatureImageLink(e.target.value)}*/}
              {/*/>*/}
            </OriginalPublishDateSection>
            <OriginalPublishDateSection>
              <StyledTitle>Link to Microsite</StyledTitle>
              <SearchBar onChange={(e) => this.onSearchChange(e)} onSearchClick={(microsite) => this.onSearchClick(microsite)} micrositeTitle={this.state.micrositeTitle} hide={false} showSearchResults={this.state.searching} postsFiltered={this.state.filteredMicrosites} />
            </OriginalPublishDateSection>
            {
              <CategorySection>
                <CategorySectionHeading>Atheneum Content Details:</CategorySectionHeading>
                <PreviewSection>
                  <div>
                    <StyledTitle>Additional Documents</StyledTitle>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleMultipleOnDrop(acceptedFiles)}
                      multiple={true}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MainButton title="Select Files" />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <MultipleImagePreview>
                    {
                      this.state.existingMultipleFiles.length > 0 &&
                      this.state.existingMultipleFiles.map((item, index) => {
                        return (
                          <FileRow key={index}>
                            {this.existingMultipleImageCheck(index)}
                            <button type="button" onClick={() => this.removeFileFromExistingMultiple(item, index)}>remove</button>
                          </FileRow>
                        )
                      })
                    }
                    {
                      this.state.multipleFiles.length > 0 &&
                      this.state.multipleFiles.map((item, index) => {
                        return (
                          <FileRow key={index}>
                            {this.multipleImageCheck(index)}
                            <button type="button" onClick={() => this.removeFileFromMultiple(index)}>remove</button>
                          </FileRow>
                        )
                      })
                    }
                  </MultipleImagePreview>
                </PreviewSection>
                <StyledTitle>Atheneum Excerpt</StyledTitle>
                <StyledTextArea
                  name="xhcange-excerpt"
                  id="xhcange-excerpt"
                  cols="30"
                  rows="10"
                  value={this.state.atheneumExcerpt || ''}
                  onChange={(e) => this.handleAtheneumExcerptChange(e.target.value)}>
                </StyledTextArea>
                <StyledTitle>Notes</StyledTitle>
                <StyledTextArea
                  name="notes"
                  id="notes"
                  cols="30"
                  rows="5"
                  value={this.state.notes || ''}
                  onChange={(e) => this.handleNotesChange(e.target.value)}>
                </StyledTextArea>
                <StyledTitle>Attribution</StyledTitle>
                <LinkInput
                  name="attribution"
                  id="attribution"
                  cols="30"
                  rows="5"
                  value={this.state.attribution || ''}
                  onChange={(e) => this.handleAttributionChange(e.target.value)}>
                </LinkInput>
                <StyledTitle>Attribution 2</StyledTitle>
                <LinkInput
                  name="attribution2"
                  id="attribution2"
                  cols="30"
                  rows="5"
                  value={this.state.attribution2 || ''}
                  onChange={(e) => this.handleAttribution2Change(e.target.value)}>
                </LinkInput>
                <StyledTitle>Client Portal ID</StyledTitle>
                <LinkInput
                  name="clientPortalId"
                  id="clientPortalId"
                  cols="30"
                  rows="5"
                  value={this.state.clientPortalId || ''}
                  onChange={(e) => this.handleClientPortalIdChange(e.target.value)}>
                </LinkInput>
              </CategorySection>
            }
          </MainContent>
          <StyledSidebar>
          <SidebarSection>
              <StyledTitle>Content Type*</StyledTitle>
              <SidebarSectionBackground>
                <RadioSection>
                  <label>
                    <input
                      name="commercialContent"
                      type="radio"
                      value="false"
                      checked={this.state.commercialContent === false}
                      onChange={() => this.handleCommercialContentChange(false)}
                    />
                    Editorial
                  </label>
                  <label>
                    <input
                      name="commercialContent"
                      type="radio"
                      value="true"
                      checked={this.state.commercialContent === true}
                      onChange={() => this.handleCommercialContentChange(true)}
                    />
                    Commercial
                  </label>
                </RadioSection>
              </SidebarSectionBackground>
            </SidebarSection>
            <SidebarSection>
              <StyledTitle>Application Type*</StyledTitle>
              <SidebarSectionBackground>
                <RadioSection>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="all"
                      checked={this.state.applicationType === 'all'}
                      onChange={() => this.handleApplicationTypeChange('all')}
                    />
                    All
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="upstream"
                      checked={this.state.applicationType === 'upstream'}
                      onChange={() => this.handleApplicationTypeChange('upstream')}
                    />
                    Upstream
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="downstream"
                      checked={this.state.applicationType === 'downstream'}
                      onChange={() => this.handleApplicationTypeChange('downstream')}
                    />
                    Downstream
                  </label>
                  <label>
                    <input
                      name="applicationType"
                      type="radio"
                      value="fillfinish"
                      checked={this.state.applicationType === 'fillfinish'}
                      onChange={() => this.handleApplicationTypeChange('fillfinish')}
                    />
                    Fill/Finish
                  </label>
                </RadioSection>
              </SidebarSectionBackground>
            </SidebarSection>
            <hr />
            {/* <SidebarSection>
              <StyledTitle>Post Type*</StyledTitle>
              <SidebarSectionBackground>
                {
                  (this.props.postTypes && this.state.formattedPostTypes.length > 0) &&
                  this.state.formattedPostTypes.map((item, index) => <CheckboxStyled key={item.id}><Checkbox name={item.name} onChange={(e) => this.postTypeSelect(e.target.checked, index)} checked={item.checked} /></CheckboxStyled>)
                }
              </SidebarSectionBackground>
            </SidebarSection> */}
            {/* <hr /> */}
            <div>
              <SidebarSection key={2}>
                <StyledTitle>Atheneum Categories</StyledTitle>
                <SidebarSectionBackground>
                  {
                    (this.props.categories && this.state.formattedAtheneumCategories.length > 0) &&
                    this.state.formattedAtheneumCategories.map((item, index) => {
                      return (
                        <CheckboxStyled key={item.id}><Checkbox name={item.name} onChange={(e) => this.categorySelect(e.target.checked, index, 'atheneum')} checked={item.checked} /></CheckboxStyled>
                      )
                    })
                  }
                </SidebarSectionBackground>
              </SidebarSection>
            </div>
            <hr />
            <div>
              <SidebarSection>
                <label>Keywords:</label>
                <FormItalicHeading>To match for related content</FormItalicHeading>
                <StyledInput
                  value={this.state.selectedKeyword}
                  onChange={this.onKeywordChange}
                  onKeyDown={this.onKeywordEnter}
                  type="text"
                />
                <KeywordButtonPadding>
                  <ABFormButton title='Add Keyword(s)' onClick={this.onAddKeyword} />
                </KeywordButtonPadding>
                <KeywordItems>
                  {
                    (this.state.keywords && this.state.keywords.length > 0) &&
                    this.state.keywords.map((item, index) => {
                      return <KeywordItem key={index}>
                        <KeywordTitle>{item.name}</KeywordTitle>
                        <RemoveButton onClick={() => this.removeItem(item)}>x</RemoveButton>
                      </KeywordItem>
                    })
                  }
                </KeywordItems>
              </SidebarSection>
            </div>
          </StyledSidebar>
        </MainContentContainer>
        <ModalStyled
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.closeModal()}
          contentLabel="Sort"
          overlayClassName="PreviewOverlay"
          modalWidth={this.state.modalWidth}
        >
          {
            this.state.modalPosition === 0 &&
            <ModalBody>
              <ModalPreviewTitle>Alerts Preview</ModalPreviewTitle>
              <ModalHeading>Discovery Alert</ModalHeading>
              {
                this.state.featuredImage &&
                <ModalFeaturedImage>
                  <a href={this.state.featuredImageLink}>
                    <img src={`https://${this.state.featuredImage}`} alt="" />
                  </a>
                </ModalFeaturedImage>
              }
              <ModalTitle>{this.state.title}</ModalTitle>
              <ModalDetails dangerouslySetInnerHTML={{
                __html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
              }} />
            </ModalBody>
          }
          {
            this.state.modalPosition === 1 &&
            <ModalBody>
              <ModalPreviewTitle>Atheneum Preview</ModalPreviewTitle>
              {
                this.state.featuredImage &&
                <ModalFeaturedImage>
                  <a href={this.state.featuredImageLink}>
                    <img src={`https://${this.state.featuredImage}`} alt="" />
                  </a>
                </ModalFeaturedImage>
              }
              <ModalTitle>{this.state.title}</ModalTitle>
              <ModalDetails dangerouslySetInnerHTML={{
                __html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
              }} />
              <ModalDocuments>
                <div>
                  <h2>Additional Documents</h2>
                  <h3>(click to download)</h3>
                </div>
                <div>
                  {
                    this.state.existingMultipleFiles.length > 0 &&
                    this.state.existingMultipleFiles.map((item) => {
                      return <img key={item.id} src={`https://${item.imageUrl}`} alt="" />
                    })
                  }
                </div>
              </ModalDocuments>
            </ModalBody>
          }
          <ModalArrowRight onClick={() => this.handleModelChange(1)}><ReactSVG src={ArrowIcon} /></ModalArrowRight>
          <ModalArrowLeft onClick={() => this.handleModelChange(-1)}><ReactSVG src={ArrowIcon} /></ModalArrowLeft>
        </ModalStyled>
        <ToastContainer />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    postTypes: state.postTypes.data,
    categories: state.categories.data,
    post: state.post.data,
    microsites: state.microsites.data,
    postKeywords: state.postKeywords.data,
    getCategorySuccess: state.categories.getCategorySuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPost: (id) => dispatch(getPost(id)),
  createPost: (postDetails, multipleCloudFrontUrls, keywords) => dispatch(createPost(postDetails, multipleCloudFrontUrls, keywords)),
  updatePost: (postDetails, postId, multipleCloudFrontUrls, keywords) => dispatch(updatePost(postDetails, postId, multipleCloudFrontUrls, keywords)),
  getPostTypes: () => dispatch(getPostTypes()),
  getCategories: () => dispatch(getCategories()),
  // getCategoriesByPostIds: (postTypeIds) => dispatch(getCategoriesByPostIds(postTypeIds)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  saveMultiplePhotos: (files, subfolder, mainFolder) => dispatch(saveMultiplePhotos(files, subfolder, mainFolder)),
  getMicrosites: () => dispatch(getMicrosites()),
  getPostKeywords: (postId) => dispatch(getPostKeywords(postId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);
