import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormInputSmall} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import {history} from '../../../routers/AppRouter';
import {createMicrosite, getMicrosite, updateMicrosite} from '../../../actions/microsite';
import {updateMicrositeSubmission} from '../../../actions/microsite-submission';
import {setLocation} from '../../../actions/location';
import {getMicrositeSetting, updateMicrositeSetting} from '../../../actions/microsite-settings';
import {createMicrositeKeyWords, getMicrositeKeywords, updateMicrositeKeyWords} from '../../../actions/microsite-keywords';
import ABFormButton from '../../../components/ABFormButton';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.form`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const KeywordItems = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`;

const KeywordItem = styled.div`
  background: ${colors.gray100};
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: 5px 5px 5px 0;
`;
const KeywordTitle = styled.div`
  font-size: 15px;
`;

const RemoveButton = styled.div`
  background: ${colors.lightestGray};
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${colors.lightGray};
  padding: 0 4px;
  margin-left: 4px;
`;

const RadioSection = styled.div`
  max-width: 150px;
  font-size: 15px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

const StyledInput = styled.div`
  width: 300px;
  padding-right: 15px;
`;

class Keywords extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSection: false,
      keywords: [],
      selectedKeyword: '',
      categoryOptions: [], // for the multi select
      selectedOptions: [], // for the multi select
    };

  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
      this.props.getMicrositeKeywords(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.micrositeKeywords !== prevProps.micrositeKeywords) {
      if (!this.state.justSaved) {
        this.loadKeywordsFields();
      }
    }
  }

  loadKeywordsFields = () => {
    this.setState({
      keywords: this.props.micrositeKeywords
    });
  };

  onNextClick = (close) => {
    if (this.state.keywords.length > 0) {

      let noDuplicatesArray = [];

      // filter out duplicates
      noDuplicatesArray = this.state.keywords.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.place === item.place && t.name === item.name
        ))
      )

      // make sure no duplicates go through
      // this delets them all and then ads the new ones
      this.props.updateMicrositeKeyWords(noDuplicatesArray, this.props.microsite.id);

      if (close) {
        history.push(`../${this.props.microsite.id}`);
        return;
      }

    } else {
      this.showValidationErrors();
    }
  };

  showValidationErrors = () => {
    if (!this.state.recName1) {
      this.setState(() => ({recName1Error: true}));
    }
    if (!this.state.recEmail1) {
      this.setState(() => ({recEmail1Error: true}));
    }
  };

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({showSection: !showSection}));
  };

  onKeywordChange = (e) => {
    const selectedKeyword = e.target.value;
    this.setState(() => (
      {selectedKeyword}));
  };

  onKeywordEnter = (e) => {
    if (e.key === 'Enter') {
      let keywordItem = {
        name: e.target.value.toLowerCase(),
      };
      this.setState(() => (
        {
          selectedKeyword: '',
          keywords: [...this.state.keywords, keywordItem]
        }
      ));
    }
  };

  onAddKeyword = () => {
    let keywordItem = {
      name: this.state.selectedKeyword.toLowerCase(),
    };
    this.setState(() => (
      {
        selectedKeyword: '',
        keywords: [...this.state.keywords, keywordItem]
      }
    ));
  };

  removeItem(item) {
    this.setState({keywords: this.state.keywords.filter(function(keptItem) {
        return keptItem.name !== item.name
      })});
  }

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Keywords</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>

            <FormSection>

              <FormSectionPadding>
                <FormSubHeading>Keywords:</FormSubHeading>
                <FormItalicHeading>Add keywords/phrases to identify your Microsite in search results.</FormItalicHeading>
                <FieldContainer>
                  <StyledInput>
                    <FormInputSmall
                      value={this.state.selectedKeyword}
                      onChange={this.onKeywordChange}
                      onKeyDown={this.onKeywordEnter}
                      type="text"
                    />
                  </StyledInput>
                  <ABFormButton title='Add Keyword(s)' onClick={this.onAddKeyword} />
                </FieldContainer>
                <KeywordItems>
                  {
                    (this.state.keywords && this.state.keywords.length > 0) &&
                    this.state.keywords.map((item, index) => {
                      return <KeywordItem key={index}>
                        <KeywordTitle>{item.name}</KeywordTitle>
                        <RemoveButton onClick={() => this.removeItem(item)}>x</RemoveButton>
                      </KeywordItem>
                    })
                  }
                </KeywordItems>
              </FormSectionPadding>

            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
    micrositeKeywords: state.micrositeKeywords.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  setLocation: (page) => dispatch(setLocation(page)),
  createMicrositeKeyWords: (micrositeKeywords, micrositeId) => dispatch(createMicrositeKeyWords(micrositeKeywords, micrositeId)),
  updateMicrositeKeyWords: (micrositeKeywords, micrositeId) => dispatch(updateMicrositeKeyWords(micrositeKeywords, micrositeId)),
  getMicrositeKeywords: (micrositeId) => dispatch(getMicrositeKeywords(micrositeId)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
