import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding} from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import {history} from '../../../routers/AppRouter';
import {onCreate, getMicrosite, updateMicrosite, createMicrosite} from '../../../actions/microsite';
import {updateMicrositeSubmission} from '../../../actions/microsite-submission';
import {setLocation} from '../../../actions/location';
import {getMicrositeSetting, updateMicrositeSetting} from '../../../actions/microsite-settings';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

class CreateMicrosite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: ''
    };

  }

  componentDidMount = () => {

  };

  componentDidUpdate(prevProps) {

  }

  onCreate = () => {
    let microsite = {
      pageTitle: 'untitled'
    };
    this.props.createMicrosite(microsite, this.state.userId);
    history.push(`../microsites`);
  };

  onUserIdChange = (e) => {
    const userId = e.target.value;
    if (userId.length <= 70) {
      this.setState(() => ({userId}));
    }
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Create Microsite</PageTitle>
          </PageHeading>
          <FormContainer>
            <FormSection>
              <FormSubHeading>Enter User ID:</FormSubHeading>
              <FormItalicHeading>Create blank microsite attached to the userID</FormItalicHeading>
              <div>
                <FormField>
                  <FormInputFull
                    value={this.state.userId}
                    onChange={this.onUserIdChange}
                    type="text"
                  />
                </FormField>
              </div>

            </FormSection>

          </FormContainer>

          <ActionButtons>
            <RightButton>
              <ABButton title="Create" onClick={() => this.onCreate()} />
            </RightButton>
          </ActionButtons>

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createMicrosite: (microsite, userId) => dispatch(createMicrosite(microsite, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMicrosite);
