import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../routers/AppRouter';
import { createMicrosite, getMicrosite, updateMicrosite } from '../../../actions/microsite';
import Spinner from "../../../components/Spinner";

import Dropzone from 'react-dropzone';
import {
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef
} from '../../../utility/reusable-utils';

import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormFileName, FormInputCharCount } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import ABSmallButton from '../../../components/ABSmallButton';
import { saveSingleFile } from '../../../actions/upload';
import { getMicrositeSubmission, updateMicrositeSubmission } from '../../../actions/microsite-submission';
import { shortenFileName } from '../../../utility/string-utils';
import { setLocation } from '../../../actions/location';
import { getMicrositeSetting, updateMicrositeSetting } from '../../../actions/microsite-settings';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.form`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ImagePreview = styled.img`
  max-width: 300px;
  max-height: 150px;
  object-fit: cover;
  margin-left: 20px;
`;

const CenterImage = styled.div`
  padding: 0 0 15px 0;
`;

const ImageSelection = styled.div`
  display: flex;
  // max-width: 300px;
  max-height: 150px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
  outline: none;
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const UploadSpinner = styled.div`
  font-size: 14px;
  padding: 20px 0;
  max-width: 1365px;
  text-align: center;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

class LogoBio extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tagLine: '',
      description: '',
      imgSrc: null,
      imgSrcExt: null,
      uploadedFile: null,
      uploadedFileName: '',
      imageUploading: false,
      showSection: false,
      imageSizeError: false,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.microsite !== prevProps.microsite) {
      this.loadFields();
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.setState({
        showSection: this.props.micrositeSetting.showBioSection || false
      });
    }
  }

  loadFields = () => {

    this.setState({
      tagLine: this.props.microsite.tagLine,
      description: this.props.microsite.description,
      headerButtonLink: this.props.microsite.headerButtonLink,
      uploadedFileName: this.props.microsite.logo
    });
  };

  onNextClick = (close) => {

    if (!this.state.imageSizeError) {
      if (this.state.uploadedFile) {
        this.setState({ imageUploading: true });

        let formattedCompanyName = this.props.microsite.company.split(' ').join('-').toLowerCase();

        this.props.saveSingleFile(this.state.uploadedFile, formattedCompanyName, 'microsites')
          .then((cloudFrontUrl) => {
            this.setState({ imageUploading: false });
            console.log('cloudfront url returned: ', cloudFrontUrl);
            this.saveForm(cloudFrontUrl, close);
          });
      } else {
        this.saveForm(null, close);
      }
    }

  };

  saveForm = (cloudFrontURL, close) => {
    let micrositeSetting = {
      showBioSection: this.state.showSection
    };
    let microsite = {
      tagLine: this.state.tagLine,
      description: this.state.description,
      userId: this.props.microsite.userId
    };
    if (cloudFrontURL) {
      microsite.logo = cloudFrontURL
    }
    this.props.updateMicrosite(microsite, this.props.microsite.id);

    let micrositeSubmission = {
      logoBio: true
    };
    this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);
    this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

    if (close) {
      history.push(`../${this.props.microsite.id}`);
      return;
    }
  };

  onTaglineChange = (e) => {
    const tagLine = e.target.value;
    if (tagLine.length <= 50) {
      this.setState(() => ({ tagLine }));
    } else {
      console.log('tagLine too long');
    }
  };

  onDescriptionChange = (e) => {
    const description = e.target.value;
    if (description.length <= 750) {
      this.setState(() => ({ description }));
    } else {
      console.log('description too long');
    }
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.isValidImageSize(myResult, 389, 167);
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile,
            uploadedFileName: files[0].name
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  isValidImageSize = (fileDetails, width, height) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function () {
      if (this.width >= width && this.height >= height && this.width <= 476 && this.height <= 247) {
        globalThis.setSingleImageError(false);
      } else {
        globalThis.setSingleImageError(true);
      }
    };
  };

  setSingleImageError = (showError) => {
    this.setState({
      imageSizeError: showError
    });
  };

  imageCheck() {
    if (this.state.imgSrc != null) {
      return <FormFileName>{this.state.uploadedFileName}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName)}</FormFileName> // puilled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({ showSection: !showSection }));
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Organization Logo and Biography</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>
            <FormSection>
              <div>
                <FormSubHeading>Organization Logo:</FormSubHeading>
                <FormItalicHeading>The submitted Organization Logo should be at least 389 pixels wide and 167 pixels tall
                  but not exceed 476 pixels wide and 247 pixels tall.</FormItalicHeading>
                <div>
                  <FormField>
                    <CenterImage>
                      <Dropzone
                        onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
                        multiple={false}
                        accept={acceptedFileTypes}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <ImageSelection {...getRootProps()}>
                              <input {...getInputProps()} />
                              <ABSmallButton title="File Upload" />
                              <UploadButtonContainer>
                                {this.imageCheck()}
                                {
                                  this.state.imageSizeError &&
                                  <ValidationError>Invalid Image Size</ValidationError>
                                }
                              </UploadButtonContainer>
                            </ImageSelection>
                          </section>
                        )}
                      </Dropzone>
                    </CenterImage>
                  </FormField>
                </div>
              </div>

              <FormSectionPadding>
                <FormSubHeading>Organization Tagline:</FormSubHeading>
                <FormItalicHeading>TheTagline should not exceed 50 characters, including spaces</FormItalicHeading>
                <div>
                  <FormField>
                    <FormInputFull
                      value={this.state.tagLine}
                      onChange={this.onTaglineChange}
                      type="text"
                    />
                    {
                      this.state.tagLine &&
                      <FormInputCharCount>Char count: {this.state.tagLine.length}</FormInputCharCount>
                    }
                  </FormField>
                </div>
              </FormSectionPadding>
            </FormSection>

            <FormSection>
              <FormSubHeading>Organization Biography:</FormSubHeading>
              <FormItalicHeading>The Biography should not exceed 750 characters, including spaces.</FormItalicHeading>
              <div>
                <FormField>
                  <FormTextArea
                    value={this.state.description}
                    onChange={this.onDescriptionChange}
                    type="text"
                    rows="10"
                    cols="10"
                  />
                  {
                    this.state.description &&
                    <FormInputCharCount>Char count: {this.state.description.length}</FormInputCharCount>
                  }
                </FormField>
              </div>
            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>
          {
            this.state.imageUploading &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.microsite.loading,
    error: state.microsite.error,
    errorMessage: state.microsite.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  setLocation: (page) => dispatch(setLocation(page)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoBio);
