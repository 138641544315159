import React from 'react';
import styled from 'styled-components';
import colors from "../styles/colors";

const BlueButton = styled.button`
  background-color: ${colors.blue};
  border: 1px solid ${colors.black};
  border-radius: 7px;
  font-size: 16px;
  color: ${colors.white};
  padding: 8px 25px;
  cursor: pointer;
  outline: none;
  :hover {
    opacity: .8;
  }
`;

const GradientButton = styled.button`
  border-radius: 5px;
  font-size: 16px;
  color: ${colors.darkGray};
  padding: 8px 25px;
  cursor: pointer;
  outline: none;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E5E6E7 100%);
  border: 1px solid #C5C6C8;
  border-radius: 7px;
  :hover {
    opacity: .8;
  }
`;

const MainButton = (props) => {
  return (
    <>
      {
        !props.dark &&
        <GradientButton type="button" onClick={props.onClick} dark={props.dark}>
          {props.title}
        </GradientButton>
      }
      {
        props.dark &&
        <BlueButton type="button" onClick={props.onClick} dark={props.dark}>
          {props.title}
        </BlueButton>
      }
    </>

  );
};

export default MainButton;