import {CATEGORY_NAME, POST_TYPE_NAME} from '../utility/constants';

const bulkUpdateDefaultState = {
  data: [],
  singlePost: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
  bulkSuccessMessage: '',
  bulkError: null
};

const bulkUpdateReducer = (state = bulkUpdateDefaultState, action) => {
  switch(action.type) {
    case 'RESET_BULK_UPDATE_NOTIFICATIONS':
      return {
        loading: false,
        error: false,
        bulkError: false,
        successMessage: '',
        errorMessage: '',
        bulkSuccessMessage: '',
      };
    case 'SHOW_BULK_UPDATE_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        bulkError: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    case 'BULK_UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        bulkError: null,
        bulkSuccessMessage: 'Bulk Update Success',
      };  
    default:
      return state;
  }
};

export default bulkUpdateReducer;
