import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { createAlertAds, deleteAlertAds, updateAlertAds } from '../../../../actions/alert/alert-ads';
import styled from 'styled-components';
import { AlertAds } from "../alert-types";
import Modal from 'react-modal';
import colors from '../../../../styles/colors';
import AlertAdForm from "../AlertAdForm";
import { getMicrosites } from "../../../../actions/microsites";
import { Microsite } from "../../../../types/types";

const EditRightContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const ImageStyle = styled.div`
  text-align: center;
  img {
    max-width: 485px;
    width: 100%;
  }
`;

const AdTextStyle = styled.div`
  text-align: center;
  font-size: 11px;
  color: ${colors.gray200};
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  a {
    text-decoration: underline;
    color: #154f91;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 0;
    :hover {
      color: black;
    }
  }
`;

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 15px 0;
  a {
    color: ${colors.gray400};
  }
  img {
    padding-right: 5px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertAds: AlertAds; // this is really just one alertAd object, just didn't want to remove the S
  buttonTitle: string;
  position: number;
  microsites: Microsite[]
  createAlertAds: (alertAds: AlertAds) => void;
  updateAlertAds: (alertAds: AlertAds) => void;
  deleteAlertAds: (id: number) => void;
  getMicrosites: () => void;
}

const AlertComponentA: React.FC<Props> = ({ alertItemId, alertAds, buttonTitle, position, microsites, createAlertAds, updateAlertAds, deleteAlertAds, getMicrosites }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertAds, setNewAlertAds] = useState<AlertAds>({ id: 0, image: '', link: '', micrositeLink: '', alertItemId: 0, position: 0, attribution: '' });

  useEffect(() => {
    getMicrosites()
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertAds: AlertAds) => {
    alertAds.position = position;
    setNewAlertAds(alertAds);
    setModalIsOpen(true);
  }

  const handleSave = (alertAds: AlertAds) => {
    alertAds.position = position;
    createAlertAds(alertAds);
    closeModal();
  }

  const handleUpdate = (alertAds: AlertAds) => {
    alertAds.position = position;
    updateAlertAds(alertAds);
    closeModal();
  }

  const handleDelete = (id: number) => {
    deleteAlertAds(id);
    closeModal();
  }

  return (
    <>
      {
        alertAds ?
          <>
            <ImageStyle><a href={alertAds.link}><img src={`https://${alertAds.image}`} alt="" /></a></ImageStyle>
            <AdTextStyle>ADVERTISEMENT</AdTextStyle>
            {
              alertAds.micrositeLink &&
              <ViewMoreContainer>
                <img src="https://dir80cd32z1m2.cloudfront.net/email/images/door.png" />
                <a href={alertAds.micrositeLink}>View Microsite</a>
              </ViewMoreContainer>
            }
            <EditRightContainer>
              <EditButton onClick={() => handleEdit(alertAds)}>Edit</EditButton>
            </EditRightContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertAdForm
          handleSave={handleSave}
          handleDelete={handleDelete}
          alertItemId={alertItemId}
          alertAds={newAlertAds}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
          microsites={microsites}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertAds: state.alertAds.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
    microsites: state.microsites.data
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertAds: (alertAds: AlertAds) => dispatch(createAlertAds(alertAds)),
  updateAlertAds: (alertAds: AlertAds) => dispatch(updateAlertAds(alertAds)),
  deleteAlertAds: (id: number) => dispatch(deleteAlertAds(id)),
  getMicrosites: () => dispatch(getMicrosites())
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentA);