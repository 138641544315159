import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import colors from "../../../styles/colors";
import {capitalizeFirstLetter} from "../../../utility/string-utils";
import {BASE_URL} from "../../../utility/constants";
import {FormInput, FormInputSmall, FormSection, FormSubHeading, FormTextArea} from "../../../styles/form-styles";
import Heading from "../../Xchange";
import ABButton from "../../../components/ABButton";
import ABFormButton from "../../../components/ABFormButton";
import ABSmallButton from "../../../components/ABSmallButton";
import Notification from "../../../components/Notification";

const PageWrapper = styled.div`
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const LinkTitle = styled.div`
  padding: 10px 0;
  font-size: 20px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const Section = styled.div`
  padding: 40px 0;
`;

const Subheading = styled.div`
  font-size: 22px;
  padding: 10px 0;
  color: ${colors.blue};
`;

const PostSection = styled.div`
  padding: 10px 0;
  display: flex;
`;

const MicrositeSection = styled.div`
  padding: 0 0 50px 0;
`;

const StyledPositionSection = styled.div`
  width: 56px;
  padding-right: 40px;
`;

const StyledIDSection = styled.div`
  width: 70px;
  padding-right: 40px;
`;

const StyledTitleSection = styled.div`
    width: 500px;
`;

const StyledMicrositeInputSection = styled.div`
  width: 800px;
  padding: 5px 0;
  textarea {
    width: 720px;
  }
`;


const InputWrapper = styled.div`
    display: flex;
`;

const ButtonPadding = styled.div`
    padding-left: 15px;
`;

const ButtonPaddingMicrosite = styled.div`
    padding-top: 15px;
`;

const EditAnnexPage = (props) =>  {
    const [posts, setPosts] = useState([]);
    const [microsites, setMicrosites] = useState([]);
    const [title, setTitle] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    function fetchPosts(title) {
        fetch(`${BASE_URL}annex-posts?title=${title}`).then(response => {
            return response.json();
        }).then(data => {
            setPosts(data);
            console.log(data);
        });
    }

    function fetchMicrosites(propTitle) {
        fetch(`${BASE_URL}annex-microsites?title=${propTitle}`).then(response => {
            return response.json();
        }).then(data => {
            setMicrosites(data);
            console.log(data);
        });
    }

    useEffect(() => {
        if (props.location.pageProps) {
            const pageTitle = props.location.pageProps.title;
            setTitle(pageTitle);
            fetchPosts(pageTitle);
            fetchMicrosites(pageTitle);
        }
    }, [])

    async function updatePost(post) {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${BASE_URL}annex-post/${post.id}`, {
                method: 'PUT',
                body: JSON.stringify(post),
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `${token}`,
                }
            });
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();
            setSuccess(true);
            clearNotifications();
            console.log('data in async: ', data);
        } catch(error) {
            setError(true);
            clearNotifications();
            console.log('error updating data');
        }
    }

    const clearNotifications = () => {
        setTimeout(() => {
            setSuccess(false);
            setError(false);
        }, 3000)
    }

    const onPostPositionChange = (e, postId) => {
        const newPosts = posts.map((post) => {
            if (post.id === postId) {
                return {
                    ...post,
                    position: e.target.value
                }
            } else {
                return post;
            }
        });
        console.log('new posts: ', newPosts);
        setPosts(newPosts);
    };

    const onPostIdChange = (e, postId) => {
        const newPosts = posts.map((post) => {
            if (post.id === postId) {
                return {
                    ...post,
                    postId: e.target.value
                }
            } else {
                return post;
            }
        });
        console.log('new posts: ', newPosts);
        setPosts(newPosts);
    };

    const onApplicationDescriptionChange = (e, postId) => {
        const newPosts = posts.map((post) => {
            if (post.id === postId) {
                return {
                    ...post,
                    applicationDescription: e.target.value
                }
            } else {
                return post;
            }
        });
        console.log('new posts: ', newPosts);
        setPosts(newPosts);
    };

    async function updateMicrosite(microsite) {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${BASE_URL}annex-microsite/${microsite.id}`, {
                method: 'PUT',
                body: JSON.stringify(microsite),
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `${token}`,
                }
            });
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();
            setSuccess(true);
            clearNotifications();
            console.log('data in async: ', data);
        } catch(error) {
            setError(true);
            clearNotifications();
            console.log('error updating data');
        }
    }

    const onMicrositeImageChange = (e, micrositeId) => {
        const newMicrosites = microsites.map((microsite) => {
            if (microsite.id === micrositeId) {
                return {
                    ...microsite,
                    micrositeImage: e.target.value
                }
            } else {
                return microsite;
            }
        });
        console.log('new microsites: ', newMicrosites);
        setMicrosites(newMicrosites);
    };

    const onMicrositeDescriptionChange = (e, micrositeId) => {
        const newMicrosites = microsites.map((microsite) => {
            if (microsite.id === micrositeId) {
                return {
                    ...microsite,
                    micrositeBody: e.target.value
                }
            } else {
                return microsite;
            }
        });
        console.log('new microsites: ', newMicrosites);
        setMicrosites(newMicrosites);
    };

    const onMicrositeLinkChange = (e, micrositeId) => {
        const newMicrosites = microsites.map((microsite) => {
            if (microsite.id === micrositeId) {
                return {
                    ...microsite,
                    micrositeLink: e.target.value
                }
            } else {
                return microsite;
            }
        });
        console.log('new microsites: ', newMicrosites);
        setMicrosites(newMicrosites);
    };

    return (
        <PageWrapper>
            {
                success &&
                <Notification className="success" message="Save Success" />
            }
            {
                error &&
                <Notification className="error" message="Error Saving" />
            }
            {
                title &&
                <HeadingContainer>
                    <StyledHeading>Edit Page: {capitalizeFirstLetter(title)}</StyledHeading>
                </HeadingContainer>
            }
            <Section>
                <Subheading>Featured Content</Subheading>
                <div>
                    {
                        posts &&
                        posts.map((post) => {
                            return (
                                <PostSection key={post.id}>
                                    <StyledPositionSection>
                                        <FormSubHeading>Position:</FormSubHeading>
                                        <FormInputSmall
                                            value={post.position}
                                            onChange={(e) => onPostPositionChange(e, post.id)}
                                            type="text"
                                        />
                                    </StyledPositionSection>
                                    <StyledIDSection>
                                        <FormSubHeading>Post ID:</FormSubHeading>
                                        <FormInputSmall
                                            value={post.postId}
                                            onChange={(e) => onPostIdChange(e, post.id)}
                                            type="text"
                                        />
                                    </StyledIDSection>
                                    <StyledTitleSection>
                                        <FormSubHeading>Content Type & Application:</FormSubHeading>
                                        <InputWrapper>
                                            <FormInputSmall
                                                value={post.applicationDescription}
                                                onChange={(e) => onApplicationDescriptionChange(e, post.id)}
                                                type="text"
                                            />
                                            <ButtonPadding>
                                                <ABFormButton title="Save" onClick={() => updatePost(post)} />
                                            </ButtonPadding>
                                        </InputWrapper>
                                    </StyledTitleSection>
                                </PostSection>
                            )
                        })
                    }
                </div>
            </Section>
            <Section>
                <Subheading>Featured Microsites</Subheading>
                <div>
                    {
                        microsites &&
                        microsites.map((microsite) => {
                            return (
                            <MicrositeSection key={microsite.id}>
                                <StyledMicrositeInputSection>
                                    <FormSubHeading>Microsite Image:</FormSubHeading>
                                    <FormInputSmall
                                        value={microsite.micrositeImage}
                                        onChange={(e) => onMicrositeImageChange(e, microsite.id)}
                                        type="text"
                                    />
                                </StyledMicrositeInputSection>
                                <StyledMicrositeInputSection>
                                    <FormSubHeading>Description:</FormSubHeading>
                                    <FormTextArea
                                        value={microsite.micrositeBody}
                                        onChange={(e) => onMicrositeDescriptionChange(e, microsite.id)}
                                        type="text"
                                        rows="4"
                                    />
                                </StyledMicrositeInputSection>
                                <StyledMicrositeInputSection>
                                    <FormSubHeading>Microsite Link:</FormSubHeading>
                                    <FormInputSmall
                                        value={microsite.micrositeLink}
                                        onChange={(e) => onMicrositeLinkChange(e, microsite.id)}
                                        type="text"
                                    />
                                </StyledMicrositeInputSection>
                                <ButtonPaddingMicrosite>
                                    <ABFormButton title="Save" onClick={() => updateMicrosite(microsite)} />
                                </ButtonPaddingMicrosite>
                            </MicrositeSection>
                            )
                        })
                    }
                </div>
            </Section>
        </PageWrapper>
    );
};

export default EditAnnexPage;