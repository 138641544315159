import React from 'react';
import styled from "styled-components";
import colors from "../styles/colors";

const StyledButton = styled.button`
  background: ${colors.blue};
  color: ${colors.white};
  padding: 15px 30px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    background: ${colors.black};
  }
  :disabled {
    background: ${colors.lightGray};
    :hover {
      background: ${colors.lightGray};
    }
  }
`;

const buttonState = (props) => {
  if (props.submitting) {
    return (
      <StyledButton onClick={props.onClick} disabled={true}>
        Submitting...
      </StyledButton>
    )
  } else {
    return (
      <StyledButton onClick={props.onClick} disabled={props.disabled}>
        {props.title}
      </StyledButton>
    )
  }
};

const ABButton = (props) =>  {
    return (
      <div>
        {buttonState(props)}
      </div>
    );
};

export default ABButton;