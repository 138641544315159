import axiosInstance from '../utility/axios';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetClients = () => ({
  type: 'START_GET_CLIENTS',
});

export const getClientsSuccess = (clients) => ({
  type: 'GET_CLIENTS_SUCCESS',
  clients
});

export const getClients = () => {
  return (dispatch) => {
    dispatch(startGetClients());
    axiosInstance.get('clients')
      .then((response) => {
        console.log('response: ', response);
        dispatch(getClientsSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};

export const clientCreated = (clientInfo) => ({
  type: 'CLIENT_CREATED',
  clientInfo
});

export const startCreateClient = (clientInfo) => {
  return (dispatch) => {
    axiosInstance.post('client', clientInfo)
      .then((response) => {
        dispatch(clientCreated(clientInfo));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(showError(error.response.data.message));
        } else {
          dispatch(showError('Network Error'));
        }
        console.log(error);
      });
  }
};

export const startClientUpdate = () => ({
    type: 'START_CLIENT_UPDATE',
  });
  
  export const updateClientdSuccess = (clientInfo) => ({
    type: 'CLIENT_UPDATE_SUCCESS',
    clientInfo
  });
  
  export const updateClient = (clientInfo) => {
    console.log('clientInfo.id: ', clientInfo.id);
    return (dispatch) => {
        console.log('Update CLient');
        dispatch(startClientUpdate());
        axiosInstance.put(`client/${clientInfo.id}`, clientInfo)
        .then((response) => {
            dispatch(updateClientdSuccess(clientInfo));
        })
        .catch((error) => {
            // dispatch(showError(error.response.data.message));
            console.log(error);
        });
    }
  };

