const colors = {
  yellow: '#FFCD00',
  orange: '#FFB718',
  blue: '#1D4E8F',
  linkBlue: '#154f91',
  black: '#000000',
  white: '#ffffff',
  lightGray: '#BCBBBA',
  darkGray: '#777779',
  gray100: '#F5F5F5',
  gray200: '#BCBBBA',
  gray300: '#8b8c8f',
  gray400: '#777779',
  lightestGray: '#F5F5F5',
  red: '#FF0000'
};

export default colors;
