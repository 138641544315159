import colors from '../styles/colors';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormFieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -25px;
  margin-left: -25px;
`;

export const FormFieldColumn = styled.div`
  font-size: 16px;
  // flex: 0 0 50%;
  padding: 10px 0;
  max-width: 50%;
  padding-right: 25px;
  padding-left: 25px;
  position: relative;
`;

export const FormSection = styled.div`
  
`;

export const FormField = styled.div`
  font-size: 16px;
  padding: 5px 0;
  flex: 0 0 100%;
  max-width: 100;
`;

export const FormInputFull = styled.input`
  font-size: 16px;
  padding: 10px;
  width: 100%;
  max-width: 430px;
  border: 1px solid ${props => props.required === true ? colors.red : colors.grayBorder};
`;

export const FormInputCharCount = styled.div`
  font-size: 13px;
  padding-top: 2px
`;

export const FormInput = styled.input`
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border: 1px solid ${props => props.required === true ? colors.red : colors.grayBorder};
`;

export const FormInputSmall = styled.input`
  font-size: 16px;
  padding: 10px;
  width: 90%;
  border: 1px solid ${props => props.required === true ? colors.red : colors.grayBorder};
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border:1px solid ${colors.grayBorder};
  font-family: Helvetica, Arial;
`;

export const FormLabel = styled.div`
  padding: 5px 0;
  flex: none;
  display: block;
  font-size: 14px;
`;

export const FormSubHeading = styled.div`
  font-weight: bold;
  padding: 10px 0;
  max-width: 430px;
`;

export const FormItalicHeading = styled.div`
  font-style: italic;
  font-size: 15px;
  padding: 5px 0 10px 0;
  color: ${colors.gray300};
  max-width: 430px;
`;

export const FormSectionPadding = styled.div`
  padding: 15px 0;
`;

export const FormFileName = styled.div`
  color: ${colors.gray400};
  font-size: 15px;
  padding-left: 10px;
`;

export const CheckboxStyling = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  input {
    margin-left: 8px;
    width: 16px;
  }
`;