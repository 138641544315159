import axiosInstance from '../utility/axios';

export const startSendMicrositeEmail = () => ({
  type: 'START_SEND_MICROSITE_EMAIL'
});

export const sendMicrositeEmailSuccess = (company) => ({
  type: 'SEND_MICROSITE_EMAIL_SUCCESS',
  company
});

export const sendMicrositeEmail = (company) => {
  console.log('sending microsite email', company);
  return (dispatch) => {
    dispatch(startSendMicrositeEmail());
    axiosInstance.get(`microsite-submission?company=${company}`, company)
      .then((response) => {
        dispatch(sendMicrositeEmailSuccess(response.data));
        console.log('microsite email submission sent');
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

