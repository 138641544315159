import React, {Component} from 'react';
import Logo from '../images/xchange-header.png';
import Logo2x from '../images/xchange-header@2x.png';
import styled from "styled-components";
import colors from "../styles/colors";


import DummyImage from '../images/demo/watson-marlow.png';

const XchangeWrapper = styled.div`
  
`;

const ThreeColumn = styled.div`
  display: flex;
  justify-content: space-around;
  width: 1215px;
`;

const LogoWrapper = styled.div`
  text-align: center;
`;

const LogoImage = styled.img`
  margin: 40px 0;
`;

const ColumnContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const Heading = styled.div`
  background: ${colors.lightGray}
  color: ${colors.white}
  padding: 7px 10px;
  font-weight: bold;
`;

const CompanyImage = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Xchange extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <XchangeWrapper>
        <LogoWrapper>
          <LogoImage src={Logo} alt="" srcSet={`${Logo} 1x, ${Logo2x} 2x`} />
        </LogoWrapper>
        <ThreeColumn>
          <ColumnContainer>
            <Heading>Upstream</Heading>
            <CompanyImage>
              <img src={DummyImage} />
            </CompanyImage>
          </ColumnContainer>
          <ColumnContainer>
            <Heading>Downstream</Heading>
            <CompanyImage>
              <img src={DummyImage} />
            </CompanyImage>
          </ColumnContainer>
          <ColumnContainer>
            <Heading>Fill/Finish</Heading>
            <CompanyImage>
              <img src={DummyImage} />
            </CompanyImage>
          </ColumnContainer>
        </ThreeColumn>
      </XchangeWrapper>
    )};
}

export { Xchange as default};