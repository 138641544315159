const alertJobDefaultState = {
    data: [],
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
};

const alertJobReducer = (state = alertJobDefaultState, action) => {
    switch(action.type) {
        case 'START_GET_ALERT_JOBS':
            return {
                ...state,
                data: [],
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false
            };
        case 'GET_ALERT_JOBS_SUCCESS':
            return {
                ...state,
                data: action.alertJobs,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_ALERT_JOB_SUCCESS':
        return {
            data: action.alertJob,
            loading: false,
            success: true,
            error: false,
            errorMessage: null,
            successMessage: 'Job Alert has been updated',
        };
        case 'START_ALERT_JOB_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_ALERT_JOB_SUCCESS':
            return {
                ...state,
                data: state.data.map((alertJobArray) => {
                    if (alertJobArray.id === action.alertJob.id) {
                        console.log('got match');
                        return {
                            ...alertJobArray,
                            ...action.alertJob /// add object to array
                        }
                    } else {
                        return alertJobArray;
                    }
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Alert News has been updated',
            };
        case 'START_REMOVE_ALERT_JOBS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_ALERT_JOBS_SUCCESS':
            return {
                ...state,
                data: state.data.filter(ad => { // check original posts and only include posts that don't have that to delete ID
                    return !action.adIds.includes(ad.id); // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'ALERT_JOBS_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default alertJobReducer;