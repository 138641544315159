const clientsDefaultState = {
    data: [],
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
  };
  
  const clientsReducer = (state = clientsDefaultState, action) => {
    switch(action.type) {
    case 'START_GET_CLIENTS':
        return {
            ...state,
            loading: true,
            error: false,
            errorMessage: null,
            successMessage: '',
        };
    case 'GET_CLIENTS_SUCCESS':
        return {
            ...state,
            data: action.clients,
            loading: false,
            error: false,
            errorMessage: null,
        };
        case 'CLIENT_CREATED':
        return {
          ...state,
          data: [
            action.clientInfo,
            ...state.data,
          ],
          loading: false,
          success: true,
          error: false,
          errorMessage: null,
          successMessage: 'Client has been added!',
        };
    case 'START_CLIENT_UPDATE':
        return {
            ...state,
            loading: true,
            error: false,
            errorMessage: null,
            successMessage: '',
        };
    case 'CLIENT_UPDATE_SUCCESS':   
        return {
            ...state,
            data: state.data.map((client) => {
                // console.log('client: ', client);
                // console.log('action.client: ', action.clients);
                if (client.id === action.clientInfo.id) {
                  console.log('got match');
                  return {
                    ...client,
                    ...action.clientInfo /// add object to array
                  }
                } else {
                  return client;
                }
              }),
            loading: false,
            success: true,
            error: false,
            errorMessage: null,
            successMessage: '',
        };
    case 'SHOW_ERROR':
        return {
          ...state,
          loading: false,
          error: true,
          successMessage: '',
          errorMessage: action.errorMessage
        };
    default:
        return state;
    }
  };
  
  export default clientsReducer;
  