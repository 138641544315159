import axiosInstance from "../../utility/axios";

export const startGetAlertEvent = () => ({
    type: 'START_GET_ALERT_EVENT',
  });
  
  export const getAlertEventSuccess = (alertEvent) => ({
    type: 'GET_ALERT_EVENT_SUCCESS',
    alertEvent
  });
  
  export const getAlertEvent = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-event/${alertItemId}`;
      console.log('get alertEvent url: ', url);
      dispatch(startGetAlertEvent());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertEvent: ', response.data);
          dispatch(getAlertEventSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertEventCreate = () => ({
    type: 'START_ALERT_EVENT_CREATE',
});

export const createAlertEventSuccess = (alertEvent) => ({
    type: 'CREATE_ALERT_EVENT_SUCCESS',
    alertEvent
});

export const createAlertEvent = (alertEvent) => {
    console.log('do alert event: ', alertEvent);
    return (dispatch) => {
        dispatch(startAlertEventCreate());
        axiosInstance.post(`alert-event`, alertEvent)
            .then((response) => {
                console.log('AlerttEvent Create: ', response.data);
                dispatch(createAlertEventSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertEventUpdate = () => ({
    type: 'START_ALERT_EVENT_UPDATE',
  });
  
  export const updateAlertEventSuccess = (alertEvent) => ({
    type: 'UPDATE_ALERT_EVENT_SUCCESS',
    alertEvent
  });
  
  export const updateAlertEvent = (alertEvent) => {
    console.log('alertEvent: ', alertEvent);
    return (dispatch) => {
      dispatch(startAlertEventUpdate());
      axiosInstance.put(`alert-event/${alertEvent.id}`, alertEvent)
        .then((response) => {
          dispatch(updateAlertEventSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});