import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertFact, updateAlertFact } from '../../../../actions/alert/alert-fact';
import styled from 'styled-components';
import { AlertFact } from "../alert-types";
import Modal from 'react-modal';
import AlertFactForm from "../AlertFactForm";
import { createAlertOtd, updateAlertOtd } from "../../../../actions/alert/alert-otd";
import { createAlertTed, updateAlertTed } from "../../../../actions/alert/alert-ted";
import colors from "../../../../styles/colors";

interface ImageStyleProps {
  width?: number;
}

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const CitationStyle = styled.div`
  /* font-style: italic; */
  color: ${colors.gray300};
  font-size: 12px;
  p {
    padding: 0;
    margin: 0;
  }
`;

interface Props {
  alertItemId: number;
  alertFact: AlertFact;
  contentType: string;
  buttonTitle: string;
  createAlertFact: (alertFact: AlertFact) => void;
  updateAlertFact: (alertFact: AlertFact) => void;
  createAlertOtd: (alertFact: AlertFact) => void;
  updateAlertOtd: (alertFact: AlertFact) => void;
  createAlertTed: (alertFact: AlertFact) => void;
  updateAlertTed: (alertFact: AlertFact) => void;
}

const AlertComponentC: React.FC<Props> = ({ alertItemId, alertFact, contentType, buttonTitle, createAlertFact, updateAlertFact, createAlertOtd, updateAlertOtd, createAlertTed, updateAlertTed }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertFact, setNewAlertFact] = useState<AlertFact>({ id: 0, title: '', body: '', image: '', link: '', imageLink: '', alertItemId: 0, attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertFact: AlertFact) => {
    setNewAlertFact(alertFact);
    setModalIsOpen(true);
  }

  const handleSave = (alertFact: AlertFact) => {
    if (contentType === 'fact') {
      createAlertFact(alertFact);
    }
    if (contentType === 'otd') {
      createAlertOtd(alertFact);
    }
    if (contentType === 'ted') {
      createAlertTed(alertFact);
    }
    closeModal();
  }

  const handleUpdate = (alertFact: AlertFact) => {
    if (contentType === 'fact') {
      updateAlertFact(alertFact);
    }
    if (contentType === 'otd') {
      updateAlertOtd(alertFact);
    }
    if (contentType === 'ted') {
      updateAlertTed(alertFact);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertFact && Object.keys(alertFact).length > 0) ?
          <>
            <TitleStyle>{alertFact.title}</TitleStyle>
            {
              alertFact.imageWidth &&
              <ImageStyle width={alertFact.imageWidth}> <a href={alertFact.link}><img src={`https://${alertFact.image}`} alt="" width={alertFact.imageWidth} /></a></ImageStyle>
            }
            {
              !alertFact.imageWidth &&
              <ImageStyle> <a href={alertFact.link}><img src={`https://${alertFact.image}`} alt="" /></a></ImageStyle>
            }
            {
              alertFact.citation &&
              <CitationStyle><div dangerouslySetInnerHTML={{ __html: alertFact.citation }} /></CitationStyle>
            }
            <BodyStyle><div dangerouslySetInnerHTML={{ __html: alertFact.body }} /></BodyStyle>
            <ButtonContainer>
              <LearnMoreLink><a href={alertFact.link}>Learn More</a></LearnMoreLink>
              <EditButton onClick={() => handleEdit(alertFact)}>Edit</EditButton>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertFactForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertFact={newAlertFact}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertFact: state.alertFact.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertFact: (alertFact: AlertFact) => dispatch(createAlertFact(alertFact)),
  updateAlertFact: (alertFact: AlertFact) => dispatch(updateAlertFact(alertFact)),
  createAlertOtd: (alertFact: AlertFact) => dispatch(createAlertOtd(alertFact)),
  updateAlertOtd: (alertFact: AlertFact) => dispatch(updateAlertOtd(alertFact)),
  createAlertTed: (alertFact: AlertFact) => dispatch(createAlertTed(alertFact)),
  updateAlertTed: (alertFact: AlertFact) => dispatch(updateAlertTed(alertFact)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentC);