const uploadDefaultState = {
  data: [],
  folders: [],
  loading: false,
  uploadLoading: false,
  error: false,
  uploadError: false,
  getFilesSuccess: false,
  getFoldersSuccess: false,
  uploadSuccess: false,
  multipleFilesSuccess: false,
  successMessage: '',
  errorMessage: null,
};

const uploadReducer = (state = uploadDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_FOLDERS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        getFoldersSuccess: false,
        getFilesSuccess: false
      };
    case 'GET_FOLDERS_SUCCESS':
      return {
        ...state,
        folders: action.folders,
        loading: false,
        error: false,
        errorMessage: null,
        getFoldersSuccess: true
      };
    case 'START_GET_FILES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        getFilesSuccess: false
      };
    case 'GET_FILES_SUCCESS':
      return {
        ...state,
        data: action.files,
        loading: false,
        error: false,
        errorMessage: null,
        getFilesSuccess: true
      };
    case 'START_SINGLE_FILE_UPLOAD':
    return {
      ...state,
      uploadLoading: true,
      uploadError: false,
      errorMessage: null,
      uploadSuccess: false,
    };
    case 'SINGLE_FILE_UPLOAD_SUCCESS':
    return {
      ...state,
      data: action.files,
      uploadLoading: false,
      uploadError: false,
      errorMessage: null,
      uploadSuccess: true,
    };
    case 'START_UPLOAD_FILES':
      return {
        ...state,
        uploadLoading: true,
        uploadError: false,
        errorMessage: null,
        uploadSuccess: false,
      };
    case 'UPLOAD_FILES_SUCCESS':
      return {
        ...state,
        data: action.files,
        uploadLoading: false,
        uploadError: false,
        errorMessage: null,
        uploadSuccess: true,
      };
    case 'START_MULTIPLE_UPLOAD_FILES':
      return {
        ...state,
        uploadLoading: true,
        uploadError: false,
        errorMessage: null,
        multipleFilesSuccess: false,
      };
    case 'UPLOAD_MULTIPLE_FILES_SUCCESS':
      return {
        ...state,
        multipleFilesSuccess: true,
        uploadLoading: false,
        uploadError: false,
        errorMessage: null,
        uploadSuccess: true,
      };
    case 'SHOW_UPLOAD_ERROR':
      return {
        ...state,
        uploadLoading: false,
        uploadError: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default uploadReducer;