import axiosInstance from "../../utility/axios";

export const startGetAlertQuote = () => ({
    type: 'START_GET_ALERT_QUOTE',
  });
  
  export const getAlertQuoteSuccess = (alertQuote) => ({
    type: 'GET_ALERT_QUOTE_SUCCESS',
    alertQuote
  });
  
  export const getAlertQuote = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-quote/${alertItemId}`;
      console.log('get alertQuote url: ', url);
      dispatch(startGetAlertQuote());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertQuote: ', response.data);
          dispatch(getAlertQuoteSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertQuoteCreate = () => ({
    type: 'START_ALERT_QUOTE_CREATE',
});

export const createAlertQuoteSuccess = (alertQuote) => ({
    type: 'CREATE_ALERT_QUOTE_SUCCESS',
    alertQuote
});

export const createAlertQuote = (alertQuote) => {
    console.log('do alert quotes: ', alertQuote);
    return (dispatch) => {
        dispatch(startAlertQuoteCreate());
        axiosInstance.post(`alert-quote`, alertQuote)
            .then((response) => {
                dispatch(createAlertQuoteSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertQuoteUpdate = () => ({
    type: 'START_ALERT_QUOTE_UPDATE',
  });
  
  export const updateAlertQuoteSuccess = (alertQuote) => ({
    type: 'UPDATE_ALERT_QUOTE_SUCCESS',
    alertQuote
  });
  
  export const updateAlertQuote = (alertQuote) => {
    console.log('alertQuote: ', alertQuote);
    return (dispatch) => {
      dispatch(startAlertQuoteUpdate());
      axiosInstance.put(`alert-quote/${alertQuote.id}`, alertQuote)
        .then((response) => {
          dispatch(updateAlertQuoteSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});