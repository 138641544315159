import axiosInstance from "../../utility/axios";

export const startGetAlertPatent = () => ({
    type: 'START_GET_ALERT_PATENT',
  });
  
  export const getAlertPatentSuccess = (alertPatent) => ({
    type: 'GET_ALERT_PATENT_SUCCESS',
    alertPatent
  });
  
  export const getAlertPatent = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-patent/${alertItemId}`;
      console.log('get alertPatent url: ', url);
      dispatch(startGetAlertPatent());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertPatent: ', response.data);
          dispatch(getAlertPatentSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertPatentCreate = () => ({
    type: 'START_ALERT_PATENT_CREATE',
});

export const createAlertPatentSuccess = (alertPatent) => ({
    type: 'CREATE_ALERT_PATENT_SUCCESS',
    alertPatent
});

export const createAlertPatent = (alertPatent) => {
    console.log('do alert patent: ', alertPatent);
    return (dispatch) => {
        dispatch(startAlertPatentCreate());
        axiosInstance.post(`alert-patent`, alertPatent)
            .then((response) => {
                console.log('AlerttPatent Create: ', response.data);
                dispatch(createAlertPatentSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertPatentUpdate = () => ({
    type: 'START_ALERT_PATENT_UPDATE',
  });
  
  export const updateAlertPatentSuccess = (alertPatent) => ({
    type: 'UPDATE_ALERT_PATENT_SUCCESS',
    alertPatent
  });
  
  export const updateAlertPatent = (alertPatent) => {
    console.log('alertPatent: ', alertPatent);
    return (dispatch) => {
      dispatch(startAlertPatentUpdate());
      axiosInstance.put(`alert-patent/${alertPatent.id}`, alertPatent)
        .then((response) => {
          dispatch(updateAlertPatentSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});