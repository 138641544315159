import axiosInstance from "../../utility/axios";

export const startGetAlertDownstream = () => ({
    type: 'START_GET_ALERT_DOWNSTREAM',
  });
  
  export const getAlertDownstreamSuccess = (alertDownstream) => ({
    type: 'GET_ALERT_DOWNSTREAM_SUCCESS',
    alertDownstream
  });
  
  export const getAlertDownstream = (alertItemId) => {
    console.log('alert downstream');
    return (dispatch) => {
      let url = `alert-downstream/${alertItemId}`;
      console.log('get alertDownstream url: ', url);
      dispatch(startGetAlertDownstream());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertDownstream: ', response.data);
          dispatch(getAlertDownstreamSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertDownstreamCreate = () => ({
    type: 'START_ALERT_DOWNSTREAM_CREATE',
});

export const createAlertDownstreamSuccess = (alertDownstream) => ({
    type: 'CREATE_ALERT_DOWNSTREAM_SUCCESS',
    alertDownstream
});

export const createAlertDownstream = (alertDownstream) => {
    console.log('do alert downstream: ', alertDownstream);
    return (dispatch) => {
        dispatch(startAlertDownstreamCreate());
        axiosInstance.post(`alert-downstream`, alertDownstream)
            .then((response) => {
                console.log('AlerttDownstream Create: ', response.data);
                dispatch(createAlertDownstreamSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertDownstreamUpdate = () => ({
    type: 'START_ALERT_DOWNSTREAM_UPDATE',
  });
  
  export const updateAlertDownstreamSuccess = (alertDownstream) => ({
    type: 'UPDATE_ALERT_DOWNSTREAM_SUCCESS',
    alertDownstream
  });
  
  export const updateAlertDownstream = (alertDownstream) => {
    console.log('alertDownstream: ', alertDownstream);
    return (dispatch) => {
      dispatch(startAlertDownstreamUpdate());
      axiosInstance.put(`alert-downstream/${alertDownstream.id}`, alertDownstream)
        .then((response) => {
          dispatch(updateAlertDownstreamSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});