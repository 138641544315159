import axiosInstance from "../../utility/axios";

export const startGetAlertAdss = () => ({
  type: 'START_GET_ALERT_ADS',
});

export const getAlertAdsSuccess = (alertAds) => ({
  type: 'GET_ALERT_ADS_SUCCESS',
  alertAds
});

export const getAlertAds = (alertItemId) => {
  return (dispatch) => {
    let url = `alert-ads/${alertItemId}`;
    console.log('get alertAds url: ', url);
    dispatch(startGetAlertAdss());
    axiosInstance.get(url)
      .then((response) => {
        console.log('getAlertAds: ', response.data);
        dispatch(getAlertAdsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertAdsCreate = () => ({
  type: 'START_ALERT_ADS_CREATE',
});

export const createAlertAdsSuccess = (alertAds) => ({
  type: 'CREATE_ALERT_ADS_SUCCESS',
  alertAds
});

export const createAlertAds = (alertAds) => {
  console.log('do alert ads: ', alertAds);
  return (dispatch) => {
    dispatch(startAlertAdsCreate());
    axiosInstance.post(`alert-ads`, alertAds)
      .then((response) => {
        dispatch(createAlertAdsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertAdsUpdate = () => ({
  type: 'START_ALERT_ADS_UPDATE',
});

export const updateAlertAdsSuccess = (alertAds) => ({
  type: 'UPDATE_ALERT_ADS_SUCCESS',
  alertAds
});

export const updateAlertAds = (alertAds) => {
  console.log('alertAds: ', alertAds);
  return (dispatch) => {
    dispatch(startAlertAdsUpdate());
    axiosInstance.put(`alert-ads/${alertAds.id}`, alertAds)
      .then((response) => {
        dispatch(updateAlertAdsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startAlertAdsRemove = () => ({
  type: 'START_REMOVE_ALERT_ADS',
});

export const deleteAlertAdsSuccess = (id) => ({
  type: 'REMOVE_ALERT_ADS_SUCCESS',
  id
});

export const deleteAlertAds = (id) => {
  return (dispatch) => {
    dispatch(startAlertAdsRemove());

    axiosInstance.delete(`alert-ads/${id}`)
      .then((response) => {
        dispatch(deleteAlertAdsSuccess(id));
      })
      .catch((error) => {
        console.error('Error deleting alert ad:', error);
        dispatch(showError(error));
      });
  }
};

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});