import axiosInstance from "../../utility/axios";

export const startGetAlertHumor = () => ({
    type: 'START_GET_ALERT_HUMOR',
  });
  
  export const getAlertHumorSuccess = (alertHumor) => ({
    type: 'GET_ALERT_HUMOR_SUCCESS',
    alertHumor
  });
  
  export const getAlertHumor = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-humor/${alertItemId}`;
      console.log('get alertHumor url: ', url);
      dispatch(startGetAlertHumor());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertHumor: ', response.data);
          dispatch(getAlertHumorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertHumorCreate = () => ({
    type: 'START_ALERT_HUMOR_CREATE',
});

export const createAlertHumorSuccess = (alertHumor) => ({
    type: 'CREATE_ALERT_HUMOR_SUCCESS',
    alertHumor
});

export const createAlertHumor = (alertHumor) => {
    console.log('do alert humors: ', alertHumor);
    return (dispatch) => {
        dispatch(startAlertHumorCreate());
        axiosInstance.post(`alert-humor`, alertHumor)
            .then((response) => {
                dispatch(createAlertHumorSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertHumorUpdate = () => ({
    type: 'START_ALERT_HUMOR_UPDATE',
  });
  
  export const updateAlertHumorSuccess = (alertHumor) => ({
    type: 'UPDATE_ALERT_HUMOR_SUCCESS',
    alertHumor
  });
  
  export const updateAlertHumor = (alertHumor) => {
    console.log('alertHumor: ', alertHumor);
    return (dispatch) => {
      dispatch(startAlertHumorUpdate());
      axiosInstance.put(`alert-humor/${alertHumor.id}`, alertHumor)
        .then((response) => {
          dispatch(updateAlertHumorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});