import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';
import {postDocuments} from './posts';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetJob = () => ({
  type: 'START_GET_JOB',
});

export const getJobSuccess = (singleJob) => ({
  type: 'GET_JOB_SUCCESS',
  singleJob
});

export const getJob = (id) => {
  return (dispatch) => {
    dispatch(startGetJob());
    axiosInstance.get(`job/${id}`)
      .then((response) => {
        if (response.data.job === false) { // no post yet
        } else { // has post
          console.log('response.data: ', response.data);
          dispatch(getJobSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startGetJobs = () => ({
  type: 'START_GET_JOBS',
});

export const getJobsSuccess = (jobs) => ({
  type: 'GET_JOBS_SUCCESS',
  jobs
});

export const getJobs = (queryParams) => {
  return (dispatch) => {
    let url = 'jobs';
    if (queryParams) {
      url = `jobs/${queryParams}`;
    }
    console.log('get jobs url: ', url);
    dispatch(startGetJobs());
    axiosInstance.get(url)
      .then((response) => {
        dispatch(getJobsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreateJob = () => ({
  type: 'START_CREATE_JOB'
});

export const createJobSuccess = (jobDetails) => ({
  type: 'CREATE_JOB_SUCCESS',
  jobDetails
});

export const createJob = (jobDetails) => {
  console.log('creating job', jobDetails);
  return (dispatch) => {
    dispatch(startCreateJob());
    axiosInstance.post('job', jobDetails)
      .then((response) => {
        dispatch(createJobSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startJobUpdate = () => ({
  type: 'START_JOB_UPDATE',
});

export const updateJobSuccess = (jobDetails) => ({
  type: 'UPDATE_JOB_SUCCESS',
  jobDetails
});

export const updateJob = (jobDetails, jobId) => {
  return (dispatch) => {
    dispatch(startJobUpdate());
    axiosInstance.put(`job/${jobId}`, jobDetails)
      .then((response) => {
        dispatch(updateJobSuccess(jobDetails));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startRemoveJobs = () => ({
  type: 'START_REMOVE_JOBS'
});

export const removeJobsSuccess = (jobIds) => ({
  type: 'REMOVE_JOBS_SUCCESS',
  jobIds
});

export const removeJobs = (jobIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: jobIds
    };
    dispatch(startRemoveJobs());
    axiosInstance.delete('jobs', { data: items })
      .then((response) => {
        dispatch(removeJobsSuccess(jobIds));
        console.log('jobs removed!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

