import axiosInstance from '../utility/axios';

export const showBulkUpdateError = (errorMessage) => ({
    type: 'SHOW_BULK_UPDATE_ERROR',
    errorMessage
  });

export const resetBulkUpdateNotifications = () => ({
    type: 'RESET_BULK_UPDATE_NOTIFICATIONS'
});
  
export const startBulkUpdate = () => ({
    type: 'START_BULK_UPDATE',
});

export const bulkUpdateSuccess = (postIds) => ({
    type: 'BULK_UPDATE_SUCCESS',
    postIds
});

export const bulkUpdate = (bulkDetails) => {
    return (dispatch) => {
        dispatch(startBulkUpdate());
        axiosInstance.put(`bulk-update`, bulkDetails)
        .then((response) => {
            dispatch(bulkUpdateSuccess(bulkDetails));
            setTimeout(() => {
                dispatch(resetBulkUpdateNotifications());
            }, 3000)
        })
        .catch((error) => {
            dispatch(showBulkUpdateError(error));
            console.log(error);
            setTimeout(() => {
                dispatch(resetBulkUpdateNotifications());
            }, 3000)
        });
    }
};