const eventsDefaultState = {
  data: [],
  singleEvent: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null
};

const eventsReducer = (state = eventsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_EVENT':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_EVENT_SUCCESS':
      return {
        ...state,
        singleEvent: action.singleEvent,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_GET_EVENTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_EVENTS_SUCCESS':
      return {
        ...state,
        data: action.events,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_EVENT_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_EVENT_SUCCESS':
      return {
        ...state,
        data: state.data.map((event) => {
          // console.log('event: ', event);
          // console.log('action.event: ', action.eventDetails);
          if (event.id === action.eventDetails.id) {
            console.log('got match');
            return {
              ...event,
              ...action.eventDetails /// add object to array
            }
          } else {
            return event;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Event has been updated',
      };
    case 'START_REMOVE_EVENTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_EVENTS_SUCCESS':
      return {
        ...state,
        data: state.data.filter(event => { // check original events and only include events that don't have that to delete ID
          return !action.eventIds.includes(event.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your events have been removed!',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default eventsReducer;