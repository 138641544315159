import axiosInstance from '../utility/axios';
import { history } from '../routers/AppRouter';
import { getUser } from './account-info';
import { startGetProfile } from "./profile";

export const startLoading = () => ({
  type: 'START_SIGNIN_LOADING',
});

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startRetrieveStripeUser = (stripeId) => {
  console.log('stripeId: ', stripeId);
  return (dispatch) => {
    dispatch(startLoading());
    axiosInstance.get(`stripe-customer/${stripeId}`)
      .then((response) => {
        console.log('stripe user retrieved');
        console.log(response);
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};

export const userSignedIn = (token) => ({
  type: 'USER_SIGNED_IN',
  token: token
});

export const startUserLogin = (userInfo, accountCreate, token, fromSignIn) => {
  return (dispatch) => {
    dispatch(startLoading());
    if (!token) {
      axiosInstance.post('user/login', userInfo)
        .then((response) => {
          const token = response.headers.auth;
          axiosInstance.defaults.headers.common['Auth'] = token;
          localStorage.setItem('token', token);
          dispatch(userSignedIn(token));
          dispatch(getUser());
          history.push('/alerts');
          console.log('user logged in!');
        })
        .catch((error) => {
          console.log(error.response);
          if (!accountCreate) {
            if (error.response) {
              if (error.response.status === 401) {
                dispatch(showError('Invalid email or password, please try again.'));
              }
            } else {
              dispatch(showError('Sorry an error occurred, please try again. If the problem persists please contact support.'));
            }
          }
        });
    } else { // token already stored
      axiosInstance.defaults.headers.common['Auth'] = token;
      dispatch(userSignedIn(token));
      dispatch(getUser());
      if (fromSignIn) {
        history.push('/alerts');
      }
    }
  }
};

export const clearUserInfo = () => ({
  type: 'CLEAR_USER_INFO'
});

export const signOut = () => {
  console.log('sign out from dispatch');
  return (dispatch) => {
    localStorage.removeItem('token');
    axiosInstance.defaults.headers.common['Auth'] = undefined;
    dispatch(clearUserInfo());
    history.push('/sign-in');
  }
};