import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors.js';
import ABButton from '../../../components/ABButton.js';
import { history } from '../../../routers/AppRouter.js';
import ABInputField from '../../../components/ABInputField';
import { AlertPoll } from './alert-types.js';

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  min-height: 900px;
`;

const FormFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 750px;
`;

const AlertFormLeft = styled.div`
    /* width: 360px; */
`;

const AlertFormRight = styled.div`
    /* width: 360px; */
    padding-left: 40px;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 0 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px;
`;

interface AlertPollProps {
    alertItemId: number;
    pageTitle: string;
    handleSave: (alertPoll: AlertPoll) => void;
    handleUpdate: (alertPoll: AlertPoll) => void;
    alertPoll: AlertPoll
}

const AlertPollForm: React.FC<AlertPollProps> = ({ alertPoll, alertItemId, pageTitle, handleSave, handleUpdate }) => {

    const [id, setId] = useState(0);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [attribution, setAttribution] = useState('');
    const [clientId, setClientId] = useState(0);
    const [editing, setEditing] = useState(false);

    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [answer5, setAnswer5] = useState('');
    const [answer6, setAnswer6] = useState('');

    useEffect(() => {
        // check if editing
        if (alertPoll.id !== 0) {
            setId(alertPoll.id);
            setEditing(true);
            setTitle(alertPoll.title);
            setLink(alertPoll.link);
            setAttribution(alertPoll.attribution);
            setClientId(alertPoll.clientId || 0)
            setAnswer1(alertPoll.answer1);
            setAnswer2(alertPoll.answer2);
            setAnswer3(alertPoll.answer3 || '');
            setAnswer4(alertPoll.answer4 || '');
            setAnswer5(alertPoll.answer5 || '');
            setAnswer6(alertPoll.answer6 || '');
        }
    }, [])

    const onSave = () => {

        const alertPoll: AlertPoll = {
            id,
            title,
            link,
            answer1,
            answer2,
            answer3,
            answer4,
            answer5,
            answer6,
            alertItemId: alertItemId,
            attribution,
            clientId
        }

        if (editing) {
            handleUpdate(alertPoll);
        } else {
            handleSave(alertPoll);
        }
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const title = e.currentTarget.value;
        setTitle(title);
    };

    const handleAnswer1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer1 = e.currentTarget.value;
        setAnswer1(answer1);
    };

    const handleAnswer2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer2 = e.currentTarget.value;
        setAnswer2(answer2);
    };

    const handleAnswer3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer3 = e.currentTarget.value;
        setAnswer3(answer3);
    };

    const handleAnswer4Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer4 = e.currentTarget.value;
        setAnswer4(answer4);
    };

    const handleAnswer5Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer5 = e.currentTarget.value;
        setAnswer5(answer5);
    };

    const handleAnswer6Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const answer6 = e.currentTarget.value;
        setAnswer6(answer6);
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const link = e.currentTarget.value;
        setLink(link);
    };

    const handleAttributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const attribution = e.currentTarget.value;
        setAttribution(attribution);
    };

    const handleClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const clientId = parseInt(e.currentTarget.value, 10);
        setClientId(clientId);
    };

    return (
        <>
            <PageWrapper>
                <PageHeading>
                    <PageTitle>{pageTitle}</PageTitle>
                </PageHeading>

                <FormFlex>
                    <AlertFormLeft>
                        <FormSubHeading>Title:</FormSubHeading>
                        <ABInputField
                            value={title}
                            onChange={handleTitleChange}
                            placeholder="Title"
                        />

                        <FormSubHeading>Answer1:</FormSubHeading>
                        <ABInputField
                            value={answer1}
                            onChange={handleAnswer1Change}
                            placeholder="Answer1"
                        />

                        <FormSubHeading>Answer2:</FormSubHeading>
                        <ABInputField
                            value={answer2}
                            onChange={handleAnswer2Change}
                            placeholder="Answer2"
                        />

                        <FormSubHeading>Answer3:</FormSubHeading>
                        <ABInputField
                            value={answer3}
                            onChange={handleAnswer3Change}
                            placeholder="Answer3"
                        />

                        <FormSubHeading>Answer4:</FormSubHeading>
                        <ABInputField
                            value={answer4}
                            onChange={handleAnswer4Change}
                            placeholder="Answer4"
                        />
                    </AlertFormLeft>

                    <AlertFormRight>
                        <FormSubHeading>Answer5:</FormSubHeading>
                        <ABInputField
                            value={answer5}
                            onChange={handleAnswer5Change}
                            placeholder="Answer5"
                        />

                        <FormSubHeading>Answer6:</FormSubHeading>
                        <ABInputField
                            value={answer6}
                            onChange={handleAnswer6Change}
                            placeholder="Answer6"
                        />

                        <FormSubHeading>Participate Link:</FormSubHeading>
                        <ABInputField
                            value={link}
                            onChange={handleLinkChange}
                            placeholder="Link"
                        />

                        <FormSubHeading>Attribution Tracking:</FormSubHeading>
                        <ABInputField
                            value={attribution}
                            onChange={handleAttributionChange}
                            placeholder="Attribution"
                        />
                    </AlertFormRight>
                </FormFlex>

                {/* <FormSubHeading>Client ID:</FormSubHeading>
                <ABInputField
                    value={clientId || 0}
                    onChange={handleClientIdChange}
                    placeholder="Client ID"
                /> */}

                <ActionButtons>
                    <RightButton>
                        <ABButton title="Save" onClick={() => onSave()} />
                    </RightButton>
                </ActionButtons>
            </PageWrapper>
        </>
    );
}

export default AlertPollForm;
