const postKeywordsDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const postKeywordsReducer = (state = postKeywordsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_POST_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_POST_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: action.postKeywords,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_CREATE_POST_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'CREATE_POST_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: action.postKeyword,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your account has been created!',
      };
    case 'START_UPDATE_POST_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_POST_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.postKeyword
        },
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default postKeywordsReducer;
