const alertNewsDefaultState = {
    data: [],
    singleAd: {},
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
};

const alertNewsReducer = (state = alertNewsDefaultState, action) => {
    switch (action.type) {
        case 'START_GET_ALERT_NEWS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false,
            };
        case 'GET_ALERT_NEWS_SUCCESS':
            return {
                ...state,
                data: action.alertNews,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_ALERT_NEWS_SUCCESS':
            return {
                data: [
                    ...state.data,
                    action.alertNews
                ],
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'News Alert has been updated',
            };
        case 'START_ALERT_NEWS_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_ALERT_NEWS_SUCCESS':
            return {
                ...state,
                data: state.data.map((alertNewsArray) => {
                    if (alertNewsArray.id === action.alertNews.id) {
                        console.log('got match');
                        return {
                            ...alertNewsArray,
                            ...action.alertNews /// add object to array
                        }
                    } else {
                        return alertNewsArray;
                    }
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Alert News has been updated',
            };
        case 'START_REMOVE_ALERT_NEWS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_ALERT_NEWS_SUCCESS':
            return {
                ...state,
                data: state.data.filter(item => { // check original posts and only include posts that don't have that to delete ID
                    return action.id !== item.id; // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'ALERT_NEWS_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default alertNewsReducer;