const alertTedDefaultState = {
    data: {},
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
};

const alertTedReducer = (state = alertTedDefaultState, action) => {
    switch(action.type) {
        case 'START_GET_ALERT_TED':
            return {
                ...state,
                data: {},
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false
            };
        case 'GET_ALERT_TED_SUCCESS':
            return {
                ...state,
                data: action.alertTed,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_ALERT_TED_SUCCESS':
        return {
            data: action.alertTed,
            loading: false,
            success: true,
            error: false,
            errorMessage: null,
            successMessage: 'Ted Alert has been updated',
        };
        case 'START_ALERT_TED_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_ALERT_TED_SUCCESS':
            return {
                ...state,
                data: action.alertTed,
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Alert News has been updated',
            };
        case 'START_REMOVE_ALERT_TEDS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_ALERT_TEDS_SUCCESS':
            return {
                ...state,
                data: state.data.filter(ad => { // check original posts and only include posts that don't have that to delete ID
                    return !action.adIds.includes(ad.id); // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'ALERT_TEDS_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default alertTedReducer;