import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {history} from "../../../routers/AppRouter";
import moment from 'moment';
import styled from 'styled-components'
import { useTable, useRowSelect } from 'react-table';
import {connect} from "react-redux";
import colors from '../../../styles/colors';

import MainButton from '../../../components/MainButton';
import {getMediaImages, removeMediaImages} from '../../../actions/media-images';
import {FormInput} from '../../../styles/form-styles';
import ABButton from '../../../components/ABButton';
import ABSmallButton from '../../../components/ABSmallButton';
import Modal from 'react-modal';
import MediaImage from './MediaImage';

import ReactSVG from 'react-svg';
import PDFIcon from './pdf-icon.svg';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const PageContainer = styled.div`
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const Title = styled.div`
  cursor: pointer;
  color: ${colors.blue}
  text-decoration: underline;
`;

const SearchBarPadding = styled.div`
  padding: 25px 0 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TitleInput = styled.div`
  margin-right: 25px;
  width: 280px;
`;

const TagInput = styled.div`
  margin-left: 15px;
  width: 280px;
  margin-right: 25px;
`;

const CategoryInput = styled.div`
  margin-left: 15px;
  width: 280px;
`;

const SearchButton = styled.div`
  margin-left: 50px;
`;

const BlueLink = styled.div`
  cursor: pointer;
  color: ${colors.blue};
  padding-left: 20px;
`;

const ImageContainer = styled.div`
  text-align: center;
  cursor: pointer;
  img {
    max-width: 140px;
    max-height: 140px;
  }
  :hover {
    opacity: .7;
  }
`;

const Pagination = styled.div`
  padding: 20px 0;
  text-align: right;
`;

const SmallTitle = styled.div`
  font-size: 18px;
  padding: 10px 0;
`;

const StyledSVG = styled(ReactSVG)`
  width: 35px;
  text-align: center;
  display: inline-block;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const uploadMultipleImages = () => {
  window.open("https://myaspenbrook.com/media-library/multiple-upload");
  // history.push('/media-library/multiple-upload')
};


function Table({ columns, data, deleteSelectedMediaImages, onTitleSearchChange, onTagSearchChange, onCategorySearchChange, onSearchFilter, showImageView, nextPage, previousPage, pageCount, mediaImages, pageLimit, modalOpen, closeModal, mediaImageId, createPost }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  // Render the UI for your table
  return (
    <PageContainer>
      <HeadingContainer>
        <StyledHeading>Media Library</StyledHeading>
        <MainButton title="Add Single Image" onClick={() => createPost()} />
        <MainButton title="Add Multiple Images" onClick={() => uploadMultipleImages()} />
        <MainButton title="Delete Images" onClick={() => deleteSelectedMediaImages(selectedFlatRows)} />
      </HeadingContainer>
      <SearchBarPadding>
        <TitleInput>
          <FormInput placeholder="Title/Name" onChange={(e) => onTitleSearchChange(e)} onKeyDown={(e) => onSearchFilter(e)} />
        </TitleInput>
        <TagInput>
          <FormInput placeholder="Tag" onChange={(e) => onTagSearchChange(e)} onKeyDown={(e) => onSearchFilter(e)} />
        </TagInput>
        <CategoryInput>
          <FormInput placeholder="Category" onChange={(e) => onCategorySearchChange(e)} onKeyDown={(e) => onSearchFilter(e)} />
        </CategoryInput>
        <SearchButton>
          <ABSmallButton title="Filter" onClick={() => onSearchFilter()} />
        </SearchButton>
        <BlueLink onClick={() => showImageView()}>Show Image View</BlueLink>
      </SearchBarPadding>
      <Pagination>
        {
          pageCount !== 0 &&
          <MainButton onClick={() => previousPage()} title="Previous Page" />
        }
        {
          mediaImages.length > pageLimit - 1 &&
          <MainButton onClick={() => nextPage()} title="Next Page" />
        }
        <SmallTitle>Page: {pageCount + 1}</SmallTitle>
      </Pagination>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, pageLimit).map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      <Pagination>
        {
          pageCount !== 0 &&
          <MainButton onClick={() => previousPage()} title="Previous Page" />
        }
        {
          mediaImages.length > 49 &&
          <MainButton onClick={() => nextPage()} title="Next Page" />
        }
        <SmallTitle>Page: {pageCount + 1}</SmallTitle>
        {/*<p>Selected Rows: {Object.keys(selectedRowIds).length}</p>*/}
        {/*<pre>*/}
          {/*<code>*/}
            {/*{JSON.stringify(*/}
              {/*{*/}
                {/*selectedRowIds: selectedRowIds,*/}
                {/*'selectedFlatRows[].original': selectedFlatRows.map(*/}
                  {/*d => d.original*/}
                {/*),*/}
              {/*},*/}
              {/*null,*/}
              {/*2*/}
            {/*), []}*/}
          {/*</code>*/}
        {/*</pre>*/}
      </Pagination>
      <ToastContainer />
      <Modal
        isOpen={modalOpen || false}
        onRequestClose={() => closeModal()}
        contentLabel="Sort"
        className="PostEditModal"
        overlayClassName="PostEditOverlay"
        ariaHideApp={false}
      >
        <MediaImage mediaImageId={mediaImageId} closeModal={closeModal} />
      </Modal>
    </PageContainer>
  )
}

function MediaLibrary(props) {

  const [title, setTitle] = useState('');
  const [tag, setTag] = useState('');
  const [category, setCategory] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const pageLimit = 50;

  const [modalOpen, setModalOpen] = useState(false);
  const [mediaImageId, setMediaImageId] = useState(null);

  //used to get the old previous value to make props update
  const deleteError = useSelector(store => store.mediaImages.deleteError);
  const deleteSuccess = useSelector(store => store.mediaImages.deleteSuccess);

  const showImageView = () => {
    history.push('/media-library-image-view');
  };

  // had to be in here to get be able to fire props dispatch
  const deleteSelectedMediaImages = (selectedRows) => {
    let adIdsToDelete = [];
    selectedRows.map((item) => {
      adIdsToDelete.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    props.removeMediaImages(adIdsToDelete);
  };

  const onTitleSearchChange = (e) => {
    const title = e.target.value;
    setTitle(title);
  };

  const onTagSearchChange = (e) => {
    const title = e.target.value;
    setTag(title);
  };

  const onCategorySearchChange = (e) => {
    const category = e.target.value;
    setCategory(category);
  };

  const getTags = (mediaTags) => {
    let tags = [];
    if (mediaTags && mediaTags.length > 0) {
      mediaTags.map((item) => {
        tags.push(item.name);
      })
    }
    let tagsWithSpace = tags.join(', ');
    return tagsWithSpace.toString();
  };

  const onSearchFilter = (e) => {

    if (e) { // if a key was selected
      if (e.key === 'Enter') {
        doSearch();
      } else if (e.key !== 'Enter') {
        // do nothing if any other key was selected
      }
    } else { // if the filter button was selected and no a key
      doSearch();
    }

  };

  const doSearch = () => {
    if (title.length > 1 && tag.length > 1 && category.length > 1) {
      props.getMediaImages(title, tag, pageLimit, pageCount, category);
    } else if (title.length > 1 && tag.length > 1) {
      props.getMediaImages(title, tag, pageLimit, pageCount);
    } else if (title.length > 1 && category.length > 1) {
      props.getMediaImages(title, null, pageLimit, pageCount, category);
    } else if (tag.length > 1 && category.length > 1) {
      props.getMediaImages(null, tag, pageLimit, pageCount, category);
    } else if (title.length > 1) {
      props.getMediaImages(title, null, pageLimit, pageCount);
    } else if (tag.length > 1) {
      props.getMediaImages(null, tag, pageLimit, pageCount);
    } else if (category.length > 1) {
      props.getMediaImages(null, null, pageLimit, pageCount, category);
    } else {
      props.getMediaImages(null, null, pageLimit, pageCount);
    }
  }

  const handlePagination = (newPageCount) => {
    if (title.length > 1 && tag.length > 1 && tag.category > 1) {
      props.getMediaImages(title, tag, pageLimit, pageCount, category);
    } else if (title.length > 1 && category.length > 1) {
      props.getMediaImages(title, null, pageLimit, pageCount, category);
    } else if (tag.length > 1 && category.length > 1) {
      props.getMediaImages(null, tag, pageLimit, pageCount, category);
    } else if (title.length > 1 && tag.length > 1) {
      props.getMediaImages(title, tag, pageLimit, newPageCount + pageLimit);
    } else if (title.length > 1) {
      props.getMediaImages(title, null, pageLimit, newPageCount + pageLimit);
    } else if (tag.length > 1) {
      props.getMediaImages(null, tag, pageLimit, newPageCount + pageLimit);
    } else {
      props.getMediaImages(null, null, pageLimit, newPageCount);
    }
  }

  const createPost = () => {
    // window.open("https://myaspenbrook.com/media-image", "_blank");
    // history.push('/media-image');
    setMediaImageId(null);
    setModalOpen(true);
  };

  const handleEditRow = (id) => {
    setModalOpen(true);
    setMediaImageId(id);
    // window.open(`https://myaspenbrook.com/media-image/${id}, "_blank"`);
    // history.push(`media-image/${id}`);
  };

  const closeModal = (reload) => {
    setModalOpen(false);
    setTimeout(() => {
      if (reload) {
        props.getMediaImages(null, null, pageLimit, pageCount);
        setPageCount(0)
      }
    }, 2000)
  };

  const nextPage = () => {
    if (props.mediaImages.length > pageLimit - 1) {
      setPageCount(pageCount + pageLimit);
      handlePagination(pageCount + pageLimit);
    }
  };

  const previousPage = () => {
    if (pageCount !== 0) {
      setPageCount(pageCount - pageLimit);
      handlePagination(pageCount - pageLimit);
    }
  };

  useEffect(() => {
    props.getMediaImages(null, null, pageLimit, pageCount);

    // checks if there is a state change on the delete error
    const newErrorValue = deleteError;
    if (newErrorValue) {
      toast.error('Error Deleting Files');
    }

    // checks if there is a state change on delete success
    const newSuccessValue = deleteSuccess;
    if (newSuccessValue) {
      toast.success('Files Successfully Deleted');
    }

  }, [deleteError, deleteSuccess]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Media Library',
        columns: [
          {
            Header: 'Image',
            accessor: data => {
              return <ImageContainer onClick={() => handleEditRow(data.id)}>
                {
                  !data.url.includes('pdf') ?
                  <img src={`https://${data.url}`} /> : <StyledSVG src={PDFIcon} />
                }
              </ImageContainer>;
            },
          },
          {
            Header: 'Title',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.title}</Title>;
            },
          },
          {
            Header: 'URL',
            accessor: d => {
              return `https://${d.url}`;
            }
          },
          {
            Header: 'Date',
            accessor: d => {
              return moment(d.createdAt).format('MMM. Do, YYYY');
            }
          },
          {
            Header: 'Category',
            accessor: 'category',
          },
          {
            Header: 'Folder',
            accessor: 'folder',
          },
          {
            Header: 'Tags',
            accessor: data => {
              return <div>{getTags(data.mediaTags)}</div>;
            },
          },
          {
            Header: 'Alt Text',
            accessor: 'altText',
          },
          {
            Header: 'User ID',
            accessor: 'userId',
          }
        ],
      },
    ],
    []
  );

  return (
    <Styles>
      {
        <Table
          columns={columns}
          data={props.mediaImages}
          deleteSelectedMediaImages={deleteSelectedMediaImages}
          onTitleSearchChange={onTitleSearchChange}
          onTagSearchChange={onTagSearchChange}
          onCategorySearchChange={onCategorySearchChange}
          onSearchFilter={onSearchFilter}
          showImageView={showImageView}
          nextPage={nextPage}
          previousPage={previousPage}
          pageCount={pageCount / pageLimit}
          mediaImages={props.mediaImages}
          pageLimit={pageLimit}
          modalOpen={modalOpen}
          closeModal={closeModal}
          mediaImageId={mediaImageId}
          createPost={createPost}
        />
      }
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    mediaImages: state.mediaImages.data,
    success: state.mediaImages.success,
    deleteError: state.mediaImages.deleteError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaImages: (title, tag, limit, skip, category) => dispatch(getMediaImages(title, tag, limit, skip, category)),
  removeMediaImages: (idsToDelete) => dispatch(removeMediaImages(idsToDelete)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibrary);
