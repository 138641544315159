import React from 'react';
import styled from "styled-components";
import colors from "../../../styles/colors";
import { NavLink } from 'react-router-dom';
import ABSmallButton from "../../../components/ABSmallButton";
import ABFormButton from "../../../components/ABFormButton";

const PageWrapper = styled.div`
  padding: 20px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const LinkTitle = styled.div`
  padding: 10px 0;
  font-size: 20px;
  display: flex;
  align-items: center;

  span {
    width: 150px;
  }
`;

const AnnexPages = (props) =>  {
    return (
        <PageWrapper>
            <HeadingContainer>
                <StyledHeading>Edit Annex Track Pages</StyledHeading>
            </HeadingContainer>
            <div>
                <LinkTitle>
                    <span>Bioprocess</span>
                    <NavLink
                        to={{
                            pathname:"/annex/bioprocess",
                            pageProps: {
                                title: 'bioprocess'
                            }
                        }}
                    >
                        <ABFormButton title="Edit" />
                    </NavLink>
                </LinkTitle>
                <LinkTitle>
                    <span>Upstream</span>
                    <NavLink
                        to={{
                            pathname:"/annex/upstream",
                            pageProps: {
                                title: 'upstream'
                            }
                        }}
                    >
                        <ABFormButton title="Edit" />
                    </NavLink>
                </LinkTitle>
                <LinkTitle>
                    <span>Downstream</span>
                <NavLink
                        to={{
                            pathname:"/annex/downstream",
                            pageProps: {
                                title: 'downstream'
                            }
                        }}
                    >
                        <ABFormButton title="Edit" />
                    </NavLink>
                </LinkTitle>
                <LinkTitle>
                    <span>Fill Finish</span>
                    <NavLink
                        to={{
                            pathname:"/annex/fill-finish",
                            pageProps: {
                                title: 'fill-finish'
                            }
                        }}
                    >
                        <ABFormButton title="Edit" />
                    </NavLink>
                </LinkTitle>
            </div>
        </PageWrapper>
    );
};

export default AnnexPages;