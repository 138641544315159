export function stringTruncate(string, length) {
  if (string.length > length) {
    return `${string.substring(0, length)}...`;
  } else {
    return `${string.substring(0, length)}`;
  }
}

export function stringTruncateLearnMore(string, length, link) {
  if (string.length > length) {
    return `${string.substring(0, length)}... <a href=${link}>Learn More</a>`;
  } else {
    return `${string.substring(0, length)}`;
  }
}

export function shortenFileName(longFileName) {
  let fileExtension = longFileName.split('.').pop();
  let fileName = longFileName.replace(/^.*[\\\/]/, '');
  fileName = fileName.substring(0, fileName.indexOf('-'));
  return `${fileName}.${fileExtension}`;
};

export function stringLinkFix(linkString) {
  if (!/^https?:\/\//i.test(linkString)) {
    return 'http://' + linkString;
  } else {
    return linkString;
  }
}

export function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}
