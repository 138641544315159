import axiosInstance from "../../utility/axios";

export const startGetAlertBioprocess = () => ({
    type: 'START_GET_ALERT_BIOPROCESS',
  });
  
  export const getAlertBioprocessSuccess = (alertBioprocess) => ({
    type: 'GET_ALERT_BIOPROCESS_SUCCESS',
    alertBioprocess
  });
  
  export const getAlertBioprocess = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-bioprocess/${alertItemId}`;
      console.log('get alertBioprocess url: ', url);
      dispatch(startGetAlertBioprocess());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertBioprocess: ', response.data);
          dispatch(getAlertBioprocessSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertBioprocessCreate = () => ({
    type: 'START_ALERT_BIOPROCESS_CREATE',
});

export const createAlertBioprocessSuccess = (alertBioprocess) => ({
    type: 'CREATE_ALERT_BIOPROCESS_SUCCESS',
    alertBioprocess
});

export const createAlertBioprocess = (alertBioprocess) => {
    console.log('do alert bioprocess: ', alertBioprocess);
    return (dispatch) => {
        dispatch(startAlertBioprocessCreate());
        axiosInstance.post(`alert-bioprocess`, alertBioprocess)
            .then((response) => {
                console.log('AlerttBioprocess Create: ', response.data);
                dispatch(createAlertBioprocessSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertBioprocessUpdate = () => ({
    type: 'START_ALERT_BIOPROCESS_UPDATE',
  });
  
  export const updateAlertBioprocessSuccess = (alertBioprocess) => ({
    type: 'UPDATE_ALERT_BIOPROCESS_SUCCESS',
    alertBioprocess
  });
  
  export const updateAlertBioprocess = (alertBioprocess) => {
    console.log('alertBioprocess: ', alertBioprocess);
    return (dispatch) => {
      dispatch(startAlertBioprocessUpdate());
      axiosInstance.put(`alert-bioprocess/${alertBioprocess.id}`, alertBioprocess)
        .then((response) => {
          dispatch(updateAlertBioprocessSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});