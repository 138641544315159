import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';
import {createMicrositeSubmission} from './microsite-submission';
import {history} from '../routers/AppRouter';
import {createMicrositeSetting} from './microsite-settings';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrosite = () => ({
  type: 'START_GET_MICROSITE',
});

export const getMicrositeSuccess = (microsite) => ({
  type: 'GET_MICROSITE_SUCCESS',
  microsite
});

export const getMicrosite = (id) => {
  return (dispatch) => {
    dispatch(startGetMicrosite());
    axiosInstance.get(`microsite/${id}`)
      .then((response) => {
        console.log('getting microsite: ', response);
        dispatch(getMicrositeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreateMicrosite = () => ({
  type: 'START_CREATE_MICROSITE'
});

export const createMicrositeSuccess = (micrositeDetails) => ({
  type: 'CREATE_MICROSITE_SUCCESS',
  micrositeDetails
});

export const createMicrosite = (micrositeDetails, userId) => {
  console.log('creating microsite', micrositeDetails);
  let url = 'microsite';
  if (userId) {
    url = `microsite/${userId}`
  }
  return (dispatch) => {
    dispatch(startCreateMicrosite());
    axiosInstance.post(url, micrositeDetails)
      .then((response) => {
        // create microsite submission status
        let micrositeSubmission = {};
        dispatch(createMicrositeSubmission(micrositeSubmission, response.data.id));
        let micrositeSetting = {
          templateType: "standard",
          status: "draft"
        };
        dispatch(createMicrositeSetting(micrositeSetting, response.data.id));
        // history.push(`microsite/${response.data.id}`);
        dispatch(createMicrositeSuccess(response.data));
        console.log('Microsite created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startMicrositeUpdate = () => ({
  type: 'START_MICROSITE_UPDATE',
});

export const updateMicrositeSuccess = (micrositeDetails) => ({
  type: 'UPDATE_MICROSITE_SUCCESS',
  micrositeDetails
});

export const updateMicrosite = (micrositeDetails, micrositeId, multipleCloudFrontUrls, featureProducts, gallery) => {
  return (dispatch) => {
    dispatch(startMicrositeUpdate());
    console.log('micrositeDetails: ', micrositeDetails);
    axiosInstance.put(`microsite/${micrositeId}`, micrositeDetails)
      .then((response) => {
        dispatch(updateMicrositeSuccess(response.data));
        console.log('in updateMicrosite micrositeId: ', micrositeId);
        console.log('in updateMicrosite multipleCloudFrontUrls: ', multipleCloudFrontUrls);
        if (featureProducts) {
          dispatch(postMicrositeFeaturedProducts(micrositeId, multipleCloudFrontUrls));
        }
        if (gallery) {
          dispatch(postMicrositeMediaItems(micrositeId, multipleCloudFrontUrls));
        }
      })
      .catch((error) => {
        dispatch(showError('Error Saving Microsite'));
        console.log(error);
      });
  }
};

export const postMicrositeFeaturedProducts = (micrositeId, multipleCloudFrontUrls) => {
  return (dispatch) => {
    console.log('postDocuments micrositeId', micrositeId);
    console.log('postDocuments multipleCloudFrontUrls', multipleCloudFrontUrls);

    let formattedDocumentURLs = {
      productImages: []
    };

    multipleCloudFrontUrls.map((item) => {
      let imageItem = {
        imageUrl: item
      };
      formattedDocumentURLs.productImages.push(imageItem);
    });

    axiosInstance.post(`microsite-featured-products/${micrositeId}`, formattedDocumentURLs)
      .then((response) => {
        // added documents
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const postMicrositeMediaItems = (micrositeId, multipleCloudFrontUrls) => {
  return (dispatch) => {
    console.log('mediaGallery micrositeId', micrositeId);
    console.log('mediaGallery multipleCloudFrontUrls', multipleCloudFrontUrls);

    let formattedDocumentURLs = {
      mediaItems: []
    };

    multipleCloudFrontUrls.map((item) => {
      let imageItem = {
        imageUrl: item
      };
      formattedDocumentURLs.mediaItems.push(imageItem);
    });

    axiosInstance.post(`microsite-media-items/${micrositeId}`, formattedDocumentURLs)
      .then((response) => {
        // added documents
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

