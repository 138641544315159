const publicProfileDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const publicProfileReducer = (state = publicProfileDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_PUBLIC_PROFILE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_PUBLIC_PROFILE_SUCCESS':
      return {
        ...state,
        data: action.profile,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'SHOW_PUBLIC_PROFILE_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default publicProfileReducer;