import React from 'react';
import styled from 'styled-components';
import colors from "../styles/colors";

const Checkbox = (props) => {
  const name = props.name;
  return(
    <>
      <input type="checkbox" name={name} value={name} onChange={props.onChange} checked={props.checked} /> {name}<br />
    </>
  );
};

export default Checkbox;