import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import Linkedin from './images/linkedin.svg';
import Twitter from './images/twitter.svg';
import colors from './styles/colors';

const CustomHeader = styled.div`
  font-size: 1.5em;
  background: ${colors.blue};
  font-size: 0;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
`;

const SocialSVG = styled(ReactSVG)`
  padding: 7px 15px 9px 0px;
  display: inline-block;
`;

export default function Header() {
  return (
    <CustomHeader>
      <div></div>
      <div>
        <a href="https://www.linkedin.com/in/lewest/" target="_blank">
          <SocialSVG
            src={Linkedin}
            className="wrapper-class-name"
            onClick={() => {
              console.log('wrapper onClick')
            }}
          />
        </a>
        <a href="https://twitter.com/aspenalert" target="_blank">
          <SocialSVG
            src={Twitter}
            className="wrapper-class-name"
            onClick={() => {
              console.log('wrapper onClick')
            }}
          />
        </a>
      </div>
    </CustomHeader>
  );
}