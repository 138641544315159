import axios from 'axios'; // regular axios
import axiosInstance from '../utility/axios';
import {uploadMediaImage} from './media-images'; // my custom axios

export const showUploadError = (errorMessage) => ({
  type: 'SHOW_UPLOAD_ERROR',
  errorMessage
});

export const startMultipleMediaUpload = () => ({
  type: 'START_MULTIPLE_MEDIA_IMAGE_UPLOAD'
});

export const uploadMultipleMediaImageSuccess = (completed) => ({
  type: 'UPLOAD_MULTIPLE_MEDIA_IMAGE_SUCCESS',
  completed
});

export const saveMultipleMediaImages = (files, subfolder, mainFolder) => async dispatch => {
  dispatch(startMultipleMediaUpload());
  let fileKeys = [];
  let fileNames = [];

  for (let file of files) {
    let body = {
      type: file.type,
      mainFolder: mainFolder,
      subFolder: subfolder,
      name: file.name.split('.').slice(0, -1).join('.'),
      extension: file.name.substr(file.name.lastIndexOf('.') + 1)
    };
    const uploadConfig = await axiosInstance.post('upload/?bucket=my-aspen-brook', body);

    console.log('media library file upload success');

    try {
      await axios.put(uploadConfig.data.url, file, {
        headers: {
          'Content-Type': file.type
        }
      });
    } catch (error) {
      dispatch(showUploadError());
      return;
    }

    fileKeys.push(uploadConfig.data.key);
    let cloudFrontImageLink = `dir80cd32z1m2.cloudfront.net/${uploadConfig.data.key}`;
    fileNames.push(cloudFrontImageLink);

    //after getting the cloudfront url save each ones media image settings and tags
    let mediaImageDetails = {
      url: cloudFrontImageLink,
      folder: 'media-library',
    };

    let mediaTags = [];

    dispatch(uploadMediaImage(mediaImageDetails, mediaTags));

  }

  dispatch(uploadMultipleMediaImageSuccess(true));

  return fileNames;

};