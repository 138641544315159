import React from "react";
import styled from 'styled-components';

interface Props {
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    placeholder: string;
}

const StyledSelect = styled.select`
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

const ABSelect: React.FC<Props> = ({ value, onChange, placeholder }) => {
    return (
        <StyledSelect
            value={value}
            onChange={onChange}
        >
            <option value="" disabled hidden>{placeholder}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
        </StyledSelect>
    );
}

export default ABSelect;
