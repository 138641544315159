import axiosInstance from "../../utility/axios";

export const startGetAlertPreviousSurvey = () => ({
    type: 'START_GET_ALERT_PREVIOUS_SURVEY',
  });
  
  export const getAlertPreviousSurveySuccess = (alertPreviousSurvey) => ({
    type: 'GET_ALERT_PREVIOUS_SURVEY_SUCCESS',
    alertPreviousSurvey
  });
  
  export const getAlertPreviousSurvey = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-previous-survey/${alertItemId}`;
      console.log('get alertPreviousSurvey url: ', url);
      dispatch(startGetAlertPreviousSurvey());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertPreviousSurvey: ', response.data);
          dispatch(getAlertPreviousSurveySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertPreviousSurveyCreate = () => ({
    type: 'START_ALERT_PREVIOUS_SURVEY_CREATE',
});

export const createAlertPreviousSurveySuccess = (alertPreviousSurvey) => ({
    type: 'CREATE_ALERT_PREVIOUS_SURVEY_SUCCESS',
    alertPreviousSurvey
});

export const createAlertPreviousSurvey = (alertPreviousSurvey) => {
    console.log('do alert previous-surveys: ', alertPreviousSurvey);
    return (dispatch) => {
        dispatch(startAlertPreviousSurveyCreate());
        axiosInstance.post(`alert-previous-survey`, alertPreviousSurvey)
            .then((response) => {
                dispatch(createAlertPreviousSurveySuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertPreviousSurveyUpdate = () => ({
    type: 'START_ALERT_PREVIOUS_SURVEY_UPDATE',
  });
  
  export const updateAlertPreviousSurveySuccess = (alertPreviousSurvey) => ({
    type: 'UPDATE_ALERT_PREVIOUS_SURVEY_SUCCESS',
    alertPreviousSurvey
  });
  
  export const updateAlertPreviousSurvey = (alertPreviousSurvey) => {
    console.log('alertPreviousSurvey: ', alertPreviousSurvey);
    return (dispatch) => {
      dispatch(startAlertPreviousSurveyUpdate());
      axiosInstance.put(`alert-previous-survey/${alertPreviousSurvey.id}`, alertPreviousSurvey)
        .then((response) => {
          dispatch(updateAlertPreviousSurveySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});