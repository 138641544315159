import React, { Component } from 'react';
import {connect} from 'react-redux';
import ABButton from "../components/ABButton";
import './SignUp.css';
import Notification from "../components/Notification";
import {resetPassword} from "../actions/reset-password";

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
      error: ''
    };
  }

  componentDidMount = () => {
    this.setState(() => {
      return {
        token: this.props.match.params.token
      }
    });
  };

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => {
      return {
        email: email
      }
    });
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => {
      return {
        password: password
      }
    });
  };

  onConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => {
      return {
        confirmPassword: confirmPassword
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.password === '' || this.state.confirmPassword === '' || this.state.email === '') {
      this.setState(() => {
        console.log('validation error');
        return {
          error: 'Please fill out all fields.'
        }
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState(() => {
        console.log('validation error');
        return {
          error: 'Password do not match.'
        }
      });
    } else {
      let userInfo = {
        email: this.state.email,
        password: this.state.password,
        rememberToken: this.state.token
      };
      this.props.resetPassword(userInfo);
      this.setState(() => {
        return {
          email: '',
          password: '',
          confirmPassword: '',
          error: ''
        }
      });
    }
  };

  render() {
    return (
      <div className="container form-content">
        <h3>Reset your password</h3>
        {
          this.state.error &&
          <Notification className="error" message={this.state.error} />
        }
        {
          this.props.error &&
          <Notification className="error" message={this.props.errorMessage} />
        }
        {
          this.props.success &&
          <Notification className="success" message="Password reset successful." />
        }
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className="form-control"
              autoFocus
              value={this.state.email}
              onChange={this.onEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="form-control"
              value={this.state.password}
              onChange={this.onPasswordChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Confirm Password"
              className="form-control"
              value={this.state.confirmPassword}
              onChange={this.onConfirmPasswordChange}
            />
          </div>
          {
            !this.props.loading &&
            <div className="center-align">
              <ABButton title="Change Password" shadow={true} />
            </div>
          }
          {
            this.props.loading &&
            <div className="loading-text">Loading...</div>
          }
        </form>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.resetPassword.loading,
    error: state.resetPassword.error,
    errorMessage: state.resetPassword.errorMessage,
    success: state.resetPassword.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (userInfo) => dispatch(resetPassword(userInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);