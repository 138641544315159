import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from './styles/colors';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  background-color: ${colors.lightGray};
  width: 100%;
  font-size: 14px;
`;

const Copy = styled.div`
  padding: 0 20px;
  color: ${colors.black};
  @media (min-width: 1280px) {
    padding-bottom: 0px;
    text-align: left;
  }
`;

const Links = styled.div`
  ul {
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      padding-right: 25px;
      display: inline;
    }
  }
  a {
    color: ${colors.blue};
    text-decoration: none;
    :hover {
      color: ${colors.darkGray};
    }
  }
  @media (min-width: 1280px) {
     ul {
      text-align: right;
     }
  }
`;

export default function Footer(props) {
  console.log(props);
  return (
    <StyledFooter>
      <Copy>© 2019 Aspen Brook. All Rights Reserved.</Copy>
      <Links>
        {
          props.pageName !== '/microsite-submission' &&
          <ul>
            <li><NavLink to="/archive">Archive</NavLink></li>
            <li><NavLink to="/aspen-award">Aspen Award</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
            <li><NavLink to="/legal">Legal</NavLink></li>
            <li><NavLink to="/privacy">Privacy</NavLink></li>
            <li><NavLink to="/terms">Terms</NavLink></li>
          </ul>
        }
      </Links>
    </StyledFooter>
  );
}