import {CATEGORY_NAME, POST_TYPE_NAME} from '../utility/constants';

const postsDefaultState = {
  data: [],
  singlePost: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
  bulkSuccessMessage: '',
  bulkError: null
};

// add postType names using postTypesIds and add categoryNames to categories
const formatPostReturn = (posts) => {
  let formattedPosts = [];
  posts.map((item) => {
    item.selectedPostTypes.map((selectedPostTypeItem) => {
      selectedPostTypeItem.name = selectedPostTypeItem.postType.name;
    });
    item.selectedCategories.map((selectedCategoryItem) => {
      selectedCategoryItem.name = selectedCategoryItem.category.name;
    });
    formattedPosts.push(item);
  });
  return formattedPosts;
};

const postsReducer = (state = postsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_POST':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_POST_SUCCESS':
      return {
        ...state,
        singlePost: action.post,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_GET_POSTS':
      return {
        ...state,
        loading: true,
        error: false,
        bulkError: null,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_POSTS_SUCCESS':
      return {
        ...state,
        data: formatPostReturn(action.posts),
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_SAVE_POSTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SAVE_POSTS_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your posts has been saved!',
      };
    case 'START_REMOVE_POSTS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_POSTS_SUCCESS':
      return {
        ...state,
        data: state.data.filter(post => { // check original posts and only include posts that don't have that to delete ID
          return !action.postIds.includes(post.id); // includes is checking inside an array of numbers to see if any match
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your posts have been removed!',
      };
    case 'UPDATE_POST_IN_POSTS_SUCCESS':
      return {
        ...state,
        data: state.data.map(post => {
          if (post.id === action.postDetails.id) {
            post.title = action.postDetails.title;
            post.status = action.postDetails.status;
          }
          return post;
        }),
      };
    case 'SHOW_POSTS_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    case 'SHOW_POST_BULK_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        bulkError: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    case 'UPDATE_POST_BULK_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        bulkError: null,
        bulkSuccessMessage: 'Bulk Update Success',
      };  
    default:
      return state;
  }
};

export default postsReducer;
