const accountInfoReducerDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  errorMessage: null,
};

const accountInfoReducer = (state = accountInfoReducerDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_USER':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        data: action.user,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_ACCOUNT_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case 'ACCOUNT_UPDATE_SUCCESS':
      return {
        ...state,
        data: action.accountInfo,
        loading: false,
        success: true,
        error: false,
        errorMessage: null
      };
    case 'START_PASSWORD_UPDATE':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case 'PASSWORD_UPDATE_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default accountInfoReducer;