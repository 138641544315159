import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import {history} from "../../../routers/AppRouter";
import moment from 'moment';
import styled from 'styled-components'
import { useTable, useRowSelect } from 'react-table';
import {connect} from "react-redux";

import {getUsers} from '../../../actions/users';
import MainButton from '../../../components/MainButton';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const Title = styled.div`
  cursor: pointer;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const handleEditRow = (id) => {
  history.push(`user/${id}`);
};

const createPost = () => {
  history.push('/user')
};

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  // Render the UI for your table
  return (
    <>
      <HeadingContainer>
        <StyledHeading>Users</StyledHeading>
        <MainButton title="Create A User" onClick={() => createPost()} />
      </HeadingContainer>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, 500).map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre>
    </>
  )
}

function UserList(props) {

  useEffect(() => {
    props.getUsers();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Listing Info',
        columns: [
          {
            Header: 'ID',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.id}</Title>;
            },
          },
          {
            Header: 'Email',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.email}</Title>;
            },
          },
          {
            Header: 'Created At',
            accessor: d => {
              return moment(d.createdAt).format('MMM. Do, YYYY');
            }
          }
        ],
      },
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} data={props.users} />
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
