import React from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import colors from '../../../styles/colors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import ABButton from "../../../components/ABButton";
import Spinner from "../../../components/Spinner";
import MainButton from '../../../components/MainButton';
import {EditorState} from 'draft-js';
import {FormInput} from '../../../styles/form-styles';
import {getMediaImages, removeMediaImages} from '../../../actions/media-images';
import ABSmallButton from '../../../components/ABSmallButton';
import {history} from '../../../routers/AppRouter';
import {saveMultiplePhotos} from '../../../actions/upload';
import {saveMultipleMediaImages} from '../../../actions/media-library-upload';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const FormSection = styled.div`
  padding: 6px 0;
  h3 {
    color: ${colors.black};
    width: 100%;
    font-size: 18px;
    margin: 7px 0;
  }
  input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    border-radius: none;
    box-shadow: none;
  }
`;

const SelectFilesButton = styled.button`
  font-size: 15px;
  padding: 6px 10px;
  background: none;
  drop-shadow: none;
  cursor: pointer;
  outline: none;
  margin: 0;
`;

const Title = styled.div`
  cursor: pointer;
  color: ${colors.blue}
  text-decoration: underline;
`;

const SearchBarPadding = styled.div`
  padding: 25px 0 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
`;

const TitleInput = styled.div`
  margin-right: 25px;
  width: 300px;
`;

const TagInput = styled.div`
  margin-left: 25px;
  width: 300px;
`;

const SearchButton = styled.div`
  margin-left: 50px;
`;

const ImageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
`;

const ImagePadding = styled.div`
  padding: 20px;
  img {
    max-width: 400px;
    max-height: 400px;
    cursor: pointer;
    :hover {
      opacity: .7;
    }
  }
`;

const BlueLink = styled.div`
  cursor: pointer;
  color: ${colors.blue};
  padding-left: 20px;
`;

const FileRow = styled.div`
  padding: 5px 10px;
  button {
    margin-left: 10px;
  }
`;

const FileName = styled.div`
  margin: 10px 0;
  font-size: 14px;
`;

const UploadSpinner = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 10px;
  div {
    padding-left: 5px;
  }
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
`;

const SubmitButton = styled.div`
  padding: 30px 0;
  border: none;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class MultipleUpload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      tag: '',
      multipleFiles: [],
      multipleFilesNames: []
    }

  }

  componentDidMount() {
    this.props.getMediaImages();
  }

  componentDidUpdate(prevProps) {

    if (this.props.uploadSuccess !== prevProps.uploadSuccess) {
      if (this.props.uploadSuccess === true) {
        toast.success('Files uploaded successfully');
        this.setState((state) => {
          return {
            multipleFiles: [],
            multipleFilesNames: []
          };
        });
      }
    }

  }

  handleEditImage = (id) => {
    history.push(`media-image/${id}`);
  };

  showListView = () => {
    history.push('/media-library')
  };

  handleMultipleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    files.map((item) => {
      this.setState((state) => {
        return {
          multipleFiles: [...state.multipleFiles, item],
          multipleFilesNames: [...state.multipleFilesNames, item.name]
        };
      });
    });

    console.log(files);

  }

  removeFileFromMultiple = (index) => {
    this.setState((prevState) => {
      return {
        multipleFiles: prevState.multipleFiles.filter((_, i) => i !== index),
        multipleFilesNames: prevState.multipleFilesNames.filter((_, i) => i !== index)
      };
    });
  };

  submitContent = () => {
    if (this.state.multipleFiles.length > 0) {
      let finalImages = this.state.multipleFiles;
      console.log('finalImages: ', finalImages);
      this.props.saveMultipleMediaImages(finalImages, null, 'media-library');
    } else {
      toast.error('No file selected');
    }
  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Media Library</StyledHeading>
          {/*<MainButton title="Add Image" onClick={() => createPost()} />*/}
          {/*<MainButton title="Delete Images" onClick={() => this.deleteSelectedMediaImages(selectedFlatRows)} />*/}
        </HeadingContainer>
        <FormSection>
          <h3>Select Multiple Images</h3>
          <Dropzone
            onDrop={(acceptedFiles) => this.handleMultipleOnDrop(acceptedFiles)}
            multiple={true}
            accept={acceptedFileTypes}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <SelectFilesButton type="button">Select File(s)</SelectFilesButton>
                </div>
                <FileName>
                  {
                    this.state.multipleFilesNames.map((item, index) => {
                      return (
                        <FileRow key={index}>
                          {item}
                          <button type="button" onClick={() => this.removeFileFromMultiple(index)}>remove</button>
                        </FileRow>
                      )
                    })
                  }
                </FileName>
              </section>
            )}
          </Dropzone>
        </FormSection>
        <SubmitButtonWrapper>
          <SubmitButton>
            <ABButton
              onClick={() => this.submitContent()}
              title='Upload Images'
              submitting={this.props.uploadLoading}/>
          </SubmitButton>
          {
            this.props.uploadLoading &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }
        </SubmitButtonWrapper>
        <ToastContainer />
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mediaImages: state.mediaImages.data,
    uploadLoading: state.uploadMediaLibrary.uploadLoading,
    uploadSuccess: state.uploadMediaLibrary.uploadSuccess,
    uploadError: state.uploadMediaLibrary.uploadError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaImages: (title, tag) => dispatch(getMediaImages(title, tag)),
  removeMediaImages: (idsToDelete) => dispatch(removeMediaImages(idsToDelete)),
  saveMultipleMediaImages: (files, subfolder, mainFolder) => dispatch(saveMultipleMediaImages(files, subfolder, mainFolder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleUpload);