import React from 'react';
import styled from "styled-components";
import colors from "../styles/colors";

const StyledButton = styled.button`
  background: ${colors.blue};
  color: ${colors.white};
  padding: 10px 15px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  :hover {
    background: ${colors.black};
  }
  :disabled {
    background: ${colors.gray200};
    :hover {
      background: ${colors.gray200};
    }
  }
  border-radius: ${props => props.rounded ? '12px' : '0'};
`;

const buttonState = (props) => {
  if (props.submitting) {
    return (
      <StyledButton onClick={props.onClick} disabled={true} rounded={props.rounded}>
        Submitting...
      </StyledButton>
    )
  } else {
    return (
      <StyledButton onClick={props.onClick} disabled={props.disabled} rounded={props.rounded}>
        {props.title}
      </StyledButton>
    )
  }
};

const ABSmallButton = (props) => {
  return (
    <div>
      {buttonState(props)}
    </div>
  );
};

export default ABSmallButton;