import axiosInstance from '../utility/axios';
import {signOut} from "./sign-in";

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrositeSubmission = () => ({
  type: 'START_GET_MICROSITE_SUBMISSION',
});

export const getMicrositeSubmissionSuccess = (micrositeSubmission) => ({
  type: 'GET_MICROSITE_SUBMISSION_SUCCESS',
  micrositeSubmission
});

export const getMicrositeSubmission = (micrositeId) => {
  return (dispatch) => {
    dispatch(startGetMicrositeSubmission());
    axiosInstance.get(`microsite-submission/${micrositeId}`)
      .then((response) => {
        dispatch(getMicrositeSubmissionSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreateMicrositeSubmission = () => ({
  type: 'START_CREATE_MICROSITE_SUBMISSION'
});

export const createMicrositeSubmissionSuccess = (micrositeSubmission) => ({
  type: 'CREATE_MICROSITE_SUBMISSION_SUCCESS',
  micrositeSubmission
});

export const createMicrositeSubmission = (micrositeSubmission, micrositeId) => {
  console.log('creating microsite submission', micrositeSubmission);
  return (dispatch) => {
    dispatch(startCreateMicrositeSubmission());
    axiosInstance.post(`microsite-submission/${micrositeId}`, micrositeSubmission)
      .then((response) => {
        dispatch(createMicrositeSubmissionSuccess(response.data));
        console.log('microsite submission created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startMicrositeSubmissionUpdate = () => ({
  type: 'START_MICROSITE_SUBMISSION_UPDATE',
});

export const updateMicrositeSubmissiondSuccess = (micrositeSubmission) => ({
  type: 'MICROSITE_SUBMISSION_UPDATE_SUCCESS',
  micrositeSubmission
});

export const updateMicrositeSubmission = (micrositeSubmission, micrositeId) => {
  return (dispatch) => {
    dispatch(startMicrositeSubmissionUpdate());
    axiosInstance.put(`microsite-submission/${micrositeId}`, micrositeSubmission)
      .then((response) => {
        dispatch(updateMicrositeSubmissiondSuccess(micrositeSubmission));
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        console.log(error);
      });
  }
};