import React, {Component} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import LPAlert from './images/landing-page/LandingPage-Alert.png';
import LPAlertHover from './images/landing-page/LandingPage-Alert-MouseOver.png';

import LPXChange from './images/landing-page/LandingPage-Xchange.png';
import LPXChangeHover from './images/landing-page/LandingPage-Xchange-MouseOver.png';

import LPSurveys from './images/landing-page/LandingPage-Surveys.png';
import LPSurveysHover from './images/landing-page/LandingPage-Surveys-MouseOver.png';

const Wrapper = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: center;
  -webkit-flex-flow: row wrap;
`;

const LinkTitle = styled.div`
  color: #ffffff;
  font-size: 39px;
  background-color: rgba(29, 78, 143, 0.7);
  padding: 29px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-family: Helvetica, Arial;
`;

const AlertLink = styled.div`
  cursor: pointer;
  background: url(${LPAlert});
  width: 417px;
  height: 336px;
  position: relative;
  margin: 5px;
  :hover {
    background: url(${LPAlertHover});
  }
`;

const XchangeLink = styled.div`
  cursor: pointer;
  background: url(${LPXChange});
  width: 417px;
  height: 336px;
  position: relative;
  margin: 5px;
  :hover {
    background: url(${LPXChangeHover});
  }
`;

const SurveysLink = styled.div`
  cursor: pointer;
  background: url(${LPSurveys});
  width: 417px;
  height: 336px;
  position: relative;
  margin: 5px;
  :hover {
    background: url(${LPSurveysHover});
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Wrapper>
        <NavLink to="/aspen-alert">
          <AlertLink>
            <LinkTitle>Aspen Alert</LinkTitle>
          </AlertLink>
        </NavLink>
        <NavLink to="/microsite-submission">
          <XchangeLink>
            <LinkTitle>Aspen Xchange</LinkTitle>
          </XchangeLink>
        </NavLink>
        <SurveysLink>
          <LinkTitle>Aspen Surveys</LinkTitle>
        </SurveysLink>
      </Wrapper>
    )};
}

export { Home as default};