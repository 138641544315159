import React, { Component } from 'react';
import {connect} from "react-redux";
import {getFiles, getFolders} from "../actions/upload";
import {DEV_ENVIRONEMENT} from "../utility/constants";
import Spinner from "../components/Spinner";
import colors from "../styles/colors";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const FileNames = styled.div`
  padding: 20px 0;
`;

const FileName = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 3px 0;
  color: ${colors.blue};
  a:link {
    text-decoration: none;
  }
  a:hover {
    color: ${colors.black};
  }
`;

const StyledSpinner = styled.div`
  text-align: center;
  margin-top: 20px;
`;

class Upload extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      error: ''
    };
  }

  componentDidMount() {
    console.log('got files again');
    this.props.getFiles(this.props.match.params.folder);
  }

  componentDidUpdate(prevProps) {
    // compare props, make sure it's different or they will loop
    // make sure success is true as well
    if (this.props.success !== prevProps.success && this.props.success) {
      //
    }
  }

  getDownloadPath(string) {
    return string.replace('/home/deploy/my-aspen-brook-api/public/', 'https://api.myaspenbrook.com/')
  }

  getFileName(string) {
    if (DEV_ENVIRONEMENT) {
      return string.replace('/Users/keith/Documents/my-aspen-brook/api/public/uploads/', '');
    } else {
      return string.replace('/home/deploy/my-aspen-brook-api/public/uploads/', '')
    }
  }

  render() {
    return (
      <div>
        {
          !this.props.getFilesSuccess &&
          <StyledSpinner>
            <Spinner />
          </StyledSpinner>
        }
        <FileNames>
          {
            this.props.files &&
            this.props.files.map((filePath) => (
              <FileName key={filePath}>
                <a href={this.getDownloadPath(filePath)} download target="_blank">{this.getFileName(filePath)}</a>
              </FileName>
            ))
          }
        </FileNames>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.upload.loading,
    error: state.upload.error,
    success: state.upload.success,
    files: state.upload.data,
    getFilesSuccess: state.upload.getFilesSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFiles: (folderName) => dispatch(getFiles(folderName))
});


export default connect(mapStateToProps, mapDispatchToProps)(Upload);