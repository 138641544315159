const signInReducerDefaultState = {
  profile: [],
  token: '',
  loading: false,
  error: false,
  errorMessage: null
};

const signInReducer = (state = signInReducerDefaultState, action) => {
  switch(action.type) {
    case 'USER_PROFILE_RECIEVED':
      return {
        ...state,
        profile: action.profileInfo,
        loading: false, //after finishing add data and loading false
        error: false
      };
    case 'START_SIGNIN_LOADING':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null
      };
    case 'USER_SIGNED_IN':
      return {
        ...state,
        token: action.token,
        loading: false,
        error: false,
        errorMessage: null
      };
    case 'CLEAR_USER_INFO':
      return signInReducerDefaultState;
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default signInReducer;