import axiosInstance from '../utility/axios';
import {signOut} from './sign-in';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetPostKeywords = () => ({
  type: 'START_GET_POST_KEYWORDS',
});

export const getPostKeywordsSuccess = (postKeywords) => ({
  type: 'GET_POST_KEYWORDS_SUCCESS',
  postKeywords
});

export const getPostKeywords = (postId) => {
  return (dispatch) => {
    dispatch(startGetPostKeywords());
    axiosInstance.get(`post-keywords/${postId}`)
      .then((response) => {
        dispatch(getPostKeywordsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startGetPostKeyword = () => ({
  type: 'START_GET_POST_KEYWORD',
});

export const getPostKeywordSuccess = (postKeywords) => ({
  type: 'GET_POST_KEYWORD_SUCCESS',
  postKeywords
});

export const getPostKeyword = (postId) => {
  return (dispatch) => {
    dispatch(startGetPostKeyword());
    axiosInstance.get(`post-keyword/${postId}`)
      .then((response) => {
        dispatch(getPostKeywordSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreatePostKeyWords = () => ({
  type: 'START_CREATE_POST_KEYWORDS'
});

export const createPostKeyWordsSuccess = (postKeywords) => ({
  type: 'CREATE_POST_KEYWORDS_SUCCESS',
  postKeywords
});

export const createPostKeyWords = (postKeywords, postId) => {
  console.log('creating post keyword', postKeywords);
  return (dispatch) => {
    dispatch(startCreatePostKeyWords());
    axiosInstance.post(`post-keywords/${postId}`, postKeywords)
      .then((response) => {
        dispatch(createPostKeyWordsSuccess(response.data));
        console.log('post keyword created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startUpdatePostKeyWords = () => ({
  type: 'START_UPDATE_POST_KEYWORDS'
});

export const updatePostKeyWordsSuccess = (postKeywords) => ({
  type: 'UPDATE_POST_KEYWORDS_SUCCESS',
  postKeywords
});

export const updatePostKeyWords = (postKeywords, postId) => {
  console.log('creating post keyword', postKeywords);
  return (dispatch) => {
    dispatch(startUpdatePostKeyWords());
    axiosInstance.put(`post-keywords/${postId}`, postKeywords)
      .then((response) => {
        dispatch(updatePostKeyWordsSuccess(response.data));
        console.log('post keyword created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};
