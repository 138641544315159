import React, { Component } from 'react';
import {connect} from "react-redux";
import {history} from "../../../routers/AppRouter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormInputSmall, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormFileName} from '../../../styles/form-styles';
import Dropzone from 'react-dropzone';
import {extractImageFileExtensionFromBase64} from '../../../helpers/ReusableUtils';
import {ToastContainer, toast} from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import MainButton from '../../../components/MainButton';


// redux
import {saveSingleFile} from '../../../actions/upload';
import {slugify} from '../../../utility/string-utils';
import {getMediaImage, updateMediaImage, uploadMediaImage} from '../../../actions/media-images';
import {createMediaTags, getMediaTags, updateMediaTags} from '../../../actions/media-tags';
import {EditorState} from 'draft-js';

import ReactSVG from 'react-svg';
import PDFIcon from './pdf-icon.svg';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContent = styled.div`
  // min-width: 600px;
  width: 100%;
`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  max-width: 400px;
  padding: 0 15px;
  margin: 40px 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 0 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  :read-only {
    background-color: ${colors.lightestGray};
    border: 1px solid ${colors.lightGray}
  }
`;

const CategoryInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 300px;
  margin-right: 15px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  :read-only {
    background-color: ${colors.lightestGray};
    border: 1px solid ${colors.lightGray}
  }
`;

const StyledCheckbox = styled.input`
  margin: 20px 0;
`;


const TextAreaContainer = styled.div`
  padding-right: 22px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  background: ${colors.lightestGray};
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue}
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const CategorySelect = styled.div`
  display: flex;
  align-content: center;
  padding: 15px 0;
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 300px;
  max-width: 300px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const StyledSVG = styled(ReactSVG)`
  width: 55px;
  text-align: center;
  display: inline-block;
  padding-left: 10px;
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 290px;
  border: 1px solid ${colors.grayBorder};
`;

const StyledSelectSmall = styled.select`
  margin: 5px 0;
  font-size: 14px;
  padding: 5px 6px 6px 6px;
  width: 245px;
  border: 1px solid ${colors.grayBorder};
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue}
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const ModalPreviewTitle = styled.div`
  position: absolute;
  background: ${colors.darkGray};
  color: ${colors.white};
  left: 0;
  text-align: center;
  top: -56px;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  z-index: 100;

  @media (min-width: 720px) {
    width: ${props => props.modalWidth ? props.modalWidth : '442px'};
    min-height: 495px;
  }
`;

const ModalHeading = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${colors.lightGray}
  color: ${colors.white};
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

const ModalFeaturedImage = styled.div`
  padding: 20px 0;
  text-align: center;
  img {
    max-width: 300px;
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  color: ${colors.black};
  font-weight: bold;
  padding-top: 15px;
`;

const ModalDetails = styled.div`
  padding-top: 10px;
  padding: 0;
  opacity: 0.8;
  font-size: 15px;
  color: ${colors.black};
  // p {
  //   padding: 0;
  //   line-height: 15px;
  // }
`;

const ModalBody = styled.div`
  padding: 20px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

const ValidationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const KeywordItems = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`;

const KeywordItem = styled.div`
  background: ${colors.white};
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 0;
  padding: 8px;
`;
const KeywordTitle = styled.div`
  font-size: 15px;
`;

const RemoveButton = styled.div`
  background: ${colors.lightestGray};
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${colors.lightGray};
  padding: 0 4px;
  margin-left: 4px;
`;

const AddKeywordButtonContainer = styled.div`
  padding-top: 6px;
  text-align: right;
`;

const AddKeywordButton = styled.button`
  border: none;
  background: ${colors.lightestGray};
  color: ${colors.darkGray};
  padding: 3px 5px;
  border-radius: 6px;
  border: 1px solid ${colors.darkGray};
  cursor: pointer;
  :hover {
    opacity: .7;
  }
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class MediaImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalPosition: 0,
      modalWidth: '442px',
      title: '',
      sponsor: '',
      displayTitle: true,
      showCalendar: false,
      applicationType: '',
      imageLink: '',
      companyLogoLink: '',
      editingMediaImageId: null,
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      folder: 'media-library',
      url: '',
      altText: '',
      category: '',
      imageSizeError: false,
      keywords: [],
      selectedKeyword: '',
      presetTag: '',
    }
  }

  componentDidMount() {
    // const mediaImageId = this.props.match.params.id;

    const mediaImageId = this.props.mediaImageId;

    if (mediaImageId) {
      this.props.getMediaImage(mediaImageId);
      this.props.getMediaTags(mediaImageId);
      this.setState(() => ({
        editingMediaImageId: parseInt(mediaImageId),
      }));
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.mediaImage !== prevProps.mediaImage) {

      this.setState(() => ({
        title: this.props.mediaImage.title,
        sponsor: this.props.mediaImage.sponsor,
        imageLink: this.props.mediaImage.imageLink,
        companyLogoLink: this.props.mediaImage.companyLogoLink,
        applicationType: this.props.mediaImage.applicationType,
        folder: this.props.mediaImage.folder,
        url: this.props.mediaImage.url,
        altText: this.props.mediaImage.altText,
        category: this.props.mediaImage.category,
      }));
    }

    if (this.props.mediaTags !== prevProps.mediaTags) {
      if (!this.state.justSaved) {
        this.loadMediaTagsFields();
      }
    }

  }

  loadMediaTagsFields = () => {
    this.setState({
      keywords: this.props.mediaTags
    });
  };

  handleTitleChange = (title) => {
    this.setState(() => ({title}));
  };

  handleAltTextChange = (altText) => {
    this.setState(() => ({altText}));
  };

  handleCategoryChange = (category) => {
    this.setState(() => ({
      category: category.toLowerCase()
    }));
  };

  handleFolderChange = (e) => {
    const folder = e.target.value;
    this.setState(() => ({folder}));
  };

  onKeywordChange = (e) => {
    const selectedKeyword = e.target.value;
    this.setState(() => (
      {selectedKeyword}));
  };

  handlePresetTagChange = (e) => {
    let keywordItem = {
      name: e.target.value.toLowerCase(),
    };
    this.setState(() => (
      {
        selectedKeyword: '',
        keywords: [...this.state.keywords, keywordItem]
      }
    ));
  };

  handlePresetCategoryChange = (value) => {
    this.setState(() => (
      {
        category: value.toLowerCase(),
      }
    ));
  };

  onKeywordEnter = (e) => {
    if (e.key === 'Enter') {
      let keywordItem = {
        name: e.target.value.toLowerCase(),
      };
      this.setState(() => (
        {
          selectedKeyword: '',
          keywords: [...this.state.keywords, keywordItem]
        }
      ));
    }
  };

  onKeywordAddClick = () => {
    let keywordItem = {
      name: this.state.selectedKeyword.toLowerCase(),
    };
    this.setState(() => (
      {
        selectedKeyword: '',
        keywords: [...this.state.keywords, keywordItem]
      }
    ));
  };

  removeItem(item) {
    this.setState({keywords: this.state.keywords.filter(function(keptItem) {
        return keptItem.name !== item.name
      })});
  }


  // Modal

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleModelChange = (position) => {
    const initialModalPosition = this.state.modalPosition;
    if (position > 0 && initialModalPosition !== 3) {
      this.setModalSize(initialModalPosition + 1);
    } else if (position < 0 && initialModalPosition !== 0) {
      this.setModalSize(initialModalPosition - 1);
    }
  };

  setModalSize = (modalPosition) => {
    if (modalPosition === 0) {
      this.setState(() => ({
        modalWidth: '442px',
        modalPosition: modalPosition
      }));
    } else {
      this.setState(() => ({
        modalWidth: '1200px',
        modalPosition: modalPosition
      }));
    }
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          // this.isValidImageSize(myResult, 480, 395);
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  isValidImageSize = (fileDetails, width, height) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function() {
      if (this.width >= width && this.height >= height && this.width <= 490 && this.height <= 405) {
        globalThis.setSingleImageError(false);
      } else {
        globalThis.setSingleImageError(true);
      }
    };
  };

  setSingleImageError = (showError) => {
    this.setState({
      imageSizeError: showError
    });
  };

  imageCheck() {
    if (this.state.imgSrc !== null) {
      return <div>
        {
          !this.state.imgSrc.includes('pdf') ?
            <ImagePreview src={this.state.imgSrc} /> : <StyledSVG src={PDFIcon} />
        }
      </div>
    } else if (this.state.url) {
      return <div>
        {
          !this.props.mediaImage.url.includes('pdf') ?
            <ImagePreview src={`https://${this.props.mediaImage.url}`} /> : <StyledSVG src={PDFIcon} />
        }
      </div>
    } else {
      return (
        <ImagePlaceholderStyled>No Image</ImagePlaceholderStyled>
      )
    }
  }

  finalizePost = (type, cloudfrontUrl) => {

    let mediaImageDetails = {
      title: this.state.title,
      url: cloudfrontUrl || this.state.url || null,
      folder: this.state.folder,
      altText: this.state.altText,
      category: this.state.category,
    };

    const mediaImageId = this.state.editingMediaImageId;
    if (mediaImageId) { // is editing media image
      mediaImageDetails.id = mediaImageId;
      this.props.updateMediaImage(mediaImageDetails, mediaImageId);
      // has media tags as well
      if (this.state.keywords.length > 0) {
        let mediaTags = [];
        this.state.keywords.map((item) => {
          mediaTags.push(item)
        });
        this.props.updateMediaTags(mediaTags, this.props.mediaImage.id);
      }
    } else { // creating new media image
      if (this.state.keywords.length > 0) { // has tags
        let mediaTags = [];
        this.state.keywords.map((item) => {
          mediaTags.push(item)
        });
        console.log('new media tags: ', mediaTags);
        this.props.uploadMediaImage(mediaImageDetails, mediaTags);
      } else {
        this.props.uploadMediaImage(mediaImageDetails, null);
      }
    }

    this.clearState();

    // history.push('/media-library');

  };

  publishPost = (type) => {

    if (this.state.title && !this.state.imageSizeError && this.state.folder && (this.state.url || this.state.uploadedFile)) {

      if (type !== 'preview') {

        // Just a feature image was uploaded
        if (this.state.uploadedFile) {
          this.props.saveSingleFile(this.state.uploadedFile, null, this.state.folder)
            .then((cloudfrontUrl) => {
              // add the cloudfront url to the post
              this.finalizePost(type, cloudfrontUrl);
            });
        }

        // no images or additional documents uploaded
        if (!this.state.uploadedFile) {
          this.finalizePost(type);
        }

      } else {
        // show post preview
      }

    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  clearState = () => {

    this.setState(() => ({
      title: '',
      sponsor: '',
      displayTitle: true,
      showCalendar: false,
      applicationType: '',
      imageLink: '',
      companyLogoLink: '',
      imgSrc: null,
      uploadedFile: null,
      imgSrcExt: null,
      folder: 'media-library',
      url: '',
      altText: '',
      imageSizeError: false,
      keywords: [],
      selectedKeyword: '',
      presetTag: '',
    }));



    if (this.state.editingMediaImageId) {
      this.props.closeModal(false);
    } else {
      this.props.closeModal(true);
    }

  };

  render() {
    return (
      <PageContainer>
        <HeadingContainer>
          <StyledHeading>Media Image</StyledHeading>
          <RightSideHeading>
            <div>
              <SchedulePopup>
                <MainButton title="Schedule Now" onClick={() => this.publishPost('scheduled')}/>
              </SchedulePopup>
            </div>
            <MainButton title="Save All" dark={true} onClick={() => this.publishPost('published')}/>
          </RightSideHeading>
        </HeadingContainer>
        <MainContentContainer>
          <MainContent>
            <StyledTitle>Image Title</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.title}
              onChange={(e) => this.handleTitleChange(e.target.value)}
            />
            <FeaturedImageSection>
              <PreviewSection>
                <div>
                  <StyledTitle>Image</StyledTitle>
                  <StyledImageSection>
                    <Dropzone
                      onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}
                      multiple={false}
                      accept={acceptedFileTypes}
                    >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <ImageSelection {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MainButton title='Select' />
                          </ImageSelection>
                        </section>
                      )}
                    </Dropzone>
                  </StyledImageSection>
                </div>
                <ValidationContainer>
                  {this.imageCheck()}
                  {
                    this.state.imageSizeError &&
                    <ValidationError>Invalid Image Size</ValidationError>
                  }
                </ValidationContainer>
              </PreviewSection>
            </FeaturedImageSection>

            <CategorySelect>
              <div>
                <StyledTitle>Category</StyledTitle>
                <CategoryInput
                  type="text"
                  value={this.state.category}
                  onChange={(e) => this.handleCategoryChange(e.target.value)}
                />
              </div>

              <div>
                <StyledTitle>Category Presets</StyledTitle>
                <StyledSelectSmall
                  name="category"
                  value={this.state.category}
                  onChange={(e) => this.handlePresetCategoryChange(e.target.value)}
                >
                  <option value=''>Select</option>
                  <option value="atheneum ads">Atheneum Ads</option>
                  <option value="quotes">Quotes</option>
                  <option value="polls">Polls</option>
                  <option value="surveys">Surveys</option>
                  <option value="uncategorized">Uncategorized</option>

                </StyledSelectSmall>
              </div>
            </CategorySelect>

            <StyledTitle>Folder</StyledTitle>
            <StyledSelect
              name="folder"
              value={this.state.folder}
              onChange={this.handleFolderChange}
            >
              <option value=''>Select</option>
              <option value="media-library">Media Library</option>
              {/*<option value="body-ads">Body Ads</option>*/}
              {/*<option value="branding">Branding</option>*/}
              {/*<option value="column-ads">Column Ads</option>*/}
              {/*<option value="fact-alert">Fact Alert</option>*/}
              {/*<option value="general">General</option>*/}
              {/*<option value="logos">Logos</option>*/}
              {/*<option value="on-this-day-alert">On This Day Alert</option>*/}
              {/*<option value="podcast-alert">Podcast Alert</option>*/}
              {/*<option value="poll-alert">Poll Alert</option>*/}
              {/*<option value="quote-alert">Quote Alert</option>*/}
              {/*<option value="survey-alert">Survey Alert</option>*/}
              {/*<option value="ted-alert">Ted Alert</option>*/}
              {/*<option value="videos">Videos</option>*/}
            </StyledSelect>

            <StyledTitle>Link/URL</StyledTitle>
            <TextAreaContainer>
              <StyledTextArea
                readOnly
                type="text"
                value={`https://${this.state.url}`}
              />
            </TextAreaContainer>

            <StyledTitle>Alt Text</StyledTitle>
            <StyledInput
              type="text"
              value={this.state.altText}
              onChange={(e) => this.handleAltTextChange(e.target.value)}
            />

          </MainContent>
          <StyledSidebar>
            <SidebarSection>
              <StyledTitle>Preset Tags</StyledTitle>
              <StyledSelectSmall
                name="tag"
                value={this.state.selectedKeyword}
                onChange={this.handlePresetTagChange}
              >
                <option value=''>Select</option>
                <option value="Body Ad">Body Ad</option>
                <option value="Building">Building</option>
                <option value="Career">Career</option>
                <option value="Column Ad">Column Ad</option>
                <option value="Fact Alert">Fact Alert</option>
                <option value="Generic">Generic</option>
                <option value="Government">Government</option>
                <option value="Hospital">Hospital</option>
                <option value="Institute">Institute</option>
                <option value="Logo">Logo</option>
                <option value="On This Day Alert">On This Day Alert</option>
                <option value="Organization">Organization</option>
                <option value="Place">Place</option>
                <option value="Spotlight Alert">Spoptlight Alert</option>
                <option value="Poll Alert">Poll Alert</option>
                <option value="Quote Alert">Quote Alert</option>
                <option value="Surveys Alert">Surveys Alert</option>
                <option value="TED Alert">TED Alert</option>
                <option value="University">University</option>
                <option value="Video">Video</option>

              </StyledSelectSmall>
              <StyledTitle>Custom Tag</StyledTitle>
                <FormField>
                  <FormInputSmall
                    value={this.state.selectedKeyword}
                    onChange={this.onKeywordChange}
                    onKeyDown={this.onKeywordEnter}
                    type="text"
                  />
                  <AddKeywordButtonContainer>
                    <AddKeywordButton type="button" onClick={() => this.onKeywordAddClick()}>Add</AddKeywordButton>
                  </AddKeywordButtonContainer>
                </FormField>
                <KeywordItems>
                  {
                    (this.state.keywords && this.state.keywords.length > 0) &&
                    this.state.keywords.map((item, index) => {
                      return <KeywordItem key={index}>
                        <KeywordTitle>{item.name}</KeywordTitle>
                        <RemoveButton onClick={() => this.removeItem(item)}>x</RemoveButton>
                      </KeywordItem>
                    })
                  }
                </KeywordItems>
            </SidebarSection>
          </StyledSidebar>
        </MainContentContainer>
        <ModalStyled
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.closeModal()}
          contentLabel="Sort"
          overlayClassName="PreviewOverlay"
          modalWidth={this.state.modalWidth}
        >
          <ModalBody>
            <ModalPreviewTitle>Ad Link</ModalPreviewTitle>
            <ModalHeading>Listing</ModalHeading>
            {
              this.state.imageLink &&
              <ModalFeaturedImage>
                <a href={this.state.companyLogoLink}>
                  <img src={`https://${this.state.imageLink}`} alt="" />
                </a>
              </ModalFeaturedImage>
            }
            <ModalTitle>{this.state.name}</ModalTitle>
          </ModalBody>
        </ModalStyled>
        <ToastContainer />
      </PageContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ad: state.ads.singleAd,
    mediaImage: state.mediaImages.singleMediaImage,
    mediaTags: state.mediaTags.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaImage: (id) => dispatch(getMediaImage(id)),
  uploadMediaImage: (mediaImageDetails, mediaTags) => dispatch(uploadMediaImage(mediaImageDetails, mediaTags)),
  updateMediaImage: (mediaImageDetails, mediaImageId) => dispatch(updateMediaImage(mediaImageDetails, mediaImageId)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  getMediaTags: (id) => dispatch(getMediaTags(id)),
  updateMediaTags: (mediaTags, mediaImageId) => dispatch(updateMediaTags(mediaTags, mediaImageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaImage);
