import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import {history} from "../../../routers/AppRouter";
import moment from 'moment';
import styled from 'styled-components'
import { useTable, useRowSelect } from 'react-table';
import {connect} from "react-redux";

import {getMicrosites, removeMicrosites} from '../../../actions/microsites';
import MainButton from '../../../components/MainButton';
import {createMicrosite} from '../../../actions/microsite';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const Title = styled.div`
  cursor: pointer;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const handleEditRow = (id) => {
  history.push(`microsite/${id}`);
};

function Table({ columns, data, deleteSelectedMicrosites, createMicrosite }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );

  // Render the UI for your table
  return (
    <>
      <HeadingContainer>
        <StyledHeading>All Microsites</StyledHeading>
        <MainButton title="Create Microsite" onClick={() => createMicrosite()} />
        <MainButton title="Delete Microsites" onClick={() => deleteSelectedMicrosites(selectedFlatRows)} />
      </HeadingContainer>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.slice(0, 2000).map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre>
    </>
  )
}

function Microsites(props) {

  // had to be in here to get be able to fire props dispatch
  const deleteSelectedMicrosites = (selectedRows) => {
    let postIdsToDelete = [];
    selectedRows.map((item) => {
      postIdsToDelete.push(parseInt(item.original.id, 10)); // original is the actual object info
    });
    props.removeMicrosites(postIdsToDelete);
  };

  const createMicrosite = () => {
    history.push(`/microsite/create-microsite`);
    // let microsite = {
    //   userId: userId
    // };
    // props.createMicrosite(microsite)
  };

  useEffect(() => {
    props.getMicrosites();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Info',
        columns: [
        {
            Header: 'Id',
            accessor: data => {
                return <Title onClick={() => handleEditRow(data.id)}>{data.id}</Title>;
            },
        },
          {
            Header: 'Title',
            accessor: data => {
              return <Title onClick={() => handleEditRow(data.id)}>{data.pageTitle}</Title>;
            },
          },
          {
            Header: 'Client Portal ID',
            accessor: d => {
              return <Title>{d.clientPortalId}</Title>
            },
          },
          {
            Header: 'Created At',
            accessor: d => {
              return moment(d.createdAt).format('MMM. Do, YYYY');
            }
          },
          // {
          //   Header: 'Microsite Types',
          //   accessor: data => {
          //     let output = [];
          //     data.selectedMicrositeTypes.map((item) => {
          //       output.push(item.name);
          //     });
          //     return output.join(', ');
          //   },
          // },
          // {
          //   Header: 'Categories',
          //   accessor: data => {
          //     let output = [];
          //     data.selectedCategories.map((item) => {
          //       output.push(item.name);
          //     });
          //     return output.join(', ');
          //   },
          // },
          // {
          //   Header: 'Status',
          //   accessor: 'status',
          // },
        ],
      },
    ],
    []
  );

  return (
    <Styles>
      {
        props.microsites.length > 0 &&
        <Table columns={columns} data={props.microsites} deleteSelectedMicrosites={deleteSelectedMicrosites} createMicrosite={createMicrosite} />
      }
    </Styles>
  )
}

const mapStateToProps = (state) => {
  return {
    microsites: state.microsites.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosites: () => dispatch(getMicrosites()),
  createMicrosite: (microsite) => dispatch(createMicrosite(microsite)),
  removeMicrosites: (idsToDelete) => dispatch(removeMicrosites(idsToDelete)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Microsites);
