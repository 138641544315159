const categoriesDefaultState = {
  data: [],
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
  getCategorySuccess: false
};

const categoriesReducer = (state = categoriesDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_CATEGORIES':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
        getCategorySuccess: false
      };
    case 'GET_CATEGORIES_SUCCESS':
      return {
        ...state,
        data: action.categories,
        loading: false,
        error: false,
        errorMessage: null,
        getCategorySuccess: true
      };
    case 'START_GET_CATEGORIES_BY_POST_IDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_CATEGORIES__BY_POST_IDS_SUCCESS':
      return {
        ...state,
        data: action.categories,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'CREATE_CATEGORY_SUCCESS':
      return {
        ...state,
        data: [
          action.post,
          ...state.data,
        ],
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Post Type has been added!',
      };
    case 'UPDATE_CATEGORY_SUCCESS': // updating single post
      return {
        ...state,
        data: state.data.map((post) => {
          if (post.postId === action.post.postId) {
            console.log('got match');
            return {
              ...post,
              ...action.post /// add object to array
            }
          } else {
            return post;
          }
        }),
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_POST_TYPE_SUCCESS':
      return {
        ...state,
        data: state.data.filter(post => action.postId !== post.postId), // filter original state and return any that don't have the remove postId
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your post has been removed',
      };
    case 'START_SAVE_CATEGORY':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SAVE_CATEGORY_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your categories has been saved!',
      };
    case 'START_REMOVE_CATEGORY':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'REMOVE_CATEGORY_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your categories have been removed!',
      };
    case 'SHOW_CATEGORY_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default categoriesReducer;