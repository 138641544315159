import axiosInstance from '../utility/axios';
import {signOut} from './sign-in';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetMicrositeKeywords = () => ({
  type: 'START_GET_MICROSITE_KEYWORDS',
});

export const getMicrositeKeywordsSuccess = (micrositeKeywords) => ({
  type: 'GET_MICROSITE_KEYWORDS_SUCCESS',
  micrositeKeywords
});

export const getMicrositeKeywords = (micrositeId) => {
  return (dispatch) => {
    dispatch(startGetMicrositeKeywords());
    axiosInstance.get(`microsite-keywords/${micrositeId}`)
      .then((response) => {
        dispatch(getMicrositeKeywordsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startGetMicrositeKeyword = () => ({
  type: 'START_GET_MICROSITE_KEYWORD',
});

export const getMicrositeKeywordSuccess = (micrositeKeywords) => ({
  type: 'GET_MICROSITE_KEYWORD_SUCCESS',
  micrositeKeywords
});

export const getMicrositeKeyword = (micrositeId) => {
  return (dispatch) => {
    dispatch(startGetMicrositeKeyword());
    axiosInstance.get(`microsite-keyword/${micrositeId}`)
      .then((response) => {
        dispatch(getMicrositeKeywordSuccess(response.data));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setTimeout(function(){
            dispatch(showError('Token expired, please login again.'));
            dispatch(signOut());
          }, 3000);
        } else {
          dispatch(showError(error.response.data.message));
          console.log(error);
        }
      });
  }
};

export const startCreateMicrositeKeyWords = () => ({
  type: 'START_CREATE_MICROSITE_KEYWORDS'
});

export const createMicrositeKeyWordsSuccess = (micrositeKeywords) => ({
  type: 'CREATE_MICROSITE_KEYWORDS_SUCCESS',
  micrositeKeywords
});

export const createMicrositeKeyWords = (micrositeKeywords, micrositeId) => {
  console.log('creating microsite keyword', micrositeKeywords);
  return (dispatch) => {
    dispatch(startCreateMicrositeKeyWords());
    axiosInstance.post(`microsite-keywords/${micrositeId}`, micrositeKeywords)
      .then((response) => {
        dispatch(createMicrositeKeyWordsSuccess(response.data));
        console.log('microsite keyword created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startUpdateMicrositeKeyWords = () => ({
  type: 'START_UPDATE_MICROSITE_KEYWORDS'
});

export const updateMicrositeKeyWordsSuccess = (micrositeKeywords) => ({
  type: 'UPDATE_MICROSITE_KEYWORDS_SUCCESS',
  micrositeKeywords
});

export const updateMicrositeKeyWords = (micrositeKeywords, micrositeId) => {
  console.log('creating microsite keyword', micrositeKeywords);
  return (dispatch) => {
    dispatch(startUpdateMicrositeKeyWords());
    axiosInstance.put(`microsite-keywords/${micrositeId}`, micrositeKeywords)
      .then((response) => {
        dispatch(updateMicrositeKeyWordsSuccess(response.data));
        console.log('microsite keyword created!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};