import axiosInstance from "../../utility/axios";

export const startGetAlertFill = () => ({
    type: 'START_GET_ALERT_FILL',
  });
  
  export const getAlertFillSuccess = (alertFill) => ({
    type: 'GET_ALERT_FILL_SUCCESS',
    alertFill
  });
  
  export const getAlertFill = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-fill/${alertItemId}`;
      console.log('get alertFill url: ', url);
      dispatch(startGetAlertFill());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertFill: ', response.data);
          dispatch(getAlertFillSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertFillCreate = () => ({
    type: 'START_ALERT_FILL_CREATE',
});

export const createAlertFillSuccess = (alertFill) => ({
    type: 'CREATE_ALERT_FILL_SUCCESS',
    alertFill
});

export const createAlertFill = (alertFill) => {
    console.log('do alert fill: ', alertFill);
    return (dispatch) => {
        dispatch(startAlertFillCreate());
        axiosInstance.post(`alert-fill`, alertFill)
            .then((response) => {
                console.log('AlerttFill Create: ', response.data);
                dispatch(createAlertFillSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertFillUpdate = () => ({
    type: 'START_ALERT_FILL_UPDATE',
  });
  
  export const updateAlertFillSuccess = (alertFill) => ({
    type: 'UPDATE_ALERT_FILL_SUCCESS',
    alertFill
  });
  
  export const updateAlertFill = (alertFill) => {
    console.log('alertFill: ', alertFill);
    return (dispatch) => {
      dispatch(startAlertFillUpdate());
      axiosInstance.put(`alert-fill/${alertFill.id}`, alertFill)
        .then((response) => {
          dispatch(updateAlertFillSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});