import React, { useRef, useState } from 'react';
import ABSmallButton from './ABSmallButton';

interface CopyToClipboardButtonProps {
    textToCopy: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ textToCopy }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [clicked, setClicked] = useState(false);

    const copyToClipboard = () => {
        if (textAreaRef.current) {
            // Select the text in the textarea
            textAreaRef.current.select();

            // Execute the copy command
            document.execCommand('copy');
        }
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 2000)
    };

    return (
        <div>
            <textarea ref={textAreaRef} value={textToCopy} readOnly style={{ position: 'absolute', left: '-9999px' }} />
            <ABSmallButton onClick={copyToClipboard} title="Copy to Clipboard" />
            {
                clicked &&
                'Copied!'
            }
        </div>
    );
};

export default CopyToClipboardButton;
