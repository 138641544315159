import axiosInstance from "../../utility/axios";

export const startGetAlertProject = () => ({
    type: 'START_GET_ALERT_PROJECT',
  });
  
  export const getAlertProjectSuccess = (alertProject) => ({
    type: 'GET_ALERT_PROJECT_SUCCESS',
    alertProject
  });
  
  export const getAlertProject = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-project/${alertItemId}`;
      console.log('get alertProject url: ', url);
      dispatch(startGetAlertProject());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertProject: ', response.data);
          dispatch(getAlertProjectSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertProjectCreate = () => ({
    type: 'START_ALERT_PROJECT_CREATE',
});

export const createAlertProjectSuccess = (alertProject) => ({
    type: 'CREATE_ALERT_PROJECT_SUCCESS',
    alertProject
});

export const createAlertProject = (alertProject) => {
    console.log('do alert project: ', alertProject);
    return (dispatch) => {
        dispatch(startAlertProjectCreate());
        axiosInstance.post(`alert-project`, alertProject)
            .then((response) => {
                console.log('Alert Project Create: ', response.data);
                dispatch(createAlertProjectSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertProjectUpdate = () => ({
    type: 'START_ALERT_PROJECT_UPDATE',
  });
  
  export const updateAlertProjectSuccess = (alertProject) => ({
    type: 'UPDATE_ALERT_PROJECT_SUCCESS',
    alertProject
  });
  
  export const updateAlertProject = (alertProject) => {
    console.log('alertProject: ', alertProject);
    return (dispatch) => {
      dispatch(startAlertProjectUpdate());
      axiosInstance.put(`alert-project/${alertProject.id}`, alertProject)
        .then((response) => {
          dispatch(updateAlertProjectSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});