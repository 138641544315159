import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormInputCharCount } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import { history } from '../../../routers/AppRouter';
import { createMicrosite, getMicrosite, updateMicrosite } from '../../../actions/microsite';
import { updateMicrositeSubmission } from '../../../actions/microsite-submission';
import { setLocation } from '../../../actions/location';
import { getMicrositeSetting, updateMicrositeSetting } from '../../../actions/microsite-settings';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.form`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

class CallToAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      headerTitle: '',
      headerBody: '',
      headerButtonText: '',
      headerButtonLink: '',
      showSection: false
    };

  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.microsite !== prevProps.microsite) {
      this.loadFields();
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.setState({
        showSection: this.props.micrositeSetting.showCTASection || false
      });
    }
  }

  loadFields = () => {

    this.setState({
      headerTitle: this.props.microsite.headerTitle,
      headerBody: this.props.microsite.headerBody,
      headerButtonText: this.props.microsite.headerButtonText,
      headerButtonLink: this.props.microsite.headerButtonLink
    });
  };

  onNextClick = (close) => {
    let micrositeSetting = {
      showCTASection: this.state.showSection
    };
    let microsite = {
      headerTitle: this.state.headerTitle,
      headerBody: this.state.headerBody,
      headerButtonText: this.state.headerButtonText,
      headerButtonLink: this.state.headerButtonLink,
      userId: this.props.microsite.userId
    };
    this.props.updateMicrosite(microsite, this.props.microsite.id);

    let micrositeSubmission = {
      callToAction: true
    };
    this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);
    this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

    if (close) {
      history.push(`../${this.props.microsite.id}`);
      return;
    }
  };

  onHeaderTitleChange = (e) => {
    const headerTitle = e.target.value;
    if (headerTitle.length <= 70) {
      this.setState(() => ({ headerTitle }));
    }
  };

  onHeaderBodyChange = (e) => {
    const headerBody = e.target.value;
    if (headerBody.length <= 80) {
      this.setState(() => ({ headerBody }));
    }
  };

  onHeaderButtonTextChange = (e) => {
    const headerButtonText = e.target.value;
    if (headerButtonText.length <= 15) {
      this.setState(() => ({ headerButtonText }));
    }
  };

  onHeaderButtonLinkChange = (e) => {
    const headerButtonLink = e.target.value;
    this.setState(() => ({ headerButtonLink }));
  };

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({ showSection: !showSection }));
  };

  onPreviousClick = () => {
    if (this.props.microsite.id) {
      history.push(`/microsite-submission/logo-bio/${this.props.microsite.id}`);
    } else {
      history.push('logo-bio');
    }
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Call to Action</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>
            <FormSection>
              <FormSubHeading>Call to Action Title:</FormSubHeading>
              <FormItalicHeading>Not to exceed 70 characters including spaces.</FormItalicHeading>
              <div>
                <FormField>
                  <FormInputFull
                    value={this.state.headerTitle}
                    onChange={this.onHeaderTitleChange}
                    type="text"
                    rows="2"
                    cols="10"
                  />
                  {
                    this.state.headerTitle &&
                    <FormInputCharCount>Char count: {this.state.headerTitle.length}</FormInputCharCount>
                  }
                </FormField>
              </div>

              <FormSectionPadding>
                <FormSubHeading>Call to Action Body:</FormSubHeading>
                <FormItalicHeading>Not to exceed 80 characters including spaces.</FormItalicHeading>
                <div>
                  <FormField>
                    <FormTextArea
                      value={this.state.headerBody}
                      onChange={this.onHeaderBodyChange}
                      type="text"
                      rows="2"
                      cols="10"
                    />
                    {
                      this.state.headerBody &&
                      <FormInputCharCount>Char count: {this.state.headerBody.length}</FormInputCharCount>
                    }
                  </FormField>
                </div>
              </FormSectionPadding>

            </FormSection>

            <FormSection>
              <FormSubHeading>Button Text:</FormSubHeading>
              <FormItalicHeading>Not to exceed 15 characters including spaces.</FormItalicHeading>
              <div>
                <FormField>
                  <FormInputFull
                    value={this.state.headerButtonText}
                    onChange={this.onHeaderButtonTextChange}
                    type="text"
                    rows="10"
                    cols="10"
                  />
                  {
                    this.state.headerButtonText &&
                    <FormInputCharCount>Char count: {this.state.headerButtonText.length}</FormInputCharCount>
                  }
                </FormField>
              </div>
              <FormSectionPadding>
                <FormSubHeading>Link/URL:</FormSubHeading>
                <FormItalicHeading>Please enter the organization’s primary website link/URL.</FormItalicHeading>
                <div>
                  <FormField>
                    <FormInputFull
                      value={this.state.headerButtonLink}
                      onChange={this.onHeaderButtonLinkChange}
                      type="text"
                      rows="10"
                      cols="10"
                    />
                  </FormField>
                </div>
              </FormSectionPadding>
            </FormSection>

          </FormContainer>

          <ActionButtons>
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  setLocation: (page) => dispatch(setLocation(page)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallToAction);
