import React from 'react';
import './Notification.css'

class Notification extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={this.props.className}>{this.props.message}</div>
    );
  }
}

export default Notification;