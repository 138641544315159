import React, { Component } from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import Select from 'react-select';
import Notification from "../../../components/Notification";
import {FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormInputSmall, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormFileName} from '../../../styles/form-styles';

import {history} from "../../../routers/AppRouter";
import {getMicrositeCategories} from '../../../actions/microsite-categories';

import MainButton from '../../../components/MainButton';
import {getMicrosite, updateMicrosite} from '../../../actions/microsite';
import {getMicrositeSetting, updateMicrositeSetting} from '../../../actions/microsite-settings';

import Spinner from "../../../components/Spinner";
import {capitalizeFirstLetter, shortenFileName, slugify, stringLinkFix} from '../../../utility/string-utils';

import Dropzone from 'react-dropzone';
import {base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef} from '../../../utility/reusable-utils';

import ABSmallButton from '../../../components/ABSmallButton';
import {saveSingleFile} from '../../../actions/upload';
import {
  createMicrositeKeyWords, getMicrositeKeyword, getMicrositeKeywords,
  updateMicrositeKeyWords
} from '../../../actions/microsite-keywords';
import ABButton from '../../../components/ABButton';
import {getMicrositeGallery} from '../../../actions/microsite-gallery';


const PageContainer = styled.div`
  padding: 20px 40px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;

const Title = styled.div`
  cursor: pointer;
`;

const StyledSelect = styled.select`
  font-size: 16px;
  padding: 9px 10px 10px 10px;
  width: 290px;
  border: 1px solid ${colors.grayBorder};
`;

const CenterImage = styled.div`
  padding: 0 0 15px 0;
`;

const ImageSelection = styled.div`
  display: flex;
  // max-width: 300px;
  max-height: 150px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
  outline: none;
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const SettingsSideBar = styled.div`
  background-color: ${colors.lightestGray};
  padding: 20px;
  max-width: 300px;
  margin-left: 40px;
`;

const SettingItem = styled.div`
  padding-bottom: 30px;
`;

const CheckboxRow = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
`;

const KeywordItems = styled.div`
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
`;

const KeywordItem = styled.div`
  background: ${colors.white};
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: 5px 5px 5px 0;
  padding: 8px;
`;
const KeywordTitle = styled.div`
  font-size: 15px;
`;

const RemoveButton = styled.div`
  background: ${colors.lightestGray};
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid ${colors.lightGray};
  padding: 0 4px;
  margin-left: 4px;
`;

const TitleStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 0;
`;

const PageHeading = styled.div`
  color: ${colors.blue};
  font-size: 30px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusHeading = styled.div`
  font-size: 18px;
  text-align: right;
`;

const StatusBodyText = styled.div`
  font-size: 18px;
  padding: 0 0 0 10px;
  color: ${colors.gray300};
  text-align: right;
`;

const PreviewButton = styled.div`
  font-size: 18px;
  padding: 0 10px;
  text-align: right;
  color: ${colors.blue};
  padding: 30px 0 0 0;
  cursor: pointer;
  :hover {
    color: ${colors.black};
  }
`;

const ItalicHeading = styled.div`
  color: ${colors.gray300};
  font-style: italic;
  font-size: 16px;
  padding: 20px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const UploadSpinner = styled.div`
  font-size: 14px;
  padding: 20px 0;
  max-width: 1365px;
  text-align: center;
`;

const ReviewSection = styled.div`
  border: 1px solid ${colors.gray200};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
`;

const SectionLeft = styled.div`
  padding-right: 60px;
`;

const ReviewSectionHeading = styled.div`
  padding: 0 0 15px 0;
  font-weight: bold;
`;

const SectionHeadingWrapper = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
`;

const DisabledText = styled.div`
  padding-left: 20px;
  color: ${colors.red};
  font-weight: bold;
`;

const SubHeading = styled.div`
  padding: 5px 0 10px 0;
  font-weight: bold;
  color: ${colors.gray300};
`;

const SubHeadingBigPadding = styled.div`
  padding: 25px 0 10px 0;
  font-weight: bold;
  color: ${colors.gray300};
`;

const ItemBigHeading = styled.div`
  padding: 10px 0 10px 0;
  font-weight: bold;
  color: ${colors.gray300};
  font-size: 15px;
`;

const Item = styled.div`
  padding: 3px 0;
  display: flex;
  color: ${colors.gray300};
  font-size: 15px;
`;

const ItemHeading = styled.div`
  color: ${colors.gray400};
  padding-right: 5px;
  font-weight: bold;
  font-size: 15px;
`;

const ColumnHeading = styled.div`
  color: ${colors.gray400};
  padding: 3px 5px 3px 0;
  font-weight: bold;
  font-size: 15px;
`;

const ColumnItem = styled.div`
  padding: 4px 0;
  color: ${colors.gray300};
  font-size: 15px;
`;

const LinkItem = styled.a`
  color: ${colors.blue};
`;

const ImagePreview = styled.div`
  padding: 5px 0;
  img {
    height: 100px;
  }
`;

const FeaturedProductImagePreview = styled.div`
  padding: 5px 0;
  display: flex;
  img {
    height: 75px;
    padding-right: 8px;
  }
`;

const Text = styled.div`
  padding: 4px 0;
  display: flex;
  color: ${colors.gray300};
  font-size: 15px;
`;

const SectionGroup = styled.div`
  padding: 10px 0;
`;

const createMicrosite = () => {
  history.push('/microsite')
};

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

class OldMicrosite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      company: '',
      headerButtonText: '',
      headerButtonLink: '',
      error: '',
      modalIsOpen: '',
      selectedCategory: null,
      selectedTemplateType: null,
      keywords: [],
      keywordCount: 3,
      selectedKeyword: '',
      applicationType: '',
      justSaved: false,
      imgSrc: null,
      imgSrcExt: null,
      uploadedFile: null,
      uploadedFileName: '',
      imageUploading: false,
      imageSizeError: false,
      focusCheckbox: false,
      panelsCheckbox: false,
      resourcesCheckbox: false,
      mediaGalleryCheckbox: false,
      categoryOptions: [
        {value: 'upstream', label: 'Upstream', id: 1},
        {value: 'downstream', label: 'Downstream', id: 2}
      ], // for the multi select
      selectedOptions: [] // for the multi select
    };

  }

  componentDidMount() {
    this.props.getMicrositeCategories();
    if (this.props.match.params.id) {
      console.log('this.props.match.params.id: ', this.props.match.params.id);
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
      this.props.getMicrositeKeywords(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.micrositeCategories !== prevProps.micrositeCategories) {
      // this.loadCategoryNamesForMultiSelect(); // removed since I hard coded the categories
    }
    if (this.props.microsite !== prevProps.microsite) {
      if (!this.state.justSaved) {
        this.loadFields();
      }
      this.setState(() => ({justSaved: false}));
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.loadSettingsFields();
    }
    if (this.props.micrositeKeywords !== prevProps.micrositeKeywords) {
      if (!this.state.justSaved) {
        this.loadKeywordsFields();
      }
    }
  }

  loadFields = () => {
    if (this.props.microsite.micrositeSelectedCategories.length > 0) {
      const selectedMicrositeCategory = parseInt(this.props.microsite.micrositeSelectedCategories[0].categoryId, 10);
      console.log('selectedMicrositeCategory: ', selectedMicrositeCategory);
      this.setState({
        company: this.props.microsite.company || '',
        headerButtonText: this.props.microsite.headerButtonText || '',
        headerButtonLink: this.props.microsite.headerButtonLink || '',
        selectedCategory: selectedMicrositeCategory,
        uploadedFileName: this.props.microsite.logo,
        applicationType: this.props.microsite.applicationType
      });
      this.loadSelectedCategoriesForMultiSelect(this.props.microsite.micrositeSelectedCategories);
    } else {
      this.setState({
        company: this.props.microsite.company || '',
        headerButtonText: this.props.microsite.headerButtonText || '',
        headerButtonLink: this.props.microsite.headerButtonLink || '',
        selectedCategory: null,
        uploadedFileName: this.props.microsite.logo
      });
    }
  };

  loadSettingsFields = () => {
    this.setState({
      selectedTemplateType: this.props.micrositeSetting.templateType || '',
      keywordCount: this.props.micrositeSetting.keywordCount,
      focusCheckbox: this.props.micrositeSetting.showFeaturedProduct,
      panelsCheckbox: this.props.micrositeSetting.showAddProducts,
      resourcesCheckbox: this.props.micrositeSetting.showFeaturedResources,
      mediaGalleryCheckbox: this.props.micrositeSetting.showGallery,
    });
  };

  loadKeywordsFields = () => {
    this.setState({
      keywords: this.props.micrositeKeywords
    });
  };

  // just hard coded this since we know the categories
  // loadCategoryNamesForMultiSelect = () => {
  //   // format the names to work with the multi-select
  //   let options = [];
  //   this.props.micrositeCategories.map((item) => {
  //     options.push(
  //       { value: item.name, label: item.name, id: item.id }
  //     )
  //   });
  //   this.setState(() => ({
  //     categoryOptions: options
  //   }));
  // };

  loadSelectedCategoriesForMultiSelect = (selectedCategories) => {
    // have to loop through props selected categories and add in the names and labels
    if (this.state.categoryOptions.length === 0) {
      this.setState(() => ({
        error: 'Category not loaded, refresh page or enter from previous page'
      }));
    }
    let options = [];
    selectedCategories.map((item) => {

      console.log('item: ', item);

      this.state.categoryOptions.map((categoryItem) => {
        console.log('categoryItem: ', categoryItem);
        if (item.categoryId === categoryItem.id) {
          options.push(
            { value: categoryItem.value, label: categoryItem.value, id: item.categoryId}
          )
        }
      });

      console.log('selectedOptions: ', options);

    });

    this.setState(() => ({
      selectedOptions: options
    }));
  };

  onCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    this.setState(() => ({selectedCategory: parseInt(selectedCategory, 10)}));
  };

  onCompanyChange = (e) => {
    const company = e.target.value;
    if (company) {
      this.setState(() => ({companyError: false}));
    }
    this.setState(() => ({company}));
  };

  onButtonTextChange = (e) => {
    const headerButtonText = e.target.value;
    if (headerButtonText.length <= 15) {
      this.setState(() => ({headerButtonText}));
    }
  };

  onButtonLinkChange = (e) => {
    const headerButtonLink = e.target.value;
    this.setState(() => ({headerButtonLink}));
  };

  onTemplateTypeChange = (e) => {
    const selectedTemplateType = e.target.value;
    this.setState(() => ({selectedTemplateType}));
  };

  onKeywordChange = (e) => {
    const selectedKeyword = e.target.value;
    this.setState(() => (
      {selectedKeyword}));
  };

  onKeywordEnter = (e) => {
    if (e.key === 'Enter') {
      let keywordItem = {
        name: e.target.value.toLowerCase(),
      };
      this.setState(() => (
        {
          selectedKeyword: '',
          keywords: [...this.state.keywords, keywordItem]
        }
      ));
    }
  };

  handleApplicationTypeChange = (e) => {
    const applicationType = e.target.value;
    this.setState(() => ({
      applicationType: applicationType
    }));
  };

  onKeywordCountChange = (e) => {
    const keywordCount = e.target.value;
    this.setState(() => (
      {keywordCount}));
  };

  onSave = () => {

    if (!this.state.imageSizeError) {
      if (this.state.uploadedFile) {
        this.setState({imageUploading: true});

        let formattedCompanyName = this.state.company.split(' ').join('-').toLowerCase();

        this.props.saveSingleFile(this.state.uploadedFile, formattedCompanyName, 'microsites')
          .then((cloudFrontUrl) => {
            this.setState({imageUploading: false});
            console.log('cloudfront url returned: ', cloudFrontUrl);
            this.saveForm(cloudFrontUrl);
          });
      } else {
        this.saveForm(null);
      }
    }

  };

  saveForm = (cloudFrontURL) => {

    this.setState(() => ({justSaved: true}));

    if (this.state.company && this.state.headerButtonText && this.state.headerButtonLink && this.state.applicationType) {

      let selectedCategories = [];
      this.state.selectedOptions.map((item) => {
        selectedCategories.push(item.id);
      });

      let microsite = {
        pageTitle: slugify(this.state.company),
        company: this.state.company,
        headerButtonText: this.state.headerButtonText,
        headerButtonLink: this.state.headerButtonLink,
        micrositeSelectedCategories: selectedCategories,
        applicationType: this.state.applicationType,
      };

      if (cloudFrontURL) {
        microsite.logo = cloudFrontURL
      }

      this.props.updateMicrosite(microsite, this.props.microsite.id);

    } else {
      this.showValidationErrors();
    }
  };

  onSettingsSave = () => {

    this.setState(() => ({justSaved: true}));

    if (this.state.selectedTemplateType) {

      let micrositeSetting = {
        templateType: this.state.selectedTemplateType,
        keywordCount: this.state.keywordCount,
        showFeaturedProduct: this.state.focusCheckbox,
        showAddProducts: this.state.panelsCheckbox,
        showFeaturedResources: this.state.resourcesCheckbox,
        showGallery: this.state.mediaGalleryCheckbox
      };
      this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

    }

    if (this.state.keywords.length > 0) {
      let micrositeKeywords = [];
      this.state.keywords.map((item) => {
        micrositeKeywords.push(item)
      });
      if (this.props.micrositeKeywords && this.props.micrositeKeywords.length > 0) {
        this.props.updateMicrositeKeyWords(micrositeKeywords, this.props.microsite.id);
      } else {
        this.props.createMicrositeKeyWords(micrositeKeywords, this.props.microsite.id);
      }
    }

  };

  publishStatus = (status) => {

    let micrositeSetting = {
      status: status
    };

    this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.isValidImageSize(myResult, 389, 167);
          this.setState({
            imgSrc: myResult, //this is the base64 code for preview
            imgSrcExt: extractImageFileExtensionFromBase64(myResult),
            uploadedFile: currentFile,
            uploadedFileName: files[0].name
          })
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  isValidImageSize = (fileDetails, width, height) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function() {
      if (this.width >= width && this.height >= height && this.width <= 476 && this.height <= 247) {
        globalThis.setSingleImageError(false);
      } else {
        globalThis.setSingleImageError(true);
      }
    };
  };

  setSingleImageError = (showError) => {
    this.setState({
      imageSizeError: showError
    });
  };

  imageCheck() {
    if (this.state.imgSrc != null) {
      return <FormFileName>{this.state.uploadedFileName}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName !== null) {
      // return <ImagePreview src={this.state.imgSrc} />
      return <FormFileName>{shortenFileName(this.state.uploadedFileName)}</FormFileName> // puilled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  removeItem(item) {
    this.setState({keywords: this.state.keywords.filter(function(keptItem) {
        return keptItem.name !== item.name
      })});
  }

  handleCategoryChange = (selectedOptions) => {
    console.log('selectedOptions on change: ', selectedOptions);
    this.setState({ selectedOptions });
  };

  checkValue = (showString) => {
    if (!this.props.micrositeSetting[showString]) {
      return 'Disabled';
    } else {
      return ''
    }
  };

  onEditClick = (pageName) => {
    if (this.props.microsite.id) {
      history.push(`/microsite/${pageName}/${this.props.microsite.id}`);
    } else {
      history.push('microsite');
    }
  };

  onPreviewClick = (id, pageTitle) => {
    window.open(`https://aspenalert.com/marketplace/microsite/${pageTitle}?id=${id}`, '_blank');
  };

  onFocusCheckboxChange = () => {
    this.setState({ focusCheckbox: !this.state.focusCheckbox });
  }

  onPanelsCheckboxChange = () => {
    this.setState({ panelsCheckbox: !this.state.panelsCheckbox });
  }

  onResourcesCheckboxChange = () => {
    this.setState({ resourcesCheckbox: !this.state.resourcesCheckbox });
  }

  onMediaGalleryCheckboxChange = () => {
    this.setState({ mediaGalleryCheckbox: !this.state.mediaGalleryCheckbox });
  }

  render() {
    return (
      <PageContainer>
        {
          this.state.error &&
          <Notification className="error" message={this.state.error} />
        }
        {
          this.props.error &&
          <Notification className="error" message={this.props.errorMessage} />
        }
        {
          this.props.errorSetting &&
          <Notification className="error" message={this.props.errorMessageSetting} />
        }
        {
          this.props.successMessage &&
          <Notification className="success" message={this.props.successMessage} />
        }
        {
          this.props.successMessageSetting &&
          <Notification className="success" message={this.props.successMessageSetting} />
        }
        <HeadingContainer>
          <StyledHeading>Edit Microsite</StyledHeading>
          <RightSideHeading>
            <ButtonMargin><MainButton title="Preview Microsite" onClick={() => this.onPreviewClick(this.props.microsite.id, this.props.microsite.pageTitle)}/></ButtonMargin>
            <ButtonMargin><MainButton title="Save Draft" onClick={() => this.publishStatus('draft')}/></ButtonMargin>
            <MainButton title="Publish" dark={true} onClick={() => this.publishStatus('published')}/>
          </RightSideHeading>
        </HeadingContainer>

        <MainContent>

          {/*<div>*/}
          {/*  <FormSectionPadding>*/}
          {/*    <FormLabel>Company/Organization:</FormLabel>*/}
          {/*    <FormField>*/}
          {/*      <FormInputFull*/}
          {/*        value={this.state.company}*/}
          {/*        onChange={this.onCompanyChange}*/}
          {/*        type="text"*/}
          {/*        required={this.state.companyError}*/}
          {/*      />*/}
          {/*    </FormField>*/}
          {/*  </FormSectionPadding>*/}

          {/*  <FormSectionPadding>*/}
          {/*    <FormLabel>Category:</FormLabel>*/}
          {/*    <Select*/}
          {/*      options={this.state.categoryOptions}*/}
          {/*      isMulti*/}
          {/*      onChange={this.handleCategoryChange}*/}
          {/*      value={this.state.selectedOptions}*/}
          {/*    />*/}
          {/*  </FormSectionPadding>*/}

          {/*  <FormSectionPadding>*/}
          {/*    <FormLabel>Application Type:</FormLabel>*/}
          {/*    <StyledSelect*/}
          {/*      name="applicationType"*/}
          {/*      value={this.state.applicationType}*/}
          {/*      onChange={this.handleApplicationTypeChange}*/}
          {/*    >*/}
          {/*      <option value=''>Select</option>*/}
          {/*      <option value="all">All</option>*/}
          {/*      <option value="upstream">Upstream</option>*/}
          {/*      <option value="downstream">Downstream</option>*/}
          {/*    </StyledSelect>*/}
          {/*  </FormSectionPadding>*/}

          {/*  <FormSectionPadding>*/}
          {/*    <FormLabel>Button Text:</FormLabel>*/}
          {/*    <FormField>*/}
          {/*      <FormInputFull*/}
          {/*        value={this.state.headerButtonText}*/}
          {/*        onChange={this.onButtonTextChange}*/}
          {/*        type="text"*/}
          {/*        rows= "10"*/}
          {/*        cols="10"*/}
          {/*      />*/}
          {/*    </FormField>*/}
          {/*  </FormSectionPadding>*/}

          {/*  <FormSectionPadding>*/}
          {/*    <FormLabel>Button Link/URL:</FormLabel>*/}
          {/*    <FormField>*/}
          {/*      <FormInputFull*/}
          {/*        value={this.state.headerButtonLink}*/}
          {/*        onChange={this.onButtonLinkChange}*/}
          {/*        type="text"*/}
          {/*        rows= "10"*/}
          {/*        cols="10"*/}
          {/*      />*/}
          {/*    </FormField>*/}
          {/*  </FormSectionPadding>*/}

          {/*  <FormSectionPadding>*/}
          {/*    <div>*/}
          {/*      <FormSubHeading>Organization Logo:</FormSubHeading>*/}
          {/*      <FormItalicHeading>The submitted Organization Logo should be at least 389 pixels wide and 167 pixels tall*/}
          {/*        but not exceed 476 pixels wide and 247 pixels tall.</FormItalicHeading>*/}
          {/*      <div>*/}
          {/*        <FormField>*/}
          {/*          <CenterImage>*/}
          {/*            <Dropzone*/}
          {/*              onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles)}*/}
          {/*              multiple={false}*/}
          {/*              accept={acceptedFileTypes}*/}
          {/*            >*/}
          {/*              {({getRootProps, getInputProps}) => (*/}
          {/*                <section>*/}
          {/*                  <ImageSelection {...getRootProps()}>*/}
          {/*                    <input {...getInputProps()} />*/}
          {/*                    <ABSmallButton title="File Upload" />*/}
          {/*                    <UploadButtonContainer>*/}
          {/*                      {this.imageCheck()}*/}
          {/*                      {*/}
          {/*                        this.state.imageSizeError &&*/}
          {/*                        <ValidationError>Invalid Image Size</ValidationError>*/}
          {/*                      }*/}
          {/*                    </UploadButtonContainer>*/}
          {/*                  </ImageSelection>*/}
          {/*                </section>*/}
          {/*              )}*/}
          {/*            </Dropzone>*/}
          {/*          </CenterImage>*/}
          {/*        </FormField>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </FormSectionPadding>*/}

          {/*  <MainButton title="Save" onClick={() => this.onSave()} />*/}

          {/*</div>*/}

          <div>
            <TitleStatusContainer>
              <div>
                {/*<PageHeading>Edit Microsite</PageHeading>*/}
                {/*<ItalicHeading>Please review all content before publishing.</ItalicHeading>*/}
              </div>
              <div>
                <StatusContainer>
                  <StatusHeading>Status:</StatusHeading>
                  <StatusBodyText>{capitalizeFirstLetter(this.props.micrositeSetting.status)}</StatusBodyText>
                </StatusContainer>
                {/*<PreviewButton onClick={() => this.onPreviewClick(this.props.microsite.id, this.props.microsite.pageTitle)}>Preview Microsite</PreviewButton>*/}
              </div>
            </TitleStatusContainer>

            <ReviewSection>
              <SectionLeft>
                <ReviewSectionHeading>Access</ReviewSectionHeading>
                <Item>
                  <ItemHeading>User Id:</ItemHeading>
                  <div>{this.props.microsite.userId}</div>
                </Item>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('user-access')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <ReviewSectionHeading>Client Portal</ReviewSectionHeading>
                <Item>
                  <ItemHeading>Client Portal Id:</ItemHeading>
                  <div>{this.props.microsite.clientPortalId}</div>
                </Item>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('client-portal-access')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <ReviewSectionHeading>Organization Details</ReviewSectionHeading>
                <SubHeading>Microsite Content Contact:</SubHeading>
                <Item>
                  <ItemHeading>Name:</ItemHeading>
                  <div>{this.props.microsite.firstName} {this.props.microsite.lastName}</div>
                </Item>
                <Item>
                  <ItemHeading>Job Title:</ItemHeading>
                  <div>{this.props.microsite.jobTitle}</div>
                </Item>
                <Item>
                  <ItemHeading>Company/Organization:</ItemHeading>
                  <div>{this.props.microsite.company}</div>
                </Item>
                <Item>
                  <ItemHeading>Email Address:</ItemHeading>
                  <div>{this.props.microsite.email}</div>
                </Item>
                <Item>
                  <ItemHeading>Category:</ItemHeading>
                  <div>{capitalizeFirstLetter(this.props.microsite.applicationType)}</div>
                </Item>
                <SubHeadingBigPadding>Organization Brand Color:</SubHeadingBigPadding>
                <Item>
                  <ItemHeading>Primary:</ItemHeading>
                  <div>#{this.props.microsite.primaryColor}</div>
                </Item>
                <Item>
                  <ItemHeading>Secondary Color:</ItemHeading>
                  <div>{this.props.microsite.secondaryColor}</div>
                </Item>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('organization-details')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Keywords</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showContactInfoSection')}</DisabledText>
                </SectionHeadingWrapper>
                <Item>
                  <ItemHeading>Keywords:</ItemHeading>
                  <div>
                    {
                      (this.props.keywords && this.props.keywords.length > 0) &&
                      this.props.keywords.map((item, index) => {
                        if (this.props.keywords.length > index + 1) {
                          return `${item.name}, `
                        } else {
                          return `${item.name}`
                        }
                      })
                    }
                  </div>
                </Item>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('keywords')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Call to Action</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showCTASection')}</DisabledText>
                </SectionHeadingWrapper>
                <Item>
                  <ItemHeading>Call to Action Title:</ItemHeading>
                  <div>{this.props.microsite.headerTitle}</div>
                </Item>
                <Item>
                  <ItemHeading>Call to Action Body:</ItemHeading>
                  <div>{this.props.microsite.headerBody}</div>
                </Item>
                <Item>
                  <ItemHeading>Button Text:</ItemHeading>
                  <div>{this.props.microsite.headerButtonText}</div>
                </Item>
                <Item>
                  <ItemHeading>Link/URL:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.headerButtonLink)} target="_blank">{this.props.microsite.headerButtonLink}</LinkItem></div>
                </Item>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('call-to-action')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Organization Logo and Biography</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showBioSection')}</DisabledText>
                </SectionHeadingWrapper>
                <ColumnHeading>Organization Logo:</ColumnHeading>
                <ImagePreview><img src={`https://${this.props.microsite.logo}`} alt=""/></ImagePreview>
                <Item>
                  <ItemHeading>Organization Tagline:</ItemHeading>
                  <div>{this.props.microsite.tagLine}</div>
                </Item>
                <ColumnHeading>Organization Biography:</ColumnHeading>
                <Text>{this.props.microsite.description}</Text>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('logo-bio')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Focus</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showFeaturedProduct')}</DisabledText>
                </SectionHeadingWrapper>
                <Item>
                  <ItemHeading>Name:</ItemHeading>
                  <div>{this.props.microsite.featureProductName}</div>
                </Item>
                <ColumnHeading>Description:</ColumnHeading>
                <Text>{this.props.microsite.featureProductDescription}</Text>
                <Item>
                  <ItemHeading>Button Text:</ItemHeading>
                  <div>{this.props.microsite.featureProductButtonText}</div>
                </Item>
                <Item>
                  <ItemHeading>Link/URL:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.featureProductButtonLink)} target="_blank">{this.props.microsite.featureProductButtonLink}</LinkItem></div>
                </Item>
                <ColumnHeading>Featured Product/Service Banner Image:</ColumnHeading>
                <ImagePreview><img src={`https://${this.props.microsite.featureProductBannerImage}`} alt=""/></ImagePreview>
                <ColumnHeading>Featured Product/Service Images:</ColumnHeading>
                <FeaturedProductImagePreview>
                  <img src={`https://${this.props.microsite.featureProductImage1}`} alt=""/>
                </FeaturedProductImagePreview>
                <FeaturedProductImagePreview>
                  <img src={`https://${this.props.microsite.featureProductImage2}`} alt=""/>
                </FeaturedProductImagePreview>
                <FeaturedProductImagePreview>
                  <img src={`https://${this.props.microsite.featureProductImage3}`} alt=""/>
                </FeaturedProductImagePreview>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('focus')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Panels</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showAddProducts')}</DisabledText>
                </SectionHeadingWrapper>
                <SectionGroup>
                  <SubHeading>Additional Product/Service 1: </SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.addProductName}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.addProductButtonText}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.addProductButtonLink)} target="_blank">{this.props.microsite.addProductButtonLink}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Product/Service Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.addProductImage}`} alt=""/></ImagePreview>
                </SectionGroup>
                <SectionGroup>
                  <SubHeading>Additional Product/Service 2: </SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.addProductName2}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.addProductButtonText2}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.addProductButtonLink2)} target="_blank">{this.props.microsite.addProductButtonLink2}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Product/Service Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.addProductImage2}`} alt=""/></ImagePreview>
                </SectionGroup>
                <SectionGroup>
                  <SubHeading>Additional Product/Service 3: </SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.addProductName3}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.addProductButtonText3}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.addProductButtonLink3)} target="_blank">{this.props.microsite.addProductButtonLink3}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Product/Service Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.addProductImage3}`} alt=""/></ImagePreview>
                </SectionGroup>
                <SectionGroup>
                  <SubHeading>Additional Product/Service 4: </SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.addProductName4}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.addProductButtonText4}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.addProductButtonLink4)} target="_blank">{this.props.microsite.addProductButtonLink4}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Product/Service Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.addProductImage4}`} alt=""/></ImagePreview>
                </SectionGroup>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('panels')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Resources</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showFeaturedResources')}</DisabledText>
                </SectionHeadingWrapper>
                <SectionGroup>
                  <SubHeading>Featured Resource 1:</SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.resourceName}</div>
                  </Item>
                  <div>
                    <ItemHeading>Description:</ItemHeading>
                    <Text>{this.props.microsite.resourceDescription}</Text>
                  </div>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.resourceButtonText}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.resourceButtonLink)} target="_blank">{this.props.microsite.resourceButtonLink}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Resource Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.resourceImage}`} alt=""/></ImagePreview>
                </SectionGroup>
                <SectionGroup>
                  <SubHeading>Featured Resource 2:</SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.resourceName2}</div>
                  </Item>
                  <div>
                    <ItemHeading>Description:</ItemHeading>
                    <Text>{this.props.microsite.resourceDescription2}</Text>
                  </div>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.resourceButtonText2}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.resourceButtonLink2)} target="_blank">{this.props.microsite.resourceButtonLink2}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Resource Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.resourceImage2}`} alt=""/></ImagePreview>
                </SectionGroup>
                <SectionGroup>
                  <SubHeading>Featured Resource 3:</SubHeading>
                  <Item>
                    <ItemHeading>Name:</ItemHeading>
                    <div>{this.props.microsite.resourceName3}</div>
                  </Item>
                  <div>
                    <ItemHeading>Description:</ItemHeading>
                    <Text>{this.props.microsite.resourceDescription3}</Text>
                  </div>
                  <Item>
                    <ItemHeading>Button Text:</ItemHeading>
                    <div>{this.props.microsite.resourceButtonText3}</div>
                  </Item>
                  <Item>
                    <ItemHeading>Link/URL:</ItemHeading>
                    <div><LinkItem href={stringLinkFix(this.props.microsite.resourceButtonLink3)} target="_blank">{this.props.microsite.resourceButtonLink3}</LinkItem></div>
                  </Item>
                  <ColumnHeading>Resource Image:</ColumnHeading>
                  <ImagePreview><img src={`https://${this.props.microsite.resourceImage3}`} alt=""/></ImagePreview>
                </SectionGroup>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('resources')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Media Gallery</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showGallery')}</DisabledText>
                </SectionHeadingWrapper>
                <SubHeading>Images</SubHeading>
                <div>
                  {
                    this.props.micrositeGallery.imageUrl1 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl1}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl2 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl2}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl3 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl3}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl4 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl4}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl5 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl5}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl6 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl6}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                  {
                    this.props.micrositeGallery.imageUrl7 &&
                    <FeaturedProductImagePreview>
                      <img src={`https://${this.props.micrositeGallery.imageUrl7}`} alt=""/>
                    </FeaturedProductImagePreview>
                  }
                </div>
                <SubHeading>Videos</SubHeading>
                <div>
                  {
                    this.props.micrositeGallery.imageUrl8 &&
                    <Item>
                      {shortenFileName(this.props.micrositeGallery.imageUrl8)}
                    </Item>
                  }
                  {
                    this.props.micrositeGallery.imageUrl9 &&
                    <Item>
                      {shortenFileName(this.props.micrositeGallery.imageUrl9)}
                    </Item>
                  }
                  {
                    this.props.micrositeGallery.imageUrl10 &&
                    <Item>
                      {shortenFileName(this.props.micrositeGallery.imageUrl10)}
                    </Item>
                  }
                </div>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('media-gallery')} />
            </ReviewSection>

            <ReviewSection>
              <SectionLeft>
                <SectionHeadingWrapper>
                  <ReviewSectionHeading>Be Connected</ReviewSectionHeading>
                  <DisabledText>{this.checkValue('showContactInfoSection')}</DisabledText>
                </SectionHeadingWrapper>
                <SubHeading>Organization Social Media Channels:</SubHeading>
                <Item>
                  <ItemHeading>Facebook:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.facebookLink)} target="_blank">{this.props.microsite.facebookLink}</LinkItem></div>
                </Item>
                <Item>
                  <ItemHeading>Instagram:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.instagramLink)} target="_blank">{this.props.microsite.instagramLink}</LinkItem></div>
                </Item>
                <Item>
                  <ItemHeading>Twitter:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.twitterLink)} target="_blank">{this.props.microsite.twitterLink}</LinkItem></div>
                </Item>
                <Item>
                  <ItemHeading>LinkedIn:</ItemHeading>
                  <div><LinkItem href={stringLinkFix((this.props.microsite.linkedInLink))} target="_blank">{this.props.microsite.linkedInLink}</LinkItem></div>
                </Item>
                <Item>
                  <ItemHeading>Website URL:</ItemHeading>
                  <div><LinkItem href={stringLinkFix(this.props.microsite.websiteLink)} target="_blank">{this.props.microsite.websiteLink}</LinkItem></div>
                </Item>
                <ItemBigHeading>Microsite Contact Request Email Addresses:</ItemBigHeading>
                <ColumnItem>
                  <div>{this.props.microsite.recName1} <LinkItem href={`mailto:${this.props.microsite.recEmail1}`}>{this.props.microsite.recEmail1}</LinkItem></div>
                  <div>{this.props.microsite.recName2} <LinkItem href={`mailto:${this.props.microsite.recEmail2}`}>{this.props.microsite.recEmail2}</LinkItem></div>
                  <div>{this.props.microsite.recName3} <LinkItem href={`mailto:${this.props.microsite.recEmail3}`}>{this.props.microsite.recEmail3}</LinkItem></div>
                </ColumnItem>
              </SectionLeft>
              <ABButton title="Edit" onClick={() => this.onEditClick('be-connected')} />
            </ReviewSection>

            <ActionButtons>
              {/*<div>*/}
              {/*{*/}
              {/*this.props.editing &&*/}
              {/*<ABButton title="Previous Page" color="gray300" onClick={() => this.onPreviousClick()} />*/}
              {/*}*/}
              {/*</div>*/}
              {/*<RightButton>*/}
              {/*<Link*/}
              {/*href={{*/}
              {/*pathname: `./`*/}
              {/*}}>*/}
              {/*<ABButton title="Save & Close" />*/}
              {/*</Link>*/}
              {/*<ABButton title="Submit" color="orange" onClick={() => this.onNextClick()} />*/}
              {/*</RightButton>*/}
              <div>

              </div>
              {/*<RightButton>*/}
              {/*  <div></div>*/}
              {/*  {*/}
              {/*    this.props.micrositeSetting.status === 'draft' &&*/}
              {/*    <ABButton title="Publish" color="blue" onClick={() => this.onNextClick('published')} />*/}
              {/*  }*/}
              {/*  {*/}
              {/*    this.props.micrositeSetting.status === 'published' &&*/}
              {/*    <ABButton title="Save as Draft" color="blue" onClick={() => this.onNextClick('draft')} />*/}
              {/*  }*/}
              {/*</RightButton>*/}
            </ActionButtons>
            {
              this.props.loading &&
              <UploadSpinner>
                <Spinner />
                <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
              </UploadSpinner>
            }

          </div>


          <SettingsSideBar>
            <SettingItem>
              <StyledTitle>Template Type:</StyledTitle>
              <StyledSelect
                name="category"
                value={this.state.selectedTemplateType}
                onChange={this.onTemplateTypeChange}
              >
                <option value=''>Select</option>
                <option value="complimentary">Complimentary</option>
                <option value="standard">Standard</option>
                <option value="signature">Signature</option>
                <option value="professional">Professional</option>
              </StyledSelect>
            </SettingItem>
            <SettingItem>
              <StyledTitle>Keyword Limit:</StyledTitle>
              <FormField>
                <FormInputSmall
                  value={this.state.keywordCount}
                  onChange={this.onKeywordCountChange}
                  type="text"
                />
              </FormField>
            </SettingItem>
            <SettingItem>
              <StyledTitle>Sections Enabled:</StyledTitle>
              <CheckboxRow>
                <label>Focus:</label>
                <input
                  type="checkbox"
                  name="focus"
                  checked={this.state.focusCheckbox}
                  onChange={this.onFocusCheckboxChange}
                >
                </input>
              </CheckboxRow>
              <CheckboxRow>
                <label>Panels:</label>
                <input
                  type="checkbox"
                  name="panels"
                  checked={this.state.panelsCheckbox}
                  onChange={this.onPanelsCheckboxChange}
                >
                </input>
              </CheckboxRow>
              <CheckboxRow>
                <label>Resources:</label>
                <input
                  type="checkbox"
                  name="resources"
                  checked={this.state.resourcesCheckbox}
                  onChange={this.onResourcesCheckboxChange}
                >
                </input>
              </CheckboxRow>
              <CheckboxRow>
                <label>Media Gallery:</label>
                <input
                  type="checkbox"
                  name="media-gallery"
                  checked={this.state.mediaGalleryCheckbox}
                  onChange={this.onMediaGalleryCheckboxChange}
                >
                </input>
              </CheckboxRow>
            </SettingItem>
            {/*<SettingItem>*/}
            {/*  <FormLabel>Keyword:</FormLabel>*/}
            {/*  /!*<FormField>*!/*/}
            {/*  /!*  <FormInputSmall*!/*/}
            {/*  /!*    value={this.state.selectedKeyword}*!/*/}
            {/*  /!*    onChange={this.onKeywordChange}*!/*/}
            {/*  /!*    onKeyDown={this.onKeywordEnter}*!/*/}
            {/*  /!*    type="text"*!/*/}
            {/*  /!*  />*!/*/}
            {/*  /!*</FormField>*!/*/}
            {/*  /!*<KeywordItems>*!/*/}
            {/*  /!*  {*!/*/}
            {/*  /!*    (this.state.keywords && this.state.keywords.length > 0) &&*!/*/}
            {/*  /!*    this.state.keywords.map((item, index) => {*!/*/}
            {/*  /!*      return <KeywordItem key={index}>*!/*/}
            {/*  /!*        <KeywordTitle>{item.name}</KeywordTitle>*!/*/}
            {/*  /!*        <RemoveButton onClick={() => this.removeItem(item)}>x</RemoveButton>*!/*/}
            {/*  /!*      </KeywordItem>*!/*/}
            {/*  /!*    })*!/*/}
            {/*  /!*  }*!/*/}
            {/*  /!*</KeywordItems>*!/*/}
            {/*</SettingItem>*/}
            <MainButton title="Save Settings" onClick={() => this.onSettingsSave()} />
          </SettingsSideBar>
        </MainContent>



      </PageContainer>

    );
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.microsite.loading,
    error: state.microsite.error,
    errorSetting: state.micrositeSetting.error,
    errorMessage: state.microsite.errorMessage,
    errorMessageSetting: state.micrositeSetting.errorMessage,
    successMessage: state.microsite.successMessage,
    successMessageSetting: state.micrositeSetting.successMessage,
    micrositeCategories: state.micrositeCategories.data,
    micrositeSetting: state.micrositeSetting.data,
    micrositeKeywords: state.micrositeKeywords.data,
    microsite: state.microsite.data,
    keywords: state.micrositeKeywords.data,
    micrositeGallery: state.micrositeGallery.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrositeCategories: () => dispatch(getMicrositeCategories()),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
  createMicrositeKeyWords: (micrositeKeywords, micrositeId) => dispatch(createMicrositeKeyWords(micrositeKeywords, micrositeId)),
  updateMicrositeKeyWords: (micrositeKeywords, micrositeId) => dispatch(updateMicrositeKeyWords(micrositeKeywords, micrositeId)),
  getMicrositeKeywords: (micrositeId) => dispatch(getMicrositeKeywords(micrositeId)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  getMicrositeGallery: (id) => dispatch(getMicrositeGallery(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OldMicrosite);
