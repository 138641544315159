import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../routers/AppRouter';
import { createMicrosite, getMicrosite, updateMicrosite } from '../../../actions/microsite';
import Spinner from "../../../components/Spinner";

import Dropzone from 'react-dropzone';
import {
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
  image64toCanvasRef
} from '../../../utility/reusable-utils';

import styled from 'styled-components';
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding, FormFileName, FormInputCharCount } from '../../../styles/form-styles.js';
import colors from '../../../styles/colors';
import ABButton from '../../../components/ABButton';
import ABSmallButton from '../../../components/ABSmallButton';
import { saveSingleFile } from '../../../actions/upload';
import { updateMicrositeSubmission } from '../../../actions/microsite-submission';
import { setLocation } from '../../../actions/location';
import { shortenFileName } from '../../../utility/string-utils';
import { getMicrositeSetting, updateMicrositeSetting } from '../../../actions/microsite-settings';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0;
`;

const SkipSection = styled.form`
  font-size: 15px;
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ResourceContainer = styled.div`
  padding: 10px 0;
`;

const ImagePreview = styled.img`
  max-width: 300px;
  max-height: 150px;
  object-fit: cover;
  margin-left: 20px;
`;

const CenterImage = styled.div`
  padding: 0 0 15px 0;
`;

const ImageSelection = styled.div`
  display: flex;
  // max-width: 300px;
  max-height: 150px;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
  outline: none;
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  color: red;
  padding-left: 10px;
  font-weight: normal;
  font-size: 15px;
`;

const UploadSpinner = styled.div`
  font-size: 14px;
  padding: 20px 0;
  max-width: 1365px;
  text-align: center;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

class Resources extends Component {

  constructor(props) {
    super(props);

    this.state = {
      resourceName: '',
      resourceDescription: '',
      resourceButtonText: '',
      resourceButtonLink: '',
      resourceName2: '',
      resourceDescription2: '',
      resourceButtonText2: '',
      resourceButtonLink2: '',
      resourceName3: '',
      resourceDescription3: '',
      resourceButtonText3: '',
      resourceButtonLink3: '',
      imgSrc: null,
      imgSrc2: null,
      imgSrc3: null,
      imgSrcExt: null,
      uploadedFile: null,
      uploadedFileName: '',
      imageUploading: false,
      uploadedFile2: null,
      uploadedFileName2: '',
      uploadedFile3: null,
      uploadedFileName3: '',
      showSection: false,
      imageSizeError1: false,
      imageSizeError2: false,
      imageSizeError3: false,
    };

  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.getMicrosite(this.props.match.params.id);
      this.props.getMicrositeSetting(this.props.match.params.id);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.microsite !== prevProps.microsite) {
      this.loadFields();
    }
    if (this.props.micrositeSetting !== prevProps.micrositeSetting) {
      this.setState({
        showSection: this.props.micrositeSetting.showFeaturedResources || false
      });
    }
  }

  loadFields = () => {

    this.setState({
      resourceName: this.props.microsite.resourceName,
      resourceDescription: this.props.microsite.resourceDescription,
      resourceButtonText: this.props.microsite.resourceButtonText,
      resourceButtonLink: this.props.microsite.resourceButtonLink,

      resourceName2: this.props.microsite.resourceName2,
      resourceDescription2: this.props.microsite.resourceDescription2,
      resourceButtonText2: this.props.microsite.resourceButtonText2,
      resourceButtonLink2: this.props.microsite.resourceButtonLink2,

      resourceName3: this.props.microsite.resourceName3,
      resourceDescription3: this.props.microsite.resourceDescription3,
      resourceButtonText3: this.props.microsite.resourceButtonText3,
      resourceButtonLink3: this.props.microsite.resourceButtonLink3,

      uploadedFileName: this.props.microsite.resourceImage,
      uploadedFileName2: this.props.microsite.resourceImage2,
      uploadedFileName3: this.props.microsite.resourceImage3
    });
  };

  onNextClick = (close) => {

    if (!this.state.imageSizeError1 && !this.state.imageSizeError2 && !this.state.imageSizeError3) {

      if (this.state.uploadedFile || this.state.uploadedFile2 || this.state.uploadedFile3) {
        console.log('thinks there is an image');
        this.setState({ imageUploading: true });

        let formattedCompanyName = this.props.microsite.company.split(' ').join('-').toLowerCase();

        let allCloudFrontUrls = [];
        let allUploadedFiles = [];

        if (this.state.uploadedFile) {
          allUploadedFiles.push({ fileNumber: 1, file: this.state.uploadedFile });
        }

        if (this.state.uploadedFile2) {
          allUploadedFiles.push({ fileNumber: 2, file: this.state.uploadedFile2 });
        }

        if (this.state.uploadedFile3) {
          allUploadedFiles.push({ fileNumber: 3, file: this.state.uploadedFile3 });
        }

        let count = 0;
        allUploadedFiles.map((item, index) => {
          this.props.saveSingleFile(item.file, formattedCompanyName, 'microsites')
            .then((cloudFrontUrl) => {
              allCloudFrontUrls.push({ fileNumber: item.fileNumber, cloudFrontUrl: cloudFrontUrl });
              count++;
              if (count === allUploadedFiles.length) {
                this.setState({ imageUploading: false });
                this.saveForm(allCloudFrontUrls, close);
              }
            });
        });
      } else {
        this.saveForm(null, close);
      }
    }

  };

  saveForm = (allCloudFrontUrls, close) => {

    let micrositeSetting = {
      showFeaturedResources: this.state.showSection
    };

    let microsite = {
      resourceName: this.state.resourceName,
      resourceDescription: this.state.resourceDescription,
      resourceButtonText: this.state.resourceButtonText,
      resourceButtonLink: this.state.resourceButtonLink,
      resourceName2: this.state.resourceName2,
      resourceDescription2: this.state.resourceDescription2,
      resourceButtonText2: this.state.resourceButtonText2,
      resourceButtonLink2: this.state.resourceButtonLink2,
      resourceName3: this.state.resourceName3,
      resourceDescription3: this.state.resourceDescription3,
      resourceButtonText3: this.state.resourceButtonText3,
      resourceButtonLink3: this.state.resourceButtonLink3,
      userId: this.props.microsite.userId
    };

    if (allCloudFrontUrls) {
      allCloudFrontUrls.map((item, index) => {
        switch (item.fileNumber) {
          case 1:
            console.log('image 1');
            microsite.resourceImage = item.cloudFrontUrl;
            break;
          case 2:
            console.log('image 2');
            microsite.resourceImage2 = item.cloudFrontUrl;
            break;
          case 3:
            console.log('image 3');
            microsite.resourceImage3 = item.cloudFrontUrl;
            break;
          default:
          // should only be 3 max
        }
      });
    }

    this.props.updateMicrosite(microsite, this.props.microsite.id);

    let micrositeSubmission = {
      resources: true
    };
    this.props.updateMicrositeSubmission(micrositeSubmission, this.props.microsite.id);

    this.props.updateMicrositeSetting(micrositeSetting, this.props.microsite.id);

    if (close) {
      history.push(`../${this.props.microsite.id}`);
      return;
    }
  };

  onResourceNameChange = (e) => {
    const resourceName = e.target.value;
    if (resourceName.length <= 50) {
      this.setState(() => ({ resourceName }));
    }
  };

  onResourceDescriptionChange = (e) => {
    const resourceDescription = e.target.value;
    if (resourceDescription.length <= 600) {
      this.setState(() => ({ resourceDescription }));
    }
  };

  onResourceButtonTextChange = (e) => {
    const resourceButtonText = e.target.value;
    if (resourceButtonText.length <= 15) {
      this.setState(() => ({ resourceButtonText }));
    }
  };

  onResourceButtonLinkChange = (e) => {
    const resourceButtonLink = e.target.value;
    this.setState(() => ({ resourceButtonLink }));
  };

  onResourceNameChange2 = (e) => {
    const resourceName2 = e.target.value;
    if (resourceName2.length <= 50) {
      this.setState(() => ({ resourceName2 }));
    }
  };

  onResourceDescriptionChange2 = (e) => {
    const resourceDescription2 = e.target.value;
    if (resourceDescription2.length <= 600) {
      this.setState(() => ({ resourceDescription2 }));
    }
  };

  onResourceButtonTextChange2 = (e) => {
    const resourceButtonText2 = e.target.value;
    if (resourceButtonText2.length <= 15) {
      this.setState(() => ({ resourceButtonText2 }));
    }
  };

  onResourceButtonLinkChange2 = (e) => {
    const resourceButtonLink2 = e.target.value;
    this.setState(() => ({ resourceButtonLink2 }));
  };

  onResourceNameChange3 = (e) => {
    const resourceName3 = e.target.value;
    if (resourceName3.length <= 50) {
      this.setState(() => ({ resourceName3 }));
    }
  };

  onResourceDescriptionChange3 = (e) => {
    const resourceDescription3 = e.target.value;
    if (resourceDescription3.length <= 600) {
      this.setState(() => ({ resourceDescription3 }));
    }
  };

  onResourceButtonTextChange3 = (e) => {
    const resourceButtonText3 = e.target.value;
    if (resourceButtonText3.length <= 15) {
      this.setState(() => ({ resourceButtonText3 }));
    }
  };

  onResourceButtonLinkChange3 = (e) => {
    const resourceButtonLink3 = e.target.value;
    this.setState(() => ({ resourceButtonLink3 }));
  };

  toggleCheckbox = (e) => {
    const showSection = e.target.checked;
    this.setState(() => ({ showSection: !showSection }));
  };

  // File upload

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        console.log('file type not allowed!');
        alert('This file is not allowed. Only images are allowed.');
        return false
      }
      return true
    }
  };

  handleOnDrop(files, rejectedFiles, fileNumber) {

    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        // imageBase64Data
        const currentFile = files[0];
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener("load", () => {
          // console.log(myFileItemReader.result)
          const myResult = myFileItemReader.result;
          this.isValidImageSize(myResult, 300, 300, fileNumber);
          if (fileNumber === 2) {
            this.setState({
              imgSrc2: myResult,
              uploadedFile2: currentFile,
              uploadedFileName2: files[0].name
            })
          } else if (fileNumber === 3) {
            this.setState({
              imgSrc3: myResult,
              uploadedFile3: currentFile,
              uploadedFileName3: files[0].name
            })
          } else {
            this.setState({
              imgSrc: myResult, //this is the base64 code for preview
              imgSrcExt: extractImageFileExtensionFromBase64(myResult),
              uploadedFile: currentFile,
              uploadedFileName: files[0].name
            })
          }
        }, false);

        myFileItemReader.readAsDataURL(currentFile)

      }
    }

  }

  imageCheck() {
    if (this.state.imgSrc != null) {
      return <FormFileName>{this.state.uploadedFileName}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName !== null) {
      return <FormFileName>{shortenFileName(this.state.uploadedFileName)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck2() {
    if (this.state.imgSrc2 != null) {
      return <FormFileName>{this.state.uploadedFileName2}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName2 !== null) {
      return <FormFileName>{shortenFileName(this.state.uploadedFileName2)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  imageCheck3() {
    if (this.state.imgSrc3 != null) {
      return <FormFileName>{this.state.uploadedFileName3}</FormFileName> // just uploaded
    } else if (this.state.uploadedFileName3 !== null) {
      return <FormFileName>{shortenFileName(this.state.uploadedFileName3)}</FormFileName> // pulled from server
    } else {
      return (
        <div></div>
      )
    }
  }

  isValidImageSize = (fileDetails, width, height, fileNumber) => {
    let globalThis = this;
    let image = new Image();
    image.src = fileDetails;
    image.onload = function () {
      if (this.width === width && this.height === height) {
        globalThis.setSingleImageError(false, fileNumber);
      } else {
        globalThis.setSingleImageError(true, fileNumber);
      }
    };
  };

  setSingleImageError = (showError, fileNumber) => {
    if (fileNumber === 1) {
      this.setState({
        imageSizeError1: showError
      });
    } else if (fileNumber === 2) {
      this.setState({
        imageSizeError2: showError
      });
    } else if (fileNumber === 3) {
      this.setState({
        imageSizeError3: showError
      });
    }
  };

  render() {
    return (
      <>
        <PageWrapper>
          <PageHeading>
            <PageTitle>Resources</PageTitle>
            <SkipSection>
              <input
                type='checkbox'
                checked={!this.state.showSection}
                onChange={(e) => this.toggleCheckbox(e)}
              />
              Disable this section
            </SkipSection>
          </PageHeading>
          <FormContainer>
            <FormSection>

              <ResourceContainer>
                <FormSubHeading>Featured Resource 1:</FormSubHeading>
                <FormLabel>Name</FormLabel>
                <FormItalicHeading>Resource Name should not exceed 50 characters including spaces.</FormItalicHeading>
                <div>
                  <FormField>
                    <FormInputFull
                      value={this.state.resourceName}
                      onChange={this.onResourceNameChange}
                      type="text"
                      tabIndex="1"
                    />
                    {
                      this.state.resourceName &&
                      <FormInputCharCount>Char count: {this.state.resourceName.length}</FormInputCharCount>
                    }
                  </FormField>
                </div>

                <FormSectionPadding>
                  <FormLabel>Resource Description</FormLabel>
                  <FormItalicHeading>The Featured Resource Descriptions should not exceed 600 characters, including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormTextArea
                        value={this.state.resourceDescription}
                        onChange={this.onResourceDescriptionChange}
                        type="text"
                        rows="10"
                        cols="10"
                        tabIndex="2"
                      />
                      {
                        this.state.resourceDescription &&
                        <FormInputCharCount>Char count: {this.state.resourceDescription.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Button Text</FormLabel>
                  <FormItalicHeading>Not to exceed 15 characters including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonText}
                        onChange={this.onResourceButtonTextChange}
                        type="text"
                        tabIndex="3"
                      />
                      {
                        this.state.resourceButtonText &&
                        <FormInputCharCount>Char count: {this.state.resourceButtonText.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Link URL</FormLabel>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonLink}
                        onChange={this.onResourceButtonLinkChange}
                        type="text"
                        tabIndex="4"
                      />
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Resource Image</FormLabel>
                  <FormItalicHeading>The submitted Resource Image should be exactly 300 pixels wide and 300 pixels tall.</FormItalicHeading>
                  <div>
                    <FormField>
                      <CenterImage>
                        <Dropzone
                          onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 1)}
                          multiple={false}
                          accept={acceptedFileTypes}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section tabIndex="5">
                              <ImageSelection {...getRootProps()}>
                                <input {...getInputProps()} />
                                <ABSmallButton title="File Upload" />
                                <UploadButtonContainer>
                                  {this.imageCheck()}
                                  {
                                    this.state.imageSizeError1 &&
                                    <ValidationError>Invalid Image Size</ValidationError>
                                  }
                                </UploadButtonContainer>
                              </ImageSelection>
                            </section>
                          )}
                        </Dropzone>
                      </CenterImage>
                    </FormField>
                  </div>
                </FormSectionPadding>
              </ResourceContainer>

              <ResourceContainer>
                <FormSubHeading>Featured Resource 3:</FormSubHeading>
                <FormLabel>Name</FormLabel>
                <FormItalicHeading>Resource Name should not exceed 50 characters including spaces.</FormItalicHeading>
                <div>
                  <FormField>
                    <FormInputFull
                      value={this.state.resourceName3}
                      onChange={this.onResourceNameChange3}
                      type="text"
                      tabIndex="12"
                    />
                    {
                      this.state.resourceName3 &&
                      <FormInputCharCount>Char count: {this.state.resourceName3.length}</FormInputCharCount>
                    }
                  </FormField>
                </div>

                <FormSectionPadding>
                  <FormLabel>Resource Description</FormLabel>
                  <FormItalicHeading>The Featured Resource Descriptions should not exceed 600 characters, including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormTextArea
                        value={this.state.resourceDescription3}
                        onChange={this.onResourceDescriptionChange3}
                        type="text"
                        rows="10"
                        cols="10"
                        tabIndex="12"
                      />
                      {
                        this.state.resourceDescription3 &&
                        <FormInputCharCount>Char count: {this.state.resourceDescription3.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Button Text</FormLabel>
                  <FormItalicHeading>Not to exceed 15 characters including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonText3}
                        onChange={this.onResourceButtonTextChange3}
                        type="text"
                        tabIndex="13"
                      />
                      {
                        this.state.resourceButtonText3 &&
                        <FormInputCharCount>Char count: {this.state.resourceButtonText3.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Link URL</FormLabel>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonLink3}
                        onChange={this.onResourceButtonLinkChange3}
                        type="text"
                        tabIndex="14"
                      />
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Resource Image</FormLabel>
                  <FormItalicHeading>The submitted Resource Image should be exactly 300 pixels wide and 300 pixels tall.</FormItalicHeading>
                  <div>
                    <FormField>
                      <CenterImage>
                        <Dropzone
                          onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 3)}
                          multiple={false}
                          accept={acceptedFileTypes}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section tabIndex="15">
                              <ImageSelection {...getRootProps()}>
                                <input {...getInputProps()} />
                                <ABSmallButton title="File Upload" />
                                <UploadButtonContainer>
                                  {this.imageCheck3()}
                                  {
                                    this.state.imageSizeError3 &&
                                    <ValidationError>Invalid Image Size</ValidationError>
                                  }
                                </UploadButtonContainer>
                              </ImageSelection>
                            </section>
                          )}
                        </Dropzone>
                      </CenterImage>
                    </FormField>
                  </div>
                </FormSectionPadding>
              </ResourceContainer>

            </FormSection>

            <FormSection>

              <ResourceContainer>
                <FormSubHeading>Featured Resource 2:</FormSubHeading>
                <FormLabel>Name</FormLabel>
                <FormItalicHeading>Resource Name should not exceed 50 characters including spaces.</FormItalicHeading>
                <div>
                  <FormField>
                    <FormInputFull
                      value={this.state.resourceName2}
                      onChange={this.onResourceNameChange2}
                      type="text"
                      tabIndex="6"
                    />
                    {
                      this.state.resourceName2 &&
                      <FormInputCharCount>Char count: {this.state.resourceName2.length}</FormInputCharCount>
                    }
                  </FormField>
                </div>

                <FormSectionPadding>
                  <FormLabel>Resource Description</FormLabel>
                  <FormItalicHeading>The Featured Resource Descriptions should not exceed 600 characters, including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormTextArea
                        value={this.state.resourceDescription2}
                        onChange={this.onResourceDescriptionChange2}
                        type="text"
                        rows="10"
                        cols="10"
                        tabIndex="7"
                      />
                      {
                        this.state.resourceDescription2 &&
                        <FormInputCharCount>Char count: {this.state.resourceDescription2.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Button Text</FormLabel>
                  <FormItalicHeading>Not to exceed 15 characters including spaces.</FormItalicHeading>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonText2}
                        onChange={this.onResourceButtonTextChange2}
                        type="text"
                        tabIndex="8"
                      />
                      {
                        this.state.resourceButtonText2 &&
                        <FormInputCharCount>Char count: {this.state.resourceButtonText2.length}</FormInputCharCount>
                      }
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Link URL</FormLabel>
                  <div>
                    <FormField>
                      <FormInputFull
                        value={this.state.resourceButtonLink2}
                        onChange={this.onResourceButtonLinkChange2}
                        type="text"
                        tabIndex="9"
                      />
                    </FormField>
                  </div>
                </FormSectionPadding>

                <FormSectionPadding>
                  <FormLabel>Resource Image</FormLabel>
                  <FormItalicHeading>The submitted Resource Image should be exactly 300 pixels wide and 300 pixels tall.</FormItalicHeading>
                  <div>
                    <FormField>
                      <CenterImage>
                        <Dropzone
                          onDrop={(acceptedFiles) => this.handleOnDrop(acceptedFiles, null, 2)}
                          multiple={false}
                          accept={acceptedFileTypes}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section tabIndex="10">
                              <ImageSelection {...getRootProps()}>
                                <input {...getInputProps()} />
                                <ABSmallButton title="File Upload" />
                                <UploadButtonContainer>
                                  {this.imageCheck2()}
                                  {
                                    this.state.imageSizeError2 &&
                                    <ValidationError>Invalid Image Size</ValidationError>
                                  }
                                </UploadButtonContainer>
                              </ImageSelection>
                            </section>
                          )}
                        </Dropzone>
                      </CenterImage>
                    </FormField>
                  </div>
                </FormSectionPadding>
              </ResourceContainer>

            </FormSection>

          </FormContainer>

          <ActionButtons tabIndex="16">
            <div></div>
            <RightButton>
              <ABButton title="Save & Close" onClick={() => this.onNextClick(true)} />
            </RightButton>
          </ActionButtons>
          {
            (this.state.imageUploading) &&
            <UploadSpinner>
              <Spinner />
              <div>Please wait, this could take a few minutes. Please allow for additional time if your submission includes video files.</div>
            </UploadSpinner>
          }

        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.accountInfo.loading,
    error: state.accountInfo.error,
    errorMessage: state.accountInfo.errorMessage,
    microsite: state.microsite.data,
    micrositeSetting: state.micrositeSetting.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMicrosite: (id) => dispatch(getMicrosite(id)),
  getMicrositeSetting: (id) => dispatch(getMicrositeSetting(id)),
  updateMicrosite: (microsite, micrositeId) => dispatch(updateMicrosite(microsite, micrositeId)),
  updateMicrositeSubmission: (micrositeSubmission, micrositeId) => dispatch(updateMicrositeSubmission(micrositeSubmission, micrositeId)),
  saveSingleFile: (file, subFolder, mainFolder) => dispatch(saveSingleFile(file, subFolder, mainFolder)),
  setLocation: (page) => dispatch(setLocation(page)),
  updateMicrositeSetting: (micrositeSetting, micrositeId) => dispatch(updateMicrositeSetting(micrositeSetting, micrositeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);
