import React, { useState } from "react";
import { connect } from 'react-redux';
import { createAlertQuote, updateAlertQuote } from '../../../../actions/alert/alert-quote';
import styled from 'styled-components';
import { AlertQuote } from "../alert-types";
import Modal from 'react-modal';
import AlertQuoteForm from "../AlertQuoteForm";
import { createAlertHumor, updateAlertHumor } from "../../../../actions/alert/alert-humor";
import colors from '../../../../styles/colors';

interface ImageStyleProps {
  width?: number;
}

const ImageStyle = styled.div<ImageStyleProps>`
  text-align: center;
  img {
    width: ${props => props.width ? props.width : '100%'};
    /* width: 100%; */
  }
`;

const CitationStyle = styled.div`
  /* font-style: italic; */
  color: ${colors.gray300};
  font-size: 12px;
  p {
    padding: 0;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertQuote: AlertQuote;
  contentType: string;
  buttonTitle: string;
  createAlertQuote: (alertQuote: AlertQuote) => void;
  updateAlertQuote: (alertQuote: AlertQuote) => void;
  createAlertHumor: (alertQuote: AlertQuote) => void;
  updateAlertHumor: (alertQuote: AlertQuote) => void;
}

const AlertComponentB: React.FC<Props> = ({ alertItemId, alertQuote, contentType, buttonTitle, createAlertQuote, updateAlertQuote, createAlertHumor, updateAlertHumor }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertQuote, setNewAlertQuote] = useState<AlertQuote>({ id: 0, image: '', link: '', alertItemId: 0, attribution: '' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertQuote: AlertQuote) => {
    setNewAlertQuote(alertQuote);
    setModalIsOpen(true);
  }

  const handleSave = (alertQuote: AlertQuote) => {
    if (contentType === 'quote') {
      createAlertQuote(alertQuote);
    }
    if (contentType === 'humor') {
      createAlertHumor(alertQuote);
    }
    closeModal();
  }

  const handleUpdate = (alertQuote: AlertQuote) => {
    if (contentType === 'quote') {
      updateAlertQuote(alertQuote);
    }
    if (contentType === 'humor') {
      updateAlertHumor(alertQuote);
    }
    closeModal();
  }

  return (
    <>
      {
        (alertQuote && Object.keys(alertQuote).length > 0) ?
          <>
            {
              alertQuote.imageWidth &&
              <ImageStyle width={alertQuote.imageWidth}> <a href={alertQuote.link}><img src={`https://${alertQuote.image}`} alt="" width={alertQuote.imageWidth} /></a></ImageStyle>
            }
            {
              !alertQuote.imageWidth &&
              <ImageStyle> <a href={alertQuote.link}><img src={`https://${alertQuote.image}`} alt="" /></a></ImageStyle>
            }
            <CitationStyle>
              <div dangerouslySetInnerHTML={{ __html: alertQuote.citation || '' }} />
            </CitationStyle>
            <ButtonContainer>
              <EditButton onClick={() => handleEdit(alertQuote)}>Edit</EditButton>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertQuoteForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertQuote={newAlertQuote}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertQuote: state.alertQuote.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertQuote: (alertQuote: AlertQuote) => dispatch(createAlertQuote(alertQuote)),
  updateAlertQuote: (alertQuote: AlertQuote) => dispatch(updateAlertQuote(alertQuote)),
  createAlertHumor: (alertQuote: AlertQuote) => dispatch(createAlertHumor(alertQuote)),
  updateAlertHumor: (alertQuote: AlertQuote) => dispatch(updateAlertHumor(alertQuote)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertComponentB);