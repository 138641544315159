const alertAdsDefaultState = {
    data: [],
    singleAd: {},
    loading: false,
    error: false,
    success: false,
    successMessage: '',
    errorMessage: null
};

const alertAdsReducer = (state = alertAdsDefaultState, action) => {
    switch (action.type) {
        case 'START_GET_ALERT_ADS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
                success: false,
            };
        case 'GET_ALERT_ADS_SUCCESS':
            return {
                ...state,
                data: action.alertAds,
                loading: false,
                error: false,
                errorMessage: null,
            };
        case 'CREATE_ALERT_ADS_SUCCESS':
            return {
                data: [
                    ...state.data,
                    action.alertAds
                ],
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Ads Alert has been updated',
            };
        case 'START_ALERT_ADS_UPDATE':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'UPDATE_ALERT_ADS_SUCCESS':
            return {
                ...state,
                data: state.data.map((alertAdsArray) => {
                    if (alertAdsArray.id === action.alertAds.id) {
                        console.log('got match');
                        return {
                            ...alertAdsArray,
                            ...action.alertAds /// add object to array
                        }
                    } else {
                        return alertAdsArray;
                    }
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Alert Ads has been updated',
            };
        case 'START_REMOVE_ALERT_ADS':
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: null,
                successMessage: '',
            };
        case 'REMOVE_ALERT_ADS_SUCCESS':
            return {
                ...state,
                data: state.data.filter(item => { // check original posts and only include posts that don't have that to delete ID
                    return action.id !== item.id; // includes is checking inside an array of numbers to see if any match
                }),
                loading: false,
                success: true,
                error: false,
                errorMessage: null,
                successMessage: 'Your ads have been removed!',
            };
        case 'ALERT_ADS_CLEAR_SUCCESS':
            return {
                ...state,
                success: false
            };
        case 'SHOW_ERROR':
            return {
                ...state,
                loading: false,
                error: true,
                successMessage: '',
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default alertAdsReducer;