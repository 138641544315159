import axiosInstance from "../../utility/axios";

export const startGetAlertTed = () => ({
    type: 'START_GET_ALERT_TED',
  });
  
  export const getAlertTedSuccess = (alertTed) => ({
    type: 'GET_ALERT_TED_SUCCESS',
    alertTed
  });
  
  export const getAlertTed = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-ted/${alertItemId}`;
      console.log('get alertTed url: ', url);
      dispatch(startGetAlertTed());
      axiosInstance.get(url)
        .then((response) => {
            console.log('getAlertTed: ', response.data);
          dispatch(getAlertTedSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertTedCreate = () => ({
    type: 'START_ALERT_TED_CREATE',
});

export const createAlertTedSuccess = (alertTed) => ({
    type: 'CREATE_ALERT_TED_SUCCESS',
    alertTed
});

export const createAlertTed = (alertTed) => {
    console.log('do alert ted: ', alertTed);
    return (dispatch) => {
        dispatch(startAlertTedCreate());
        axiosInstance.post(`alert-ted`, alertTed)
            .then((response) => {
                console.log('Alert Ted Create: ', response.data);
                dispatch(createAlertTedSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertTedUpdate = () => ({
    type: 'START_ALERT_TED_UPDATE',
  });
  
  export const updateAlertTedSuccess = (alertTed) => ({
    type: 'UPDATE_ALERT_TED_SUCCESS',
    alertTed
  });
  
  export const updateAlertTed = (alertTed) => {
    console.log('alertTed: ', alertTed);
    return (dispatch) => {
      dispatch(startAlertTedUpdate());
      axiosInstance.put(`alert-ted/${alertTed.id}`, alertTed)
        .then((response) => {
          dispatch(updateAlertTedSuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});