import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from "moment";
import { AlertEvent, AlertItem } from "../alert-types";
import Modal from 'react-modal';
import AlertEventForm from "../AlertEventForm";
import colors from '../../../../styles/colors';
import AlertItemForm from "../AlertItemForm";
import { updateAlertItem } from "../../../../actions/alert/alert-item";

const TitleStyle = styled.div`
  line-height: 30px;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
`;

const DateStyle = styled.div`
  line-height: 27px;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
`;

const ImageStyle = styled.div`
  img {
    width: 100%;
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0;
  line-height: 22px;
  text-align: justify;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  a {
    text-decoration: underline;
    color: #154f91;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 0;
    :hover {
      color: black;
    }
  }
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  a {
    color: ${colors.gray400};
  }
  img {
    padding-right: 5px;
  }
`;

const EditRightContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const EditButton = styled.div`
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

const BlueTopBar = styled.div`
    background-color: rgb(71, 113, 167);
    padding: 10px;
`;

const BlueBarContainer = styled.div`
    /* width: 600px; */
    text-align: right;
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    padding: 0 15px 0 0;
    margin: 0;
`;

interface Props {
  alertItemId: number;
  alertItem: AlertItem;
  buttonTitle: string;
  updateAlertItem: (alertItem: AlertItem) => void;
}

const AlertItemComponent: React.FC<Props> = ({ alertItemId, alertItem, buttonTitle, updateAlertItem }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAlertItem, setNewAlertItem] = useState<AlertItem>({ id: 0, title: '', issue: 0, date: new Date, status: 'draft' });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '900px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertItem: AlertItem) => {
    setNewAlertItem(alertItem);
    setModalIsOpen(true);
  }

  const handleSave = (alertItem: AlertItem) => {
    updateAlertItem(alertItem);
    closeModal();
  }

  const handleUpdate = (alertItem: AlertItem) => {
    updateAlertItem(alertItem);
    closeModal();
  }

  return (
    <>
      {
        (alertItem && Object.keys(alertItem).length > 0) &&
        <>
          <BlueTopBar>
            <BlueBarContainer>
              Issue# {alertItem.issue} | {moment(alertItem.date).format('MMMM Do, YYYY')}
              &nbsp;&nbsp;
              <button onClick={() => handleEdit(alertItem)}>Edit</button>
            </BlueBarContainer>
          </BlueTopBar>
        </>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertItemForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertItem={newAlertItem}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertItem: state.alertItem.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  updateAlertItem: (alertItem: AlertItem) => dispatch(updateAlertItem(alertItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertItemComponent);