import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import AppRouter from "./routers/AppRouter";
import {signOut, startUserLogin} from "./actions/sign-in";
import './index.css';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.css';

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

const token = localStorage.getItem("token");

if (token) {
  if (window.location.pathname === '/') {
    store.dispatch(startUserLogin(null, null, token, true));
  } else {
    store.dispatch(startUserLogin(null, null, token, false));
  }
} else {
  store.dispatch(signOut());
}

ReactDOM.render(jsx, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
