import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { createAlertJob, updateAlertJob } from '../../../../actions/alert/alert-job';
import styled from 'styled-components';
import { AlertJob, Job } from "../alert-types";
import Modal from 'react-modal';
import AlertJobForm from "../AlertJobForm";
import colors from '../../../../styles/colors';
import { stringTruncate } from "../../../../utility/string-utils";
import { getJobs } from "../../../../actions/jobs";

const JobContainer = styled.div`
  display: flex;
`;

const TitleStyle = styled.div`
  line-height: 25px;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0 4px 0;
`;

const LocationStyle = styled.div`
  font-style: italic;
  padding-bottom: 5px;
  font-weight: bold;
`;

const ImageStyle = styled.div`
    padding-bottom: 10px;
  img {
    max-width: 140px;
  }
`;

const BodyStyle = styled.div`
  padding: 10px 0 0 0;
  line-height: 22px;
  p {
        margin: 0 0 15px 0;
        padding-right: 20px;
    }
`;

const CitationStyle = styled.div`
  font-style: italic;
  color: ${colors.gray300};
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LearnMoreLink = styled.div`
  border: 1px solid ${colors.linkBlue};
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
      opacity: .6;
    }
  a {
    text-decoration: none;
    color: ${colors.linkBlue};
    font-size: 14px;
  }
`;

const EditButton = styled.div`
  border: 1px solid red;
  padding: 3px 9px 6px 9px;
  border-radius: 10px;
  color: red;
  cursor: pointer;
`;

const AddNewButton = styled.div`
  color: red;
  cursor: pointer;
  text-align: right;
`;

interface Props {
  alertItemId: number;
  alertJob: AlertJob;
  buttonTitle: string;
  position: number;
  createAlertJob: (alertJob: AlertJob) => void;
  updateAlertJob: (alertJob: AlertJob) => void;
  getJobs: () => void;
  jobs: Job[];
}

const AlertJobComponent: React.FC<Props> = ({ alertItemId, alertJob, buttonTitle, position, createAlertJob, updateAlertJob, getJobs, jobs }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    getJobs();
  }, []);

  const initialJob: Job = {
    id: 0,
    jobTitle: '',
    description: '',
    location: '',
    companyLogo: '',
    company: '',
    link: ''
  };

  const [newAlertJob, setNewAlertJob] = useState<AlertJob>({ id: 0, alertItemId: 0, position: 0, attribution: '', job: initialJob });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px'
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEdit = (alertJob: AlertJob) => {
    setNewAlertJob(alertJob);
    setModalIsOpen(true);
  }

  const handleSave = (alertJob: AlertJob) => {
    createAlertJob(alertJob);
    closeModal();
  }

  const handleUpdate = (alertJob: AlertJob) => {
    updateAlertJob(alertJob);
    closeModal();
  }

  return (
    <>
      {
        alertJob ?
          <>
            <JobContainer>
              <div>
                <TitleStyle>{alertJob.job?.jobTitle}</TitleStyle>
                <LocationStyle>{alertJob.job?.location}</LocationStyle>
                <BodyStyle><div dangerouslySetInnerHTML={{ __html: stringTruncate(alertJob.job?.description, 350) }} /></BodyStyle>
              </div>
              <ImageStyle><img src={`https://${alertJob.job?.companyLogo}`} alt="" /></ImageStyle>
            </JobContainer>
            <ButtonContainer>
              <LearnMoreLink><a href={alertJob.job?.link}>Learn More</a></LearnMoreLink>
              <EditButton onClick={() => handleEdit(alertJob)}>Edit</EditButton>
            </ButtonContainer>
          </>
          : <AddNewButton onClick={openModal}>{buttonTitle}</AddNewButton>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AlertJobForm
          handleSave={handleSave}
          alertItemId={alertItemId}
          alertJob={newAlertJob}
          handleUpdate={handleUpdate}
          pageTitle={buttonTitle}
          jobs={jobs}
          position={position}
        />
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    //   alertJob: state.alertJob.data
    // loading: state.accountInfo.loading,
    // error: state.accountInfo.error,
    // errorMessage: state.accountInfo.errorMessage,
    // microsite: state.microsite.data,
    jobs: state.jobs.data
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  createAlertJob: (alertJob: AlertJob) => dispatch(createAlertJob(alertJob)),
  updateAlertJob: (alertJob: AlertJob) => dispatch(updateAlertJob(alertJob)),
  getJobs: () => dispatch(getJobs()) // pull all the jobs in to select from
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertJobComponent);