const micrositeKeywordsDefaultState = {
  data: {},
  loading: false,
  error: false,
  success: false,
  successMessage: '',
  errorMessage: null,
};

const micrositeKeywordReducer = (state = micrositeKeywordsDefaultState, action) => {
  switch(action.type) {
    case 'START_GET_MICROSITE_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'GET_MICROSITE_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: action.micrositeKeywords,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case 'START_CREATE_MICROSITE_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'CREATE_MICROSITE_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: action.micrositeKeyword,
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: 'Your account has been created!',
      };
    case 'START_UPDATE_MICROSITE_KEYWORDS':
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'UPDATE_MICROSITE_KEYWORDS_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.micrositeKeyword
        },
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
        successMessage: '',
      };
    case 'SHOW_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        successMessage: '',
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export default micrositeKeywordReducer;