import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';
import {postDocuments} from './posts';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const startGetAd = () => ({
  type: 'START_GET_AD',
});

export const getAdSuccess = (singleAd) => ({
  type: 'GET_AD_SUCCESS',
  singleAd
});

export const getAd = (id) => {
  console.log('getting ad');
  return (dispatch) => {
    dispatch(startGetAd());
    axiosInstance.get(`ad/${id}`)
      .then((response) => {
        if (response.data.ad === false) { // no post yet
        } else { // has post
          console.log('response.data getAd: ', response.data);
          dispatch(getAdSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startGetAds = () => ({
  type: 'START_GET_ADS',
});

export const getAdsSuccess = (ads) => ({
  type: 'GET_ADS_SUCCESS',
  ads
});

export const getAds = (queryParams) => {
  return (dispatch) => {
    let url = 'ads';
    if (queryParams) {
      url = `ads/${queryParams}`;
    }
    console.log('get ads url: ', url);
    dispatch(startGetAds());
    axiosInstance.get(url)
      .then((response) => {
        dispatch(getAdsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startCreateAd = () => ({
  type: 'START_CREATE_AD'
});

export const createAdSuccess = (adDetails) => ({
  type: 'CREATE_AD_SUCCESS',
  adDetails
});

export const createAd = (adDetails) => {
  console.log('creating ad', adDetails);
  return (dispatch) => {
    dispatch(startCreateAd());
    axiosInstance.post('ad', adDetails)
      .then((response) => {
        dispatch(createAdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startAdUpdate = () => ({
  type: 'START_AD_UPDATE',
});

export const updateAdSuccess = (adDetails) => ({
  type: 'UPDATE_AD_SUCCESS',
  adDetails
});

export const updateAd = (adDetails, adId) => {
  return (dispatch) => {
    dispatch(startAdUpdate());
    axiosInstance.put(`ad/${adId}`, adDetails)
      .then((response) => {
        dispatch(updateAdSuccess(adDetails));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startRemoveAds = () => ({
  type: 'START_REMOVE_ADS'
});

export const removeAdsSuccess = (adIds) => ({
  type: 'REMOVE_ADS_SUCCESS',
  adIds
});

export const removeAds = (adIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: adIds
    };
    dispatch(startRemoveAds());
    axiosInstance.delete('ads', { data: items })
      .then((response) => {
        dispatch(removeAdsSuccess(adIds));
        console.log('ads removed!');
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

