import axiosInstance from '../utility/axios';
import {POST_TYPE_NAME} from '../utility/constants';
import {postDocuments} from './posts';
import {createMediaTags} from './media-tags';

export const showError = (errorMessage) => ({
  type: 'SHOW_ERROR',
  errorMessage
});

export const showDeleteError = (errorMessage) => ({
  type: 'SHOW_DELETE_ERROR',
  errorMessage
});

export const startGetMediaImage = () => ({
  type: 'START_GET_MEDIA_IMAGE',
});

export const getMediaImageSuccess = (singleMediaImage) => ({
  type: 'GET_MEDIA_IMAGE_SUCCESS',
  singleMediaImage
});

export const getMediaImage = (id) => {
  console.log('getting mediaImage');
  return (dispatch) => {
    dispatch(startGetMediaImage());
    axiosInstance.get(`media-image/${id}`)
      .then((response) => {
        if (response.data.mediaImage === false) { // no post yet
        } else { // has post
          dispatch(getMediaImageSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startGetMediaImages = () => ({
  type: 'START_GET_MEDIA_IMAGES',
});

export const getMediaImagesSuccess = (mediaImages) => ({
  type: 'GET_MEDIA_IMAGES_SUCCESS',
  mediaImages
});

export const getMediaImages = (title, tag, limit, skip, category) => {
  return (dispatch) => {
    let url = `media-images?limit=${limit}&skip=${skip}`;
    if (tag && title && tag) {
      url = `media-images?tag=${tag}&title=${title}&category=${category}&limit=${limit}&skip=${skip}`;
    } else if (tag && title) {
      url = `media-images?tag=${tag}&title=${title}&limit=${limit}&skip=${skip}`;
    } else if (tag && category) {
      url = `media-images?tag=${tag}&category=${category}&limit=${limit}&skip=${skip}`;
    } else if (title && category) {
      url = `media-images?title=${title}&category=${category}&limit=${limit}&skip=${skip}`;
    } else if (tag) {
      url = `media-images?tag=${tag}&limit=${limit}&skip=${skip}`;
    } else if (title) {
      url = `media-images?title=${title}&limit=${limit}&skip=${skip}`;
    } else if (category) {
      url = `media-images?category=${category}&limit=${limit}&skip=${skip}`;
    } else if (limit && skip) {
      url = `media-images?limit=${limit}&skip=${skip}`;
    }
    console.log('url: ', url);
    dispatch(startGetMediaImages());
    axiosInstance.get(url)
      .then((response) => {
        console.log('media images: ', response.data);
        dispatch(getMediaImagesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startUploadMediaImage = () => ({
  type: 'START_MEDIA_IMAGE_UPLOAD'
});

export const uploadMediaImageSuccess = (mediaImageDetails) => ({
  type: 'MEDIA_IMAGE_UPLOAD_SUCCESS',
  mediaImageDetails
});

export const uploadMediaImage = (mediaImageDetails, mediaTags) => {
  console.log('mediaTags in uploadMediaImage: ', mediaTags);
  return (dispatch) => {
    dispatch(startUploadMediaImage());
    axiosInstance.post('media-image', mediaImageDetails)
      .then((response) => {
        console.log('should do media image upload: ', response.data);
        dispatch(uploadMediaImageSuccess(response.data));
        if (mediaTags) {
          console.log('should create media tags on post: ', mediaTags);
          dispatch(createMediaTags(mediaTags, response.data.id));
        }
      })
      .catch((error) => {
        dispatch(showError());
        console.log(error);
      });
  }
};

export const startMediaImageUpdate = () => ({
  type: 'START_MEDIA_IMAGE_UPDATE',
});

export const updateMediaImageSuccess = (mediaImageDetails) => ({
  type: 'UPDATE_MEDIA_IMAGE_SUCCESS',
  mediaImageDetails
});

export const updateMediaImage = (mediaImageDetails, mediaImageId) => {
  return (dispatch) => {
    dispatch(startMediaImageUpdate());
    axiosInstance.put(`media-image/${mediaImageId}`, mediaImageDetails)
      .then((response) => {
        dispatch(updateMediaImageSuccess(response.data));
      })
      .catch((error) => {
        dispatch(showError(error));
        console.log(error);
      });
  }
};

export const startRemoveMediaImages = () => ({
  type: 'START_REMOVE_MEDIA_IMAGES'
});

export const removeMediaImagesSuccess = (mediaImageIds) => ({
  type: 'REMOVE_MEDIA_IMAGES_SUCCESS',
  mediaImageIds
});

export const removeMediaImages = (mediaImageIds) => {
  return (dispatch) => {
    const items = {
      itemsToDelete: mediaImageIds
    };
    dispatch(startRemoveMediaImages());
    axiosInstance.delete('media-images', { data: items })
      .then((response) => {
        dispatch(removeMediaImagesSuccess(mediaImageIds));
        console.log('mediaImages removed!');
      })
      .catch((error) => {
        dispatch(showDeleteError());
        console.log(error);
      });
  }
};

