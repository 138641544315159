import axiosInstance from "../../utility/axios";

export const startGetAlertDiscovery = () => ({
    type: 'START_GET_ALERT_DISCOVERY',
  });
  
  export const getAlertDiscoverySuccess = (alertDiscovery) => ({
    type: 'GET_ALERT_DISCOVERY_SUCCESS',
    alertDiscovery
  });
  
  export const getAlertDiscovery = (alertItemId) => {
    return (dispatch) => {
      let url = `alert-discovery/${alertItemId}`;
    //   console.log('get alertDiscovery url: ', url);
      dispatch(startGetAlertDiscovery());
      axiosInstance.get(url)
        .then((response) => {
            // console.log('getAlertDiscovery: ', response.data);
          dispatch(getAlertDiscoverySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const startAlertDiscoveryCreate = () => ({
    type: 'START_ALERT_DISCOVERY_CREATE',
});

export const createAlertDiscoverySuccess = (alertDiscovery) => ({
    type: 'CREATE_ALERT_DISCOVERY_SUCCESS',
    alertDiscovery
});

export const createAlertDiscovery = (alertDiscovery) => {
    // console.log('do alert discovery: ', alertDiscovery);
    return (dispatch) => {
        dispatch(startAlertDiscoveryCreate());
        axiosInstance.post(`alert-discovery`, alertDiscovery)
            .then((response) => {
                console.log('Alert Discovery Create: ', response.data);
                dispatch(createAlertDiscoverySuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startAlertDiscoveryUpdate = () => ({
    type: 'START_ALERT_DISCOVERY_UPDATE',
  });
  
  export const updateAlertDiscoverySuccess = (alertDiscovery) => ({
    type: 'UPDATE_ALERT_DISCOVERY_SUCCESS',
    alertDiscovery
  });
  
  export const updateAlertDiscovery = (alertDiscovery) => {
    // console.log('alertDiscovery: ', alertDiscovery);
    return (dispatch) => {
      dispatch(startAlertDiscoveryUpdate());
      axiosInstance.put(`alert-discovery/${alertDiscovery.id}`, alertDiscovery)
        .then((response) => {
          dispatch(updateAlertDiscoverySuccess(response.data));
        })
        .catch((error) => {
          dispatch(showError(error));
          console.log(error);
        });
    }
  };

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});